import { Box, Button, IconButton, TextField } from "@mui/material";
import { IconTrashX } from "@tabler/icons-react";
import { useState } from "react";

const GlobalVars = ({ app, onSave, handleClose }: any) => {
  const [vars, setVars] = useState<any[]>(app?.appConfig?.vars || []);

  const addField = () => {
    const newField = { id: Date.now(), varKey: "", type: "string", varValue: "" };
    setVars(prevFields => {
      const updatedVars = [...prevFields, newField];
      return updatedVars;
    });
  };
  const removeField = (id: number) => {
    setVars(prevFields => {
      const updatedVars = prevFields.filter(field => field.id !== id);
      return updatedVars;
    });
  };

  const handleFieldChange = (id: number, newValue: string, name) => {
    setVars(prevFields => {
      const updatedVars = prevFields.map(field => (field.id === id ? { ...field, [name]: newValue } : field));
      return updatedVars;
    });
  };

  const handleSave = () => {
    onSave(vars, app);
    handleClose();
  };

  return (
    <Box sx={{ p: 1 }}>
      {vars.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              mb: 2,
            }}
          >
            <TextField
              label={`Key ${index + 1}`}
              variant='outlined'
              size='medium'
              value={field.varKey}
              onChange={e => handleFieldChange(field.id, e.target.value, "varKey")}
              sx={{ width: "45%" }}
            />
            <TextField
              label={`Value ${index + 1}`}
              variant='outlined'
              size='medium'
              value={field.varValue}
              onChange={e => handleFieldChange(field.id, e.target.value, "varValue")}
              sx={{ width: "45%" }}
            />
          </Box>

          <IconButton aria-label='delete' color='error' onClick={() => removeField(field.id)} sx={{ ml: 1 }}>
            <IconTrashX color='red' />
          </IconButton>
        </Box>
      ))}

      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
        <Button variant='contained' onClick={addField}>
          Add Var
        </Button>
        <Button variant='contained' onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default GlobalVars;
