import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { IconTrashX } from "@tabler/icons-react";
import _ from "lodash";
import { FC } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import * as yup from "yup";

type ScriptArgsModalProps = {
  scriptArgs?: any[];
  onSave: any;
  onCancel?: Function;
};

export const ScriptArgsModal: FC<ScriptArgsModalProps> = ({ scriptArgs, onCancel = _.noop, onSave = _.noop }) => {
  const schema = yup
    .object({
      args: yup
        .array()
        .of(
          yup.object({
            scriptArgValue: yup.string().required("Script Arg Value is required"),
          })
        )
        .required("At least one script argument is required"),
    })
    .required();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      args: scriptArgs?.length ? scriptArgs : [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "args",
  });

  return (
    <Box component='form' noValidate autoComplete='off' mt={2}>
      {fields.length === 0 && (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <Typography>No Script Args, add one below</Typography>
          </Grid>
        </Grid>
      )}
      {fields?.map((scriptArgItem, scriptArgIndex) => (
        <Grid container spacing={2} mb={2} key={scriptArgItem.id}>
          <Grid item xs={10}>
            <BXInput
              fullWidth
              size='small'
              label='Script Arg Value'
              name={`args.${scriptArgIndex}.scriptArgValue`}
              control={control}
              error={errors?.args?.[scriptArgIndex]?.scriptArgValue}
              value={watch(`args.${scriptArgIndex}.scriptArgValue`)}
            />
          </Grid>
          <Grid item justifyContent='center' alignItems='center' xs={2}>
            <IconButton onClick={() => remove(scriptArgIndex)}>
              <IconTrashX color='red' size={18} />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid container justifyContent='center' alignItems='center' spacing={2} mt={2}>
        <Grid item>
          <Button variant='outlined' onClick={() => append({ scriptArgValue: "" })}>
            Add Script Arg
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit(data => {
              onSave(data);
            })}
            variant='contained'
            aria-label={"save"}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScriptArgsModal;
