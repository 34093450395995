import { Box } from "@mui/material";
import { FC, useRef } from "react";
import { CustomContainerProps } from "./types";

export const TableContainer: FC<CustomContainerProps> = props => {
  const { config, __config, metaData, isInfiniteScroll, ...restProps } = props;
  const boxRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box {...restProps} ref={boxRef} onScroll={() => {}}>
      {props?.children}
    </Box>
  );
};
