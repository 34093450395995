import { Box, FormHelperText } from "@mui/material";
import { joinObjects } from "hd-utils";
import _ from "lodash";
import { selectComponentById } from "src/features/endUser/selectors";
import store from "src/store/store";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";
import ViewLink from "../ViewLink/ViewLink";
import ChildrenRenderer from "./ChildrenRenderer";
import { handleChange, handleClick, handleKeyDown } from "./utils";

export const RenderComponent = props => {
  const {
    id,
    boxStyles,
    element,
    leftDrawerOpened,
    hiddenByStepper,
    componentVisibility,
    toolTipParentProps,
    overlay,
    resolvedProps,
    Component,
    ref,
    isComponentDisabled,
    metaData,
    pageId,
    collectionId,
    info,
    views,
    pageOutlet,
    handleLeftDrawerToggle,
    produceTrigger,
    index,
    parentKey,
    stripe,
    dataEntry,
    viewName,
    dataSource,
    isRepeated,
    __data,
    parentIds,
    closeModal,
    handleSelectRow,
    path,
    parentDataIndex,
    replaceDataPlaceholders,
    currentApp,
    multiLingual,
    item,
    CheckToolTipParent,
    viewsState,
    getValues,
    viewNameOption,
    disablePageDirty,
    repeatedParentKey,
    enableDirtyCheck,
    isContainer,
    isControlled,
    error,
    value,
    onChange: handleControlledChange,
    pageIdFromLayout,
  } = props;

  const dynamicProps = isControlled
    ? {
        error,
        value,
        onKeyDown: event => handleKeyDown(event, element, element?.props?.key, pageId, info?.viewName, viewsState, produceTrigger),
        onChange: (event: any, disabledDirty?: boolean) =>
          handleChange(
            event,
            element,
            handleControlledChange,
            element?.props?.key,
            pageId,
            props?.info?.viewName,
            viewsState,
            disabledDirty,
            produceTrigger
          ),
        onClick: event => handleClick(event, element, pageId, props?.info?.viewName, viewsState, produceTrigger),
      }
    : {};

  return (
    <Box
      sx={joinObjects(boxStyles, {
        height: element?.config?.isDynamicHeight
          ? "auto"
          : [ComponentItemType.GridContainer].includes(
              selectComponentById(store.getState(), pageId, info?.viewName, element?.parentKey)?.type
            )
          ? "100%"
          : !element?.config?.isPercentageHeight
          ? element?.config?.heightPx
          : element?.config?.heightPercentage,
        overflow: element?.config?.overflow || "visible",
        ...((!leftDrawerOpened && element.type === ComponentItemType.BXSideBar) || hiddenByStepper ? { display: "none" } : {}),
        contentVisibility: componentVisibility,
        ...(element?.config?.transition?.enabled && {
          transition: "all 0.5s ease",
        }),
        ...(element?.config?.position?.enabled || element?.config?.sticky?.enabled
          ? {
              position: element?.config?.position?.type || "sticky",
              top: element?.config?.position?.top || element?.config?.sticky?.top,
              right: element?.config?.position?.right || element?.config?.sticky?.right,
              left: element?.config?.position?.left || element?.config?.sticky?.left,
              bottom: element?.config?.position?.bottom || element?.config?.sticky?.bottom,
              zIndex: (element?.config?.position?.zIndex || element?.config?.sticky?.zIndex) ?? 1200,
            }
          : {}),
        ...(element?.isModalComponent
          ? {
              position: element?.props?.sx?.visibility === "visible" ? "fixed" : "unset",
              top: element?.props?.sx?.visibility === "visible" ? 0 : "unset",
              left: element?.props?.sx?.visibility === "visible" ? 0 : "unset",
              width: element?.props?.sx?.visibility === "visible" ? "100vw" : "auto",
              height: element?.props?.sx?.visibility === "visible" ? "100vh" : "auto",
              zIndex: 9999,
              opacity: element?.props?.sx?.visibility === "visible" ? 1 : 0,
            }
          : {}),
      })}
      aria-invalid={!!error}
    >
      <CheckToolTipParent {...toolTipParentProps}>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          {overlay}
          <>
            {isControlled && <ViewLink collectionId={collectionId} pageId={pageId} id={id} componentId={element?.id} />}
            <Component
              {...resolvedProps}
              hover={element?.config?.hover}
              appId={currentApp?.id}
              config={element?.config}
              __config={element?.config}
              $views={views}
              $parentRef={ref}
              $configData={element?.configData}
              $pageOutlet={pageOutlet}
              $drawerOpen={leftDrawerOpened}
              $drawerToggle={handleLeftDrawerToggle}
              loading={element?.props?.enableLoading ? element?.isLoading : false}
              disabled={isComponentDisabled}
              metaData={metaData}
              pageId={pageId}
              collectionId={collectionId}
              info={info}
              id={element?.props?.testId}
              renderId={"renderedView"}
              data-bx-key={`${pageId}.${props?.info?.viewName}.${element?.props?.key}`}
              data-testid={element?.props?.testId}
              isLoadingImage
              {...dynamicProps}
              _sx={resolvedProps?.sx}
            >
              {!element?.children?.length ? (
                resolvedProps.children
              ) : (
                <ChildrenRenderer
                  id={id}
                  element={element}
                  stripe={stripe}
                  index={index}
                  parentKey={parentKey}
                  isComponentDisabled={isComponentDisabled}
                  dataEntry={dataEntry}
                  viewName={viewName}
                  dataSource={dataSource}
                  isRepeated={isRepeated}
                  __data={__data}
                  pageId={pageId}
                  collectionId={collectionId}
                  getValues={getValues}
                  parentIds={parentIds}
                  closeModal={closeModal}
                  info={info}
                  pageOutlet={pageOutlet}
                  handleSelectRow={handleSelectRow}
                  path={path}
                  parentDataIndex={parentDataIndex}
                  pageIdFromLayout={pageIdFromLayout}
                />
              )}
            </Component>

            {!!error && !element?.props?.isChildContainerGroup && (element?.props?.showError ?? true) && (
              <FormHelperText error>
                {replaceDataPlaceholders({
                  obj: _.cloneDeep(element?.props),
                  queryString: error,
                  validationRules: element?.props,
                  item,
                  viewsState,
                  index,
                  pageId,
                  __data,
                  env: currentApp?.env,
                  fallback: "",
                  multiLingual: multiLingual,
                })}
              </FormHelperText>
            )}
          </>
        </div>
      </CheckToolTipParent>
    </Box>
  );
};
