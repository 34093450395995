import { Box } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { useAppState } from "src/features/appState/hooks";
import { CustomContainerProps } from "./types";

export const CustomContainer: FC<CustomContainerProps> = props => {
  const {
    config,
    __config,
    metaData,
    onChange,
    info,
    pageId,
    appId,
    renderId,
    "data-bx-key": dataBxKey,
    background,
    border,
    extraSmall,
    small,
    medium,
    large,
    extraLarge,
    placeContent,
    placeItems,
    infiniteScroll,
    hover,
    ...restProps
  } = props;
  const boxRef = useRef<HTMLDivElement | null>(null);
  const { watch, produceTrigger } = useAppState();
  const { viewName } = metaData || {};
  const keyComp = props?.children?.props?.element?.props?.key;
  const elementKey = `${pageId}.${viewName}.${keyComp}`;
  const InfoForElement = watch(`${elementKey}.data._pagination.nextCursor`, { pageId, viewName });

  const handleScroll = async () => {
    if (boxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = boxRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        if (infiniteScroll) {
          await new Promise((resolve, reject) => {
            produceTrigger(
              elementKey,
              "onReach",
              {
                resolver: resolve,
              },
              { pageId, viewName }
            );
          });
        }
      }
    }
  };

  useEffect(() => {
    if (infiniteScroll) {
      handleScroll();
    }
  }, [InfoForElement]);

  return (
    <Box
      {...restProps}
      sx={{ ...(background && { background: background }), ...(border && { border: border }), ...(hover?.styles && { "&:hover": { ...hover.styles } }), }}
      ref={boxRef}
      onScroll={handleScroll}
    >
      {props?.children}
    </Box>
  );
};
