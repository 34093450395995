import React from "react";
import AnimateButton from "src/components/extended/AnimateButton";

export const CustomGoogleLoginButton: React.FC<any> = props => {
  return (
    <AnimateButton>
      <img
        src={require("src/assets/images/btn_google_signin.png")}
        style={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
        alt='Sign in with Google'
      />
    </AnimateButton>
  );
};
