import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setViewBuilder, stepBackState, stepForwardState } from "src/features/builder/builderSlice";
import { selectAllComponentsArray, selectComponentById, selectTopLevelComponents } from "src/features/builder/selectors";
import store from "src/store/store";
import { findObjectsByIds, getNewPosition, moveBoxFunction } from "./utils";
import { readFromClipboard } from "src/utils/clipboardUtils";

interface FormBuilderListenerProps {
  handleDeleteItem: (ids: string[]) => void;
  handleCopyItems: (activeComponentId: string | undefined, selectedItemsId: string[]) => void;
  handlePasteItems: () => void;
  handleKeyCodeChange: (newKeyCode: string | undefined) => void;
  componentId: string[];
  boxItemRefs: any;
}
const FormBuilderListener: FC<FormBuilderListenerProps> = ({
  handleDeleteItem,
  handleCopyItems,
  handlePasteItems,
  handleKeyCodeChange,
  componentId,
  boxItemRefs,
}) => {
  const [keyCode, setKeyCode] = useState<string>();
  const [keyboardStep, setKeyboardStep] = useState(1);
  const [mouseStep, setMouseStep] = useState(10);
  const isKeyboardActive = useSelector((state: any) => state.builder.isKeyboardActive);

  const activeComponent = useSelector((state: any) => state.builder.activeComponent);
  const selectedItemsId = useSelector((state: any) => state.builder.selectedItemsId);
  const containerItem = useSelector(state => selectComponentById(state, componentId));

  const dispatch = useDispatch();
  const stepBack = useCallback(() => {
    dispatch(stepBackState());
    dispatch(setViewBuilder({ selectedItemsId: [], addToHistory: false }));
  }, []);

  const stepForward = useCallback(() => {
    dispatch(stepForwardState());
    dispatch(setViewBuilder({ selectedItemsId: [], addToHistory: false }));
  }, []);
  useEffect(() => {
    if (isKeyboardActive) {
      if (keyCode && keyCode !== "Delete" && keyCode !== "Select" && keyCode !== "copy" && keyCode !== "paste") {
        const builderElements = selectAllComponentsArray(store.getState());
        const filterElement = builderElements.filter((el: any) => {
          if (el.parentId) {
            const parentItem = selectComponentById(store.getState(), el.parentId);
            if (parentItem.type != "FlexContainer" && parentItem.type != "GridContainer") {
              return el;
            } else {
              return false;
            }
          } else {
            return el;
          }
        });

        const item = findObjectsByIds(filterElement, selectedItemsId?.length > 0 ? selectedItemsId : [activeComponent]);
        const topLevel = selectTopLevelComponents(store.getState());
        const elements = selectComponentById(store.getState(), topLevel);
        item?.length > 0 &&
          moveBoxFunction(
            item,
            boxItemRefs?.current[topLevel[0]],
            elements,
            null,
            getNewPosition(keyCode, keyboardStep),
            true,
            keyboardStep
          ); // need to more work pass the correct props
      }
      if (keyCode === "Delete") {
        if (selectedItemsId.length) {
          handleDeleteItem(selectedItemsId);
          dispatch(setViewBuilder({ selectedItemsId: [], addToHistory: false }));
        } else if (activeComponent) {
          handleDeleteItem([activeComponent]);
          dispatch(setViewBuilder({ selectedItemsId: [], addToHistory: false }));
        }
      }
      if (keyCode === "copy") {
        handleCopyItems(activeComponent, selectedItemsId);
      }
      if (keyCode === "paste") {
        handlePasteItems();
        dispatch(setViewBuilder({ selectedItemsId: [], addToHistory: false }));
      }
    }
  }, [keyCode, selectedItemsId?.length]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Delete" || e.key === "." || e.key === "Backspace") {
        setKeyCode("Delete");
        handleKeyCodeChange("Delete");
      }
      if (e.key === "Shift") {
        setKeyboardStep(10);
        setMouseStep(step => (step === 1 ? 10 : 1));
        handleKeyCodeChange("Shift");
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "c") {
        setKeyCode("copy");
        handleKeyCodeChange("copy");
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "v") {
        setKeyCode("paste");
        handleKeyCodeChange("paste");
      }
      if (e.ctrlKey || e.metaKey || e.key === "Control") {
        setKeyCode("Select");
        handleKeyCodeChange("Select");
      }
      if (getNewPosition(e.key, keyboardStep)) {
        setKeyCode(e.key);
        handleKeyCodeChange(e.key);
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "z" && !e.shiftKey) {
        e.preventDefault();

        stepBack();
      }
      if ((e.ctrlKey && e.key === "y") || (e.metaKey && e.shiftKey && e.key === "z")) {
        e.preventDefault();
        stepForward();
      }

      return true;
    },
    [keyboardStep, stepBack, stepForward]
  );

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setKeyboardStep(1);
    }
    setKeyCode(undefined);
    handleKeyCodeChange(undefined);
  }, []);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    document.body.addEventListener("keyup", handleKeyUp);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  }, [activeComponent, stepBack, stepForward]);

  return null;
};

export default FormBuilderListener;
