import _ from "lodash";
import { BXApp } from "src/types/BXAppType";
import { compressData, decompressData } from "src/utils/services";

export function migrateTo1_0_6(_app: BXApp) {
  const app: any = {
    ..._app,
    appConfig: decompressData(_app?.appConfig),
    templateConfig: decompressData(_app?.templateConfig),
    upTemplateConfig: decompressData(_app?.upTemplateConfig),
  };

  traverseApp(app);
  _.set(app, "appVersion", "1.0.6");

  const compressedApp = {
    ...app,
    appConfig: compressData(app?.appConfig),
    templateConfig: compressData(app?.templateConfig),
    upTemplateConfig: compressData(app?.upTemplateConfig),
  };

  return compressedApp;
}

function traverseApp(obj, currentPath = "", isParentRepeated = null) {
  if (obj === null) {
    return null;
  }

  if (Array.isArray(obj)) {
    return obj.map((el, index) => {
      const path = `${currentPath}[${index}]`;
      return traverseApp(el, path, isParentRepeated);
    });
  }

  if (typeof obj === "object") {
    const isRepeatedEnabled = obj?.config?.repeated?.enabled;
    const computedIsRepeated: any = isRepeatedEnabled ? (isParentRepeated !== null ? isParentRepeated : true) : isParentRepeated;

    // Only add isRepeated property if non-null
    if (computedIsRepeated !== null) {
      obj.isRepeated = computedIsRepeated;
    } else {
      delete obj.isRepeated;
    }

    // Process children to propagate isRepeated (directly pass computedIsRepeated)
    const children = Array.isArray(obj.children) ? obj.children : [];
    children.forEach(child => {
      traverseApp(child, `${currentPath}.children`, computedIsRepeated);
    });

    // Traverse other properties without propagating isRepeated
    Object.keys(obj).forEach(key => {
      if (key !== "children") {
        traverseApp(obj[key], `${currentPath}.${key}`, null);
      }
    });

    return obj;
  }

  return obj;
}
