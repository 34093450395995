import { lazy } from "react";

// project imports
import Loadable from "src/components/Loadable";
import MinimalLayout from "src/layout/MinimalLayout";
import NavMotion from "src/layout/NavMotion";
import GuestGuard from "src/utils/route-guard/GuestGuard";

// login routing
const AuthLogin = Loadable(lazy(() => import("src/views/pages/authentication/login")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = [
  {
    path: "login",
    element: <MinimalLayout />,
    children: [
      {
        path: "",
        element: (
          <NavMotion>
            <GuestGuard>
              <AuthLogin />
            </GuestGuard>
          </NavMotion>
        ),
      },
    ],
  },
  {
    path: "admin/login",
    element: <MinimalLayout />,
    children: [
      {
        path: "",
        element: (
          <NavMotion>
            <GuestGuard>
              <AuthLogin />
            </GuestGuard>
          </NavMotion>
        ),
      },
    ],
  },
  {
    path: "auth/google",
    element: <MinimalLayout />,
    children: [
      {
        path: "",
        element: (
          <NavMotion>
            <GuestGuard>
              <AuthLogin />
            </GuestGuard>
          </NavMotion>
        ),
      },
    ],
  },
  {
    path: "auth/shopify",
    element: <MinimalLayout />,
    children: [
      {
        path: "",
        element: (
          <NavMotion>
            <GuestGuard>
              <AuthLogin />
            </GuestGuard>
          </NavMotion>
        ),
      },
    ],
  },
];

export default LoginRoutes;
