import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import _ from "lodash";
import React, { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { MUI_COMPONENTS } from "./FormBuilderEditor";

import axios from "axios";
import { joinObjects } from "hd-utils";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useQuery } from "react-query";
import { ResizeElement } from "src/components/ResizeElement";
import { updateComponent } from "src/features/builder/builderSlice";
import { selectComponentById } from "src/features/builder/selectors";
import { BXEngine } from "../../../../BXEngine";
import store from "../../../../store/store";
import ActionPopover from "./BuilderComponentsTree/ActionPopover";
import { StripeContainer } from "./components/StripeComponents/StripeContainer";
import ParentDragElement from "./ParentDragElement";
import { ComponentItemType } from "./types";
import {
  chartsNames,
  componentsHaveChildren,
  getBuilderElementById,
  getItemClosestProp,
  isParentFlex,
  isParentGrid,
  mapValuesRecursively,
  resolveResponsiveProperties,
  resolveSxStylingInBuilder,
  stepperCount,
} from "./utils";

interface DragElementProps {
  setActiveComponent?: (item: any) => void;
  handleCopyItems?: (activeComponentId: string | undefined, selectedItemsId: string[]) => void;
  handleDeleteItem?: (itemId: any) => void;
  handlePasteItems?: (targetElementId?: string) => void;
  // setIsContainerDragging?: (e: boolean) => void;
  itemIndex?: number;
  componentId?: number;
  className?: string;
  active?: boolean;
  canDrag?: boolean;
  resizable?: boolean;
  hideSource?: boolean;
  isSideMenu?: boolean;
  boxHeightRef?: any;
  item?: any;
  children?: any;
  path?: any;
  onLayout?: any;
  boxItemRefs?: any;
  canDropItem?: any;
  isOverCurrentComponents?: any;
  $views?: any;
  copyItemsRef?: any;
  getCanvasDimension?: any;
}
export const DragElement = memo((props: DragElementProps) => {
  const {
    itemIndex,
    item: _item,
    componentId,
    className,
    active,
    canDrag,
    children,
    setActiveComponent,
    handleCopyItems,
    handleDeleteItem,
    handlePasteItems,
    resizable = true,
    hideSource = true,
    isSideMenu = false,
    // setIsContainerDragging,
    boxHeightRef,
    boxItemRefs,
    path,
    onLayout,
    canDropItem,
    $views,
    isOverCurrentComponents,
    copyItemsRef,
    getCanvasDimension,
  } = props;
  const layoutBreak = useSelector((state: any) => state.builder.layoutBreak, shallowEqual);
  const theme = useTheme();
  const dragItemRef = useRef<any>();
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const resizedItemRef = useRef<HTMLDivElement>(null);

  const [contextMenuPosition, setContextMenuPosition] = useState<{ top: number; left: number } | null>(null);
  const isContainersBorderEnabled = useSelector((state: any) => state.builder.isContainersBorderEnabled);

  //The builder item is the element fetched from the store, the item is the dynamic resolved element.
  const builderItem = useSelector(state => selectComponentById(state, componentId)) || _item;
  const item = useMemo(() => {
    const clonedElement = _.cloneDeep(builderItem);
    resolveResponsiveProperties(clonedElement, layoutBreak);
    return clonedElement;
  }, [builderItem, layoutBreak]);

  const activeComponent = useSelector(
    (state: any) => (isSideMenu ? null : item?.id == state.builder.activeComponent ? state.builder.activeComponent : undefined),
    shallowEqual
  );

  const isFlexCanvasEnabled = useSelector((state: any) => state.builder.isFlexCanvasEnabled, shallowEqual);
  const scaleFactor = useSelector((state: any) => state.builder.scaleFactor, shallowEqual);
  const selectedItemsId = useSelector((state: any) => (isSideMenu ? [] : state.builder.selectedItemsId), shallowEqual);
  const isRTL = useSelector((state: any) => state.builder.isRTL, shallowEqual);

  const isActiveComponent = useSelector(
    (state: any) => (state.builder.activeComponent === item?.id && item?.id ? true : false),
    shallowEqual
  );

  const Component = useMemo(() => {
    return MUI_COMPONENTS[item?.type];
  }, [item]);

  const { type, leftPercentage, left, config, top } = item || {};
  const { widthPx, heightPx, widthPercentage, heightPercentage, fixedWidth, isPercentageHeight, disableResizeHeight, minWidth, minHeight } =
    config || {};

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { ...builderItem, index: itemIndex, boxPosition: store.getState().builder.boxPosition, level: null, isSideMenu },
    canDrag:
      item?.config?.flexCanvas ||
      item?.config?.disableDrag ||
      location.pathname.startsWith("/buildx/form-builder/template/history/") ||
      item?.isChildTableContainer
        ? false
        : canDrag,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      // difference: monitor.getDifferenceFromInitialOffset(),
    }),
    end: () => {
      if (getCanvasDimension) {
        getCanvasDimension();
      }
    },
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: item?.config?.flexCanvas || item?.config?.disableDrag ? false : canDrag });

    // setIsContainerDragging?.(isDragging);
  }, [isDragging]);

  const itemsIds = selectedItemsId?.map(el => ({
    id: el,
    boxPosition: boxItemRefs?.current[item.id]?.getBoundingClientRect(),
    level: null,
  }));

  const [{ isDragging: isDraggingItems }, dragItems, previewItems] = useDrag({
    type: "Group",
    item: itemsIds,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      // difference: monitor.getDifferenceFromInitialOffset(),
    }),
  });

  useLayoutEffect(() => {
    previewItems(getEmptyImage(), { captureDraggingState: false });
  }, [isDraggingItems]);

  const onResize = (size: any) => {
    if (size?.width < minWidth || size?.height < minHeight) return;
    const dx = Number(left) <= store.getState().builder.boxPosition?.width - size.width;
    const dy = Number(top) <= (store.getState().builder.boxPosition?.height || getItemClosestProp(boxHeightRef, layoutBreak)) - size.height;

    const width = Math.round(
      dx || isParentFlex(item) || isParentGrid(item)
        ? size.width <= store.getState().builder.boxPosition?.width
          ? size.width
          : store.getState().builder.boxPosition?.width
        : store.getState().builder.boxPosition?.width -
            (Number((leftPercentage || "")?.replace("%", "")) / 100) * store.getState().builder.boxPosition?.width
    );

    const height = Math.round(
      dy || isParentFlex(item) || isParentGrid(item)
        ? size.height <= store.getState().builder.boxPosition?.height
          ? size.height
          : store.getState().builder.boxPosition?.height
        : (store.getState().builder.boxPosition?.height || getItemClosestProp(boxHeightRef, layoutBreak)) - top
    );

    const PWidth = Math.round((width / store.getState().builder.boxPosition?.width) * 100);
    const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

    const PHeight = Math.round((height / store.getState().builder.boxPosition?.height) * 100);
    const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

    // Build updated configuration after resize
    const updatedConfig = {
      ...builderItem.config,
      widthPx: {
        ...builderItem.config.widthPx,
        [layoutBreak]: width,
      },
      widthPercentage: {
        ...builderItem.config.widthPercentage,
        [layoutBreak]: percentageWidth,
      },
      heightPx: {
        ...builderItem.config.heightPx,
        [layoutBreak]: Number(height),
      },
      heightPercentage: {
        ...builderItem.config.heightPercentage,
        [layoutBreak]: percentageHeight,
      },
    };

    // Dispatch the update
    dispatch(
      updateComponent({
        id: builderItem?.id,
        changes: {
          config: updatedConfig,
        },
      })
    );
  };

  const itemParent = getBuilderElementById(item?.parentId);
  const isActiveComponentResizable =
    resizable && !item?.config?.isNotResizable && store.getState().builder.boxPosition && isActiveComponent;

  let getDimensions = {
    position: !resizable || item?.config?.flexCanvas ? "relative" : "absolute",

    top: top,
    left: left,
    ...(resizable && {
      width: item?.config?.isDynamicWidth
        ? "fit-content"
        : String(itemParent?.type) === ComponentItemType.GridContainer || item?.isModalComponent
        ? "100%"
        : !fixedWidth
        ? widthPercentage
        : `${widthPx}px`,
      height: item?.config?.isDynamicHeight
        ? "fit-content"
        : item?.config?.flexCanvas || item?.isModalComponent
        ? "100%"
        : isPercentageHeight
        ? heightPercentage
        : String(itemParent?.type) === ComponentItemType.GridContainer
        ? "100%"
        : `${heightPx}px`,
      // height: item?.config?.isDynamicHeight ? "auto" : item?.config?.flexCanvas ? "100%" : getItemClosestProp(heightPx, layoutBreak),
    }),
    // opacity: isDragging && !isSideMenu ? 0 : 1,
    ...(isDragging && { visibility: isSideMenu ? "visible" : "hidden" }),

    // ...(selectedItemsId.length <= 0 && { visibility: isDragging && !isSideMenu ? "hidden" : "visible" }),
    zIndex: 1,
    backgroundColor: "transparent",
    boxSizing: "border-box",
  };

  let fullInnerWidth = false;
  let parentComponentWidthHeight = {};

  if (item?.parentId) {
    const element = getBuilderElementById(item?.parentId);

    fullInnerWidth = componentsHaveChildren.includes(element?.type);
    parentComponentWidthHeight = {
      height: getDimensions.height,
      width: getDimensions.width,
    };
  }

  const parentFlex = isParentFlex(item);
  const parentGrid = isParentGrid(item);
  let stylesFromChild = item?.config?.parentStyle;

  if ((parentFlex || parentGrid) && !isActiveComponentResizable) {
    getDimensions = _.set(getDimensions, "width", "100%");
    getDimensions = _.set(getDimensions, "height", "100%");
  }

  const fetchCustomComponentData = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${item?.config?.customComponentId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
    });
    const ids: any = {};
    data?.items?.forEach((item: any) => {
      ids[item?.id] = item;
    });

    return ids;
  };

  const { data: componentData, isLoading: isCustomComponentLoading } = useQuery(
    ["customComponent", item?.config?.customComponentId],
    fetchCustomComponentData,
    {
      enabled: !!item?.config?.customComponentId, // Only run the query if customComponentId exists
      staleTime: Infinity,
    }
  );

  const resolvedProps = useMemo(() => {
    return resolveSxStylingInBuilder(mapValuesRecursively({ ...item?.props }), layoutBreak);
  }, [item?.props, layoutBreak]);

  const componentProps = _.cloneDeep(item?.props);
  useEffect(() => {
    if (item?.config?.cssPaths) {
      item?.config?.cssPaths?.forEach((path: any) => {
        _.set(componentProps, path, item?.config?.isDynamicHeight ? "unset" : _.get(componentProps, path));
      });
    }
  }, [item?.config?.isDynamicHeight]);

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (item?.id && !item.isCustomCanvas) {
      setActiveComponent?.(item);
    }
    setContextMenuPosition({ top: event.clientY, left: event.clientX });
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (item?.id && !item.isCustomCanvas) {
      setActiveComponent?.(item);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isRemovingChildren =
    isDragging &&
    !isSideMenu &&
    (String(item?.type) === ComponentItemType.CustomContainer ||
      String(item?.type) === ComponentItemType.FlexContainer ||
      String(item?.type) === ComponentItemType.TableContainer ||
      String(item?.type) === ComponentItemType.GridContainer ||
      String(item?.type) === ComponentItemType.StepperContainer) &&
    false;

  const additionalProps = {
    style: {
      ...stylesFromChild,
      ...(item?.isModalComponent ? { display: "contents" } : {}),
    },
    ...parentComponentWidthHeight,
    ...(item?.config?.flexCanvas ? { height: "100%", width: "100%" } : {}),
  };

  const shouldShowBorder =
    (isContainersBorderEnabled && !item?.isCustomCanvas && !isSideMenu && !isActiveComponent && !item?.config?.flexCanvas) ||
    (resizable && (isActiveComponent || active) && !item?.config?.flexCanvas);
  const isInteractable = !isSideMenu && !isContainersBorderEnabled && !item?.isCustomCanvas && !item?.config?.flexCanvas;

  const boxShadow = shouldShowBorder
    ? `inset 0 0 0 1px ${isActiveComponent || active ? theme.palette.primary.main : theme.palette.text.primary}`
    : "none";

  if (item?.config?.hideElement || item?.config?.hideIn?.includes(layoutBreak)) {
    return <></>;
  }

  if (item?.isCustomCanvas && !isFlexCanvasEnabled) {
    return (
      <Box onClick={handleClick} onContextMenu={handleContextMenu}>
        <ParentDragElement
          boxItemRefs={boxItemRefs}
          builderItem={builderItem}
          item={item}
          parentFlex={parentFlex}
          isOverCurrentComponents={isOverCurrentComponents}
          canDropItem={canDropItem}
        >
          <Component {...resolvedProps}>
            {item?.children && (
              <>
                {item?.children
                  ?.filter((id: any) => !selectedItemsId?.includes(id))
                  ?.map((id: any, index: number) => {
                    return (
                      <StripeContainer element={item} key={`${id}`}>
                        <DragElement
                          {...props}
                          key={`${id}`}
                          itemIndex={index}
                          componentId={id}
                          active={selectedItemsId?.includes(id)}
                          boxItemRefs={boxItemRefs}
                          className={"mouse-select__selectable"}
                          canDropItem={canDropItem}
                          isOverCurrentComponents={isOverCurrentComponents}
                          $views={$views}
                        />
                      </StripeContainer>
                    );
                  })}
              </>
            )}
            {!!item?.children?.filter((id: any) => selectedItemsId?.includes(id))?.length && (
              <Box
                sx={{ display: "contents", visibility: isDraggingItems ? "hidden" : "visible" }}
                ref={dragItems}
                data-testid={`component-${item?.props?.key}`}
                className={"mouse-select__selectable"}
              >
                {item?.children
                  ?.filter((id: any) => selectedItemsId.includes(id))
                  ?.map((id: any, index: number) => {
                    return (
                      <DragElement
                        {...props}
                        key={`${id}`}
                        itemIndex={index}
                        componentId={id}
                        active={selectedItemsId?.includes(id)}
                        canDrag={!(selectedItemsId?.length || item?.children?.some((id: any) => id === activeComponent))}
                        boxItemRefs={boxItemRefs}
                        className={"mouse-select__selectable"}
                        canDropItem={canDropItem}
                        isOverCurrentComponents={isOverCurrentComponents}
                        $views={$views}
                      />
                    );
                  })}
              </Box>
            )}
          </Component>
          <ActionPopover
            open={open}
            onClose={handleClose}
            handleDeleteItem={handleDeleteItem}
            handleCopyItems={handleCopyItems}
            handlePasteItems={handlePasteItems}
            activeComponentId={activeComponent}
            selectedItemsId={selectedItemsId}
            contextMenuPosition={contextMenuPosition}
            hideDragActions={false}
            copyItemsRef={copyItemsRef}
            isSideMenu={isSideMenu}
          />
        </ParentDragElement>
      </Box>
    );
  }

  if (!_.isNil(item?.config?.isVisiblePreviewByStepper) && !item?.config?.isVisiblePreviewByStepper) {
    return <></>;
  }

  return (
    <>
      <ResizeElement
        resizedItemRef={resizedItemRef}
        onResize={onResize}
        isDragging={isDragging}
        disableHeight={disableResizeHeight}
        isRTL={isRTL}
        stylesFromChild={stylesFromChild}
        isActiveComponentResizable={isActiveComponentResizable}
        parentComponentWidthHeight={parentComponentWidthHeight}
        stepper={stepperCount}
        additionalProps={additionalProps}
        item={item}
        isFlexCanvasEnabled={isFlexCanvasEnabled}
      >
        <Box
          ref={resizedItemRef}
          onClick={handleClick}
          onContextMenu={handleContextMenu}
          sx={joinObjects(
            getDimensions,
            (parentFlex || parentGrid) && {
              position: "static",
            },
            item?.isModalComponent && {
              visibility: item?.props?.sx?.visibility,
              position: "absolute",
            },
            String(itemParent?.type) !== ComponentItemType.CustomContainer &&
              String(item?.type) !== ComponentItemType.FlexContainer &&
              String(item?.type) !== ComponentItemType.TableContainer &&
              String(item?.type) !== ComponentItemType.StepperContainer &&
              String(item?.type) !== ComponentItemType.GridContainer && {
                width: fullInnerWidth && !isActiveComponentResizable ? "100%" : getDimensions.width,
              }
          )}
          data-id={item?.id}
          id={item?.id}
          style={joinObjects(stylesFromChild)}
          className={`${className} ${active ? "active" : ""}`}
        >
          <Box
            onMouseOver={e => {
              e.stopPropagation();
              if (isInteractable) {
                e.currentTarget.style.boxShadow = `inset 0 0 0 1px ${
                  isActiveComponent ? theme.palette.primary.main : theme.palette.text.primary
                }`;
              }
            }}
            onMouseOut={e => {
              if (isInteractable) {
                e.currentTarget.style.boxShadow = "";
              }
            }}
            height={disableResizeHeight && !isSideMenu ? item?.config?.defaultHeight * scaleFactor : "100%"}
            width={disableResizeHeight && !isSideMenu ? item?.config?.defaultWidth * scaleFactor : "100%"}
            ref={(e: any) => {
              if (e) {
                drag(e);
                dragItemRef.current = e;
                e.style.boxShadow = isActiveComponent && !item?.config?.flexCanvas ? `inset 0 0 0 1px ${theme.palette.primary.main}` : "";
              }
            }}
            sx={joinObjects({
              position: "relative",
              boxShadow: boxShadow,
            })}
          >
            {String(item?.type) !== ComponentItemType.CustomContainer &&
              String(item?.type) !== ComponentItemType.FlexContainer &&
              String(item?.type) !== ComponentItemType.StepperContainer &&
              String(item?.type) !== ComponentItemType.TableContainer &&
              String(item?.type) !== ComponentItemType.GridContainer && (
                <Box sx={{ position: "absolute", top: -2, right: 0, bottom: -2, left: 0, zIndex: itemIndex || 0 + 1 }} />
              )}
            {isRemovingChildren ? (
              React.Children.map(props.children, (child, index) => (index === 0 ? React.cloneElement(child, { children: [] }) : child))
            ) : isSideMenu ? (
              children
            ) : [
                ComponentItemType.CustomContainer,
                ComponentItemType.FlexContainer,
                ComponentItemType.GridContainer,
                ComponentItemType.StepperContainer,
                ComponentItemType.TableContainer,
              ].includes(item?.type) ? (
              <ParentDragElement
                boxItemRefs={boxItemRefs}
                builderItem={builderItem}
                item={item}
                parentFlex={parentFlex}
                isOverCurrentComponents={isOverCurrentComponents}
                canDropItem={canDropItem}
              >
                {Component && (
                  <Component {...resolvedProps} _sx={resolvedProps.sx}>
                    {item?.children && (
                      <>
                        {item?.children
                          ?.filter((id: any) => !selectedItemsId?.includes(id))
                          ?.map((id: any, index: number) => {
                            return (
                              <StripeContainer element={item} key={`${id}`}>
                                <DragElement
                                  {...props}
                                  key={`${id}`}
                                  itemIndex={index}
                                  componentId={id}
                                  active={selectedItemsId?.includes(id)}
                                  boxItemRefs={boxItemRefs}
                                  className={"mouse-select__selectable"}
                                  canDropItem={canDropItem}
                                  isOverCurrentComponents={isOverCurrentComponents}
                                  $views={$views}
                                />
                              </StripeContainer>
                            );
                          })}
                      </>
                    )}
                    {!!item?.children?.filter((id: any) => selectedItemsId?.includes(id))?.length && (
                      <Box
                        sx={{ display: "contents", visibility: isDraggingItems ? "hidden" : "visible" }}
                        ref={dragItems}
                        className={"mouse-select__selectable"}
                      >
                        {item?.children
                          ?.filter((id: any) => selectedItemsId.includes(id))
                          ?.map((id: any, index: number) => {
                            return (
                              <DragElement
                                {...props}
                                key={`${id}`}
                                itemIndex={index}
                                componentId={id}
                                active={selectedItemsId?.includes(id)}
                                canDrag={!(selectedItemsId?.length || item?.children?.some((id: any) => id === activeComponent))}
                                boxItemRefs={boxItemRefs}
                                className={"mouse-select__selectable"}
                                canDropItem={canDropItem}
                                isOverCurrentComponents={isOverCurrentComponents}
                                $views={$views}
                              />
                            );
                          })}
                      </Box>
                    )}
                  </Component>
                )}
              </ParentDragElement>
            ) : item?.config?.customComponent ? (
              <>
                {!isCustomComponentLoading && (
                  <BXEngine
                    key={item?.id}
                    path={path}
                    auth={{}}
                    componentName={componentData?.[item?.config?.customComponentId]?.name}
                    componentData={{
                      ...item?.props,
                      config: chartsNames.includes(item?.type)
                        ? item?.configData
                        : componentData?.[item?.config?.customComponentId]?.config,
                      disableApis: true,
                    }}
                    layout={[{ id: item?.id, type: item?.type } as any]}
                    onLayout={(values: any) => onLayout(values, { skip: !!componentData?.[item?.config?.customComponentId]?.config })}
                  />
                )}
              </>
            ) : item?.config?.BxComponent ? (
              <BXEngine
                key={item?.id}
                path={path}
                auth={{}}
                layout={[{ id: item?.id, type: item?.type } as any]}
                componentData={{
                  ...item?.props,
                  config: item?.configData,
                  disableApis: true,
                }}
              />
            ) : (
              Component && (
                <Component
                  key={`${item?.id}-Comp`}
                  {...resolvedProps}
                  $config={item?.config}
                  $views={$views}
                  _sx={resolvedProps.sx}
                  // stepperGroups={stepperGroups}
                />
              )
            )}
          </Box>
          <ActionPopover
            open={open}
            onClose={handleClose}
            handleDeleteItem={handleDeleteItem}
            handleCopyItems={handleCopyItems}
            handlePasteItems={handlePasteItems}
            activeComponentId={activeComponent}
            selectedItemsId={selectedItemsId}
            contextMenuPosition={contextMenuPosition}
            hideDragActions={false}
            copyItemsRef={copyItemsRef}
            isSideMenu={isSideMenu}
          />
        </Box>
      </ResizeElement>
    </>
  );
});
