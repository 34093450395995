// Helper to validate and sanitize API key
export const sanitizeApiKey = (apiKey: string | undefined): string => {
  // Validate API key - it should be a string and not a URL
  if (!apiKey || typeof apiKey !== "string" || apiKey.startsWith("http")) {
    return ""; // Fallback API key
  }
  return apiKey;
};

/**
 * Check if a Google Maps API key is valid
 * @param apiKey The API key to check
 * @returns A boolean indicating if the API key is valid
 */
export const isValidGoogleMapsApiKey = (apiKey: string | undefined): boolean => {
  const sanitizedKey = sanitizeApiKey(apiKey);
  // A valid API key should be a non-empty string with a reasonable length
  // Google API keys are typically around 39 characters
  return !!sanitizedKey && sanitizedKey.length >= 30 && sanitizedKey.length <= 40;
};
