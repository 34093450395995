import useTheme from "@mui/material/styles/useTheme";
import React, { useEffect, useRef, useState } from "react";

interface ResizableContainerProps {
  minWidth: number;
  maxWidth: number;
  position: string;
  onResizeFinished?: () => void;
}

const ResizableContainer: React.FC<ResizableContainerProps> = ({ children, minWidth, maxWidth, position, onResizeFinished }) => {
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const initialMouseX = useRef<number>(0);
  const cardRef = useRef<HTMLDivElement>(null);
  const resizingHandleRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const positionRef = useRef<string>(position);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const containerLeft = cardRef.current?.getBoundingClientRect().left || 0;
    const containerRight = cardRef.current?.getBoundingClientRect().right || 0;

    const isLeftIndicator = resizingHandleRef.current && event.clientX <= containerLeft + resizingHandleRef.current.offsetWidth;

    const isRightIndicator = resizingHandleRef.current && event.clientX >= containerRight - resizingHandleRef.current.offsetWidth;

    if (event.button === 0) {
      if ((position === "left" && isLeftIndicator) || (position === "right" && isRightIndicator)) {
        setIsResizing(true);
        initialMouseX.current = event.clientX;

        document.body.style.userSelect = "none";
        document.body.style.cursor = "ew-resize";
      }
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);

    document.body.style.userSelect = "";
    document.body.style.cursor = "";

    if (onResizeFinished) {
      onResizeFinished();
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (isResizing && cardRef.current) {
      const deltaX = event.clientX - initialMouseX.current;

      if (position === "left") {
        const newWidth = cardRef.current.offsetWidth - deltaX;
        const finalWidth = Math.min(Math.max(newWidth, minWidth), maxWidth);
        cardRef.current.style.width = `${finalWidth}px`;
      } else if (position === "right") {
        const newWidth = cardRef.current.offsetWidth + deltaX;
        const finalWidth = Math.min(Math.max(newWidth, minWidth), maxWidth);
        cardRef.current.style.width = `${finalWidth}px`;
      }

      initialMouseX.current = event.clientX;
    } else {
      setIsResizing(false);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (cardRef.current) {
      cardRef.current.style.width = `${maxWidth}px`;
    }
  }, [maxWidth]);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  const resizingHandleStyle = {
    width: "2px",
    height: "100%",
    backgroundColor: isResizing ? theme.palette.primary.main : isHovered ? theme.palette.divider : "",
    cursor: "ew-resize",
    transition: "opacity 0.3s",
    opacity: isResizing || isHovered ? 1 : 0,
    zIndex: 99,
  };

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: position === "right" ? "row-reverse" : "row",
    position: "relative",
  };

  return (
    <>
      <div style={containerStyle}>
        <div
          ref={resizingHandleRef}
          style={{ ...resizingHandleStyle }}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <div ref={cardRef} style={{ userSelect: isResizing ? "none" : "auto" }}>
          {children}
        </div>
      </div>
    </>
  );
};

export default ResizableContainer;
