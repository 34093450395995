import _, { isObject } from "lodash";
import moment from "moment";
import { endUserAdapter } from "src/features/endUser/endUserSlice";
import store from "src/store/store";
import { colorToRgbVariants, generateKeyDescriptor, isUnixTimestamp, isValidDate } from "src/utils/generalUtils";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";
import { getSelectedPageInfo } from "src/views/pages/BuildX/FormBuilder/utils";

export const getControllerDefaultValue = (
  element: any,
  item?: any,
  viewsState?: any,
  pageId?: any,
  __data?: any,
  currentApp?: any,
  replaceDataPlaceholders?: any,
  replaceDataPlaceholdersRecursively?: any,
  componentValue?: any,
  skipResolving?: any
) => {
  if (element?.props?.key) {
    let value = componentValue;
    if (element?.type === ComponentItemType.StepperContainer) {
      let stepperGroup = value;
      if (stepperGroup) {
        stepperGroup = { ...stepperGroup, selected: getSelectedPageInfo(stepperGroup.selectedPage, stepperGroup.pages) };
      }
      return stepperGroup;
    }
    if (!skipResolving) {
      if (!isObject(element?.props?.defaultValue)) {
        if (replaceDataPlaceholders) {
          value = replaceDataPlaceholders({
            queryString: element?.props?.defaultValue,
            item,
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            fallback: "",
          });
        } else {
          value = element?.props?.defaultValue;
        }
      } else {
        if (replaceDataPlaceholdersRecursively) {
          value = replaceDataPlaceholdersRecursively({
            obj: _.cloneDeep(element?.props?.defaultValue),
            item,
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            fallback: "",
          });
        } else {
          value = element?.props?.defaultValue;
        }
      }
    }

    // default value for color picker
    if (element?.type === ComponentItemType.ColorPicker) {
      value = colorToRgbVariants(value);
    }

    // default value for auto complete
    if (element?.type === ComponentItemType.CustomAutoCompleteBX) {
      value = { userOptions: { label: "", value: value, selectedData: null } };
    }

    //default value for date picker
    if (element?.type === ComponentItemType.DatePicker || element?.type === ComponentItemType.DateTimePicker) {
      const isUserLocalTime = element?.config?.isUserLocalTime;

      if (!value || !isValidDate(value)) {
        value = {
          utcISO: null,
          userLocalISO: null,
          epoch: null,
        };
      } else if (isUnixTimestamp(Number(value))) {
        const formattedUtcIso = moment(Number(value)).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        const date = new Date(Number(value));

        value = isUserLocalTime
          ? {
              utcISO: date.toISOString(),
              userLocalISO: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: date.valueOf(),
            }
          : {
              utcISO: formattedUtcIso,
              userLocalISO: moment(formattedUtcIso).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: moment(formattedUtcIso).valueOf(),
            };
      } else {
        let formattedUtcIso = moment.utc(value).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

        const date = new Date(value);

        value = isUserLocalTime
          ? {
              utcISO: date.toISOString(),
              userLocalISO: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: date.valueOf(),
            }
          : {
              utcISO: formattedUtcIso,
              userLocalISO: moment(formattedUtcIso).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: moment(formattedUtcIso).valueOf(),
            };
      }
    }

    // default for missing value according to the type of the component
    if (!value) {
      switch (element?.type) {
        case ComponentItemType.FileUploadInput:
          value = {
            key: null,
            url: null,
            file: null,
            ext: null,
          };
          break;
        case ComponentItemType.CustomSwitch:
          value = String(element?.props?.defaultValue) === "true" ? true : false;
          break;
        case ComponentItemType.BXSelect:
          value = element?.props?.defaultValue || element?.configData?.selectValues?.[0]?.value;
          break;
        case "formTable":
          value = [];
          break;
        case "keyValue":
          value = {};
          break;
        default:
          value = "";
          break;
      }
    }
    return value;
  }
};

export const getControllerName = (element: any, repeatedParent: any, index, pageId, viewName): string => {
  let componentKey;

  switch (element?.type) {
    case ComponentItemType.CustomRadio:
    case ComponentItemType.CustomCheckbox: {
      componentKey = element?.props?.groupName;
      break;
    }
    default: {
      componentKey = element?.props?.key;
      break;
    }
  }

  componentKey = componentKey || "";

  // if (!_.isNil(index) && !_.isNil(repeatedParent)) {
  //   // componentKey = `${repeatedParent}.state[${index}].${componentKey}`;
  //   componentKey = `${repeatedParent}.state[${index}]`;
  // }
  if (viewName) return `${pageId}.${viewName}.${componentKey}`;
  if (pageId) return `${pageId}.${componentKey}`;
  return `application.layout.${componentKey}`;
};

export function isElementParentFlex(element, formBuilder): boolean {
  if (!formBuilder) return false;
  const findParent = (item, id) => {
    if (Array.isArray(item)) {
      for (let topLevelComp of item) {
        const res = findParent(topLevelComp, id);
        if (res) {
          return res;
        }
      }
    }

    if (item.id === id) {
      return item;
    }

    if (item.children) {
      for (let child of item.children) {
        const res = findParent(child, id);
        if (res) {
          return res;
        }
      }
    }
  };

  const parent = findParent(formBuilder, element.parentId);

  return parent?.type === ComponentItemType.FlexContainer || parent?.type === ComponentItemType.StepperContainer;
}

export const getEndUserElementById = (parentKey: string, pageId, viewName) => {
  let parentComponent = pageId
    ? viewName
      ? endUserAdapter.getSelectors().selectById(store.getState().endUser.application.pages[pageId]?.views[viewName], parentKey)
      : endUserAdapter.getSelectors().selectById(store.getState().endUser.application.pages[pageId], parentKey)
    : endUserAdapter.getSelectors().selectById(store.getState().endUser.application.layout, parentKey);

  return parentComponent;
};

export function isParentFlexEndUser(item: any, pageId, viewName): boolean {
  const itemParent = getEndUserElementById(item?.parentKey, pageId, viewName);
  return (
    itemParent?.type === ComponentItemType.FlexContainer ||
    itemParent?.type === ComponentItemType.StepperContainer ||
    itemParent?.type === ComponentItemType.GridContainer ||
    itemParent?.type === ComponentItemType.TableContainer
  );
}

export const triggerActionableComponent = async (event, pageId, viewName, key, viewsState, produceTrigger) => {
  const elementKey = `${pageId}.${viewName}.${key}`;
  await new Promise((resolve, reject) => {
    produceTrigger(elementKey, "action", { resolver: resolve }, { pageId, viewName, componentKey: key });
  });
};

export const handleKeyDown = async (event, element, elementKey, pageId, viewName, viewsState, produceTrigger) => {
  const keyDescriptor = generateKeyDescriptor(event);
  if (!_.has(element, "interactionConfig")) {
    return;
  }

  const onKeyPressTriggers = element.interactionConfig?.filter(
    interaction => interaction.type === "OnKeyPress" && interaction?.key && interaction?.key === keyDescriptor
  );

  onKeyPressTriggers?.forEach(async (trigger, index) => {
    if (trigger?.triggerSelf === false) {
      await triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState, produceTrigger);
    } else {
      await triggerActionableComponent(event, pageId, viewName, elementKey, viewsState, produceTrigger);
    }
  });
};

export const handleChange = (event, element, onChange, elementKey, pageId, viewName, viewsState, disabledDirty, produceTrigger) => {
  if (!element?.config?.isGroup) {
    onChange(event, disabledDirty);
  }

  if (element?.type == "CustomAutoCompleteBX") {
    if (event?.noChange) {
      return;
    }
  }

  const onChangeTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnChange");

  onChangeTriggers?.forEach((trigger, index) => {
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState, produceTrigger);
    } else {
      triggerActionableComponent(event, pageId, viewName, elementKey, viewsState, produceTrigger);
    }
  });
};

export const handleClick = (event, element, pageId, viewName, viewsState, produceTrigger) => {
  const onClickTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnClick");
  onClickTriggers?.forEach((trigger, index) => {
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState, produceTrigger);
    }
  });
};
export const handleLoad = (element, elementKey, pageId, viewName, viewsState, produceTrigger) => {
  const onLoadTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnLoad");

  onLoadTriggers?.forEach(trigger => {
    const loadEvent = {
      type: "OnLoad",
    };
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(loadEvent, pageId, viewName, trigger?.actionableKey, viewsState, produceTrigger);
    } else {
      triggerActionableComponent(loadEvent, pageId, viewName, elementKey, viewsState, produceTrigger);
    }
  });
};

export const handleOnReach = (element, elementKey, pageId, viewName, viewsState, produceTrigger) => {
  const onReachTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnReach");
  onReachTriggers?.forEach(trigger => {
    const loadEvent = {
      type: "OnReach",
    };
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(loadEvent, pageId, viewName, trigger?.actionableKey, viewsState, produceTrigger);
    } else {
      triggerActionableComponent(loadEvent, pageId, viewName, elementKey, viewsState, produceTrigger);
    }
  });
};
