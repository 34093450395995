import { gql } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, CircularProgress, Paper, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconRefresh } from "@tabler/icons-react";
import update from "immutability-helper";
import _, { PropertyPath } from "lodash";
import { FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
import { TableVirtuoso } from "react-virtuoso";
import { BXEngine } from "src/BXEngine";
import { TableItem } from "src/components/BXUI/DataTable/Item";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { Pagination } from "src/components/pagination";
import { useAppState } from "src/features/appState/hooks";
import { encodeURLParams, replaceBaseUrl } from "src/features/buildxProvider/buildxProviderUtils";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { setEndUser } from "src/features/endUser/endUserSlice";
import { useFetchData } from "src/hooks/useFetchData";
import store from "src/store/store";
import { ElementBaseProps } from "src/types/UIElement";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import axiosServices from "src/utils/axios";
import { getAuth } from "src/utils/buildxProviderOperations";
import { getAuthorizationHeader, handleRefetchQueries, handleSetQueryData, returnArrayValue } from "src/utils/generalUtils";
import DraggableRow from "src/views/pages/BuildX/AppBuilder/forms/DraggableRow";
import { getSharedViews } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import { BXIcon } from "../Icon";
import ViewLink from "../ViewLink/ViewLink";
import { ActionButton, useReplaceDataPlaceholders } from "./ActionButton";

/**
 *
 */
type BXDataTableCellType = "numeric" | "text" | "complex";

/**
 * @type BXDataTableColumn
 * description of table column. including header and cell type
 */

export type BXDataTableColumn = {
  openLinkAs: string;
  linkUrl: any;
  headerCellType: string;
  viewBuilderHeaderId: string;
  customHeight: string;
  name: string;
  sortable?: boolean;
  filterable?: boolean;
  cellType?: BXDataTableCellType;
  children: BXDataTableColumn[]; // applies only if cellType is complex
  verticalAlign?: string;
  horizontalAlign?: string;
  source?: string;
  type?: string;
  allowWrap?: boolean;
  allowCopy?: boolean;
  customWidth?: string;
  alignColumn?: string;
  showEllipsisForActions?: boolean;
};

type DataTableProps = {
  columns: BXDataTableColumn[];
  actions?: any[];
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
    paginationMode?: string;
    enableMultipleSelection?: string;
    viewName?: string;
    hideViewName?: string;
    disableRowSelect?: boolean;
    disableTableHeader?: boolean;
    disableBorder?: boolean;
    enableRowHeight?: boolean;
    hidePaginationBar?: string;
    hideRefreshButton?: string;
    refreshWithCurrentCursor?: any;
    loadingIcon?: any;
    emptyStateView?: string;
    rowHeight?: number;
  };
  order?: {
    enabled?: boolean;
  };
  views: any;
  showModalHeader?: boolean;
} & ElementBaseProps;

let disableBorder;
const VirtuosoTableComponents = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} sx={{ overflowY: "clip" }} />
  )),
  Table: props => {
    const { context, ...restProps } = props || {};
    return (
      <Table
        {...restProps}
        sx={{
          borderCollapse: "separate",
          tableLayout: "fixed",
          minWidth: "100%",
          width: "auto",
          border: disableBorder ? "none !important" : "inherit",
        }}
      />
    );
  },
  TableHead,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
  TableRow: props => {
    const { children, ...restProps } = props;
    const { context } = restProps || {};
    const isDraggable = context?.order?.enabled;
    const rowItemId = props.item?.__id || props.item?.id;

    const rowProps = {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        context?.handleSelectRow?.(props.item);
      },
      selected:
        (context?.selectedRows && context?.selectedRows.includes(rowItemId)) ||
        (context?.selectedRow && rowItemId === context?.selectedRow?.__id),
      hover: true,
      ...restProps,
    };

    const rowChildren = (
      <>
        {children}
        {children ? <TableCell sx={{ width: "auto" }} /> : <></>}
      </>
    );

    if (isDraggable) {
      return (
        <DraggableRow
          component={TableRow}
          componentProps={rowProps}
          id={props.item.id}
          index={props["data-index"]}
          name={`table-columns${context?.id}`}
          moveElement={context?.handleDragHover}
          updateElement={context?.handleUpdateElement}
        >
          {rowChildren}
        </DraggableRow>
      );
    } else {
      return (
        <TableRow {...rowProps} sx={context?.disableRowSelect ? { background: "transparent !important" } : {}}>
          {rowChildren}
        </TableRow>
      );
    }
  },
};

const CustomLoader = ({ loadingIcon }) => {
  if (!loadingIcon?.icon) {
    return <CircularProgress disableShrink />;
  }

  const icon =
    (loadingIcon?.visibility === "PUBLIC" && loadingIcon?.icon?.match(BxPatterns.mediaUrlPattern) ? loadingIcon?.url : loadingIcon?.icon) ||
    loadingIcon?.icon;

  return <BXIcon icon={icon} width={"auto"} height={"auto"} />;
};

export const BXDataTable: FC<DataTableProps> = props => {
  const {
    columns = [],
    auth,
    id = "",
    dataSource,
    actions,
    views,
    info,
    order,
    selectedViewId,
    __data = {},
    closeModal,
    parentIds = [],
    showModalHeader,
    config,
    pageId,
    collectionId,
    path,
    parentRef,
    isCustomLayout,
  } = props;

  const [isApiMode, setIsApiMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState([undefined]);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentActionEndpoint, setCurrentActionEndpoint] = useState("");
  const [dataEntry, setDataEntry] = useState<any>(dataSource?.dataEntry);
  const [currentAction, setCurrentAction] = useState({});
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const { setValue, watch, produceTrigger } = useAppState();

  let hasMore = useRef<any>(true);
  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const containerRef = useRef<HTMLDivElement>(null);
  let gqlQuery;
  const isGraphQL = dataSource?.payload?.isGraphQL;
  if (isGraphQL) {
    gqlQuery = gql`
      ${dataSource?.payload?.graphqlQuery}
    `;
  }
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { getValue } = useAppState();
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const queriesStateGraphQL = useBuildxProviderValue("queriesStateGraphQL");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const appTokens = useBuildxProviderValue("appTokens");

  const withInfiniteScroll = !info?.paginationMode || info?.paginationMode == "Infinite Scroll";
  const selectedDataEntry = dataSource?.sourceType == "USER INPUT" ? dataEntry : dataSource?.dataEntry;
  const queryKeys = [
    `${pageId}-${info?.viewName}`,
    // selectedViewId
  ];
  const selectedRow = watch(`${pageId}.${info?.viewName}.selectedItem`, { pageId, viewName: info?.viewName });
  const selectedRows = watch(`${pageId}.${info?.viewName}.selectedItems`, { pageId, viewName: info?.viewName }) || [];
  const userInputsValues = viewsState?.[queryKeys[0]]?.userInputs || {};

  if (cursors?.[currentPage - 1]) {
    queryKeys.push(cursors?.[currentPage - 1] as any);
  }
  // we need the key here to access the right data
  const { data, isFetching, hasNextPage, fetchNextPage, firstLoadOfData, refetchDataSource } = useFetchData({
    queryKeys,
    __data,
    selectedItem: selectedRow,
    cursor: cursors?.[currentPage - 1],
    withInfiniteQuery: withInfiniteScroll,
    viewId: id,
    limit: dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    isApiCallInFirstLoad: dataSource?.isApiCallInFirstLoad,
    dataEntry: selectedDataEntry,
    pageId,
    endpoint: currentActionEndpoint,
    dataSource: { ...dataSource, payload: { ...dataSource?.payload, ...currentAction } },
    viewName: info?.viewName,
    isPagination: true,
    path: path,
    options: {
      enabled: true,
    },
  });

  disableBorder = info?.disableBorder;

  // @ts-ignore
  // if (!withInfiniteScroll) {
  //   entries = (
  //     _.isNil(selectedDataEntry) || !selectedDataEntry ? returnArrayValue(data) : returnArrayValue(_.get(data, selectedDataEntry as any))
  //   )?.filter?.(Boolean);
  // } else {
  //   entries = (
  //     _.isNil(selectedDataEntry) || !selectedDataEntry
  //       ? _.flatten(returnArrayValue(data?.pages))
  //       : _.flatten(data?.pages?.map((p: any) => returnArrayValue(_.get(p, selectedDataEntry as any))))
  //   )?.filter?.(Boolean);
  // }

  // Memoized entries calculation based on data and selected data entry
  const entries = useMemo(() => {
    if (withInfiniteScroll) {
      const items = selectedDataEntry ? _.get(data, selectedDataEntry as string, []) : data;
      return items?.map(item => ({ ...item, __id: uuid() })) || [];
    }

    const processedData =
      _.isNil(selectedDataEntry) || !selectedDataEntry ? returnArrayValue(data) : returnArrayValue(_.get(data, selectedDataEntry as any));

    return processedData?.filter(Boolean)?.map(item => ({ ...item, __id: uuid() })) || [];
  }, [data, selectedDataEntry, withInfiniteScroll]);

  const loadMore = useCallback(async () => {
    if (hasNextPage && withInfiniteScroll && !isFetching) {
      await fetchNextPage?.();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  //TODO: Add cleanup for resources if any (Now in redux store)
  // useEffect(() => {
  //   return () => {
  //     if (dataSource?.sourceType == "USER INPUT") {
  //       handleCleanupQueries({
  //         isGraphQL,
  //         gqlQuery,
  //         queryKeys,
  //       });
  //     }
  //   };
  // }, []);

  const handleSelectRow = (itemData: any) => {
    setValue(`${pageId}.${info?.viewName}.selectedItem`, itemData, { pageId, viewName: info?.viewName });
  };

  const handleDragHover = (dragIndex: any, hoverIndex: number) => {
    const _queryKeys = [...queryKeys];
    const cursor = cursors?.[currentPage - 1];

    if (cursor) {
      _queryKeys.push(cursor);
    }

    const pageIndex = data?.pages?.findIndex(
      (items: any) => _.get(items, selectedDataEntry as any)?.findIndex((item: any) => entries.indexOf(item) === dragIndex) !== -1
    );

    const pageSize = 20;
    const page = _.cloneDeep(data?.pages?.[pageIndex]);
    let item;

    if (selectedDataEntry) {
      const source = withInfiniteScroll ? page : data;
      item = _.get(source, selectedDataEntry)?.[dragIndex % pageSize];
    } else {
      item = withInfiniteScroll ? page?.[dragIndex % pageSize] : data?.[dragIndex];
    }

    let payload = {};

    let newPage = page;
    let newData: any = [];

    if (isGraphQL && withInfiniteScroll) {
      payload = { ..._.cloneDeep(data?.pages?.[0]) };

      if (selectedDataEntry) {
        _.set(
          payload,
          selectedDataEntry,
          update(_.get(_.cloneDeep(data?.pages?.[0]), selectedDataEntry as any), {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, item],
            ],
          })
        );
      }
    } else {
      if (withInfiniteScroll) {
        if (selectedDataEntry) {
          _.get(page, selectedDataEntry as any).splice(dragIndex % pageSize, 1);
        } else {
          page.splice(dragIndex % pageSize, 1);
        }
        if (selectedDataEntry) {
          _.set(newPage, selectedDataEntry, [
            ..._.get(page, selectedDataEntry as any).slice(0, hoverIndex % pageSize),
            item,
            ..._.get(page, selectedDataEntry as any).slice(hoverIndex % pageSize),
          ]);
        } else {
          newPage = [...page.slice(0, hoverIndex % pageSize), item, ...page.slice(hoverIndex % pageSize)];
        }
        newData = [...data.pages.slice(0, pageIndex), newPage, ...data.pages.slice(pageIndex + 1)];
      }

      payload = !withInfiniteScroll ? _.cloneDeep(data) : { ..._.cloneDeep(data), pages: newData };

      if (!withInfiniteScroll) {
        if (selectedDataEntry) {
          _.set(
            payload,
            selectedDataEntry,
            update(_.get(data, selectedDataEntry as any), {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, item],
              ],
            })
          );
        } else {
          payload = update(data, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, item],
            ],
          });
        }
      }
    }

    const variables = replaceDataPlaceholdersRecursively({
      obj: JSON.parse(dataSource?.payload?.graphqlVariables || "{}"),
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      cursor: cursors?.[currentPage - 1],
      fallback: null,
    });

    handleSetQueryData({
      isGraphQL,
      gqlQuery,
      payload,
      queryKeys: _queryKeys,
      variables,
    });
  };

  const handleUpdateElement = (dragIndex: any, hoverIndex: number) => {
    const _queryKeys = [...queryKeys];

    const cursor = cursors?.[currentPage - 1];

    if (cursor) {
      _queryKeys.push(cursor);
    }

    const pageIndex = data?.pages?.findIndex(
      (items: any) => _.get(items, selectedDataEntry as any)?.findIndex((item: any) => entries.indexOf(item) === dragIndex) !== -1
    );

    const pageSize = 20;
    const page = data?.pages?.[pageIndex];
    const dragItem = !withInfiniteScroll
      ? _.get(data, selectedDataEntry as any)?.[dragIndex]
      : _.get(page, selectedDataEntry as any)?.[dragIndex % pageSize];

    const dropItem = !withInfiniteScroll
      ? _.get(data, selectedDataEntry as any)?.[dragIndex - 1 === -1 ? 1 : dragIndex - 1]
      : _.get(page, selectedDataEntry as any)?.[(dragIndex - 1 === -1 ? 1 : dragIndex - 1) % pageSize];

    let url = replaceDataPlaceholders({
      queryString: config.order.endpoint,
      item: dragItem,
      viewsState,
      pageId,
      __data,
      dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
      env: currentApp?.env,
    });
    url = encodeURLParams(replaceBaseUrl(url, currentApp));
    const { token } = getAuth(currentApp?.id!, currentProfileId, appProfiles, appTokens, currentApp) || {};

    axiosServices.request({
      url: url,
      data: config?.order?.isGraphQL
        ? {
            query: config?.order?.graphqlQuery,
            variables: JSON.stringify(
              replaceDataPlaceholdersRecursively({
                obj: JSON.parse(config?.order?.graphqlVariables || "{}"),
                viewsState,
                pageId,
                __data,
                env: currentApp?.env,
                dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
              })
            ),
          }
        : replaceDataPlaceholdersRecursively({
            obj: JSON.parse(config?.order?.body || "{}"),
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
          }),
      method: config?.order?.isGraphQL ? "post" : config?.order?.method,
      headers: {
        ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
        ...replaceDataPlaceholdersRecursively({
          obj: config?.order?.headers,
          viewsState,
          pageId,
          __data,
          env: currentApp?.env,
          dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
        }),
      },
    });
  };
  const userInputView = views?.find((view: any) => view?.id === dataSource?.userInputFormBuilderId);

  function handleSelectAllChange(event: any) {
    setIsSelectAllChecked(event.target.checked);
    if (!isSelectAllChecked) {
      if (data) {
        setValue(
          `${pageId}.${info?.viewName}.selectedItems`,
          entries || [],

          { pageId, viewName: info?.viewName }
        );
      }
    } else {
      setValue(`${pageId}.${info?.viewName}.selectedItems`, [], { pageId, viewName: info?.viewName });
    }
  }

  useEffect(() => {
    if (actions) {
      setValue(`${pageId}.${info?.viewName}.actions`, [], { pageId, viewName: info?.viewName });
    }

    if (isSelectAllChecked) {
      if (entries) {
        setValue(
          `${pageId}.${info?.viewName}.selectedItems`,
          entries || [],

          { pageId, viewName: info?.viewName }
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (cursors?.length === 1 && data !== null) {
      setCursors(prev => {
        const populatedCursors = [...prev, data?.cursor];
        return populatedCursors;
      });
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setEndUser({
        pageId: pageId,
        viewName: info?.viewName,
      })
    );
  }, []);

  const isHeaderVisible =
    (_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isGlobal))) ||
    selectedRows.length > 0 ||
    (!withInfiniteScroll && !!entries?.length) ||
    (info?.showApiMode !== "Hidden" && !showModalHeader) ||
    dataSource?.sourceType == "USER INPUT";

  const targetView = useMemo(
    () => [...(views || []), ...(getSharedViews(currentApp) || [])]?.find((v: { id: string }) => v.id === info?.emptyStateView),
    [views, currentApp, info?.emptyStateView]
  );

  const rowRenderer = (index, data) => {
    return (
      <TableItem
        key={data.__id}
        path={path}
        auth={auth}
        viewName={info?.viewName}
        pageId={pageId}
        collectionId={collectionId}
        views={views}
        queryKeys={queryKeys}
        columns={columns}
        actions={actions}
        userInputsValues={userInputsValues}
        isUserInput={dataSource?.sourceType == "USER INPUT"}
        item={data}
        onSelectRow={handleSelectRow}
        tableId={id}
        __data={__data}
        closeModal={closeModal}
        parentIds={parentIds}
        info={info}
        selectedRows={selectedRows}
        isSelectAllChecked={isSelectAllChecked}
        index={index}
        dataEntry={selectedDataEntry}
      />
    );
  };

  function getWidth(col: BXDataTableColumn) {
    if (!col?.customWidth) {
      return col?.type == "Video" || col?.type == "Audio" ? "360px" : "auto";
    }
    return `${col?.customWidth}px`;
  }

  //Respond to specific events and signals related to the current view or component and consume them
  const viewKey: string = `${pageId}.${info?.viewName}`;
  const trigger = watch(`${viewKey}.trigger`, { pageId, viewName: info?.viewName });
  const redux = store.getState().endUser;
  useEffect(() => {
    // Perform an action when the event/signal/trigger is triggered and brooked
    const handleTrigger = async () => {
      if (trigger?.type === "refetch") {
        const cursor = trigger?.payload?.currentCursorParam ?? null;
        const currentCursor = info?.refreshWithCurrentCursor ? cursor : "";
        await refetchDataSource?.(currentCursor);
        trigger?.payload?.resolver?.();
      }

      if (trigger?.type === "fetchNextPage") {
        const cursor = trigger?.payload?.currentCursorParam ?? null;
        const isPaginationBar = trigger?.payload?.isPaginationBar ?? false;
        await fetchNextPage?.(cursor, true, isPaginationBar);

        trigger?.payload?.resolver?.();
      }
    };

    handleTrigger();
  }, [trigger, viewKey]);

  const targetKey = `${pageId}.${info?.viewName}`;
  const paginationInfoForElementData = watch(`${targetKey}.data._pagination`, { pageId, viewName: info?.viewName });
  const nextCursor = paginationInfoForElementData?.nextCursor;
  const isRefetchOperation = paginationInfoForElementData?.isRefetchOperation;
  const lengthViewData = paginationInfoForElementData?.length;

  useEffect(() => {
    setCursors(prevCursors => {
      let updatedCursors;

      if (!prevCursors.includes(nextCursor)) {
        updatedCursors = [...prevCursors, nextCursor];
      } else {
        const index = prevCursors.indexOf(nextCursor);
        updatedCursors = prevCursors.slice(0, index + 1);
      }

      hasMore.current = paginationInfoForElementData?.hasMore;

      if (!isRefetchOperation) {
        if (!hasMore.current && lengthViewData == 0) {
          setTotalPageCount(updatedCursors.length - 2);
          setCurrentPage(updatedCursors.length - 2);
        } else {
          setTotalPageCount(updatedCursors.length - 1);
          setCurrentPage(updatedCursors.length - 1);
        }
      } else {
        if (nextCursor !== "") {
          updatedCursors = [undefined, nextCursor];
          setTotalPageCount(updatedCursors.length - 1);
          setCurrentPage(updatedCursors.length - 1);
        } else {
          updatedCursors = [undefined];
          setTotalPageCount(updatedCursors.length);
          setCurrentPage(updatedCursors.length);
        }
      }

      return updatedCursors.filter(cursor => cursor !== "");
    });
  }, [nextCursor]);

  function fixedHeaderContent() {
    return (
      <>
        {!isApiMode && !info?.disableTableHeader && info && (
          <TableRow sx={info?.disableRowSelect ? { background: "transparent !important" } : {}}>
            {info?.enableMultipleSelection && (
              <TableCell style={{ background: palette.background.default, paddingInlineStart: "8px" }}>
                <Checkbox color='primary' onChange={handleSelectAllChange} />
                Rows
              </TableCell>
            )}
            {columns.map(column => (
              <PermissibleRender key={column.name} isAllowed action={["VIEW"]} path={[path, "column", column.name].join(".")}>
                {({ permitted }) => {
                  if (!permitted) return null;

                  const builderView = views?.find((view: any) => view?.id === column?.viewBuilderHeaderId);

                  const renderViewBuilder = () => (
                    <Box
                      sx={{
                        maxHeight: column?.customHeight ? `${column?.customHeight}px` : "41px",
                        ...(column?.customHeight ? { minHeight: `${column?.customHeight}px` } : {}),
                        overflow: "hidden",
                        height: "100%",
                      }}
                    >
                      <BXEngine
                        auth={{}}
                        path={[path, builderView?.name].join(".")}
                        pageId={pageId}
                        page={{ views, layout: builderView, id: pageId } as any}
                        layout={[{ ...(builderView as any), type: builderView?.type }]}
                        data={column}
                        __data={__data}
                        insideCell
                      />
                    </Box>
                  );

                  return (
                    <TableCell
                      style={{
                        background: palette.background.default,
                        minWidth: getWidth(column),
                      }}
                      align={(column?.alignColumn as any) ?? "left"}
                      key={column.name}
                      sx={{ paddingInlineStart: 3 }}
                    >
                      {column?.headerCellType === "viewBuilder" && builderView ? renderViewBuilder() : column.name}
                    </TableCell>
                  );
                }}
              </PermissibleRender>
            ))}
            <TableCell sx={{ width: "auto" }} />
          </TableRow>
        )}
      </>
    );
  }
  if (
    info.emptyStateView &&
    info.emptyStateView !== "None" &&
    _.isEmpty(entries) &&
    _.isEmpty(firstLoadOfData) &&
    !isFetching &&
    targetView
  ) {
    return (
      <BXEngine
        auth={{}}
        pageId={pageId}
        page={{ views: views, layout: targetView, id: pageId } as any}
        path={[path, targetView?.info?.name]?.join(".")}
        layout={[{ ...(targetView as any), type: targetView?.type }]}
        __data={__data}
      />
    );
  }

  return (
    <Box marginY={1} position={"relative"}>
      <ViewLink collectionId={collectionId} pageId={pageId} id={id} isModal />
      <Card variant={"outlined"}>
        {selectedRows.length > 0 && (
          <div>
            <Grid
              style={{ backgroundColor: palette?.primary?.light, minHeight: "60px", display: "flex", alignItems: "center" }}
              container
              paddingX={2}
            >
              <Grid container item xs={12} justifyContent='flex-end'>
                <>
                  <Typography flex={1} fontSize={"16px"} lineHeight={2}>
                    {selectedRows?.length} selected
                  </Typography>
                </>

                {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.enableSelectedRows)) && (
                  <>
                    {actions
                      ?.filter(action => action.enableSelectedRows)
                      .map(action => (
                        <PermissibleRender isAllowed key={action.id} action={["VIEW"]} path={[path, "action", action.label].join(".")}>
                          {({ permitted }) => (
                            <Box key={action.id} marginInlineStart={1} alignSelf='center'>
                              <>
                                <ActionButton
                                  path={[path, action.label].join(".")}
                                  queryKeys={queryKeys}
                                  viewName={info?.viewName}
                                  pageId={pageId}
                                  item={{}}
                                  tableId={id}
                                  views={views}
                                  __data={__data}
                                  closeModal={closeModal}
                                  iconButton={!action?.showButtonLabel}
                                  parentIds={parentIds}
                                  entries={entries}
                                  selectedRows={selectedRows}
                                  disabled={!permitted}
                                  tableAction={{
                                    action: action,
                                    label: action?.label,
                                    icon:
                                      (action?.iconConfig?.visibility === "PUBLIC" &&
                                      action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                        ? action?.iconConfig?.url
                                        : action?.iconConfig?.icon) || action?.icon,
                                    condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                                  }}
                                  isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                                  actionsMap={action?.actionsMap}
                                  actionsKey={action?.actionsKey}
                                  isChainMapped={action?.isChainMapped}
                                  conditionKeyFromTable={action?.conditionActionMapKey}
                                  actionIndex={action?.actionIndex}
                                  actions={actions}
                                />
                              </>
                            </Box>
                          )}
                        </PermissibleRender>
                      ))}
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        )}

        {selectedRows.length == 0 &&
          (!info?.hideViewName || !info?.hideRefreshButton || !info.hidePaginationBar || info?.showApiMode !== "Hidden") && (
            <Grid
              style={{ minHeight: !isHeaderVisible ? 0 : "60px", alignItems: "center" }}
              container
              paddingX={isCustomLayout ? 0 : 2}
              mt={2}
            >
              <Grid container item xs={12} justifyContent='flex-end'>
                {!showModalHeader && !info?.hideViewName && (
                  <>
                    <Typography flex={1} fontSize={"16px"} lineHeight={2}>
                      {replaceDataPlaceholders({
                        queryString: info?.name,
                        viewsState,
                        pageId,
                        env: currentApp?.env,
                        __data,
                      })}
                    </Typography>
                  </>
                )}
                {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isGlobal && !action?.enableSelectedRows)) && (
                  <>
                    {actions
                      ?.filter(action => action.isGlobal)
                      .map(action => (
                        <PermissibleRender isAllowed action={["VIEW"]} path={[path, "action", action.label].join(".")}>
                          {({ permitted }) => (
                            <Box key={action.id} marginInlineStart={1} alignSelf='center'>
                              <>
                                <ActionButton
                                  path={[path, action.label].join(".")}
                                  queryKeys={queryKeys}
                                  viewName={info?.viewName}
                                  pageId={pageId}
                                  item={{}}
                                  tableId={id}
                                  tableAction={{
                                    action: action,
                                    label: action?.label,
                                    icon:
                                      (action?.iconConfig?.visibility === "PUBLIC" &&
                                      action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                        ? action?.iconConfig?.url
                                        : action?.iconConfig?.icon) || action?.icon,
                                    condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                                  }}
                                  views={views}
                                  __data={__data}
                                  closeModal={closeModal}
                                  iconButton={!action?.showButtonLabel}
                                  parentIds={parentIds}
                                  entries={entries}
                                  disabled={!permitted}
                                  isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                                  actionsMap={action?.actionsMap}
                                  actionsKey={action?.actionsKey}
                                  isChainMapped={action?.isChainMapped}
                                  conditionKeyFromTable={action?.conditionActionMapKey}
                                  actionIndex={action?.actionIndex}
                                  actions={actions}
                                  _key={action.id}
                                />
                              </>
                            </Box>
                          )}
                        </PermissibleRender>
                      ))}
                  </>
                )}
                {selectedRows.length > 0 && (
                  <Tooltip title='Delete'>
                    <Box marginInlineStart={1} alignSelf='center'>
                      <IconButton style={{ backgroundColor: palette.primary.light }}>
                        <DeleteIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
                {!showModalHeader && !info?.hideRefreshButton && (
                  <Tooltip title='Refresh'>
                    <Box marginInlineStart={1} alignSelf='center'>
                      <IconButton
                        onClick={() => {
                          setIsRefreshing(true);
                          const key = `${pageId}.${info?.viewName}`;
                          let currentCursor = cursors?.[currentPage - 1];
                          handleRefetchQueries({
                            isGraphQL,
                            queriesStateGraphQL,
                            key,
                            setIsRefreshing,
                            produceTrigger,
                            pageId,
                            viewName: info?.viewName,
                            currentCursor,
                          });
                        }}
                        disabled={isFetching}
                        style={{ backgroundColor: palette.primary.light }}
                      >
                        {isRefreshing ? (
                          <CustomLoader loadingIcon={info?.loadingIcon} />
                        ) : (
                          <IconRefresh color={palette.text.primary} size={16} />
                        )}
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}

                {!withInfiniteScroll && !!entries?.length && !info.hidePaginationBar && (
                  <>
                    <Pagination
                      currentPage={currentPage}
                      totalPageCount={totalPageCount}
                      // hasNextPage={_.get(data, (dataSource?.hasMoreKey as PropertyPath) || "hasMore") && !isFetching}
                      hasNextPage={currentPage == 1 ? _.get(data, (dataSource?.hasMoreKey as PropertyPath) || "hasMore") : hasMore.current}
                      isFetching={isFetching}
                      onPageChange={async (page: number) => {
                        const targetKey = `${pageId}.${info?.viewName}`;

                        let currentCursor = cursors?.[page - 1];
                        await fetchNextPage?.(currentCursor, true, true);
                        hasMore.current = getValue(`${targetKey}.data._pagination.hasMore`, { pageId, viewName: info?.viewName });
                        const lengthViewData = getValue(`${targetKey}.data._pagination.length`, { pageId, viewName: info?.viewName });
                        if (!hasMore.current && lengthViewData == 0) {
                          setCurrentPage(page - 1);
                        } else {
                          setCurrentPage(page);
                        }
                      }}
                    />
                  </>
                )}
                {info?.showApiMode !== "Hidden" && !showModalHeader && (
                  <Box
                    display='flex'
                    alignItems='center'
                    borderColor='palette.text.primary'
                    marginInlineStart={2}
                    paddingX={1.5}
                    borderRadius={10}
                    border={1}
                  >
                    <Typography>API Mode</Typography>
                    <Switch onChange={(_, value) => setIsApiMode(value)} />
                  </Box>
                )}
              </Grid>

              {dataSource?.sourceType == "USER INPUT" && <Box width='100%'></Box>}
            </Grid>
          )}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer ref={containerRef} sx={{ position: "relative" }}>
            {isApiMode ? (
              <ReactJson src={entries} theme={"paraiso"} style={{ width: "100%" }} />
            ) : (
              <>
                {!_.isEmpty(entries) ? (
                  <Box sx={{ position: "relative", width: "100%" }}>
                    {isFetching && !withInfiniteScroll && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          zIndex: 999999,
                        }}
                      >
                        <CustomLoader loadingIcon={info?.loadingIcon} />
                      </Grid>
                    )}
                    <TableVirtuoso
                      context={{
                        id,
                        order,
                        selectedRows,
                        selectedRow,
                        handleSelectRow,
                        handleDragHover,
                        handleUpdateElement,
                        disableRowSelect: info?.disableRowSelect,
                      }}
                      data={entries}
                      totalCount={entries?.length || 0}
                      endReached={() => {
                        loadMore?.();
                      }}
                      computeItemKey={(_index, item) => item.__id}
                      {...(info?.enableRowHeight && info?.rowHeight
                        ? {
                            increaseViewportBy: Number(info?.rowHeight ?? 0) * 5,
                            overscan: Number(info?.rowHeight ?? 0) * 5,
                            defaultItemHeight: Number(info?.rowHeight),
                            itemSize: () => Number(info?.rowHeight),
                          }
                        : {
                            increaseViewportBy: 200,
                            overscan: 200,
                          })}
                      fixedHeaderContent={fixedHeaderContent}
                      itemContent={rowRenderer}
                      components={VirtuosoTableComponents}
                      useWindowScroll
                      customScrollParent={parentRef?.current}
                    />
                    {isFetching && withInfiniteScroll && (
                      <Grid item xs={12} display={"flex"} justifyContent={"center"} alignContent='center' paddingY={2}>
                        <CustomLoader loadingIcon={info?.loadingIcon} />
                      </Grid>
                    )}
                  </Box>
                ) : (
                  <Table>
                    <TableHead>{fixedHeaderContent()}</TableHead>
                    <TableRow sx={info?.disableRowSelect ? { background: "transparent !important" } : {}}>
                      <TableCell colSpan={(columns.length || 0) + 1}>
                        {isFetching ? (
                          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                            <CustomLoader loadingIcon={info?.loadingIcon} />
                          </Grid>
                        ) : (
                          <Typography component='div' align='center'>
                            {dataSource?.sourceType !== "TABLE"
                              ? "No Records Available"
                              : `Select Data from ${dataSource?.table?.info?.name} Table`}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                )}
              </>
            )}
          </TableContainer>
        </Paper>
      </Card>
    </Box>
  );
};
