import { loadStripe } from "@stripe/stripe-js/pure";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { replaceBaseUrl } from "src/features/buildxProvider/buildxProviderUtils";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import store from "src/store/store";
import { UIElement } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import { getAuth, loginToApp, registerAppDevice } from "src/utils/buildxProviderOperations";
import { getAuthorizationHeader } from "src/utils/generalUtils";
import { adminPaths, formBuilderPath, loginPath, pageBuilderPath } from "../config";
import useAuth from "./useAuth";

const useBuildxProvider = () => {
  const dispatch = useDispatch();
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const appTokens = useBuildxProviderValue("appTokens");
  const currentProfilePermissions = useBuildxProviderValue("currentProfilePermissions");
  const appDescriptor = useBuildxProviderValue("appDescriptor");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const loadingApps = useBuildxProviderValue("loadingApps");
  const currentApp = useBuildxProviderValue("currentApp");
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const stripeEndpoints = useBuildxProviderValue("stripeEndpoints");
  const stripePromises = useBuildxProviderValue("stripePromises");
  const clientSecrets = useBuildxProviderValue("clientSecrets");
  const allowedApps = useBuildxProviderValue("allowedApps");
  const isSwitchingMode = useBuildxProviderValue("isSwitchingMode");
  const navigate = useNavigate();
  const location = useLocation();
  const { replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders();
  const { checkPermissions, appRoutesMap } = useBuildxContext();
  const { loginSuccess, loginFailure } = useAuth();

  useEffect(() => {
    if (stripeEndpoints.length > 0) {
      const updatedStripePromises = [...stripePromises];
      const updatedClientSecrets = [...clientSecrets];
      stripeEndpoints.forEach(async ({ id, publishableKeyEndpoint, clientSecretKeyEndpoint }) => {
        if (publishableKeyEndpoint && clientSecretKeyEndpoint) {
          const publishableResponse = await fetch(publishableKeyEndpoint);
          const { publishableKey } = await publishableResponse.json();
          loadStripe.setLoadParameters({ advancedFraudSignals: false });
          const stripePromise = await loadStripe(publishableKey);
          const existingStripePromise = updatedStripePromises.find(entry => entry.id === id);
          if (existingStripePromise) {
            existingStripePromise.stripePromise = stripePromise;
          } else {
            updatedStripePromises.push({ id, stripePromise });
          }
          const secretResponse = await fetch(clientSecretKeyEndpoint, {
            method: "POST",
            body: JSON.stringify({}),
          });
          const { clientSecret } = await secretResponse.json();

          const existingClientSecret = updatedClientSecrets?.find(entry => entry.id === id);
          if (existingClientSecret) {
            existingClientSecret.clientSecret = clientSecret;
          } else {
            updatedClientSecrets.push({ id, clientSecret });
          }
        }
        dispatch(setProviderState({ stripePromises: updatedStripePromises, clientSecrets: updatedClientSecrets }));
      });
    }
  }, [stripeEndpoints]);

  useEffect(() => {
    if (!loadingApps) {
      if (isAdministrationMode) {
        if (
          adminPaths.includes(location.pathname) ||
          location.pathname.startsWith(formBuilderPath) ||
          location.pathname.startsWith(pageBuilderPath)
        ) {
          navigate({ pathname: location.pathname });
        } else {
          localStorage.setItem("app-location", location.pathname);
          const adminLocation = localStorage.getItem("admin-location");
          if (!adminLocation) {
            navigate({ pathname: "/buildx/app" });
          } else {
            navigate({ pathname: adminLocation });
          }
        }

        if (currentApp?.name) {
          document.title = currentApp.name;
        }
      } else {
        document.title = fqdnApp?.name || process.env.REACT_APP_NAME!;
        const appLocation = localStorage.getItem("app-location");

        if (!appLocation) {
          if (
            !adminPaths.includes(location.pathname) &&
            !location.pathname.startsWith(formBuilderPath) &&
            !location.pathname.startsWith(pageBuilderPath) &&
            !location.pathname.startsWith(loginPath) &&
            !location.pathname.startsWith("/")
          ) {
            localStorage.setItem("admin-location", "/buildx/app");
            navigate({ pathname: "/buildx/app" });
          }
        } else {
          if (adminPaths.includes(location.pathname)) {
            localStorage.setItem("admin-location", location.pathname);
          }
          navigate({ pathname: appLocation });
        }
      }
    }
  }, [isAdministrationMode]);

  useEffect(() => {
    if (currentApp?.id && currentApp?.multilingualId) {
      axios
        .get(`${process.env.REACT_APP_WITH_BUCKET_ENDPOINT}/i18n/${currentApp?.id}.json`)
        .then(({ data }) => {
          if (data.errorCode === "MULTILINGUAL_NOT_FOUND") {
            dispatch(setProviderState({ multiLingual: null }));
          } else {
            dispatch(setProviderState({ multiLingual: data }));
          }
        })
        .catch(error => {
          dispatch(setProviderState({ multiLingual: null }));
          console.error("Error fetching multilingual data:", error);
        });
    }
  }, [currentApp?.id, currentApp?.multilingualId]);

  const [data, setData] = useState<any>({});

  useEffect(() => {
    const extractedData = _.flatMapDeep(_.flatMapDeep(_.flatMapDeep(allowedApps, "templateConfig.collections"), "pages"), "views");
    const initialStateData = extractedData.reduce((prev, current) => {
      current = current as UIElement;
      let code = current?.dataSource?.simple;
      try {
        code = JSON.parse(current?.dataSource?.simple);
      } catch (e) {}
      return {
        ...prev,
        [current?.id]:
          current?.dataSource?.sourceType !== "SIMPLE" && current?.dataSource?.sourceType !== "LIST" ? current?.dataSource?.url : code,
      };
    }, {});
    setData(initialStateData);
  }, [allowedApps, currentProfilePermissions]);

  useEffect(() => {
    if (currentApp?.id) {
      if (
        (location.pathname === "/buildx/app" ||
          location.pathname === "/buildx/users" ||
          location.pathname === "/buildx/policies" ||
          location.pathname === "/buildx/oas" ||
          location.pathname === "/buildx/env" ||
          location.pathname == "/buildx/component" ||
          location.pathname == "/buildx/templates" ||
          location.pathname == "/buildx/migrations" ||
          location.pathname.startsWith("/buildx/page-builder") ||
          location.pathname.startsWith("/buildx/form-builder")) &&
        currentApp?.name
      ) {
        document.title = currentApp?.name;
      }
      if (
        !currentApp?.appConfig?.auth?.authApi?.uri ||
        currentApp?.appConfig?.auth?.type == "No Auth" ||
        isAdministrationMode ||
        location.pathname.startsWith("/buildx/form-builder")
      ) {
        dispatch(setProviderState({ loadingApps: false }));
        return;
      }
      dispatch(setProviderState({ loadingApps: true }));
      const _currentProfileId = localStorage.getItem(currentApp?.id + "-profileId");
      if (
        localStorage.getItem("admin-login") ||
        isHostAvailable ||
        (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)
          ? true
          : !currentApp?.appConfig?.isSingleSignOn
      ) {
        axiosServices
          .get(`application/${currentApp.id}/profile`)
          .then(({ data }) => {
            if (data?.length) {
              if (!_currentProfileId) {
                localStorage.setItem(currentApp?.id + "-profileId", data?.[0]?.id);
              }
              dispatch(setProviderState({ currentProfileId: _currentProfileId || data?.[0]?.id }));
            }

            let count = 0;
            const prevAppProfiles = store.getState().buildxProvider.appProfiles;
            const prevAppTokens = store.getState().buildxProvider.appTokens;
            const newData = { ...prevAppProfiles };
            newData[currentApp.id] = data;
            if (
              !localStorage.getItem("admin-login") &&
              !isHostAvailable &&
              currentApp?.appConfig?.isSingleSignOn &&
              currentApp?.appConfig?.withProfiles &&
              currentApp?.appConfig?.enableGoogleSignIn
            ) {
              newData[currentApp.id] = [{ signInWithGoogle: true }, ...data];
              const profile = {} as any;
              const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
              const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
              const prev = { ...prevAppTokens };
              prev[currentApp?.id + `-${profile?.id}-accessToken`] = token;
              prev[currentApp?.id + `-${profile?.id}-user`] = JSON.parse(user || "{}");
              dispatch(
                setProviderState({
                  appTokens: prev,
                })
              );
            }

            data?.forEach(async (profile: any, index: number) => {
              try {
                const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
                const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
                count = count + 1;
                if (!token || !user) {
                  await registerAppDevice?.(
                    currentApp?.id,
                    allowedApps,
                    currentApp,
                    currentProfileId,
                    appProfiles,
                    appTokens,
                    replaceDataPlaceholdersRecursively
                  );
                  await loginToApp?.(
                    currentApp?.id,
                    allowedApps,
                    currentApp,
                    isAdministrationMode,
                    location,
                    navigate,
                    appRoutesMap,
                    currentProfileId,
                    replaceDataPlaceholdersRecursively,
                    loginSuccess,
                    loginFailure,
                    profile?.creds?.username,
                    profile?.creds?.password,
                    profile?.creds?.recaptcha,
                    profile?.creds?.profileName,
                    () => {
                      dispatch(setProviderState({ isSwitchingMode: false }));
                    },
                    profile,
                    undefined,
                    undefined,
                    undefined,
                    {
                      skipAuthErrorClear: true,
                    }
                  );
                } else {
                  const appTokens = store.getState().buildxProvider.appTokens;
                  const prev = { ...appTokens };
                  prev[currentApp?.id + `-${profile?.id}-accessToken`] = token;
                  prev[currentApp?.id + `-${profile?.id}-user`] = JSON.parse(user || "{}");
                  dispatch(
                    setProviderState({
                      appTokens: prev,
                    })
                  );
                }

                if (data?.length - 1 == index) {
                  dispatch(setProviderState({ loadingApps: false }));
                }
              } catch (e) {
                dispatch(setProviderState({ loadingApps: false }));
              }
            });

            dispatch(
              setProviderState({
                appProfiles: newData,
              })
            );

            if (!count) {
              dispatch(setProviderState({ loadingApps: false }));
            }
          })
          .catch(() => {
            dispatch(setProviderState({ loadingApps: false }));
          });
      } else {
        const profile = {} as any;
        const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
        const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
        const appTokens = store.getState().buildxProvider.appTokens;
        const prev = { ...appTokens };
        prev[`${currentApp?.id}-${profile?.id}-accessToken`] = token;
        prev[`${currentApp?.id}-${profile?.id}-user`] = JSON.parse(user || "{}");
        dispatch(
          setProviderState({
            appTokens: prev,
          })
        );

        dispatch(setProviderState({ loadingApps: false }));
      }
    }
  }, [currentApp?.id, JSON.stringify(currentApp?.appConfig?.auth?.authApi), isSwitchingMode]);

  useEffect(() => {
    const { token } = getAuth(currentApp?.id!, currentProfileId, appProfiles, appTokens, currentApp) || {};
    if (currentProfileId && currentApp?.id && token && !currentApp?.appConfig?.isSingleSignOn) {
      axios
        .get(`${replaceBaseUrl("/user/me", currentApp)}`, {
          headers: { ...getAuthorizationHeader(currentApp?.appConfig?.auth, token) },
        })
        .then(({ data }) => {
          const appTokens = store.getState().buildxProvider.appTokens;
          const prev = { ...appTokens };
          prev[currentApp?.id + `-${currentProfileId}-user`] = data;

          dispatch(
            setProviderState({
              appTokens: prev,
            })
          );
          localStorage.setItem(currentApp?.id + `-${currentProfileId}-user`, JSON.stringify(data));
        });
    }
  }, [currentProfileId, currentApp?.id]);

  // <BuildxProvider-Changes>
  useEffect(() => {
    const allowedApps = appDescriptor.filter(app => checkPermissions([{ path: app.name, action: "VIEW" }]));

    dispatch(setProviderState({ allowedApps: allowedApps }));
  }, [appDescriptor, checkPermissions]);
  // </BuildxProvider-Changes/>
  return {};
};

export default useBuildxProvider;
