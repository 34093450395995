// material-ui
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Theme } from "@mui/material/styles";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";

// third-party
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import { useCallback } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { isHostAvailable } from "src/App";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import useAuth from "src/hooks/useAuth";
import { CurrentAppUserMenu } from "src/layout/MainLayout/Header/CurrentAppUserMenu";
import { drawerWidth } from "src/stores/constant";
import { BXApp } from "src/types/BXAppType";
import { pagesToSidebarItems } from "src/utils/transformers";
import LogoSection from "../LogoSection";
import MenuList from "./MenuList";
// style constant
const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRight: "none",
    [theme.breakpoints.up("md")]: {
      top: "85px !important",
    },
    borderRadius: 10,
  },
  ScrollHeight: {
    height: (props: any) => (props?.builderMode ? "100%" : "calc(100vh - 82px)"),
    [theme.breakpoints.down("sm")]: {
      height: (props: any) => (props?.builderMode ? "100%" : "calc(100vh - 56px)"),
    },
  },
  boxContainer: {
    display: "flex",
    padding: "16px",
    marginInlineStart: "auto",
    marginInlineEnd: "auto",
  },
}));

// ==============================|| SIDEBAR DRAWER ||============================== //

export interface SidebarProps {
  drawerOpen?: boolean;
  drawerToggle?: () => void;
  window?: Window;
  builderMode?: boolean;
}

const Sidebar = ({ drawerOpen, drawerToggle, window, builderMode }: SidebarProps) => {
  const classes = useStyles({ builderMode });
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { isSuperAdmin, user } = useAuth();
  const _isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const currentApp = useBuildxProviderValue("currentApp");
  const loadingApps = useBuildxProviderValue("loadingApps");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const allowedApps = useBuildxProviderValue("allowedApps");
  const isAdministrationMode = builderMode ? false : _isAdministrationMode;

  const _app = currentApp ?? fqdnApp;
  const application: BXApp | null = allowedApps?.find(app => app.name === _app?.name) || null;

  const menuItems = useCallback(
    (user: any, isAdministrationMode?: boolean) => pagesToSidebarItems(application, isAdministrationMode, user, fqdnApp),
    [application]
  );

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <div className={classes.boxContainer}>
          <LogoSection />
        </div>
      </Box>
      <BrowserView style={{ height: "100%" }}>
        <PerfectScrollbar component='div' className={classes.ScrollHeight}>
          {/* <div style={{ marginTop: 10 }}>{isSuperAdmin() && <AdministrationSwitcher />}</div> */}
          {(localStorage.getItem("admin-login") ||
            isHostAvailable ||
            (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)) && <CurrentAppUserMenu />}

          <Box padding={2} paddingInlineEnd={0}>
            {menuItems(user).length === 0 && !isAdministrationMode && !loadingApps && (
              <div>
                {!allowedApps?.length
                  ? "You are not connected to any application, contact the administrator"
                  : "No pages created in this app yet"}{" "}
              </div>
            )}
            <MenuList menuItems={menuItems(user, isAdministrationMode)} />
          </Box>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList menuItems={menuItems(user, isAdministrationMode)} />
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  if (builderMode && matchUpMd) {
    return <Box sx={{ backgroundColor: "background.paper", height: "100%" }}>{drawer}</Box>;
  }

  return (
    <nav className={classes.drawer} aria-label='mailbox folders'>
      {drawerOpen && (
        <Drawer
          container={container}
          variant={matchUpMd ? "persistent" : "temporary"}
          anchor='left'
          open={drawerOpen}
          onClose={drawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{ keepMounted: true }}
          color='inherit'
        >
          {drawer}
        </Drawer>
      )}
    </nav>
  );
};

export default Sidebar;
