import { Box } from "@mui/material";
import _ from "lodash";
import { useSelector } from "react-redux";
import { BXIcon } from "src/components/BXUI/Icon";
import { useAppState } from "src/features/appState/hooks";
import { selectComponentById } from "src/features/builder/selectors";
import { updateStepperGroupIndex } from "../../utils";

export const StepperNavigator = props => {
  const { watch, setValue } = useAppState();

  const { config, $config, stepperGroups: _stepperGroups, metaData } = props;
  const { pageId, viewName } = metaData || {};

  const isInBuilder = Boolean($config);

  const {
    groupReference,
    bulletsClickable,
    orientation,
    bulletHoverOpacity,
    bulletHoverColor,
    selectedBulletShape,
    selectedBulletColor,
    bulletDisplay,
    unselectedBulletOpacity,
    unselectedBulletColor,
    unselectedBulletShape,
    selectedBulletOpacity,
    selectedBulletWidth,
    selectedBulletHeight,
    unselectedBulletHeight,
    unselectedBulletWidth,
    selectedBulletBorderColor,
    unselectedBulletBorderColor,
    selectedBulletBorderWidth,
    unselectedBulletBorderWidth,
    selectedBulletIconName,
    unselectedBulletIconName,
    selectedBulletIconColor,
    unselectedBulletIconColor,
    selectedBulletIconSize,
    unselectedBulletIconSize,
    currentBulletColor,
    currentBulletOpacity,
    currentBulletShape,
    currentBulletWidth,
    currentBulletHeight,
    currentBulletBorderColor,
    currentBulletBorderWidth,
    currentBulletIconName,
    currentBulletIconColor,
    currentBulletIconSize,
  } = $config?.navigator || config?.navigator;

  const groupReferenceItem = useSelector(state => selectComponentById(state, groupReference?.id));

  const stepperGroup = isInBuilder
    ? groupReferenceItem?.props?.defaultValue
    : watch(`${pageId}.${viewName}.${groupReference.key}.state`, { pageId, viewName });

  const pages = stepperGroup?.pages || [{}];
  const selectedPageIndex = _.isFinite(+stepperGroup?.selectedPage)
    ? +stepperGroup.selectedPage
    : Math.max(
        0,
        pages?.findIndex(page => page.pageReference === stepperGroup?.selectedPage)
      );

  const currentIndex = (isInBuilder ? stepperGroup?.previewIndex : selectedPageIndex) || 0;

  const setStepperIndex = (index: number) => {
    if (isInBuilder) return;
    setValue(`${pageId}.${viewName}.${groupReference.key}.state`, updateStepperGroupIndex(stepperGroup, "SET", index), {
      pageId,
      viewName,
      reEvaluateErrorsAndDirty: true,
    });
  };

  return (
    <Box
      display='flex'
      gap={3}
      maxHeight='fit-content'
      flexWrap='wrap'
      justifyContent='center'
      alignItems='center'
      flexDirection={orientation === "vertical" ? "column" : "row"}
      padding={1}
      sx={{ transition: "all 0.3s" }}
    >
      {pages.map((_item, index) => {
        const isSelected = bulletDisplay === "showSelectedOnly" ? index === currentIndex : index <= currentIndex;
        const isCurrent = index === currentIndex;

        const bulletColor = isCurrent && currentBulletColor ? currentBulletColor : isSelected ? selectedBulletColor : unselectedBulletColor;

        const bulletOpacity =
          isCurrent && currentBulletOpacity ? currentBulletOpacity : isSelected ? selectedBulletOpacity : unselectedBulletOpacity;

        const bulletShape = isCurrent && currentBulletShape ? currentBulletShape : isSelected ? selectedBulletShape : unselectedBulletShape;

        const bulletWidth = isCurrent && currentBulletWidth ? currentBulletWidth : isSelected ? selectedBulletWidth : unselectedBulletWidth;

        const bulletHeight =
          isCurrent && currentBulletHeight ? currentBulletHeight : isSelected ? selectedBulletHeight : unselectedBulletHeight;

        const borderColor =
          isCurrent && currentBulletBorderColor
            ? currentBulletBorderColor
            : isSelected
            ? selectedBulletBorderColor
            : unselectedBulletBorderColor;

        const borderWidth =
          isCurrent && currentBulletBorderWidth
            ? currentBulletBorderWidth
            : isSelected
            ? selectedBulletBorderWidth
            : unselectedBulletBorderWidth;

        const iconName =
          isCurrent && currentBulletIconName ? currentBulletIconName : isSelected ? selectedBulletIconName : unselectedBulletIconName;

        const iconColor =
          isCurrent && currentBulletIconColor ? currentBulletIconColor : isSelected ? selectedBulletIconColor : unselectedBulletIconColor;

        const iconSize =
          isCurrent && currentBulletIconSize ? currentBulletIconSize : isSelected ? selectedBulletIconSize : unselectedBulletIconSize;

        return (
          <Box
            key={index}
            onClick={() => bulletsClickable && setStepperIndex(index)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "all 0.2s",
              opacity: bulletOpacity,
              width: bulletWidth,
              height: bulletHeight,
              bgcolor: bulletColor,
              borderRadius: bulletShape,
              border: borderColor ? `${borderWidth || "1px"} solid ${borderColor}` : "none",
              cursor: bulletsClickable ? "pointer" : "default",
              "&:hover": {
                opacity: bulletHoverOpacity,
                bgcolor: bulletHoverColor,
              },
            }}
          >
            {iconName && <BXIcon icon={iconName} color={iconColor || "#fff"} width={iconSize || "50%"} height={iconSize || "50%"} />}
          </Box>
        );
      })}
    </Box>
  );
};
