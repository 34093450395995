import {
  Box,
  Button,
  DialogActions,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { IconTrashX } from "@tabler/icons-react";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addComponent, updateComponent } from "src/features/builder/builderSlice";
import { selectComponentById, selectFullComponentItemById } from "src/features/builder/selectors";
import store from "src/store/store";
import { v4 as uuid } from "uuid";
import { components } from "../FormBuilder/utils";

type TableContainerConfig = {
  componentId?: string;
  setIsEditColumnOpen?: any;
  isBuilder?: boolean;
  handleConfigChange?: any;
  onChangeProp?: any;
  onDeleteItem?: any;
};

export const TableContainerConfig: FC<TableContainerConfig> = ({ componentId, setIsEditColumnOpen, onDeleteItem }) => {
  const item = useSelector(selectFullComponentItemById(componentId));
  const tableHeader = useSelector(selectFullComponentItemById(item?.children[0]));
  const tableBody = useSelector(selectFullComponentItemById(item?.children[1]));
  const dispatch = useDispatch();

  const [inputValues, setInputValues] = useState<any[]>([]);

  const handleInputChange = (index: number, field: string, value: string) => {
    setInputValues(prev => {
      const updatedValues = [...prev];
      updatedValues[index] = { ...updatedValues[index], [field]: value };
      return updatedValues;
    });
  };

  useEffect(() => {
    const initialValues = tableHeader?.children?.map((child: any, index: number) => {
      const fullItemHeader = selectComponentById(store.getState(), child);
      const fullItemBody = selectComponentById(store.getState(), tableBody?.children[index]);
      return {
        columnLabel: fullItemHeader?.props?.children || "",
        dataSource: fullItemBody?.props?.children || "",
      };
    });
    setInputValues(initialValues || []);
  }, [tableHeader?.children, tableBody?.children]);

  const handleSave = () => {
    const updatedComponents = inputValues.map((value, index) => {
      const fullItemHeader = selectComponentById(store.getState(), tableHeader?.children[index]);
      const fullItemBody = selectComponentById(store.getState(), tableBody?.children[index]);

      const updatedItem = {
        id: fullItemHeader?.id,
        changes: {
          ...fullItemHeader,
          props: {
            ...fullItemHeader.props,
            children: value.columnLabel,
          },
        },
      };

      const updatedDataSource = {
        id: fullItemBody?.id,
        changes: {
          ...fullItemBody,
          props: {
            ...fullItemBody.props,
            children: value.dataSource,
          },
        },
      };

      return [updatedItem, updatedDataSource];
    });

    const flattenedUpdates = updatedComponents.flat();

    flattenedUpdates.forEach(update => {
      dispatch(updateComponent(update));
    });

    setIsEditColumnOpen(false);
  };
  const handleAddColumn = () => {
    const newColumn = components.find((comp: any) => comp?.type === "Typography") || {};
    const newIdHeader = uuid();
    const newItemHeader = {
      ...newColumn,
      id: newIdHeader,
      parentId: tableHeader.id,
      props: {
        ...newColumn.props,
        key: `Typography${newIdHeader}`,
      },
    };
    const updatedTableHeader = {
      ...tableHeader,
      children: [...(tableHeader.children || []), newIdHeader],
    };

    const newIdBody = uuid();
    const newItemBody = {
      ...newColumn,
      id: newIdBody,
      parentId: tableBody.id,
      props: {
        ...newColumn.props,
        key: `Typography${newIdBody}`,
      },
    };
    const updatedTableBody = {
      ...tableBody,
      children: [...(tableBody.children || []), newIdBody],
    };

    dispatch(addComponent(newItemHeader));
    dispatch(addComponent(newItemBody));
    dispatch(
      updateComponent({
        id: tableHeader.id,
        changes: { children: updatedTableHeader.children },
      })
    );
    dispatch(
      updateComponent({
        id: tableBody.id,
        changes: { children: updatedTableBody.children },
      })
    );
  };

  const handleDeleteColumn = index => {
    let itemsIds: any = [];
    const headerId = tableHeader.children[index];
    const bodyId = tableBody.children[index];
    itemsIds.push(headerId);
    itemsIds.push(bodyId);

    onDeleteItem(itemsIds, true);
  };

  return (
    <Box component='form' noValidate autoComplete='off' flex={1}>
      <Grid container spacing={3} px={2} py={1}>
        {/* Table Section */}
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Column Label</TableCell>
                  <TableCell>Cell Type</TableCell>
                  <TableCell>Data Source</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableHeader.children?.map((child, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <TextField
                          value={inputValues[index]?.columnLabel || ""}
                          label='Column Label'
                          onChange={e => handleInputChange(index, "columnLabel", e.target.value)}
                        />{" "}
                      </TableCell>
                      <TableCell>String</TableCell>
                      <TableCell>
                        <TextField
                          sx={{ width: "90%" }}
                          value={inputValues[index]?.dataSource || ""}
                          label='Data Source'
                          onChange={e => handleInputChange(index, "dataSource", e.target.value)}
                        />{" "}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteColumn(index)}>
                          <IconTrashX />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Save Button */}
        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center", display: "flex" }}>
            <Button onClick={handleAddColumn} variant='contained' aria-label='save'>
              Add Column
            </Button>
            <Button onClick={handleSave} variant='contained' aria-label='save'>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
