import { Box, Grid, Switch, Tab, Tabs, TextField, Theme, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import axios from "axios";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setViewBuilder } from "src/features/builder/builderSlice";
import { selectBuilderSettings, selectComponentPropertyByPath } from "src/features/builder/selectors";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import store from "src/store/store";
import { CurrentViewConfig } from "./CurrentViewConfig";
import ComponentEditor from "./components/ComponentEditor/ComponentEditor";
import CurrentViewModals from "./components/ComponentEditor/EditorComponents/CurrentViewModals";
import ItemsPropertiesAccordion from "./components/ComponentEditor/EditorComponents/ItemsPropertiesAccordion";
import Vars from "./components/ComponentEditor/EditorComponents/Vars";
import { TabPanelProps } from "./types";
import { addFlexCanvas, getItemClosestProp, removeFlexCanvas } from "./utils";

const CanvasHeightField: FC<any> = ({ layoutBreak, boxHeightRef }) => {
  const height = useSelector((state: any) => state.builder.height, shallowEqual);

  return (
    <TextField
      InputLabelProps={{ style: { fontSize: "10px" } }}
      InputProps={{ style: { fontSize: "12px" } }}
      size='small'
      disabled
      fullWidth
      label={"Canvas Height"}
      value={getItemClosestProp(height, layoutBreak)}
    />
  );
};

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-2`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface RightSideMenuItemsProps {
  onDeleteItem: (ids: string[]) => void;
  onDiscardEdit: () => void;
  appId: string | undefined;
  collectionId: string | undefined;
  pageId: string | undefined;
  views?: any;
  setView?: any;
  viewId?: string;
  page?: any;
  boxHeightRef?: any;
}

export const RightSideMenuItems = memo(
  ({ onDeleteItem, viewId, appId, collectionId, pageId, page, views, boxHeightRef }: RightSideMenuItemsProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const activeComponent = useSelector((state: any) => state.builder.activeComponent);
    const componentType = useSelector(state => selectComponentPropertyByPath(state, activeComponent, "type"));
    const componentIsGroup = useSelector(state => selectComponentPropertyByPath(state, activeComponent, "config.isGroup"));
    const componentIsChildContainerGroup = useSelector(state =>
      selectComponentPropertyByPath(state, activeComponent, "props.isChildContainerGroup")
    );
    const layoutBreak = useSelector((state: any) => state.builder.layoutBreak);
    const tab = useSelector((state: any) => state.builder.tab);
    const builderSettings = useSelector(selectBuilderSettings);
    const {
      isDynamicHeight,
      isFlexCanvasEnabled,
      isStepperEnabled,
      isCanvasGrid,
      isCanvasFullHeight,
      isSyncTreeWithCanvasEnabled,
      isContainersBorderEnabled,
      isGridOverlayEnabled,
    } = builderSettings;
    const dynamicHeight = !!isDynamicHeight;
    const flexCanvasEnabled = !!isFlexCanvasEnabled;
    const stepperEnabled = !!isStepperEnabled;
    const canvasEnabled = !!isCanvasGrid;
    const canvasFullHeight = !!isCanvasFullHeight;
    const containersBorderEnable = !!isContainersBorderEnabled;
    const gridOverlayEnabled = !!isGridOverlayEnabled;
    // const { currentApp } = useBXContext();

    const currentApp = useBuildxProviderValue("currentApp");
    const [multiLingual, setMultiLingual] = useState<any>();
    const [accordionState, setAccordionState] = useState<any>({
      view: false,
      vars: false,
      modals: false,
    });
    //Edit view UI states

    const handleAccordionChange = (accordionKey: any) => {
      setAccordionState(prevState => ({
        ...prevState,
        [accordionKey]: !prevState[accordionKey],
      }));
    };
    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: number) => {
      dispatch(setViewBuilder({ tab: newValue, addToHistory: false }));
    }, []);

    useEffect(() => {
      if (appId && currentApp?.multilingualId) {
        axios.get(`${process.env.REACT_APP_WITH_BUCKET_ENDPOINT}/i18n/${appId}.json`).then(({ data }) => {
          setMultiLingual(data);
        });
      }
    }, []);

    const onCanvasToggle = useCallback(() => {
      const prevState = store.getState().builder.isCanvasGrid;
      dispatch(setViewBuilder({ isCanvasGrid: !prevState }));
    }, []);

    const handleVarsChange = useCallback(newVars => {
      dispatch(
        setViewBuilder({
          varsConfig: {
            vars: newVars,
          },
        })
      );
    }, []);

    const handleModalsChange = useCallback(newModals => {
      dispatch(
        setViewBuilder({
          modalsConfig: {
            modals: newModals,
          },
        })
      );
    }, []);

    const onFlexCanvasToggle = () => {
      if (!flexCanvasEnabled) {
        addFlexCanvas();
      } else {
        removeFlexCanvas();
        dispatch(setViewBuilder({ activeComponent: undefined, addToHistory: false }));
      }
      const prevState = store.getState().builder.isFlexCanvasEnabled;
      dispatch(setViewBuilder({ isFlexCanvasEnabled: !prevState }));
    };
    const onDynamicHeightToggle = useCallback(() => {
      const prevState = store.getState().builder.isDynamicHeight;
      dispatch(setViewBuilder({ isDynamicHeight: !prevState }));
    }, []);

    const onCanvasFullHeightToggle = useCallback(() => {
      const prevState = store.getState().builder.isCanvasFullHeight;
      dispatch(setViewBuilder({ isCanvasFullHeight: !prevState }));
    }, []);

    const onStepperToggle = useCallback(() => {
      const prevState = store.getState().builder.isStepperEnabled;
      dispatch(setViewBuilder({ isStepperEnabled: !prevState }));
    }, []);

    const onContainersBorderToggle = useCallback(() => {
      const prevState = store.getState().builder.isContainersBorderEnabled;
      dispatch(setViewBuilder({ isContainersBorderEnabled: !prevState }));
    }, []);

    const onGridOverlayToggle = useCallback(() => {
      const prevState = store.getState().builder.isGridOverlayEnabled;
      dispatch(setViewBuilder({ isGridOverlayEnabled: !prevState }));
    }, []);

    return (
      <Box
        sx={{
          backgroundColor: (theme: Theme) => theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.divider}`,
          borderLeft: `1px solid ${theme.palette.divider}`,
          width: "100%",
          height: "100%",
        }}
      >
        <Box sx={{ position: "sticky", top: "80px", zIndex: "1000", maxHeight: "calc(100vh - 100px)", overflow: "auto" }}>
          <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tab} onChange={handleChangeTab} aria-label='basic tabs example'>
              <Tab
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                  textAlign: "center",
                  width: "33.33%",
                  fontWeight: tab === 0 ? 700 : 500,
                }}
                label='Component'
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                  textAlign: "center",
                  width: "33.33%",
                  fontWeight: tab === 1 ? 700 : 500,
                }}
                label='Canvas'
                {...a11yProps(1)}
              />
              {activeComponent?.config?.isContainTabs && (
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "12px",
                    textAlign: "center",
                    width: "33.33%",
                    fontWeight: tab === 2 ? 700 : 500,
                  }}
                  label={"Tabs Settings"}
                  {...a11yProps(2)}
                />
              )}

              <Tab
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                  textAlign: "center",
                  width: "33.33%",
                  fontWeight: tab === 3 ? 700 : 500,
                }}
                label='View'
                {...a11yProps(3)}
              />
            </Tabs>
          </Grid>

          <Box sx={{ overflowY: !!activeComponent ? "auto" : "hidden", overflowX: "hidden" }}>
            <TabPanel value={tab} index={0}>
              <ComponentEditor
                componentId={activeComponent}
                componentType={componentType}
                componentIsGroup={componentIsGroup}
                componentIsChildContainerGroup={componentIsChildContainerGroup}
                multiLingual={multiLingual}
                setMultiLingual={setMultiLingual}
                onDeleteItem={onDeleteItem}
                layoutBreak={layoutBreak}
                appId={appId}
                collectionId={collectionId}
                pageId={pageId}
                views={views}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container px={1} py={2} spacing={1}>
                {!!activeComponent && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        InputLabelProps={{ style: { fontSize: "12px" } }}
                        InputProps={{ style: { fontSize: "12px" } }}
                        size='small'
                        fullWidth
                        label={"Key"}
                      />
                    </Grid>
                    <Grid item xs={6} py={2}>
                      <TextField
                        InputLabelProps={{ style: { fontSize: "12px" } }}
                        InputProps={{ style: { fontSize: "12px" } }}
                        size='small'
                        fullWidth
                        label={"Name"}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={6} py={2}>
                  <CanvasHeightField layoutBreak={layoutBreak} boxHeightRef={boxHeightRef} />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Enable Stepper</Typography>
                  <Switch size='small' checked={stepperEnabled} onChange={onStepperToggle} inputProps={{ "aria-label": "controlled" }} />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Canvas Grid</Typography>
                  <Switch size='small' checked={canvasEnabled} onChange={onCanvasToggle} inputProps={{ "aria-label": "controlled" }} />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Dynamic Height</Typography>
                  <Switch
                    size='small'
                    checked={dynamicHeight}
                    onChange={onDynamicHeightToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Canvas Flex</Typography>
                  <Switch
                    size='small'
                    checked={flexCanvasEnabled}
                    onChange={onFlexCanvasToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Canvas Full Height</Typography>
                  <Switch
                    size='small'
                    checked={canvasFullHeight}
                    onChange={onCanvasFullHeightToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Containers Border</Typography>
                  <Switch
                    size='small'
                    checked={containersBorderEnable}
                    onChange={onContainersBorderToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Grid Overlay</Typography>
                  <Switch
                    size='small'
                    checked={gridOverlayEnabled}
                    onChange={onGridOverlayToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tab} index={2}>
              <ItemsPropertiesAccordion expanded={accordionState.view} onChange={() => handleAccordionChange("view")} label='View'>
                <CurrentViewConfig
                  appId={appId as string}
                  collectionId={collectionId as string}
                  pageId={pageId as string}
                  viewId={viewId as string}
                  page={page}
                  views={views}
                />
              </ItemsPropertiesAccordion>
              <ItemsPropertiesAccordion expanded={accordionState.vars} onChange={() => handleAccordionChange("vars")} label='Variables'>
                <Vars handleVarsChange={handleVarsChange} />
              </ItemsPropertiesAccordion>
              <ItemsPropertiesAccordion expanded={accordionState.modals} onChange={() => handleAccordionChange("modals")} label='Modals'>
                <CurrentViewModals handleModalsChange={handleModalsChange} onDeleteItem={onDeleteItem} />
              </ItemsPropertiesAccordion>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    );
  }
);
