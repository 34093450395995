import React, { FC, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { setViewBuilder } from "../../../../features/builder/builderSlice";
import { useWindowSize } from "../../../../hooks/useScreenSize";

interface WindowSizeProps {
  boxRef: React.RefObject<HTMLDivElement>;
  scaleFactor: number;
}

const WindowSize: FC<WindowSizeProps> = ({ boxRef, scaleFactor }) => {
  const { scrollX, scrollY } = useWindowSize();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (!boxRef.current) return;

    const node = boxRef.current;

    const updateBoxSize = () => {
      if (!node) return;
      const rect = node.getBoundingClientRect();
      dispatch(
        setViewBuilder({
          boxPosition: {
            width: rect.width / (scaleFactor || 1),
            height: rect.height / (scaleFactor || 1),
            x: rect.left - scrollX / (scaleFactor || 1),
            y: rect.top - scrollY / (scaleFactor || 1),
          },
        })
      );
    };

    updateBoxSize();

    const resizeObserver = new ResizeObserver(() => {
      updateBoxSize();
    });

    resizeObserver.observe(node);

    const handleWindowResize = () => {
      updateBoxSize();
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [boxRef, scaleFactor]);

  return null;
};

export default WindowSize;
