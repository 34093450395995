import { Box, IconButton, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IconPaperclip, IconSend } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";

interface Message {
  id: string;
  content: string;
  sender: 'user' | 'assistant';
  timestamp: Date;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  messageContainer: {
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  message: {
    maxWidth: '80%',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
  },
  userMessage: {
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  assistantMessage: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.grey[100],
  },
  inputContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    minHeight: '85px', // Add minimum height
  },
  input: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      minHeight: '60px', // Ensure minimum height for 2 lines
    },
  },
  iconButton: {
    width: '40px',
    height: '40px',
    flexShrink: 0, // Prevent icon button from shrinking
    alignSelf: 'flex-end', // Align buttons to bottom
  },
}));

export const Chat = () => {
  const classes = useStyles();
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = () => {
    if (!input.trim()) return;

    const newMessage: Message = {
      id: Date.now().toString(),
      content: input,
      sender: 'user',
      timestamp: new Date(),
    };

    setMessages(prev => [...prev, newMessage]);
    setInput('');
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.messageContainer}>
        {messages.map((message) => (
          <Paper
            key={message.id}
            className={`${classes.message} ${
              message.sender === 'user' ? classes.userMessage : classes.assistantMessage
            }`}
          >
            {message.content}
          </Paper>
        ))}
        <div ref={messagesEndRef} />
      </Box>
      <Box className={classes.inputContainer}>
        <IconButton className={classes.iconButton} size="small">
          <IconPaperclip />
        </IconButton>
        <TextField
          className={classes.input}
          multiline
          minRows={2}
          maxRows={4}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
          size="small"
        />
        <IconButton 
          className={classes.iconButton}
          onClick={handleSend}
          color="primary"
          disabled={!input.trim()}
        >
          <IconSend />
        </IconButton>
      </Box>
    </Box>
  );
};