import { Box, Divider, Theme } from "@mui/material";
import { FC, memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "src/hooks/useScreenSize";
import store from "../../../../../../store/store";
import { getItemClosestProp } from "../../utils";

export const ContainerGrid: FC<{ boxWidth: any; parentContainerRef?: any; boxContextHeight?: any }> = memo(
  ({ boxWidth, parentContainerRef, boxContextHeight }) => {
    const boxRef = useRef<any>();
    const [numberOfVerticalBorders, setNumberOfVerticalBorders] = useState<number>(0);
    const [numberOfHorizontalBorders, setNumberOfHorizontalBorders] = useState<number>(0);
    const { width } = useWindowSize();
    const layoutBreak = useSelector((state: any) => state.builder.layoutBreak);
    const gridOverlayEnabled = useSelector((state: any) => state.builder.isGridOverlayEnabled);
    const boxHeight = getItemClosestProp(boxContextHeight, layoutBreak);

    useEffect(() => {
      const { clientWidth, clientHeight } = parentContainerRef?.current || {};
      setNumberOfVerticalBorders(clientHeight / 10);
      setNumberOfHorizontalBorders(clientWidth / 10);
    }, [layoutBreak, width, boxHeight, boxWidth]);

    return (
      <Box
        ref={boxRef}
        sx={{
          position: "absolute",
          overflow: "hidden",
          top: 0,
          left: 0,
          background: "transparent",
          zIndex: -1,
          height: "100%",
          width: "100%",
          backgroundColor: (theme: Theme) => theme.palette.background.paper,
        }}
      >
        {gridOverlayEnabled && (
          <>
            <Box sx={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}>
              {Array.from({ length: numberOfVerticalBorders }, (_, i) => (
                <Divider key={i} sx={{ width: "100%", marginBottom: "10px", borderStyle: "dotted" }} />
              ))}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}>
              {Array.from({ length: numberOfHorizontalBorders }, (_, i) => (
                <Divider key={i} orientation='vertical' sx={{ width: "10px", height: "100%", borderStyle: "dotted" }} />
              ))}
            </Box>
          </>
        )}
      </Box>
    );
  }
);
