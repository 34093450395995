// builderSlice.js
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { v4 as uuid } from "uuid";

// Create an Entity Adapter
export const builderAdapter = createEntityAdapter({
  selectId: (entity: any) => entity.id,
});

// Define the initial state using the adapter's getInitialState method
const initialState = builderAdapter.getInitialState({
  viewConfiguration: undefined,
  history: [], // Stores past states
  cursor: -1, // Points to the current state in history
  layoutBreak: "lg",
  scaleFactor: 1,
  isRTL: false,
  isCanvasGrid: false,
  isStepperEnabled: false,
  isSyncTreeWithCanvasEnabled: true,
  isGridOverlayEnabled: false,
  isDynamicHeight: true,
  isCanvasFullHeight: false,
  varsConfig: { vars: null },
  modalsConfig: { modals: null },
  isFlexCanvasEnabled: true,
  isContainersBorderEnabled: false,
  height: {},
  width: {},
  canDrop: false,
  activeComponent: undefined,
  selectedItemsId: [],
  duplicateKeyIds: [],
  isKeyboardActive: true,
  tab: 0,
  leftTabValue: 0,
  openStates: {},
  boxPosition: {} as { width: number; height: number; x: number; y: number },
  // ... other state variables
});
const addToHistory = state => {
  if (state.cursor < state.history.length - 1) {
    state.history = state.history.slice(0, state.cursor + 1);
  }

  // Clone current entities and ids
  const currentState = {
    entities: _.cloneDeep(state.entities),
    isCanvasGrid: state.isCanvasGrid,
    isStepperEnabled: state.isStepperEnabled,
    isDynamicHeight: state.isDynamicHeight,
    isCanvasFullHeight: state.isCanvasFullHeight,
    varsConfig: state.varsConfig,
    modalsConfig: state.modalsConfig,
    isFlexCanvasEnabled: state.isFlexCanvasEnabled,
    isContainersBorderEnabled: state.isContainersBorderEnabled,
    isGridOverlayEnabled: state.isGridOverlayEnabled,
  };

  // Add current state to history
  state.history.push(currentState);

  // Update cursor
  state.cursor = state.history.length - 1;
};

const builderSlice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    // setViewBuilder replaces all existing builder entities with new ones and manages history
    setViewBuilder(state, action) {
      const { builderElements, ...settings } = action.payload;
      // Set the new builder entities
      if (builderElements) {
        builderAdapter.setAll(state, builderElements);
      }

      if (action.payload.addToHistory !== false) {
        addToHistory(state);
      }

      Object.keys(settings).forEach(key => {
        if (key === undefined) {
          return;
        }

        state[key] = settings[key];
      });
    },
    // addComponent adds a single new component and manages history
    addComponent(state, action) {
      // Add the new component
      builderAdapter.addOne(state, { id: uuid(), ...action.payload });
      addToHistory(state);
    },
    // updateComponent updates an existing component by ID and manages history
    updateComponent(state, action) {
      // Update the component
      builderAdapter.updateOne(state, action.payload);
      if (!action.payload.isCopy) {
        addToHistory(state);
      }
    },
    upsertComponents(state, action) {
      // Upsert multiple components
      const skipHistory = action?.payload?.some(item => item?.skipHistory) || false;
      const payloadWithoutSkipHistory = action.payload.map(({ skipHistory, ...rest }) => rest);

      builderAdapter.upsertMany(state, payloadWithoutSkipHistory);

      if (!skipHistory && action.payload.length > 0) {
        addToHistory(state);
      }
    },
    // removeComponent removes a component by ID and manages history
    removeComponent(state, action) {
      // Remove the component
      const id = action.payload?.id ?? action.payload;
      builderAdapter.removeOne(state, id);
      if (!action.payload?.isChild) {
        addToHistory(state);
      }
    },
    // New reducer to remove a child ID from a parent's children array
    removeChildFromParent(state, action) {
      const { parentId, childId } = action.payload;

      // Use the adapter's selectors to find the parent
      const parent = state.entities[parentId];

      if (parent && parent.children) {
        // Remove the child ID from the parent's children array
        parent.children = parent.children.filter(id => id !== childId);
      }
    },
    updateSelectedItemsId(state: any, action) {
      const { itemId, newSelectedItems } = action.payload;
      if (state.selectedItemsId.includes(itemId)) {
        state.selectedItemsId = state.selectedItemsId.filter(id => id !== itemId);
      } else {
        state.selectedItemsId.push(...newSelectedItems);
      }
    },
    stepBackState(state: any) {
      if (state.cursor > 0) {
        state.cursor -= 1;
        const prevState = state.history[state.cursor];
        builderAdapter.setAll(state, prevState.entities);
        state.isCanvasGrid = prevState.isCanvasGrid;
        state.isStepperEnabled = prevState.isStepperEnabled;
        state.isDynamicHeight = prevState.isDynamicHeight;
        state.isCanvasFullHeight = prevState.isCanvasFullHeight;
        state.varsConfig = prevState.varsConfig;
        state.modalsConfig = prevState.modalsConfig;
        state.isFlexCanvasEnabled = prevState.isFlexCanvasEnabled;
        state.isContainersBorderEnabled = prevState.isContainersBorderEnabled;
        state.isGridOverlayEnabled = prevState.isGridOverlayEnabled;
      }
    },

    stepForwardState(state: any) {
      if (state.cursor < state.history.length - 1) {
        state.cursor += 1;
        const nextState = state.history[state.cursor];
        builderAdapter.setAll(state, nextState.entities);
        state.isCanvasGrid = nextState.isCanvasGrid;
        state.isStepperEnabled = nextState.isStepperEnabled;
        state.isDynamicHeight = nextState.isDynamicHeight;
        state.isCanvasFullHeight = nextState.isCanvasFullHeight;
        state.varsConfig = nextState.varsConfig;
        state.modalsConfig = nextState.modalsConfig;
        state.isFlexCanvasEnabled = nextState.isFlexCanvasEnabled;
        state.isContainersBorderEnabled = nextState.isContainersBorderEnabled;
        state.isGridOverlayEnabled = nextState.isGridOverlayEnabled;
      }
    },
    resetBuilderState() {
      return initialState;
    },
  },
});

export const {
  setViewBuilder,
  addComponent,
  updateComponent,
  upsertComponents,
  removeComponent,
  removeChildFromParent,
  stepBackState,
  stepForwardState,
  updateSelectedItemsId,
  resetBuilderState,
} = builderSlice.actions;
export default builderSlice.reducer;
