import { Box, Portal } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import store from "src/store/store";
import { isPartOfModalComponent } from "src/utils/generalUtils";
import { isPageScrollable } from "src/views/pages/BuildX/FormBuilder/utils";

const ResizeElement = props => {
  const {
    stepper = 10,
    children,
    onResize,
    isDragging,
    disableHeight,
    scrollToButton,
    isCanvas,
    isActiveComponentResizable,
    onResizeFinished,
    resizedItemRef,
    additionalProps,
    isFlexCanvasEnabled,
    item,
  } = props;
  const [position, setPosition] = useState<any>({});
  const scaleFactor = useSelector((state: any) => state.builder.scaleFactor);
  const isRTL = useSelector((state: any) => state.builder.isRTL);
  const modalsConfig = useSelector((state: any) => state.builder.modalsConfig?.modals);
  const hasActiveModal = useMemo(() => (modalsConfig || []).some((modal: any) => modal?.isActive), [modalsConfig]);

  useEffect(() => {
    if (resizedItemRef.current && (isActiveComponentResizable || isCanvas)) {
      const { top, left, width, height } = resizedItemRef.current.getBoundingClientRect();

      // Get the scroll offsets
      const scrollX = window.scrollX || document.documentElement.scrollLeft || 0;
      const scrollY = window.scrollY || document.documentElement.scrollTop || 0;

      const handleSize = 5;

      // Calculate bottom position
      const bottom = window.innerHeight - scrollY - (top + height) - handleSize;

      // Calculate right position
      const zoomWidth = isRTL ? 0 : width;
      const right = window.innerWidth - scrollX - (left + zoomWidth) - handleSize;

      // Adjust right position if the page is scrollable vertically
      const verticalScrollbarWidth = document.documentElement.scrollHeight > window.innerHeight ? handleSize : 0;

      const adjustedRight = right - verticalScrollbarWidth;

      // Update the position state if values have changed
      if (position.bottom !== bottom || position.right !== adjustedRight) {
        setPosition(prev => ({ ...prev, bottom, right: adjustedRight }));
      }
    }
  }, [isDragging, children, isRTL]);

  const handleMouseDown = (axis: "x" | "y" | "both") => (e: React.MouseEvent<HTMLDivElement>) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = resizedItemRef.current?.clientWidth || 0;
    const startHeight = resizedItemRef.current?.clientHeight || 0;
    let newWidth = startWidth;
    let newHeight = startHeight;
    let isScrolling = false;
    let autoScrollOffset = 0;
    const handleMouseMove = (e: MouseEvent) => {
      const dx = (e.clientX - startX) / scaleFactor;
      const dy = (e.clientY + autoScrollOffset - startY) / scaleFactor;

      if (axis === "x" || axis === "both") {
        newWidth = startWidth + Math.round((isRTL ? -1 : 1) * dx);

        if (store.getState().builder.boxPosition && !isCanvas) {
          newWidth = newWidth <= store.getState().builder.boxPosition?.width ? newWidth : store.getState().builder.boxPosition?.width;
        }
      }

      if (axis === "y" || axis === "both") {
        const _newHeight = startHeight + Math.round(dy);

        newHeight = document.body.clientHeight - 1 == e.clientY ? newHeight + stepper : _newHeight;

        if (store.getState().builder.boxPosition && !isCanvas) {
          newHeight = newHeight <= store.getState().builder.boxPosition?.height ? newHeight : store.getState().builder.boxPosition?.height;
        }
      }
      if (resizedItemRef.current) {
        const { top, left, width, height } = resizedItemRef?.current?.getBoundingClientRect();
        if (!disableHeight) {
          resizedItemRef.current.style.width = newWidth + "px";
        }
        resizedItemRef.current.style.height = newHeight + "px";

        const handleSize = 5;

        const scrollY =
          window.pageYOffset !== undefined
            ? window.pageYOffset
            : (document.documentElement || document.body.parentNode || document.body).scrollTop;

        const bottom = window.innerHeight - top - height - handleSize - scrollY;

        const zoomWidth = isRTL ? 0 : width;

        const right = window.innerWidth - left - zoomWidth - handleSize - (!!scrollY || isPageScrollable() ? handleSize : 0);
        setPosition(prev => ({
          ...prev,
          bottom: newHeight < 1 ? prev?.bottom : bottom,
          ...(!disableHeight && { right: newWidth < 1 ? prev?.right : right }),
          width: newWidth,
          height: newHeight,
        }));
        if (scrollToButton) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }

      if (isCanvas) {
        if (e.clientY >= window.innerHeight - 10) {
          if (!isScrolling) {
            isScrolling = true;
            handleAutoScroll();
          }
        } else {
          isScrolling = false;
        }
      }
    };

    const handleAutoScroll = () => {
      if (isScrolling) {
        autoScrollOffset += 10;
        window.scrollBy(0, 310);
        newHeight += 10;

        if (resizedItemRef.current) {
          resizedItemRef.current.style.height = newHeight + "px";
        }

        window.requestAnimationFrame(handleAutoScroll);
      }
    };

    const handleMouseUp = () => {
      onResize?.({ width: newWidth, height: newHeight });
      onResizeFinished?.();
      if (resizedItemRef.current && !isCanvas) {
        resizedItemRef.current.style.removeProperty("width");
        resizedItemRef.current.style.removeProperty("height");
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      isScrolling = false;
    };

    window.addEventListener("mousemove", handleMouseMove as any);
    window.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <>
      <Box
        display={
          isActiveComponentResizable ||
          (hasActiveModal && !isFlexCanvasEnabled && !isPartOfModalComponent(item?.id, store.getState().builder.entities))
            ? "contents"
            : ""
        }
        {...additionalProps}
      >
        {children}
      </Box>
      {!isDragging && (isActiveComponentResizable || isCanvas) && (
        <Portal>
          <div
            style={{
              position: "absolute",
              bottom: position?.bottom,
              right: position?.right,
              width: `${Math.max(10, 10 * scaleFactor)}px`,
              height: `${Math.max(10, 10 * scaleFactor)}px`,
              border: `${Math.max(1, 1 * scaleFactor)}px solid black`,
              borderRadius: "50%",
              zIndex: 9999,
              backgroundColor: "white",
              cursor: isRTL ? "nesw-resize" : "nwse-resize",
            }}
            onMouseDown={e => {
              e.preventDefault();
              handleMouseDown("both")(e);
            }}
            onDragStart={e => e.preventDefault()}
          />
        </Portal>
      )}
    </>
  );
};

export { ResizeElement };
