import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogActions, Divider, Grid, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { FC, useEffect } from "react";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import CustomMonacoEditor from "src/components/BXUI/CustomMonacoEditor/CustomMonacoEditor";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";

type CreateAutoCompleteFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  editing?: boolean;
  row?: any;
  height?: string | number;
  withoutPassword?: boolean;
  onlyPassword?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateAutoCompleteForm: FC<CreateAutoCompleteFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  height,
  setIsDirty,
  row = {},
}) => {
  const { palette } = useTheme();
  const schema = yup
    .object({
      name: yup.string().required(),
      config: yup.object().shape({
        endpoint: yup.string().when("sourceType", {
          is: "API",
          then: yup.string().required("Field is required"),
        }),
      }),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      ...row,
      config: {
        ...row?.config,
        sourceType: row?.config?.sourceType || "API",
        suggestionSourceType: row?.config?.suggestionSourceType || "SIMPLE",
      },
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const handleSave = (formData: any) => {
    const payload = {
      ...formData,
    };
    if (formData?.config?.autoCompleteValues && typeof formData?.config?.autoCompleteValues == "string") {
      payload.config.autoCompleteValues = JSON.parse(formData?.config?.autoCompleteValues || "{}");
    }
    if (formData?.config?.suggestedValues && typeof formData?.config?.suggestedValues == "string") {
      payload.config.suggestedValues = JSON.parse(formData?.config?.suggestedValues || "{}");
    }
    onSave(formData);
  };

  return (
    <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
      <Grid container spacing={3} px={2} py={1}>
        <Grid item xs={6}>
          <BXInput name={"name"} control={control} label='Name' variant='outlined' error={errors?.name} />
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <BXSwitch
              name={"config.multipleSelect"}
              control={control}
              label={"Multiple Select"}
              error={(errors as any)?.config?.multipleSelect}
            />
          </Grid>
          <Grid item xs={4}>
            <BXSwitch name={"config.readOnly"} control={control} label={"Read Only"} error={(errors as any)?.config?.readOnly} />
          </Grid>
          <Grid item xs={4}>
            <BXSwitch name={"config.freeSolo"} control={control} label={"Free Solo"} error={(errors as any)?.config?.freeSolo} />
          </Grid>
          <Grid item xs={4}>
            <BXSwitch
              name={"config.suggestionList"}
              control={control}
              label={"Suggestion List"}
              error={(errors as any)?.config?.suggestionList}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {["API", "LIST"].map((type: any) => (
            <Controller
              key={type}
              control={control}
              name={`config.sourceType`}
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup color='primary' value={value} exclusive>
                  <ToggleButton
                    style={{ marginInlineEnd: 12 }}
                    value={value}
                    onClick={() => {
                      onChange(type);
                    }}
                    selected={value === type}
                    key={type}
                  >
                    {type}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          ))}
        </Grid>
        {watch("config.sourceType") === "API" && (
          <>
            <Grid item xs={6}>
              <BXInput
                name={"config.endpoint"}
                control={control}
                label='Endpoint'
                variant='outlined'
                error={(errors as any)?.config?.endpoint}
              />
            </Grid>
            <Grid item xs={6}>
              <BXInput
                name={"config.dataEntry"}
                control={control}
                label='Data Entry'
                variant='outlined'
                error={(errors as any)?.config?.dataEntry}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <BXInput name={"config.key"} control={control} label='Key' variant='outlined' error={(errors as any)?.config?.key} />
        </Grid>
        {watch("config.sourceType") === "LIST" && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"config.autoCompleteValues"}
              render={({ field: { onChange, value } }) => (
                <>
                  <Typography fontWeight={"400"} mb={2}>
                    Options
                  </Typography>
                  <CustomMonacoEditor
                    height='150px'
                    language='json'
                    monacoEditorTheme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) || "" : value}
                    monacoEditorOptions={{ colorDecorators: true }}
                    onChange={(newValue = "") => {
                      try {
                        onChange(newValue);
                        JSON.parse(newValue);
                        // setIsTemplateError();
                      } catch (e: any) {
                        // setIsTemplateError(e?.message);
                      }
                    }}
                  />
                </>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider />
          <Typography fontWeight={"400"} mt={1}>
            Suggested values
          </Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          {["API", "SIMPLE"].map((type: any) => (
            <Controller
              key={type}
              control={control}
              name={`config.suggestionSourceType`}
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup color='primary' value={value} exclusive>
                  <ToggleButton
                    style={{ marginInlineEnd: 12 }}
                    value={value}
                    onClick={() => {
                      onChange(type);
                    }}
                    selected={value === type}
                    key={type}
                  >
                    {type}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          ))}
        </Grid>
        {watch("config.suggestionSourceType") === "API" && (
          <>
            <Grid item xs={6}>
              <BXInput
                name={"config.suggestionEndpoint"}
                control={control}
                label='Endpoint'
                variant='outlined'
                error={(errors as any)?.config?.suggestionEndpoint}
              />
            </Grid>
            <Grid item xs={6}>
              <BXInput
                name={"config.suggestionDataEntry"}
                control={control}
                label='Data Entry'
                variant='outlined'
                error={(errors as any)?.config?.suggestionDataEntry}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {watch("config.suggestionSourceType") === "SIMPLE" && (
            <Controller
              control={control}
              name={"config.suggestedValues"}
              render={({ field: { onChange, value } }) => (
                <>
                  <CustomMonacoEditor
                    height='150px'
                    language='json'
                    monacoEditorTheme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) : value}
                    monacoEditorOptions={{ colorDecorators: true }}
                    onChange={(newValue = "") => {
                      try {
                        onChange(newValue);
                        JSON.parse(newValue);

                        // setIsTemplateError();
                      } catch (e: any) {
                        // setIsTemplateError(e?.message);
                      }
                    }}
                  />
                </>
              )}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit(handleSave)} variant={"contained"} aria-label={"save"}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
