import { Backdrop } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import React, { CSSProperties, MouseEvent, ReactNode, useEffect, useRef, useState } from "react";

interface PopoverProps {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
  x: number;
  y: number;
  style?: CSSProperties;
  minWidth?: string | number;
  maxWidth?: string | number;
}

const Popover: React.FunctionComponent<PopoverProps> = ({ children, open, onClose, x, y, minWidth, maxWidth, style }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverOverlayStyleRef = useRef<CSSProperties | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      const popoverWidth = maxWidth ? parseInt(maxWidth.toString()) : 265;
      const viewportWidth = window.innerWidth;

      let leftPosition = x;
      if (x + popoverWidth > viewportWidth) {
        leftPosition = Math.max(viewportWidth - popoverWidth, 0);
      }

      const popoverOverlayStyle: CSSProperties = {
        position: "absolute",
        top: y + 35,
        left: leftPosition,
        zIndex: 10,
        minWidth: minWidth || "auto",
        maxWidth: maxWidth || "none",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "2px",
        ...style,
      };

      popoverOverlayStyleRef.current = popoverOverlayStyle;
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [open, x, y, minWidth, maxWidth, style]);

  const popoverContentStyle: CSSProperties = {};

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    onClose?.();
    setIsOpen(false);
  };
  if (!open) {
    return <></>;
  }
  return (
    <Backdrop
      open={isOpen}
      sx={{
        backgroundColor: "rgba(45, 49, 62, 0.5)",
        zIndex: theme.zIndex.modal,
      }}
      onClick={handleClick}
      draggable
      onDragStart={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {open && isOpen && popoverOverlayStyleRef.current && (
        <div style={popoverOverlayStyleRef.current}>
          <div style={popoverContentStyle} onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </div>
      )}
    </Backdrop>
  );
};

export default Popover;
