import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import React, { memo, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import CustomMonacoEditor from "src/components/BXUI/CustomMonacoEditor/CustomMonacoEditor";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXIconPicker } from "src/components/BXUI/FormControls/IconPicker";
import { selectAllComponentsArray, selectComponentPropertyByPath } from "src/features/builder/selectors";
import { RootState } from "src/store/store";
import { Breakpoint } from "src/types/BXAppType";
import { TranslationInput } from "src/views/pages/BuildX/AppBuilder/components/TranslationInput";
import { EditorRenderComponentProps } from "../componentEditorTypes";
import {
  evaluateConditions,
  getPropertyValue,
  resolveDisabledState,
  resolveProPathStyle,
  selectComponentValues,
} from "../componentEditorUtils";
import { AutoCompleteCSSComponent } from "./AutoCompleteCSSComponent";
import { AutoCompleteSyntax } from "./AutoCompleteSyntax";
import CustomErrorMessage from "./CustomErrorMessage";
import MapValuesComponent from "./MapValuesComponent";
import MarkdownEditor from "./MarkdownEditor";

// Component to render different types of components in the Component editor using redux state
const EditorRenderComponent: React.FC<EditorRenderComponentProps> = React.memo(
  ({
    // General props
    type,
    componentId,
    propPath,
    fallbackValuePath,
    onChangeLabel,
    onChangeSecondaryLabel,
    eventNeeded,
    placeholder,
    label,
    disabledConditions,
    handleChangeProp,
    customLogicOnPropPath,
    fallbackEvaluatedValue,
    fallbackAsValue,
    // TextField props
    checked,
    fullWidth,
    select,
    optionsSelect,
    stepperNavigator,
    withoutChangeLabel,
    propValueCondition,
    textFieldType,
    layoutBreak,
    conditionalValuePaths,
    textFieldName,
    getClosestPropValue,
    defaultValue,
    // Switch props
    onChangeSwitch,
    sx,
    // Button props
    onClickButton,
    buttonStyle,
    buttonVariant,
    stateProps,
    // Typography props
    typographyColor,
    typographyFontWeight,
    typographyStyle,
    proPathStyle,
    typographyStaticLabel,
    // MapValuesComponent props
    enableMapValuesPath,
    valueString,
    // Autocomplete props
    autocompleteOptions,
    autocompleteGetOptionLabel,
    isGroupReference,
    // TranslationInput props
    appId,
    multiLingual,
    setMultiLingual,
    selectedRow,
    setSelectedRow,
    // BXIconPicker props
    bXIconPickerName,
    showCaption,
    previewIcon,
    objName,
    handleSetChangeIconProp,
    // CustomErrorMessage props
    handleToggleProp,
    validationTypeString,
    customMessageContentPath,
    // Checkbox props
    includeLowercase,
    checkboxName,
    // AutoCompleteSyntax props
    view,
    views,
    // MarkdownEditor props
    onClose,
    // monacoEditor Props
    handleEditorChange,
    // other props
    disableResponsive,
    //
    children,
    ...props
  }) => {
    const isStyleObject = type === "autoCompleteCSSComponent";
    const currentLayoutBreakpoint = useSelector((state: RootState) => state.builder.layoutBreak);
    let componentProperty = useSelector(state => selectComponentPropertyByPath(state, componentId, propPath), shallowEqual);
    const [localComponentProperty, setLocalComponentProperty] = useState(
      disableResponsive ? componentProperty : getPropertyValue(componentProperty, currentLayoutBreakpoint, isStyleObject)
    );
    const steppersArray: any[] = useSelector(state =>
      selectAllComponentsArray(state).filter((component: any) => component?.type === "StepperContainer")
    );
    if (isGroupReference) {
      autocompleteOptions = steppersArray;
    }
    const debounceTimer = useRef<NodeJS.Timeout | null>(null);
    const enableMapValues = useSelector(state => selectComponentPropertyByPath(state, componentId, enableMapValuesPath), shallowEqual);
    const customMessageContent = useSelector(
      state => selectComponentPropertyByPath(state, componentId, customMessageContentPath),
      shallowEqual
    );
    const isDisabled = useSelector(state => resolveDisabledState(state, componentId, disabledConditions), shallowEqual);
    const evaluatedValue = useSelector(
      state => evaluateConditions(state, componentId, conditionalValuePaths, fallbackEvaluatedValue),
      shallowEqual
    );
    let processedComponentProperty = customLogicOnPropPath ? customLogicOnPropPath(localComponentProperty) : localComponentProperty;
    const values = useSelector(state => selectComponentValues(proPathStyle, componentId, state), shallowEqual);
    const resolvedStyles = resolveProPathStyle(proPathStyle || [], values || {});
    const fallbackValue = useSelector(
      state => (fallbackValuePath ? selectComponentPropertyByPath(state, componentId, fallbackValuePath) || defaultValue : defaultValue),
      shallowEqual
    );
    const localComponentPropertyButton = useSelector(
      state => (type === "button" ? selectComponentPropertyByPath(state, componentId, stateProps?.propPath || "") : undefined),
      shallowEqual
    );

    if (conditionalValuePaths) {
      processedComponentProperty = evaluatedValue;
    }

    if (fallbackAsValue && !localComponentProperty) {
      processedComponentProperty = fallbackAsValue;
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, disableDebounce: boolean = false) => {
      const newValue = event?.target?.value;
      setLocalComponentProperty(newValue);
      if (disableDebounce) {
        if (eventNeeded) {
          handleChangeProp(event);
        } else {
          handleChangeProp(onChangeLabel, onChangeSecondaryLabel ? onChangeSecondaryLabel : null)(event);
        }
        return;
      }
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => {
        if (eventNeeded) {
          handleChangeProp(event);
        } else {
          handleChangeProp(onChangeLabel, onChangeSecondaryLabel ? onChangeSecondaryLabel : null)(event);
        }
      }, 300);
    };

    const handleDateChange = (newDate: Date | null) => {
      setLocalComponentProperty(newDate);
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => {
        if (eventNeeded) {
          handleChangeProp(newDate);
        } else {
          handleChangeProp(onChangeLabel, onChangeSecondaryLabel ? onChangeSecondaryLabel : null)(newDate);
        }
      }, 300);
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = (event.target as HTMLInputElement).checked;
      setLocalComponentProperty(newValue);
      if (eventNeeded) {
        onChangeSwitch(event);
      } else {
        const secondaryParam = propPath === "props.isMapValues" ? newValue : onChangeSecondaryLabel ? onChangeSecondaryLabel : null;
        onChangeSwitch(onChangeLabel, secondaryParam)(event);
      }
    };

    useEffect(() => {
      setLocalComponentProperty(getPropertyValue(componentProperty, currentLayoutBreakpoint, isStyleObject) ?? fallbackValue);
    }, [componentId, componentProperty]);

    useEffect(() => {
      setLocalComponentProperty(getPropertyValue(componentProperty, currentLayoutBreakpoint, isStyleObject));
      if (enableMapValues) {
        setLocalComponentProperty(componentProperty);
      }
      if (isGroupReference) {
        setLocalComponentProperty(componentProperty);
      }
    }, [currentLayoutBreakpoint, enableMapValues, componentProperty, isGroupReference]);

    if (type === "textField") {
      return (
        <TextField
          size='small'
          fullWidth={fullWidth}
          label={label}
          defaultValue={defaultValue}
          name={textFieldName}
          value={processedComponentProperty ?? fallbackValue}
          type={textFieldType}
          onChange={handleChange}
          disabled={isDisabled}
          select={select}
          InputLabelProps={{ style: { fontSize: "12px" } }}
          InputProps={{ style: { fontSize: "12px" } }}
        >
          {select &&
            optionsSelect?.map(option => {
              const optionValue = typeof option === "string" ? option : option.id;
              const optionLabel = typeof option === "string" ? option : option.title;
              return (
                <MenuItem key={optionValue} value={optionValue}>
                  {optionLabel}
                </MenuItem>
              );
            })}
        </TextField>
      );
    } else if (type === "autocomplete") {
      return (
        <Autocomplete
          options={autocompleteOptions}
          getOptionLabel={autocompleteGetOptionLabel}
          renderInput={params => (
            <TextField {...params} type='text' placeholder={placeholder} label={label} size='small' value={localComponentProperty || ""} />
          )}
          value={localComponentProperty}
          onChange={(_event, value) => {
            setLocalComponentProperty(value);
            handleChangeProp(onChangeLabel)(value);
          }}
          fullWidth={fullWidth}
        />
      );
    } else if (type === "switch") {
      return (
        <Switch
          checked={localComponentProperty ?? false}
          onChange={handleSwitchChange}
          defaultValue={defaultValue || false}
          disabled={isDisabled}
          size='small'
          sx={sx}
        />
      );
    } else if (type === "button") {
      // const isDisabledButton = stateProps ? JSON.stringify(stateProps.state) === JSON.stringify(localComponentPropertyButton) : false;
      return (
        <Button variant={buttonVariant || "contained"} onClick={onClickButton} style={buttonStyle}>
          {label}
        </Button>
      );
    } else if (type === "typography") {
      return (
        <Typography sx={{ ...typographyStyle, ...resolvedStyles }} color={typographyColor} fontWeight={typographyFontWeight}>
          {processedComponentProperty ?? typographyStaticLabel}
        </Typography>
      );
    } else if (type === "mapValuesComponent") {
      return (
        <MapValuesComponent
          label={label || ""}
          value={localComponentProperty || componentProperty?.value || fallbackValue}
          enableMapValues={enableMapValues}
          mapValuesObject={localComponentProperty?.mapValuesObject}
          handleChangeProp={handleChangeProp}
          valueString={valueString}
          view={view}
          views={views}
          inputLabelProps={{ style: { fontSize: "12px" } }}
          inputProps={{ style: { fontSize: "12px" } }}
        />
      );
    } else if (type === "translationInput") {
      return (
        <TranslationInput
          label={label || "Translation"}
          fullWidth={fullWidth}
          value={localComponentProperty}
          onChange={handleChange}
          appId={appId}
          multiLingual={multiLingual}
          setMultiLingual={setMultiLingual}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          view={view}
          views={views}
        />
      );
    } else if (type === "iconPicker") {
      return (
        <BXIconPicker
          name={bXIconPickerName}
          label={label}
          defaultValue={localComponentProperty}
          onChange={event => handleChange(event, true)}
          objName={objName}
          disabled={isDisabled}
          handleSetChangeIconProp={handleSetChangeIconProp}
          showCaption={showCaption}
          previewIcon={previewIcon}
          size='small'
          inputLabelProps={{ style: { fontSize: "12px" } }}
          inputProps={{ style: { fontSize: "12px" } }}
        />
      );
    } else if (type === "customErrorMessage") {
      return (
        <CustomErrorMessage
          handleToggleProp={handleToggleProp}
          validationType={localComponentProperty}
          validationTitle={label}
          validationTypeString={validationTypeString}
          textFieldProps={{
            size: "small",
            type: "text",
            value: customMessageContent,
            onChange: handleChangeProp(onChangeLabel),
          }}
        />
      );
    } else if (type === "checkbox") {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={localComponentProperty}
              onChange={handleToggleProp(onChangeLabel)}
              disabled={isDisabled}
              name={checkboxName}
              color='primary'
              size='small'
            />
          }
          label={label}
          sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
        />
      );
    } else if (type === "radioGroup") {
      return (
        <RadioGroup
          row
          value={localComponentProperty}
          onChange={(_event, value) => {
            setLocalComponentProperty(value);
            handleChangeProp(onChangeLabel)(value);
          }}
        >
          {optionsSelect?.map((option, index) => (
            <Grid xs={6}>
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio size='small' disabled={isDisabled} />}
                label={<Typography fontSize={12}>{option.label}</Typography>}
              />
            </Grid>
          ))}
        </RadioGroup>
      );
    } else if (type === "dateTimePicker") {
      return <DateTimePicker label={label} disabled={isDisabled} value={localComponentProperty} onChange={handleDateChange} sx={sx} />;
    } else if (type === "autoCompleteSyntax") {
      return (
        <AutoCompleteSyntax
          label={label}
          value={localComponentProperty || defaultValue}
          fullWidth
          onChange={handleChange}
          view={view}
          views={views}
          type={textFieldType}
          size='small'
          InputLabelProps={{ style: { fontSize: "12px" } }}
          inputProps={{ style: { fontSize: "12px" } }}
        />
      );
    } else if (type === "multiSelect") {
      return (
        <Select
          multiple
          labelId='upload-allowed-types'
          value={localComponentProperty || []}
          onChange={
            handleChangeProp(onChangeLabel, undefined, (value: any) => {
              return typeof value === "string" ? value?.split(",") : value;
            }) as any
          }
          input={<OutlinedInput label='Tag' />}
          label={label}
          renderValue={selected => selected.join(", ")}
          disabled={isDisabled}
        >
          {optionsSelect?.map(name => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={localComponentProperty?.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      );
    } else if (type === "autoCompleteCSSComponent") {
      let processedValue = localComponentProperty;
      if (propPath === "props.stripeRules" && processedValue && typeof processedValue === "object") {
        processedValue = Object.keys(processedValue).reduce((acc: any, key) => {
          acc[key] =
            typeof processedValue[key] === "object" ? JSON.stringify(processedValue[key], null, 2) : processedValue[key].toString();
          return acc;
        }, {});
      }

      return (
        <AutoCompleteCSSComponent
          breakpoint={currentLayoutBreakpoint as Breakpoint}
          handleChangeStyles={handleChangeProp}
          styles={processedValue}
          id={componentId}
        />
      );
    } else if (type === "monacoEditor") {
      return (
        <CustomMonacoEditor
          width='100%'
          height='200px'
          language='json'
          monacoEditorTheme='vs-dark'
          defaultValue={formatJSON(JSON.stringify(defaultValue))}
          onChange={handleEditorChange}
          monacoEditorOptions={{
            fontSize: 12,
            lineDecorationsWidth: "1px",
            colorDecorators: true,
            minimap: {
              enabled: false,
            },
          }}
        />
      );
    } else if (type === "markdownEditor") {
      return <MarkdownEditor markdownText={localComponentProperty} handleChangeProp={handleChangeProp} onClose={onClose} />;
    }

    return null;
  }
);

export default memo(EditorRenderComponent);
