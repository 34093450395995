import { normalize } from "normalizr";
import { components, mergeItemWithDefaults } from "src/views/pages/BuildX/FormBuilder/utils";
import { component } from "./schemas";

const normalizeFormBuilder = (formBuilder, parentId = null) => {
  // Assign unique IDs if not present
  if (!formBuilder) return;
  const assignIds = (items, parentId = null) => {
    if (!Array.isArray(items)) return [];

    return items.map(item => {
      return recursivelyAssignObject(item, parentId);
    });
  };

  function recursivelyAssignObject(obj, parentId) {
    if (!obj || typeof obj !== "object") {
      return obj;
    }

    const { id } = obj;

    let assignedChildren: any = [];
    if (Array.isArray(obj.children)) {
      assignedChildren = assignIds(obj.children, id);
    }

    let assignedOptionMap;
    if (obj.optionMap && typeof obj.optionMap === "object") {
      assignedOptionMap = Object.fromEntries(
        Object.entries(obj.optionMap).map(([key, optionValue]) => {
          let assignedVal = recursivelyAssignObject(optionValue, parentId);

          assignedVal = {
            ...assignedVal,
            id, // same ID as the parent
            parentId, // same parentId as the parent
          };

          // If that optionValue has children, fix their parentId = assignedVal.id
          if (Array.isArray(assignedVal.children)) {
            assignedVal.children = assignIds(assignedVal.children, assignedVal.id);
          }

          return [key, assignedVal];
        })
      );
    }

    return {
      ...obj,
      id,
      parentId,
      children: assignedChildren,
      optionMap: assignedOptionMap && Object.keys(assignedOptionMap).length ? assignedOptionMap : undefined,
    };
  }

  const dataWithIds = assignIds(formBuilder, parentId);

  // Normalize the data
  const normalizedData = normalize(dataWithIds, [component]);

  // Extract entities and top-level IDs
  const { entities, result } = normalizedData;

  const mergedComponents = Object.entries(entities?.components ?? {}).reduce((acc, [compId, compData]) => {
    // Merge main component
    const defaults = components.find(comp => comp?.type === compData.type) || {};
    const merged = mergeItemWithDefaults(compData, defaults);

    // Process optionMap if exists
    if (merged.optionMap) {
      Object.keys(merged.optionMap).forEach(optionKey => {
        const optionValue = merged.optionMap[optionKey];
        merged.optionMap[optionKey] = mergeItemWithDefaults(optionValue, defaults);
      });
    }

    acc[compId] = merged;
    return acc;
  }, {});
  return {
    entities: mergedComponents,
    result, // Array of top-level component IDs
  };
};

export default normalizeFormBuilder;
