import { Box, BoxProps, Divider, MenuItem, Popover, Stack, Typography } from "@mui/material";
import { IconBackspace, IconSelectAll, IconStackBackward, IconStackForward } from "@tabler/icons-react";
import { NestedMenuItem } from "mui-nested-menu";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { setViewBuilder, upsertComponents } from "src/features/builder/builderSlice";
import { selectComponentById, selectTopLevelComponents } from "src/features/builder/selectors";
import store from "src/store/store";
import { v4 as uuid } from "uuid";
import CustomContainerImage from "../../../../../assets/images/icons/custom.svg";
import GridContainerImage from "../../../../../assets/images/icons/GridContainer.svg";
import FlexContainerImage from "../../../../../assets/images/icons/rectangle.svg";
import { ComponentItemType } from "../types";
import { ActionMenuItem, ActionPopoverProps } from "./TreeTypes";

const ActionPopover: React.FC<ActionPopoverProps> = ({
  onClose,
  open,
  handleDeleteItem,
  handleCopyItems,
  activeComponentId,
  handlePasteItems,
  selectedItemsId,
  contextMenuPosition,
  hideDragActions,
  copyItemsRef,
  isSideMenu = false,
}) => {
  const dispatch = useDispatch();
  const activeComponent = selectComponentById(store.getState(), activeComponentId);
  const topLevel = selectTopLevelComponents(store.getState());

  const handleDelete = () => {
    if (selectedItemsId?.length > 0) {
      if (handleDeleteItem) {
        handleDeleteItem(selectedItemsId);
      }
    } else if (activeComponentId) {
      if (handleDeleteItem) {
        handleDeleteItem([activeComponentId]);
      }
    }
    onClose();
  };

  const handleCopy = () => {
    if (handleCopyItems) {
      handleCopyItems(activeComponentId, selectedItemsId);
    }
    onClose();
  };

  const handlePasteIntoTarget = () => {
    const targetElementId = activeComponent?.id || topLevel[0];
    if (handlePasteItems) {
      handlePasteItems(targetElementId);
    }
    onClose();
  };

  const getRandomValue = (min, max) => Math.random() * (max - min) + min;

  const findSelectedItems = selectedItemsId => {
    const normalizedIds = Array.isArray(selectedItemsId) ? selectedItemsId : [selectedItemsId];
    let foundItems: any = [];
    normalizedIds.forEach(itemId => {
      const item = selectComponentById(store.getState(), itemId);
      if (item) {
        foundItems.push(item);
      }
    });

    return foundItems;
  };

  const findParentComponent = selectedItemsId => {
    const normalizedIds = Array.isArray(selectedItemsId) ? selectedItemsId : [selectedItemsId];
    for (const itemId of normalizedIds) {
      // Get the current item
      const item = selectComponentById(store.getState(), itemId);
      if (!item) continue;
      // Get the parent component by comparing parentId
      const parent = item.parentId ? selectComponentById(store.getState(), item.parentId) : null;
      if (parent) {
        return parent;
      }
    }

    return null;
  };

  const getLevel = element => {
    let level = 0;
    let current = element;
    while (current) {
      const parent = findParentComponent(current.id);
      if (parent) {
        level += 1;
        current = parent;
      } else {
        break;
      }
    }
    return level;
  };

  const areAllSelectedItemsAtSameLevel = selectedItemsId => {
    const normalizedIds = Array.isArray(selectedItemsId) ? selectedItemsId : [selectedItemsId];
    const selectedItems = findSelectedItems(normalizedIds);
    if (selectedItems?.length === 0) return false;

    const levels = selectedItems.map(item => getLevel(item));
    return levels.every(level => level === levels[0]);
  };

  const handlePlaceInContainer = (containerType: string) => {
    const newId = uuid();

    const images = {
      FlexContainer: FlexContainerImage,
      GridContainer: GridContainerImage,
      CustomContainer: CustomContainerImage,
    };

    const typeMappings = {
      FlexContainer: ComponentItemType.FlexContainer,
      GridContainer: ComponentItemType.GridContainer,
      CustomContainer: ComponentItemType.CustomContainer,
    };

    const randomWidthPx = getRandomValue(100, 300);
    const randomHeightPx = getRandomValue(50, 200);
    const randomTop = getRandomValue(50, 500);
    const randomLeft = getRandomValue(50, 500);

    const containerParent = {
      id: newId,
      type: typeMappings[containerType] || ComponentItemType.FlexContainer,
      props: {
        sx: {
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          padding: "10px",
          flexDirection: "column",
        },
        id: `${containerType}-${newId}`,
        key: `${containerType}-${newId}`,
        testId: "",
      } as BoxProps,
      config: {
        defaultWidth: 300,
        defaultHeight: 200,
        minWidth: 0,
        minHeight: 0,
        fixedWidth: false,
        fixedHeight: false,
        isPercentageHeight: {
          lg: true,
        },
        isDynamicHeight: {
          lg: true,
        },
        isDynamicWidth: {
          lg: true,
        },
        sideMenuSx: {
          border: "1px dashed #57585c",
          minHeight: "100px",
        },
        placeholderConfig: {
          title: "Flex",
          image: images[containerType] || FlexContainerImage,
          group: "container",
        },
        widthPx: {
          lg: 300,
        },
        heightPx: {
          lg: 200,
        },
        widthPercentage: {
          lg: `${(randomWidthPx / 1182) * 100}%`,
        },
        heightPercentage: {
          lg: `${(randomHeightPx / 485) * 100}%`,
        },
      },
      level: null,
      isSideMenu: true,
      top: {
        xs: randomTop,
        lg: randomTop,
      },
      left: {
        xs: randomLeft,
        lg: randomLeft,
      },
      leftPercentage: {
        xs: `${(randomLeft / 1182) * 100}%`,
        lg: `${(randomLeft / 1182) * 100}%`,
      },
      index: 1,
      boxPosition: {
        width: 1182,
        height: 485,
        y: randomTop,
        x: randomLeft,
      },
      parentId: null,
    };

    let selectedItems = findSelectedItems(selectedItemsId);
    if (selectedItems.length === 0 && activeComponentId) {
      selectedItems = findSelectedItems([activeComponentId]);
    }
    if (!selectedItems || selectedItems.length === 0) {
      return;
    }
    const parentComponent = findParentComponent(selectedItemsId?.length === 0 ? activeComponent?.id : selectedItemsId);
    const idsToRemove = selectedItemsId?.length > 0 ? selectedItemsId : activeComponent ? [activeComponent.id] : [];

    if (parentComponent) {
      containerParent.parentId = parentComponent.id;

      // Remove the selected items from their current parent
      const updatedParentComponent = {
        ...parentComponent,
        children: [...(parentComponent?.children || []).filter((childId: any) => !idsToRemove.includes(childId)), newId],
      };

      const updatedContainer = {
        ...containerParent,
        children: idsToRemove,
      };

      const updateChildrenParentId = (childIds, newParentId) => {
        const updatedChildren = childIds.map(childId => {
          const child = selectComponentById(store.getState(), childId);

          if (!child) return null;

          if (
            (child.type === ComponentItemType.FlexContainer ||
              child.type === ComponentItemType.GridContainer ||
              child.type === ComponentItemType.CustomContainer) &&
            child.children?.length > 0
          ) {
            updateChildrenParentId(child.children, childId);
          }

          return {
            ...child,
            parentId: newParentId,
          };
        });

        return updatedChildren.filter(Boolean);
      };

      const updatedChildren = updateChildrenParentId(idsToRemove, newId);

      dispatch(upsertComponents([updatedParentComponent, updatedContainer, ...updatedChildren]));
      dispatch(setViewBuilder({ selectedItemsId: [], activeComponent: newId, addToHistory: true }));
    }
    onClose();
  };

  const handleSelectParentComponent = () => {
    if (!activeComponentId) return;
    const parentComponent = findParentComponent(activeComponentId);

    if (parentComponent && !parentComponent.isCustomCanvas) {
      dispatch(setViewBuilder({ activeComponent: parentComponent.id, addToHistory: false }));
    } else {
      dispatch(setViewBuilder({ activeComponent: undefined, addToHistory: false }));
    }

    onClose();
  };

  const handleBringToTop = () => {
    const selectedId = selectedItemsId?.[0] || activeComponentId;

    const parentComponent = findParentComponent(selectedId);

    const updatedParent = {
      ...parentComponent,
      children: [...(parentComponent.children?.filter((childId: any) => childId !== selectedId) || []), selectedId],
    };

    dispatch(upsertComponents([updatedParent]));
    onClose();
  };

  const handlePushToDown = () => {
    const selectedId = selectedItemsId?.[0] || activeComponentId;

    const parentComponent = findParentComponent(selectedId);

    const updatedParent = {
      ...parentComponent,
      children: [selectedId, ...(parentComponent.children?.filter((childId: any) => childId !== selectedId) || [])],
    };

    dispatch(upsertComponents([updatedParent]));
    onClose();
  };

  const isContainerType = (componentType: string) => {
    return (
      componentType === ComponentItemType.FlexContainer ||
      componentType === ComponentItemType.GridContainer ||
      componentType === ComponentItemType.StripeContainer ||
      componentType === ComponentItemType.StepperContainer
    );
  };

  const filteredActions = hideDragActions
    ? []
    : !activeComponentId
    ? []
    : [
        {
          label: "Select Parent Component",
          shortcut: "",
          action: () => handleSelectParentComponent(),
          divider: true,
          icon: <IconSelectAll width={18} height={18} />,
        },
        ...(!activeComponentId ||
        (!isContainerType(activeComponent?.type) && activeComponent?.isCustomCanvas) ||
        findParentComponent(activeComponent?.id)?.type === ComponentItemType.FlexContainer ||
        findParentComponent(activeComponent?.id)?.type === ComponentItemType.GridContainer
          ? []
          : [
              {
                label: "Bring to Top",
                shortcut: "",
                action: () => handleBringToTop(),
                divider: true,
                icon: <IconStackForward width={18} height={18} />,
              },
              {
                label: "Push to Down",
                shortcut: "",
                action: () => handlePushToDown(),
                icon: <IconStackBackward width={18} height={18} />,
              },
            ]),
      ];

  const filteredCrud = !activeComponentId
    ? [{ label: "Paste", shortcut: "Ctrl + V", action: handlePasteIntoTarget, disable: copyItemsRef?.current?.length === 0 }]
    : isContainerType(activeComponent?.type)
    ? [
        { label: "Copy", shortcut: "Ctrl + C", action: handleCopy, disable: false },
        { label: "Paste", shortcut: "Ctrl + V", action: handlePasteIntoTarget, disable: copyItemsRef?.current?.length === 0 },
        {
          label: "Delete",
          shortcut: "",
          action: () => handleDelete(),
          icon: <IconBackspace width={18} height={18} />,
          divider: true,
          disable: false,
        },
      ]
    : [
        { label: "Copy", shortcut: "Ctrl + C", action: handleCopy, disable: false },
        {
          label: "Delete",
          shortcut: "",
          action: () => handleDelete(),
          icon: <IconBackspace width={18} height={18} />,
          divider: true,
          disable: false,
        },
      ];

  const actionMenuItems: ActionMenuItem[] = [...filteredCrud, ...filteredActions];

  const nestedActionMenuItems = [
    { label: "Custom Container", type: "CustomContainer" },
    { label: "Grid Container", type: "GridContainer" },
    { label: "Flex Container", type: "FlexContainer" },
  ];

  const getNestedActionMenuItems = (actionMenuItems: Array<any>, name: string) => {
    return (
      <NestedMenuItem
        parentMenuOpen={true}
        label={name}
        sx={{
          marginBottom: "8px",
          padding: "4px 10px",
          fontSize: "14px",
          alignItems: "center",
        }}
      >
        {actionMenuItems &&
          actionMenuItems.map((option, index) =>
            option && option.items && option.items.length > 0 ? (
              getNestedActionMenuItems(option.items, option.name)
            ) : (
              <MenuItem key={index} onClick={() => handlePlaceInContainer(option.type)}>
                {option.label}
              </MenuItem>
            )
          )}
      </NestedMenuItem>
    );
  };

  return (
    <>
        <Popover
          open={open && !isSideMenu}
          onClick={e => e.stopPropagation()}
          onContextMenu={e => e.stopPropagation()}
          onMouseOver={e => e.stopPropagation()}
          onClose={onClose}
          anchorReference='anchorPosition'
          anchorPosition={
            contextMenuPosition
              ? {
                  top: contextMenuPosition.top,
                  left: contextMenuPosition.left,
                }
              : undefined
          }
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              maxHeight: "80vh",
              overflow: "auto",
            },
          }}
          marginThreshold={16}
        >
          <Stack spacing={1} sx={{ padding: "4px" }}>
            {actionMenuItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.divider && <Divider />}
                <MenuItem onClick={item.action} sx={{ marginBottom: item.divider ? "8px" : "0px" }} disabled={item.disable}>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: "100%" }}>
                    <Typography sx={{ marginRight: 10 }}>{item.label}</Typography>
                    <Stack direction='row' alignItems='center'>
                      {item.shortcut && <Typography sx={{ opacity: 0.6, fontSize: "10px" }}>{item.shortcut}</Typography>}
                      {item.icon && (
                        <>
                          <Box sx={{ opacity: 0.6 }}>{item.icon}</Box>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </MenuItem>
              </React.Fragment>
            ))}
            {areAllSelectedItemsAtSameLevel(selectedItemsId?.length === 0 ? [activeComponentId] : selectedItemsId) &&
              !activeComponent?.isCustomCanvas && (
                <React.Fragment>{getNestedActionMenuItems(nestedActionMenuItems, "Place in Container")}</React.Fragment>
              )}
          </Stack>
        </Popover>
    </>
  );
};

export default memo(ActionPopover);
