import { normalize } from "normalizr";
import { components, mergeItemWithDefaults } from "src/views/pages/BuildX/FormBuilder/utils";
import { component } from "./schemas";

const normalizeEndUser = endUser => {
  if (!endUser) return;
  //
  // Assign unique IDs if not present
  const assignIds = (items, parentKey = null) => {
    return items?.map(item => {
      const id = item.id;
      const keyComp = item?.props?.key;
      // const children = item.children ? assignIds(item.children, keyComp) : [];
      const assignedChildren = item.children ? assignIds(item.children, keyComp) : [];
      let assignedOptionMap = {};
      if (item.optionMap && typeof item.optionMap === "object") {
        assignedOptionMap = Object.fromEntries(
          Object.entries(item.optionMap).map(([optionKey, optionValue]) => {
            const processed = assignIds([optionValue], keyComp);
            return [optionKey, processed[0]];
          })
        );
      }

      let additionalComponentData: any = {};

      const componentOnLoadTrigger = item?.interactionConfig?.some(i => i?.type === "OnLoad");
      if (componentOnLoadTrigger) {
        additionalComponentData.trigger = {
          type: "onLoad",
          eventPayload: {},
        };
      }

      return {
        ...item,
        id,
        parentKey,
        children: assignedChildren,
        optionMap: assignedOptionMap,
        ...additionalComponentData,
      };
    });
  };

  const dataWithIds = assignIds(endUser);

  // Normalize the data
  const normalizedData = normalize(dataWithIds, [component]);

  // Extract entities and top-level IDs
  const { entities, result } = normalizedData;

  const mergedComponents = Object.entries(entities?.components ?? {}).reduce((acc, [compId, compData]) => {
    // Merge main component
    const defaults = components.find(comp => comp?.type === compData.type) || {};
    const merged = mergeItemWithDefaults(compData, defaults);

    // Process optionMap if exists
    if (merged.optionMap) {
      Object.keys(merged.optionMap).forEach(optionKey => {
        const optionValue = merged.optionMap[optionKey];
        merged.optionMap[optionKey] = mergeItemWithDefaults(optionValue, defaults);
      });
    }

    acc[compId] = merged;
    return acc;
  }, {});

  return {
    entities: mergedComponents,
    result, // Array of top-level component IDs
  };
};

export default normalizeEndUser;
