import { Box, Button, IconButton, Switch, TextField, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { IconTrashX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateComponent } from "src/features/builder/builderSlice";
import store from "src/store/store";
import { addModalContainer } from "../../../utils";

const CurrentViewModals: React.FC<any> = ({ handleModalsChange, onDeleteItem }) => {
  const dispatch = useDispatch();
  const [modals, setModals] = useState<any[]>(store.getState().builder.modalsConfig?.modals || []);
  const builderElements = store.getState().builder.entities;
  const theme = useTheme();

  const addModal = () => {
    const newId = addModalContainer(builderElements, theme);
    if (!newId) return;
    const modalName = `Modal-Component-${newId}`;
    const newField = {
      id: newId,
      modalName: modalName,
      isActive: false,
    };
    setModals(prevFields => {
      const updatedVars = [...prevFields, newField];
      handleModalsChange(updatedVars);
      return updatedVars;
    });
  };

  const removeField = (id: number) => {
    setModals(prevFields => {
      const updatedVars = prevFields.filter(field => field.id !== id);
      handleModalsChange(updatedVars);
      return updatedVars;
    });
    onDeleteItem([id], true);
  };

  const handleFieldChange = (id: number, newValue: string) => {
    setModals(prevFields => {
      const updatedVars = prevFields.map(field => (field.id === id ? { ...field, modalName: newValue } : field));
      handleModalsChange(updatedVars);
      const builderItem = builderElements[id];
      if (builderItem) {
        dispatch(
          updateComponent({
            id: builderItem.id,
            changes: {
              props: {
                ...builderItem.props,
                key: newValue,
              },
            },
          })
        );
      }

      return updatedVars;
    });
  };

  const handleSwitchChange = (id: number, isActive: boolean) => {
    setModals(prevFields => {
      const shouldDisableAll = isActive;
      const updatedVars = prevFields.map(field => ({
        ...field,
        isActive: shouldDisableAll ? false : field.id === id,
      }));

      handleModalsChange(updatedVars);
      updatedVars.forEach(field => {
        const builderItem = builderElements[field.id];
        if (!builderItem) return;

        const updatedProps = {
          ...builderItem.props,
          sx: {
            ...builderItem.props.sx,
            visibility: { lg: field.isActive ? "visible" : "hidden" },
          },
        };

        dispatch(updateComponent({ id: builderItem.id, changes: { props: updatedProps } }));
      });

      return updatedVars;
    });
  };

  useEffect(() => {
    const elementsArray = Object.values(builderElements);
    const extractedModals = elementsArray
      .filter((element: any) => element?.isModalComponent)
      .map((modal: any) => ({
        id: modal.id,
        modalName: modal.props?.key || `Modal-Component-${modal.id}`,
        isActive: modal.props?.sx?.visibility?.lg !== "hidden",
      }));

    setModals(prevModals => {
      const prevIds = new Set(prevModals.map(m => m.id));
      const currentIds = new Set(extractedModals.map(m => m.id));

      // Check if modals are the same
      if (
        extractedModals.length === prevModals.length &&
        extractedModals.every(m => prevIds.has(m.id)) &&
        prevModals.every(m => currentIds.has(m.id))
      ) {
        return prevModals;
      }

      handleModalsChange(extractedModals);
      return extractedModals;
    });
  }, [builderElements, handleModalsChange]);

  return (
    <Box sx={{ p: 1 }}>
      {modals.length > 0 ? (
        modals.map((field, index) => (
          <Box key={field.id} display='flex' alignItems='center' mb={2}>
            <TextField
              label={`Modal ${index + 1}`}
              variant='outlined'
              size='small'
              fullWidth
              value={field.modalName}
              onChange={e => handleFieldChange(field.id, e.target.value)}
            />
            <Switch size='small' checked={field.isActive} onChange={() => handleSwitchChange(field.id, field.isActive)} />
            <IconButton aria-label='delete' color='error' onClick={() => removeField(field.id)} sx={{ ml: 1 }}>
              <IconTrashX color='red' size={18} />
            </IconButton>
          </Box>
        ))
      ) : (
        <Typography variant='body2' color='textSecondary' textAlign='center' sx={{ mb: 2 }}>
          No modals available. Click "Add Modal" to create one.
        </Typography>
      )}

      <Button fullWidth variant='contained' onClick={addModal}>
        Add Modal
      </Button>
    </Box>
  );
};

export default CurrentViewModals;
