// withLogging.js

import { Box } from "@mui/material";
import { FormBuilder } from "../FormBuilder";

const withPageLayout = WrappedComponent => {
  return function WithPageLayout(props) {
    const { page, layouts, collectionId } = props;
    const layout = layouts?.find(layout => layout?.id == page?.layoutId);

    if (!layout)
      return (
        <Box marginX={1}>
          <WrappedComponent {...props} />
        </Box>
      );

    return <FormBuilder {...layout} collectionId={collectionId} pageIdFromLayout={page?.id} pageOutlet={<WrappedComponent {...props} />} />;
  };
};

export default withPageLayout;
