import { useEffect, useState } from "react";
import { isValidGoogleMapsApiKey, sanitizeApiKey } from "src/utils/helpers";
import "src/views/pages/BuildX/FormBuilder/components/GoogleMapAutocomplete/GoogleMapAutocompleteStyles.css";

// Global variables to track Google Maps API loading state
const googleMapsState = {
  apiLoaded: typeof window !== "undefined" && typeof window.google !== "undefined" && typeof window.google.maps !== "undefined",
  validApiKey: "",
  loadingInProgress: false,
};

// Load Google Maps API script manually via script tag
export const loadGoogleMapsApi = (apiKey: string) => {
  // Don't load if already loaded or loading in progress
  if (googleMapsState.apiLoaded || googleMapsState.loadingInProgress) {
    return;
  }

  // Mark as loading
  googleMapsState.loadingInProgress = true;

  // Create script element
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
  script.async = true;
  script.defer = true;

  // Add event listeners
  script.addEventListener("load", () => {
    googleMapsState.apiLoaded = true;
    googleMapsState.validApiKey = apiKey;
    googleMapsState.loadingInProgress = false;

    // Dispatch event so components can update
    window.dispatchEvent(new Event("google-maps-loaded"));
  });

  script.addEventListener("error", () => {
    console.error("Failed to load Google Maps API");
    googleMapsState.loadingInProgress = false;

    // Dispatch event so components can update
    window.dispatchEvent(new Event("google-maps-error"));
  });

  // Add script to document
  document.head.appendChild(script);
};

// Custom hook to use Google Maps API
export const useMapsApiLoader = (apiKey: string) => {
  const [isLoaded, setIsLoaded] = useState(googleMapsState.apiLoaded);
  const [error, setError] = useState<Error | null>(null);

  // Sanitize API key
  const sanitizedKey = sanitizeApiKey(apiKey);
  const isValid = isValidGoogleMapsApiKey(sanitizedKey);
  const hasApiKey = sanitizedKey !== "";

  useEffect(() => {
    // If already loaded, we're good
    if (googleMapsState.apiLoaded) {
      setIsLoaded(true);
      return;
    }

    // Only attempt to load with a valid key
    if (isValid && !googleMapsState.loadingInProgress) {
      loadGoogleMapsApi(sanitizedKey);
    }

    // Handler for successful load
    const handleLoaded = () => {
      setIsLoaded(true);
    };

    // Handler for errors
    const handleError = () => {
      setError(new Error("Failed to load Google Maps API"));
    };

    // Add event listeners
    window.addEventListener("google-maps-loaded", handleLoaded);
    window.addEventListener("google-maps-error", handleError);

    // Cleanup
    return () => {
      window.removeEventListener("google-maps-loaded", handleLoaded);
      window.removeEventListener("google-maps-error", handleError);
    };
  }, [sanitizedKey, isValid]);

  return {
    isLoaded,
    loadError: error,
    isValid,
    apiKey: sanitizedKey,
    hasApiKey,
  };
};
