/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Divider, styled, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _, { isArray } from "lodash";
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { CustomAccordion } from "./components/CustomAccordion";
import { CustomAutocomplete } from "./components/CustomAutocomplete";
import { CustomCheckbox } from "./components/CustomCheckboxGroup/CustomCheckboxGroup";
import { CustomContainer } from "./components/CustomContainer";
import { CustomIconButton } from "./components/CustomIconButton";
import { CustomImage } from "./components/CustomImage";
import { DatePicker } from "./components/DatePicker";

import { useTheme } from "@mui/material/styles";
import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { BXSelect } from "src/components/BXUI/SelectInput";
import { ReactMouseSelect, TFinishSelectionCallback } from "src/components/ReactMouseSelect";
import {
  addComponent,
  removeChildFromParent,
  removeComponent,
  setViewBuilder,
  updateComponent,
  updateSelectedItemsId,
  upsertComponents,
} from "src/features/builder/builderSlice";
import normalizeFormBuilder from "src/features/builder/normalizeFormBuilder";
import {
  selectAllComponentsArray,
  selectAllIds,
  selectComponentById,
  selectComponentParent,
  selectTopLevelComponents,
} from "src/features/builder/selectors";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import store from "src/store/store";
import { BXApp, ILayout } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import { ClipboardOperationStatus, copyToClipboard, readFromClipboard } from "src/utils/clipboardUtils";
import { toCamelCase, updateElementsIds } from "src/utils/generalUtils";
import { ResizeElement } from "../../../../components/ResizeElement";
import { BXLayout } from "./BXLayout";
import { BXNavBar } from "./BXNavBar";
import { BXSideBar } from "./BXSideBar";
import { BXView } from "./BXView";
import { CustomDragLayer } from "./CustomDragLayer";
import { DragElement } from "./DragElement";
import FormBuilderListener from "./FormBuilderListener";
import FormBuilderSideEffect from "./FormBuilderSideEffect";
import { LeftSideMenuItems } from "./LeftSideMenuItems";
import ResizableContainer from "./ResizableContainer";
import { RightSideMenuItems } from "./RightSideMenuComponent";
import ViewBuilderHeader from "./ViewBuilderHeader";
import WindowSize from "./WindowSize";
import { ContainerGrid, CustomMediaCard } from "./components";
import { CircularChart } from "./components/CircularChart";
import { ColorPicker } from "./components/ColorPicker";
import { ColumnChart } from "./components/ColumnChart";
import { CustomAutoCompleteBX } from "./components/CustomAutoCompleteBX";
import { CustomAvatar } from "./components/CustomAvatar/CustomAvatar";
import { CustomButton } from "./components/CustomButton/CustomButton";
import { CustomChip } from "./components/CustomChip";
import { CustomGoogleLoginButton } from "./components/CustomGoogleLoginButton/CustomGoogleLoginButton";
import { CustomGoogleMap } from "./components/CustomGoogleMap";
import { CustomQR } from "./components/CustomQR/CustomQR";
import { CustomRadio } from "./components/CustomRadioGroup";
import { CustomSpinner } from "./components/CustomSpinner/CustomSpinner";
import { CustomSwitch } from "./components/CustomSwitch";
import { CustomTabs } from "./components/CustomTabs";
import { CustomTextField } from "./components/CustomTextField/CustomTextField";
import { Typography } from "./components/CustomTypography";
import { DateTimePicker } from "./components/DateTimePicker";
import { FileUploadInput } from "./components/FileUploadInput";
import { GoogleMapAutocomplete } from "./components/GoogleMapAutocomplete";
import { JsonViewer } from "./components/JsonViewer";
import { MarkdownViewer } from "./components/MarkdownViewer";
import { PaginationBar } from "./components/PaginationBar";
import { PieChart } from "./components/PieChart";
import { SplineChart } from "./components/SplineChart";
import { StepperNavigator } from "./components/StepperNavigator";
import { StripeAddressElement } from "./components/StripeComponents/StripeAddressElement";
import { StripeCardCvcElement } from "./components/StripeComponents/StripeCardCvcElement";
import { StripeCardExpiryElement } from "./components/StripeComponents/StripeCardExpiryElement";
import { StripeCardNumberElement } from "./components/StripeComponents/StripeCardNumberElement";
import { StripeContainer } from "./components/StripeComponents/StripeContainer";
import { StripePaymentElements } from "./components/StripeComponents/StripePaymentElements";
import { TableContainer } from "./components/TableContainer/TableContainer";
import { CustomTimePicker } from "./components/TimePicker/CustomTimePicker";
import { ComponentItemType } from "./types";
import { getItemClosestProp, getLastElementPositionX, getLastElementPositionY, layoutBreakPointsValues, populateViews } from "./utils";

export const MUI_COMPONENTS: any = {
  TextField: styled(CustomTextField)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  Button: styled(CustomButton)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
    overflow: hidden;
  `,
  CustomIconButton: styled(CustomIconButton)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  CustomGoogleLoginButton: styled(CustomGoogleLoginButton)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  CustomChip: styled(CustomChip)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  CustomTabs: styled(CustomTabs)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  CustomAccordion: styled(CustomAccordion)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  Spinner: styled(CustomSpinner)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  Typography: styled(Typography)``,
  Avatar: styled(CustomAvatar)``,
  Divider: styled(Divider)``,
  CustomSwitch: styled(CustomSwitch)``,
  CustomCheckbox: styled(CustomCheckbox)``,
  CustomRadio: styled(CustomRadio)``,
  CustomImage: styled(CustomImage)``,
  CustomAutocomplete: styled(CustomAutocomplete)``,
  CustomAutoCompleteBX: styled(CustomAutoCompleteBX)``,
  BXIframe: styled("iframe")``,
  StripePaymentElements: styled(StripePaymentElements)``,
  StripeContainer: styled(StripeContainer)``,
  StripeCardNumberElement: styled(StripeCardNumberElement)``,
  StripeCardCvcElement: styled(StripeCardCvcElement)``,
  StripeCardExpiryElement: styled(StripeCardExpiryElement)``,
  StripeAddressElement: styled(StripeAddressElement)``,
  CustomGoogleMap: styled(CustomGoogleMap)``,
  GoogleMapAutocomplete: styled(GoogleMapAutocomplete)``,
  FileUploadInput: styled(FileUploadInput)``,
  CustomContainer: styled(CustomContainer)``,
  FlexContainer: styled(CustomContainer)``,
  TableContainer: styled(TableContainer)``,
  GridContainer: styled(CustomContainer)``,
  StepperContainer: styled(CustomContainer)``,
  DatePicker: styled(DatePicker)``,
  DateTimePicker: styled(DateTimePicker)``,
  TimePicker: styled(CustomTimePicker)``,
  PieChart: styled(PieChart)``,
  SplineChart: styled(SplineChart)``,
  ColumnChart: styled(ColumnChart)``,
  CircularChart: styled(CircularChart)``,
  Select: styled(BXSelect)``,
  JsonViewer: styled(JsonViewer)``,
  StepperNavigator: styled(StepperNavigator)``,
  MarkdownViewer: styled(MarkdownViewer)``,
  ColorPicker: styled(ColorPicker)``,
  CustomQR: styled(CustomQR)``,
  PaginationBar: styled(PaginationBar)``,
  BXView: styled(BXView)``,
  BXLayout: styled(BXLayout)``,
  BXSideBar: styled(BXSideBar)``,
  BXNavBar: styled(BXNavBar)``,
  CustomMediaCard: styled(CustomMediaCard)``,
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: { position: "fixed", top: 24, zIndex: 1100 },
  },
}));

type FormBuilderEditorProps = {
  params?: {
    _currentApp?: BXApp;
    appId?: string;
    collectionId?: string;
    pageId?: string;
    viewId?: string;
    templateId?: string;
    historyId?: any;
    layoutId?: any;
    componentId?: string;
  };
  layout?: ILayout;
  pageBuilderMode?: boolean;
  appBuilderMode?: boolean;
  page?: BXPageType;
  onSave?: (data: any, onSuccess?: () => void) => Promise<any>;
  onBackClick?: () => void;
};

const FormBuilderEditor: FC<FormBuilderEditorProps> = ({
  params,
  layout,
  pageBuilderMode = false,
  appBuilderMode = false,
  page,
  onSave,
  onBackClick,
}) => {
  const { apps, editView, getTemplateById, getTemplateByHistoryId } = useBXBuilderContext();
  const currentApp = useBuildxProviderValue("currentApp");
  const selectedAppId = useBuildxProviderValue("selectedAppId");
  const allowedApps = useBuildxProviderValue("allowedApps");

  const paramsValues = useParams();
  const location = useLocation();
  const popoverMode = Number(new URLSearchParams(useLocation().search).get("popoverMode")) || 0;
  const {
    appId,
    collectionId,
    pageId,
    viewId,
    templateId,
    historyId,
    layoutId,
    componentId: componentIdFromParams,
  } = params || paramsValues;

  const navigate = useNavigate();
  const classes = useStyles();
  const viewHeight = store.getState().builder.height;
  const viewWidth = store.getState().builder.width;

  const [views, setViews] = useState<any>([]); // will be deleted
  const [hiddenPercentage, setHiddenPercentage] = useState(0);
  const boxHeightRef = useRef<any>(store.getState().builder.height || {});
  const boxWidthRef = useRef<any>(viewWidth || { xs: 600 });
  const factorsRef = useRef<any>({});
  const keyCodeRef = useRef<string>();
  const copyItemsRef = useRef<string[]>();
  const boxRef = useRef<any>();
  const layoutBreakRef = useRef<any>("xs");
  const saveButtonClickedRef = useRef(false);
  const parentContainerRef = useRef<HTMLElement>(null);
  const hasAddedToHistory = useRef(false);
  const boxItemRefs = useRef({});
  const canDrop = useRef(false);
  const isOverCurrentComponents = useRef({});
  const resizedContainerRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const topLevelIds = useSelector(selectTopLevelComponents, shallowEqual);
  const scaleFactor = useSelector((state: any) => state.builder.scaleFactor, shallowEqual);
  const layoutBreak = useSelector((state: any) => state.builder.layoutBreak, shallowEqual);
  const height = useSelector((state: any) => state.builder.height, shallowEqual);
  const theme = useTheme();

  useEffect(() => {
    if (currentApp) {
      const _currentApp = allowedApps?.find(app => app.id === (selectedAppId || currentApp?.id));
      if (appBuilderMode) {
        const _views = _currentApp?.templateConfig?.collections?.flatMap(col => col.pages).flatMap(page => page.views);
        setViews(_views);
      } else {
        let _views = populateViews(currentApp, collectionId, pageId);
        const sharedAppViews = currentApp?.templateConfig?.collections
          ?.flatMap(collection => collection.pages)
          ?.flatMap(page => page.views)
          .filter(v => v.info?.isShared);

        _views = _views.concat(sharedAppViews);
        setViews(_views);
      }
    }
  }, [currentApp]);

  useLayoutEffect(() => {
    if (!parentContainerRef.current) return;

    const node = parentContainerRef.current;

    function measureScale() {
      const width = node.offsetWidth || 1;
      const newScale = width / layoutBreakPointsValues[layoutBreak];
      dispatch(setViewBuilder({ scaleFactor: newScale, addToHistory: false }));
      getCanvasDimension(false, layoutBreak);
    }

    measureScale();

    const observer = new ResizeObserver(() => {
      measureScale();
    });
    observer.observe(node);

    return () => observer.disconnect();
  }, [layoutBreak]);

  const handleChangePropConfigTreeMenu = useCallback(() => {
    const prevState = store.getState().builder.isSyncTreeWithCanvasEnabled;
    dispatch(setViewBuilder({ isSyncTreeWithCanvasEnabled: !prevState }));
  }, []);

  const moveBox = useCallback((data: any) => {
    const { item, topLevel, ...rest } = data || {};
    const parentComponent = selectComponentById(store.getState(), topLevel);

    let newItem = { ...item, ...rest, parentId: parentComponent?.id };
    if (newItem?.id) {
      //Update
      dispatch(updateComponent(newItem));
    } else {
      //Create element and add
      const camelCaseType = newItem?.type ? toCamelCase(newItem.type) : "";
      const newId = uuid();
      newItem = {
        ...newItem,
        id: newId,
        props: {
          ...newItem.props,
          id: `${camelCaseType}${store.getState().builder.ids.length || 0}`,
          key: `${camelCaseType}${store.getState().builder.ids.length || 0}`,
        },
      };
      const containerItemWithNewChildren = {
        children: [...Array.from(new Set([...(parentComponent?.children || []), newId]))],
      };
      dispatch(addComponent(newItem));

      dispatch(updateComponent({ id: parentComponent?.id, changes: containerItemWithNewChildren }));
    }
  }, []);

  const getCanvasDimension = (withSetTimeout: boolean = true, layoutBreakPoint: any = "lg") => {
    const calculateDimension = () => {
      const _layoutBreak = layoutBreakPoint || layoutBreak;
      const builderElements = store.getState().builder.entities;
      let newHeight;
      let newWidth;

      if (Object.keys(builderElements.length > 0)) {
        newHeight = getLastElementPositionY(builderElements, store.getState().builder.boxPosition, boxHeightRef.current, _layoutBreak);
        newWidth = getLastElementPositionX(builderElements, store.getState().builder.boxPosition, boxWidthRef.current, _layoutBreak);
      } else {
        newHeight = { [layoutBreak]: parentContainerRef.current?.offsetHeight };
        newWidth = { [layoutBreak]: parentContainerRef.current?.offsetWidth };
      }

      boxHeightRef.current = newHeight;
      boxWidthRef.current = newWidth;

      dispatch(
        setViewBuilder({
          height: newHeight,
          width: newWidth,
          addToHistory: false,
        })
      );
    };
    if (withSetTimeout) {
      setTimeout(() => {
        calculateDimension();
      }, 0);
    } else {
      calculateDimension();
    }
  };

  const handleBackButton = useCallback(() => {
    const popoverModeValue = popoverMode === 1 ? 1 : 0;
    if (onBackClick) {
      return onBackClick();
    }
    if (!!templateId || !!historyId) {
      navigate(`/buildx/templates`);
    } else {
      navigate(`/buildx/app?appId=${appId}&collectionId=${collectionId}&pageId=${pageId}&viewId=${viewId}&popoverMode=${popoverModeValue}`);
    }
  }, []);

  const handleChangeLayout = (value: string | null) => {
    dispatch(setViewBuilder({ layoutBreak: value, addToHistory: false }));
    layoutBreakRef.current = value;
    getCanvasDimension(true, value);
  };

  const handleDeleteItem = useCallback((itemIds: any[], fromTable?: any) => {
    if (itemIds.length === 0) return;
    const deleteItemRecursive = (itemId: any, deletedFromParent?: any) => {
      const item = selectComponentById(store.getState(), itemId);
      const parentComponent = selectComponentById(store.getState(), item.parentId);
      if (!item || item.isCustomCanvas || (!deletedFromParent && (item.isChildTableContainer || item.isChildModalComponent))) return;
      const parentComponentId = item.parentId;
      dispatch(removeChildFromParent({ parentId: parentComponentId, childId: itemId }));

      if (item.children?.length > 0) {
        item.children.forEach((childId: any) => {
          deleteItemRecursive(childId, deletedFromParent);
        });
      }

      dispatch(removeComponent({ id: itemId, isChild: !itemIds.includes(itemId) }));
      if (parentComponent?.isTableHeader) {
        const indexChild = parentComponent.children.indexOf(itemId);
        const tableComponent = selectComponentById(store.getState(), parentComponent.parentId);
        const bodyTable = selectComponentById(store.getState(), tableComponent.children[1]);
        const idComponentToDelete = bodyTable.children[indexChild];
        dispatch(removeChildFromParent({ parentId: bodyTable.id, childId: idComponentToDelete }));
        dispatch(removeComponent({ id: idComponentToDelete, isChild: !itemIds.includes(itemId) }));
      }
    };

    itemIds.forEach((itemId: any) => {
      deleteItemRecursive(itemId);
    });

    if (!fromTable) {
      dispatch(setViewBuilder({ selectedItemsId: [], activeComponent: undefined, addToHistory: false }));
    }
  }, []);

  const getComponentsTreeRecursively = (ids: string[]): any[] =>
    ids.flatMap(id => {
      if (!id) return [];
      const component = _.clone(selectComponentById(store.getState(), id));
      if (!component || component.isCustomCanvas || component.isChildTableContainer || component.isChildModalComponent) {
        return [];
      }
      if (component?.children?.length > 0) {
        component.children = getComponentsTreeRecursively(component.children);
      }
      return component;
    });

  const handleCopyItems = useCallback((activeComponent: any | undefined, selectedItemsId: string[]) => {
    const componentIds = selectedItemsId?.length > 0 ? selectedItemsId : [activeComponent];
    let componentsTree = getComponentsTreeRecursively(componentIds);
    copyToClipboard(JSON.stringify(componentsTree));
    copyItemsRef.current = componentsTree;
  }, []);

  const handlePasteItems = (targetElementId?: string) => {
    readFromClipboard().then(res => {
      if (!res || !res.data || res.status === ClipboardOperationStatus.ERROR) return;
      try {
        const parsedData = JSON.parse(res.data);
        processClipboardData(parsedData, targetElementId);
      } catch (e) {
        return;
      }
    });
  };

  const findClosestGreedyParent = (componentId: string | undefined): any => {
    if (!componentId) return null;
    const component = selectComponentById(store.getState(), componentId);
    const greedyComponents = ["FlexContainer", "GridContainer", "CustomContainer", "StepperContainer"];
    if (greedyComponents.includes(component?.type)) {
      return component;
    }
    return findClosestGreedyParent(component?.parentId);
  };

  const findGrandParent = (element, targetElementId) => {
    const targetElement = targetElementId ? selectComponentById(store.getState(), targetElementId) : null;
    const activeComponent = store.getState().builder.activeComponent;
    const isGreedyComponent = (component: any) =>
      component?.type === "FlexContainer" ||
      component?.type === "GridContainer" ||
      component?.type === "CustomContainer" ||
      component?.type === "StepperContainer";
    const closestGreedyParent = targetElement
      ? findClosestGreedyParent(targetElementId)
      : findClosestGreedyParent(
          activeComponent && isGreedyComponent(selectComponentById(store.getState(), activeComponent)) ? activeComponent : element?.parentId
        );
    let newParentId;
    if (closestGreedyParent) {
      newParentId = closestGreedyParent.id;
    } else if (targetElementId && isGreedyComponent(targetElement)) {
      newParentId = targetElementId;
    } else if (activeComponent && isGreedyComponent(selectComponentById(store.getState(), activeComponent))) {
      newParentId = activeComponent;
    } else if (element?.parentId && isGreedyComponent(selectComponentById(store.getState(), element.parentId))) {
      newParentId = element?.parentId;
    } else {
      newParentId = selectTopLevelComponents(store.getState())[0];
    }
    return newParentId;
  };

  const getMinTopLg = copiedElements => {
    return copiedElements.reduce(
      (min, obj) => {
        const topLg = obj.top.lg;
        const leftLg = obj.left.lg;
        return {
          top: topLg < min.top ? topLg : min.top,
          left: leftLg < min.left ? leftLg : min.left,
        };
      },
      {
        top: Number.POSITIVE_INFINITY,
        left: Number.POSITIVE_INFINITY,
      }
    );
  };
  const getUpdatedOptionMap = (optionMap, minTopLg, randomId, camelCaseType, index, newKey) => {
    return optionMap
      ? Object.keys(optionMap).reduce((acc, key) => {
          const optionEl = optionMap[key];
          acc[key] = {
            ...optionEl,
            id: randomId,
            top: { lg: optionEl.top.lg - minTopLg.top + (hiddenPercentage + 50) },
            left: { lg: optionEl.left.lg + 1 - minTopLg.left },
            leftPercentage: {
              lg: ((optionEl.left.lg + 1 - minTopLg.left) / store.getState().builder.boxPosition.width) * 100 + "%",
            },
            props: {
              ...optionEl?.props,
              id: `${camelCaseType}${selectAllIds(store.getState())?.length + index + 1 || 0}`,
              key: `${camelCaseType}${selectAllIds(store.getState())?.length + index + 1 || 0}`,
            },
            children: Array.isArray(optionEl?.children) ? updateElementsIds(optionEl.children, newKey, randomId) : [],
          };
          return acc;
        }, {})
      : {};
  };

  const getUpdatedCopiedElements = (copiedElements: any, targetElementId: string) => {
    const minTopLg = getMinTopLg(copiedElements);
    let updatedParent = null as any;
    const updateParent = (randomId: string, newParentId: string) => {
      if (newParentId) {
        const parent = selectComponentById(store.getState(), newParentId);
        if (parent) {
          updatedParent = {
            ...parent,
            children: [...(updatedParent?.children || parent.children || []), randomId],
          };
        }
      }
    };
    const updatedElements = copiedElements.map((el: any, index: number) => {
      const camelCaseType = el?.type ? toCamelCase(el.type) : "";
      const randomId = uuid();
      const baseKey = `${camelCaseType}${selectAllIds(store.getState())?.length + index + 1 || 0}`;
      const newKey = `${baseKey}-${uuid}`;
      const updatedOptionMap = getUpdatedOptionMap(el.optionMap, minTopLg, randomId, camelCaseType, index, newKey);
      let newParentId = findGrandParent(el, targetElementId);
      const newElement = {
        ...el,
        id: randomId,
        top: { lg: el.top.lg - minTopLg.top + (hiddenPercentage + 50) },
        left: { lg: el.left.lg + 1 - minTopLg.left },
        leftPercentage: { lg: ((el.left.lg + 1 - minTopLg.left) / store.getState().builder.boxPosition.width) * 100 + "%" },
        ...(Object.keys(updatedOptionMap).length > 0 && { optionMap: updatedOptionMap }),
        props: {
          ...el?.props,
          id: newKey,
          key: newKey,
          defaultValue: "",
        },
        parentId: newParentId,
        children: el?.children?.length ? updateElementsIds(el.children, newKey, randomId) : [],
      };
      updateParent(randomId, newParentId);
      return newElement;
    });

    return [updatedElements, updatedParent];
  };

  const processClipboardData = async (clipBoardData, targetElementId) => {
    if (!Array.isArray(clipBoardData) || clipBoardData.length === 0) return;
    const [updatedElements, updatedParent] = getUpdatedCopiedElements(clipBoardData, targetElementId);
    const normalized = normalizeFormBuilder(updatedElements, updatedParent.isCustomCanvas ? null : updatedParent.id) as any;
    const formBuilderArray = Object.values(normalized.entities);
    dispatch(upsertComponents(updatedParent ? [updatedParent, ...formBuilderArray] : formBuilderArray));
    dispatch(setViewBuilder({ selectedItemsId: normalized.result, addToHistory: false }));
  };

  const updateParentStates = (item, prevStates) => {
    if (!item?.parentId) {
      return prevStates;
    }
    const newStates = { ...prevStates, [item?.parentId]: true };
    return updateParentStates(item.config.parent, newStates);
  };
  const handleKeyCodeChange = newKeyCode => {
    keyCodeRef.current = newKeyCode;
  };

  const handleSelectComponent = useCallback(
    (item: any) => {
      if (historyId) {
        return;
      }
      const flexCanvasEnabled = store.getState().builder.isFlexCanvasEnabled;
      const SyncTreeWithCanvasEnabled = store.getState().builder.isSyncTreeWithCanvasEnabled;
      const openStates = store.getState().builder.openStates;

      dispatch(setViewBuilder({ isKeyboardActive: true, addToHistory: false }));
      const activeComponent = store.getState().builder.activeComponent;
      dispatch(setViewBuilder({ tab: item?.config?.selectParent ? 2 : 0, addToHistory: false }));

      if (keyCodeRef.current === "Select") {
        let newSelectedItems = [item?.id];
        if (activeComponent && activeComponent != item?.id) {
          newSelectedItems = [item?.id, activeComponent];
        }
        if (flexCanvasEnabled) {
          // newSelectedItems = newSelectedItems?.filter(id => id != view?.dataSource?.formBuilder?.[0]?.id);
          newSelectedItems = newSelectedItems.filter(id => selectComponentParent(store.getState(), id) !== null);
        }

        dispatch(updateSelectedItemsId({ itemId: item?.id, newSelectedItems }));
        dispatch(setViewBuilder({ activeComponent: undefined, addToHistory: false }));
      } else {
        if (item?.parentId && SyncTreeWithCanvasEnabled) {
          const updatedOpenStates = updateParentStates(item, openStates);
          dispatch(
            setViewBuilder({
              openStates: updatedOpenStates,
              addToHistory: false,
            })
          );
        }
        if (item?.config?.selectParent) {
          dispatch(setViewBuilder({ activeComponent: item?.parentId, addToHistory: false }));
        } else {
          if (item?.isCustomCanvas) {
            dispatch(setViewBuilder({ activeComponent: flexCanvasEnabled ? item?.id : undefined, addToHistory: false }));
          } else {
            dispatch(setViewBuilder({ activeComponent: item?.id, addToHistory: false }));
          }
        }
        // else {
        //   dispatch(setViewBuilder({ activeComponent: flexCanvasEnabled ? item?.id : undefined, addToHistory: false }));
        // }
        dispatch(setViewBuilder({ selectedItemsId: [], addToHistory: false }));
      }
    },
    []
    // [activeComponent, flexCanvasEnabled, keyCodeRef.current, view?.dataSource?.formBuilder?.[0]?.id]
  );

  const { mutateAsync, data: templateData } = useMutation({ mutationFn: getTemplateById, mutationKey: "getTemplateById" });
  const { mutateAsync: getTemplateFromHistory } = useMutation({
    mutationFn: getTemplateByHistoryId,
    mutationKey: "getTemplateByHistoryId",
  });

  useEffect(() => {
    (async function () {
      if (!templateId) return;
      try {
        const { data: templateConfig } = (await mutateAsync(templateId)) as AxiosResponse<any>;

        const normalized = normalizeFormBuilder(
          isArray(templateConfig?.config?.formBuilder) ? templateConfig?.config?.formBuilder : []
        ) as any;

        // Prepare the formBuilder array for setAll
        const formBuilderArray = normalized?.entities ? Object.values(normalized.entities) : [];
        dispatch(
          setViewBuilder({
            builderElements: formBuilderArray,
            isFlexCanvasEnabled: !!templateConfig?.config?.flexCanvas,
            isDynamicHeight: !!templateConfig?.config?.dynamicHeight,
            isCanvasFullHeight: !!templateConfig?.config?.canvasFullHeight,
          })
        );
      } catch (error) {}
    })();
  }, [templateId]);

  useEffect(() => {
    (async function () {
      if (!historyId) return;
      try {
        const { data: templateConfig } = (await getTemplateFromHistory(historyId)) as AxiosResponse<any>;

        const normalized = normalizeFormBuilder(
          isArray(templateConfig?.config?.formBuilder) ? templateConfig?.config?.formBuilder : []
        ) as any;

        // Prepare the formBuilder array for setAll
        const formBuilderArray = normalized?.entities ? Object.values(normalized.entities) : [];
        dispatch(
          setViewBuilder({
            builderElements: formBuilderArray,
            isFlexCanvasEnabled: !!templateConfig?.config?.flexCanvas,
            isDynamicHeight: !!templateConfig?.config?.dynamicHeight,
            isCanvasFullHeight: !!templateConfig?.config?.canvasFullHeight,
          })
        );
      } catch (error) {}
    })();
  }, [historyId]);

  const handleCloseEdit = useCallback(() => {
    dispatch(setViewBuilder({ activeComponent: undefined, tab: 1, addToHistory: false }));
  }, []);

  const handleDynamicLayout = (values: any, item: any, options?: any) => {
    if (options?.skip) return;
    const newItem = {
      ...item,
      config: {
        ...item?.config,
        defaultWidth: values?.width,
        defaultHeight: values?.height,
        heightPx: {
          xs: values?.height,
          xl: values?.height,
        },
      },
    };

    const builderElements = selectAllComponentsArray(store.getState());
    const elements = builderElements?.map((el: any, index: any) => (el.id === newItem.id ? { ...newItem } : el));

    dispatch(
      setViewBuilder({
        builderElements: elements,
      })
    );
  };

  const handleCustomComponentSelect = useCallback(
    (data: any) => {
      const item: any = {
        type: data?.config?.type,
        name: data?.name,
        props: data,
        left: 10,
        top: 10,
        configData: {
          ...data?.config,
        },
        config: {
          defaultWidth: data?.config?.defaultWidth || 150,
          defaultHeight: data?.config?.defaultHeight || 50,
          heightPx: {
            xs: data?.config?.defaultHeight || 50,
          },
          widthPx: {
            xs: data?.config?.defaultWidth || 150,
          },
          fixedWidth: !!data?.config?.defaultWidth || false,
          isPercentageHeight: false,
          controlledComponent: true,
          disableResizeHeight: false,
          customComponent: true,
          BxComponent: true,
          customComponentId: data?.id,
        },
      };

      const left = 10;
      const top = 10;
      const leftPercentage = (left / store.getState().builder.boxPosition.width) * 100 + "%";

      const PWidth = store.getState().builder.boxPosition.width * 100;
      const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

      const PHeight = store.getState().builder.boxPosition.height * 100;
      const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

      moveBox({
        item,
        left: { ...item?.left, [layoutBreak]: left },
        top: { ...item?.top, [layoutBreak]: top },
        leftPercentage: { ...item?.leftPercentage, [layoutBreak]: leftPercentage },
        widthPercentage: { ...item?.widthPercentage, [layoutBreak]: percentageWidth },
        heightPercentage: { ...item?.heightPercentage, [layoutBreak]: percentageHeight },
      });
      return undefined;
    },
    [store.getState().builder.boxPosition.width, store.getState().builder.boxPosition.height, layoutBreak, moveBox]
  );

  const handleViewSelect = useCallback(
    (data: any) => {
      const item: any = {
        type: ComponentItemType.BXView,
        viewName: data.viewName,
        props: {
          sx: {
            width: "100%",
            height: "100%",
            backgroundColor: "primary.main",
          },
        },
        left: 0,
        top: 0,
        config: {
          defaultWidth: 150,
          defaultHeight: 400,
          heightPx: {
            xs: 400,
            xl: 400,
          },
          widthPercentage: {
            xs: "100%",
          },
          viewRef: {
            id: data?.value,
          },
          fixedWidth: false,
          isPercentageHeight: false,
          disableResizeHeight: false,
        },
      };

      const left = 0;
      const top = 0;
      const leftPercentage = (left / store.getState().builder.boxPosition.width) * 100 + "%";

      const PWidth = store.getState().builder.boxPosition.width * 100;
      const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

      const PHeight = store.getState().builder.boxPosition.height * 100;
      const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

      const topLevel = selectTopLevelComponents(store.getState());

      moveBox({
        item,
        left: { ...item?.left, [layoutBreak]: left },
        top: { ...item?.top, [layoutBreak]: top },
        leftPercentage: { ...item?.leftPercentage, [layoutBreak]: leftPercentage },
        widthPercentage: { ...item?.widthPercentage, [layoutBreak]: percentageWidth },
        heightPercentage: { ...item?.heightPercentage, [layoutBreak]: percentageHeight },
        topLevel,
      });
      return undefined;
    },
    [store.getState().builder.boxPosition.width, store.getState().builder.boxPosition.height, layoutBreak, moveBox]
  );

  const borderSelectionContainer = document.body;
  const containerRef = useRef<HTMLElement>(null);

  const handleStartSelection = () => {
    dispatch(
      setViewBuilder(prev => {
        if (prev.selectedItemsId.length === 0 && prev.activeComponent === undefined && prev.tab === 1) {
          return prev;
        }
        return { selectedItemsId: [], activeComponent: undefined, tab: 1, addToHistory: false };
      })
    );
  };

  const finishSelection: TFinishSelectionCallback = (items, e) => {
    const selectedIds = items.map(item => item?.getAttribute("data-id") || "");
    const topLevelIds = selectTopLevelComponents(store.getState());
    const canvasComponent = selectComponentById(store.getState(), topLevelIds);
    const filteredIds = canvasComponent?.children?.filter((el: any) => selectedIds.includes(el));
    dispatch(setViewBuilder({ selectedItemsId: filteredIds, activeComponent: undefined, tab: 0, addToHistory: false }));
  };

  const handleCanvasNotFocused = () => {
    dispatch(setViewBuilder({ isKeyboardActive: false, addToHistory: false }));
  };
  const handleCanvasFocused = (e: any) => {
    e.stopPropagation();
    dispatch(setViewBuilder({ isKeyboardActive: true, addToHistory: false }));
  };

  useEffect(() => {
    // calculate how much hidden from the canvas board
    const handleScroll = () => {
      const div = containerRef.current;
      if (div) {
        const rect = div.getBoundingClientRect();
        const { top } = rect;
        if (top < 0) {
          setHiddenPercentage(Math.abs(top));
        } else {
          setHiddenPercentage(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const componentId = (() => {
      if (componentIdFromParams) return componentIdFromParams;
      const pathSegments = location.pathname.split("/");
      const builderKeywords = ["page-builder", "layout"];
      const builderIndex = pathSegments.findIndex(segment => builderKeywords.includes(segment));
      if (builderIndex !== -1 && pathSegments.length > builderIndex + 3) {
        return pathSegments.slice(builderIndex + 3).pop();
      }
      return undefined;
    })();

    if (componentId) {
      dispatch(setViewBuilder({ activeComponent: componentId, addToHistory: false }));
    }
  }, [componentIdFromParams]);

  const addTemplateToConfig = (template: any, replaceAll: boolean) => {
    const normalizedTemplate = normalizeFormBuilder(template.config.formBuilder);
    const normalizedTemplateElements = normalizedTemplate?.entities ? Object.values(normalizedTemplate.entities) : [];
    const { flexCanvasEnabled }: any = store.getState().builder;
    const templateElementsIds = normalizedTemplateElements?.map((el: any) => el.id);

    if (flexCanvasEnabled) {
      const builderElements = selectAllComponentsArray(store.getState());
      const flexCanvas: any = builderElements.find((el: any) => el?.parentId === null);

      const templateWithParents = normalizedTemplateElements.map((el: any) => ({
        ...el,
        parentId: flexCanvas?.id,
      }));

      if (replaceAll) {
        flexCanvas.children = [...templateElementsIds];
        dispatch(upsertComponents([...templateWithParents, flexCanvas]));
      } else {
        flexCanvas.children = [...Array.from(new Set([...flexCanvas?.children, ...templateElementsIds]))];
        dispatch(upsertComponents([...templateWithParents, flexCanvas]));
      }
    } else {
      if (replaceAll) {
        dispatch(setViewBuilder({ builderElements: normalizedTemplate }));
      } else {
        dispatch(upsertComponents(normalizedTemplateElements));
      }
    }
  };

  return (
    <>
      <FormBuilderSideEffect
        pageBuilderMode={pageBuilderMode}
        appBuilderMode={appBuilderMode}
        params={params}
        layout={layout}
        page={page}
        getCanvasDimension={getCanvasDimension}
      />

      <FormBuilderListener
        handleDeleteItem={handleDeleteItem}
        handleCopyItems={handleCopyItems}
        handlePasteItems={handlePasteItems}
        handleKeyCodeChange={handleKeyCodeChange}
        componentId={topLevelIds}
        boxItemRefs={boxItemRefs}
      />
      <WindowSize boxRef={boxRef} scaleFactor={scaleFactor} />
      <Box onClick={handleCanvasNotFocused} height='100%' paddingBottom={1}>
        <Box display='flex' className={classes.root}>
          <CustomDragLayer factorsRef={factorsRef} boxPosition={store.getState().builder.boxPosition} canDrop={canDrop} />
          <ViewBuilderHeader
            appBuilderMode={appBuilderMode}
            templateData={templateData}
            template={templateId}
            isEditingTemplate={!!templateId}
            isHistory={!!historyId}
            handleBackButton={handleBackButton}
            handleChangeLayout={handleChangeLayout}
            addTemplate={addTemplateToConfig}
            params={params || paramsValues}
            handleSelectComponent={handleSelectComponent}
            onSave={onSave}
            getCanvasDimension={getCanvasDimension}
          />
        </Box>
        <Box display='flex' height='100%' gap={1}>
          <Box onClick={handleCanvasFocused} display='flex' flex='0 0 auto' justifyContent='center'>
            {!historyId && (
              <ResizableContainer
                minWidth={230}
                maxWidth={350}
                position='right'
                onResizeFinished={() => {
                  getCanvasDimension();
                }}
              >
                <LeftSideMenuItems
                  onDiscardEdit={handleCloseEdit}
                  onCustomComponentSelect={handleCustomComponentSelect}
                  onViewSelect={handleViewSelect}
                  views={views}
                  appBuilderMode={appBuilderMode}
                  pageBuilderMode={pageBuilderMode}
                  handleSelectComponent={handleSelectComponent}
                  handleCopyItems={handleCopyItems}
                  handleDeleteItem={handleDeleteItem}
                  handlePasteItems={handlePasteItems}
                  onChangePropConfigTreeMenu={handleChangePropConfigTreeMenu}
                  copyItemsRef={copyItemsRef}
                  getCanvasDimension={getCanvasDimension}
                />
              </ResizableContainer>
            )}
          </Box>
          {/* canvas */}
          <Box
            onClick={handleCanvasFocused}
            ref={parentContainerRef}
            display='flex'
            flex={"1 1 auto"}
            justifyContent='center'
            width={"100%"}
            data-testId='canvas-drop-layer'
            overflow='auto'
          >
            <ResizeElement
              resizedItemRef={resizedContainerRef}
              disableHeight={true}
              scrollToButton
              isCanvas={true}
              isActiveComponentResizable={true}
              onResizeFinished={() => {
                getCanvasDimension(true, layoutBreak);
              }}
            >
              <Box
                ref={resizedContainerRef}
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  width: "100%",
                  overflow: "hidden",
                  height: "100%",
                  minHeight: getItemClosestProp(height, layoutBreak),
                  background: "unset",
                  boxShadow: `inset 0 0 0 2px ${theme.palette.divider}`,
                }}
                id='canvas-box'
              >
                <ContainerGrid
                  boxWidth={boxWidthRef.current}
                  parentContainerRef={parentContainerRef}
                  boxContextHeight={boxHeightRef.current}
                />
                <Box ref={boxRef} height='100%' width='100%' overflow='hidden'>
                  <div
                    style={{
                      transform: `scale(${scaleFactor})`,
                      transformOrigin: "0 0",
                      maxWidth: `${100 / scaleFactor}%`,
                      width: `${100 / scaleFactor}%`,
                      height: `${100 / scaleFactor}%`,
                    }}
                  >
                    {!!topLevelIds?.length &&
                      topLevelIds.map((componentId: number, index: number) => {
                        return (
                          <DragElement
                            key={`${componentId}`}
                            componentId={componentId}
                            itemIndex={index}
                            className={"mouse-select__selectable"}
                            setActiveComponent={handleSelectComponent}
                            boxItemRefs={boxItemRefs}
                            boxHeightRef={boxHeightRef.current}
                            canDropItem={canDrop}
                            handleCopyItems={handleCopyItems}
                            handleDeleteItem={handleDeleteItem}
                            handlePasteItems={handlePasteItems}
                            isOverCurrentComponents={isOverCurrentComponents}
                            $views={views}
                            copyItemsRef={copyItemsRef}
                          />
                        );
                      })}
                  </div>
                </Box>
              </Box>
            </ResizeElement>
          </Box>
          {!historyId && (
            <ReactMouseSelect
              boxRef={boxRef}
              portalContainer={borderSelectionContainer}
              itemClassName='mouse-select__selectable'
              sensitivity={5}
              tolerance={5}
              notStartWithSelectableElements={true}
              startSelectionCallback={handleStartSelection}
              finishSelectionCallback={finishSelection}
            />
          )}
          <Box display='flex' flex='0 0 auto'>
            {!historyId && (
              <ResizableContainer
                minWidth={230}
                maxWidth={350}
                position='left'
                onResizeFinished={() => {
                  getCanvasDimension();
                }}
              >
                <RightSideMenuItems
                  onDiscardEdit={handleCloseEdit}
                  appId={appId}
                  collectionId={collectionId}
                  pageId={pageId}
                  onDeleteItem={handleDeleteItem}
                  views={views}
                  boxHeightRef={boxHeightRef.current}
                />
              </ResizableContainer>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export { FormBuilderEditor };
