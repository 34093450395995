import { IconButton } from "@mui/material";
import { joinObjects } from "hd-utils";
import { FC } from "react";
import { BXIcon } from "src/components/BXUI/Icon";
import { CustomIconButtonProps } from "./types";

export const CustomIconButton: FC<CustomIconButtonProps> = ({
  onClick,
  size,
  sx,
  loading,
  disabled,
  backgroundColor,
  borderWidth,
  borderColor,
  style,
  iconSize,
  iconConfig,
  iconName,
  iconColor,
  ...restProps
}) => {
  // TODO: remove this after supporting action config upload remove the onclick
  return (
    <IconButton
      onClick={onClick}
      size={size}
      sx={sx}
      disabled={loading || disabled}
      style={joinObjects(
        {
          background: backgroundColor,
          width: "100%",
          height: "100%",
          border: `${borderWidth} solid transparent`,
          borderColor: borderColor,
          padding: 0,
        },
        style
      )}
    >
      <BXIcon
        width={iconSize}
        height={iconSize}
        icon={iconConfig?.icon || iconName || "User"}
        url={iconConfig?.url}
        visibility={iconConfig?.visibility}
        color={iconColor || "#fff"}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      />
    </IconButton>
  );
};
