export const defaultValidationMessages = {
  required: "This field is required",
  min: (minValue: number, maxValue: number) => `Value must be between ${minValue} and ${maxValue}`,
  max: (maxValue: number, minValue: number) => `Value must be between ${minValue} and ${maxValue}`,
  minLength: (minLength: number) => `Value must be at least ${minLength} characters long`,
  maxLength: (maxLength: number) => `Value must be at most ${maxLength} characters long`,
  shouldBeNumber: "Value must be a number",
  shouldBeEmail: "Invalid email address",
  invalidDate: "Invalid Date",
  shouldBeRegex: "Invalid input format",
  shouldBePassword: {
    minLength: (minLength: number) => `Password must be at least ${minLength} characters long`,
    includeLowercase: "Password must contain at least one lowercase letter",
    includeUppercase: "Password must contain at least one uppercase letter",
    includeNumbers: "Password must contain at least one number",
    includeSymbols: "Password must contain at least one special character",
  },
  confirmPasswordValidation: "Passwords do not match",
  shouldBeUrl: "Invalid URL",
  shouldBeUUID: "Invalid UUID",
  shouldMatch: (matchValue: string) => `The value does not match the ${matchValue}`,
  shouldBeColor: "Invalid color code",
  fileUploader: "Invalid file type",
  fileSize: (maxSize: number) => `File size should be less than ${maxSize} MB`,
};
