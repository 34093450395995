import { Box, IconButton, Menu, MenuItem, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Icon, IconList, IconRobot, IconSettings } from "@tabler/icons-react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  setShowComponentLinks as setShowComponentLinksAction,
  setShowViewLinks as setShowViewLinksAction,
} from "src/features/endUser/endUserSlice";

import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import BuildxWindow, { MIN_DIMENSIONS, VIEWPORT_PADDING } from "../../../components/BuildxWindow/BuildxWindow";
import { Chat } from "../../../components/Chat/Chat";
import { AdministrationSwitcher } from "../Header/AdministrationSwitcher";

interface BuildxGearProps {
  position: { x: number; y: number };
  aiPosition: { x: number; y: number };
  updatePosition: (newPosition: { x: number; y: number }) => void;
  updateAiPosition: (newPosition: { x: number; y: number }) => void;
}

export const GEAR_BOX_DIMENSIONS = {
  width: 100,
  height: 48,
};

export const MENU_SPACING = 5; // Add this constant

type StyleClasses = {
  dragHandle: string;
  gearButton: string;
};

const useStyles = makeStyles(
  (theme: any) => ({
    dragHandle: {
      position: "fixed",
      zIndex: 1260, // Update this value
    },
    gearButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    menu: {
      // Add this style
      "& .MuiMenu-paper": {
        zIndex: 1270, // Keep menu above gear
      },
    },
  }),
  { name: "BuildxGear" }
) as () => StyleClasses & { menu: string };

const useDraggable = (type: string, position: { x: number; y: number }, isResizing: boolean, onDragStart?: () => void) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      canDrag: !isResizing, // Disable dragging when resizing
      item: () => {
        onDragStart?.();
        const element = elementRef.current;
        const rect = element?.getBoundingClientRect() || { width: 0, height: 0 };
        const actualDimensions = {
          width: Math.ceil(rect.width),
          height: Math.ceil(rect.height + 2),
        };
        return { type, position, dimensions: actualDimensions };
      },
      collect: monitor => ({
        isDragging: !!monitor.isDragging(), // Add double negation to ensure boolean
      }),
    }),
    [position, onDragStart, isResizing]
  );

  const setRef = useCallback(
    (element: HTMLDivElement | null) => {
      elementRef.current = element;
      drag(element);
    },
    [drag]
  );

  return { isDragging, dragRef: setRef };
};

const adjustPositionToViewport = (position: { x: number; y: number }, dimensions: { width: number; height: number }) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  // Add padding to all edge calculations
  if (dimensions.width >= viewportWidth - VIEWPORT_PADDING * 2) {
    position.x = VIEWPORT_PADDING;
  } else if (position.x + dimensions.width > viewportWidth - VIEWPORT_PADDING) {
    position.x = viewportWidth - dimensions.width - VIEWPORT_PADDING;
  }

  if (dimensions.height >= viewportHeight - VIEWPORT_PADDING * 2) {
    position.y = VIEWPORT_PADDING;
  } else if (position.y + dimensions.height > viewportHeight - VIEWPORT_PADDING) {
    position.y = viewportHeight - dimensions.height - VIEWPORT_PADDING;
  }

  position.x = Math.max(VIEWPORT_PADDING, position.x);
  position.y = Math.max(VIEWPORT_PADDING, position.y);

  return position;
};

interface WindowConfig {
  title: string;
  enabled: boolean;
  position: { x: number; y: number };
  dimensions: { width: number; height: number };
  icon?: Icon; // Add this line
}

const WINDOW_DEFAULTS = {
  ai: {
    title: "BuildX AI Assistant",
    icon: IconRobot,
    getInitialPosition: () => ({
      x: window.innerWidth - MIN_DIMENSIONS.width - VIEWPORT_PADDING,
      y: window.innerHeight - MIN_DIMENSIONS.height - VIEWPORT_PADDING,
    }),
  },
  outline: {
    title: "Outline",
    icon: IconList,
    getInitialPosition: () => ({
      x: VIEWPORT_PADDING,
      y: VIEWPORT_PADDING,
    }),
  },
};

const BuildxGear: FC<BuildxGearProps> = ({ position, aiPosition, updatePosition, updateAiPosition }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showViewLinks, setShowViewLinks] = useState(false);
  const [showComponentLinks, setShowComponentLinks] = useState(false);
  const [windows, setWindows] = useState<{ [key: string]: WindowConfig }>({
    ai: {
      title: WINDOW_DEFAULTS.ai.title,
      enabled: false,
      position: aiPosition,
      dimensions: MIN_DIMENSIONS,
    },
    outline: {
      title: WINDOW_DEFAULTS.outline.title,
      enabled: false,
      position: WINDOW_DEFAULTS.outline.getInitialPosition(),
      dimensions: MIN_DIMENSIONS,
    },
  });

  const calculateAiPosition = () => ({
    x: window.innerWidth - MIN_DIMENSIONS.width - VIEWPORT_PADDING,
    y: window.innerHeight - MIN_DIMENSIONS.height - VIEWPORT_PADDING,
  });

  const [menuPosition, setMenuPosition] = useState<"bottom" | "top">("bottom");

  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
      return;
    }

    const buttonRect = event.currentTarget.getBoundingClientRect();
    const spaceBelow = window.innerHeight - buttonRect.bottom;

    // Open above if there's not enough space below
    const shouldOpenTop = spaceBelow < 100; // Use a reasonable minimum space threshold
    setMenuPosition(shouldOpenTop ? "top" : "bottom");
    setAnchorEl(event.currentTarget);
  };

  const { isDragging: isDraggingGear, dragRef: gearRef } = useDraggable("gear", position, false, () => setAnchorEl(null));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleWindow = (windowKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setWindows(prev => {
      const window = prev[windowKey];
      const newState = {
        ...prev,
        [windowKey]: {
          ...window,
          enabled: e.target.checked,
          position:
            e.target.checked && window.position.x === 0 && window.position.y === 0
              ? WINDOW_DEFAULTS[windowKey as keyof typeof WINDOW_DEFAULTS].getInitialPosition()
              : window.position,
        },
      };
      return newState;
    });

    if (e.target.checked && aiPosition.x === 0 && aiPosition.y === 0) {
      updateAiPosition(calculateAiPosition());
    }
  };

  const handleWindowDimensionsChange = (windowKey: string) => (dimensions: { width: number; height: number }) => {
    setWindows(prev => ({
      ...prev,
      [windowKey]: {
        ...prev[windowKey],
        dimensions,
      },
    }));
  };

  const handleAdministrationChange = (event: { target: { checked: boolean } }) => {
    setAnchorEl(null); // Close menu when toggling administration
  };

  // Add new useEffect for window resize handling
  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Adjust gear position
      const newGearPosition = adjustPositionToViewport({ ...position }, GEAR_BOX_DIMENSIONS);
      updatePosition(newGearPosition);

      // Adjust window positions
      setWindows(prev => {
        const updatedWindows = { ...prev };
        Object.entries(updatedWindows).forEach(([key, window]) => {
          if (window.enabled) {
            const maxRight = viewportWidth - window.dimensions.width - VIEWPORT_PADDING;
            const maxBottom = viewportHeight - window.dimensions.height - VIEWPORT_PADDING;

            const newPosition = {
              x: Math.max(VIEWPORT_PADDING, Math.min(window.position.x, maxRight)),
              y: Math.max(VIEWPORT_PADDING, Math.min(window.position.y, maxBottom)),
            };

            updatedWindows[key] = {
              ...window,
              position: newPosition,
            };
          }
        });
        return updatedWindows;
      });

      // Update AI position if needed
      if (aiPosition.x > 0 || aiPosition.y > 0) {
        const maxRight = viewportWidth - MIN_DIMENSIONS.width - VIEWPORT_PADDING;
        const maxBottom = viewportHeight - MIN_DIMENSIONS.height - VIEWPORT_PADDING;
        const newAiPosition = {
          x: Math.max(VIEWPORT_PADDING, Math.min(aiPosition.x, maxRight)),
          y: Math.max(VIEWPORT_PADDING, Math.min(aiPosition.y, maxBottom)),
        };
        updateAiPosition(newAiPosition);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [position, aiPosition, updatePosition, updateAiPosition]);

  const handleShowViewLinks = () => {
    setShowViewLinks(prev => {
      dispatch(setShowViewLinksAction(!prev));
      return !prev;
    });
  };

  const handleShowComponentLinks = () => {
    setShowComponentLinks(prev => {
      dispatch(setShowComponentLinksAction(!prev));
      return !prev;
    });
  };

  return (
    <>
      <Box
        ref={gearRef}
        className={classes.dragHandle}
        display='flex'
        justifyContent='center'
        alignItems='center'
        style={{
          top: position.y,
          left: position.x,
          opacity: isDraggingGear ? 0.5 : 1,
          cursor: isDraggingGear ? "grabbing" : "grab",
          pointerEvents: isDraggingGear ? "none" : "auto",
        }}
      >
        <IconButton className={classes.gearButton} onClick={handleClick}>
          <IconSettings size={26} />
        </IconButton>
        <div>
          <AdministrationSwitcher onChange={handleAdministrationChange} />
        </div>
      </Box>

      <Menu
        ref={menuRef}
        className={classes.menu} // Add this line
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: menuPosition,
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: menuPosition === "bottom" ? "top" : "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              minWidth: "150px",
              marginTop: menuPosition === "bottom" ? `${MENU_SPACING}px` : `-${MENU_SPACING}px`,
            },
          },
        }}
        disableRestoreFocus
        keepMounted
      >
        {process.env.NODE_ENV !== "production" && (
          <>
            <MenuItem sx={{ display: "flex", justifyContent: "space-between", minHeight: 40 }}>
              <Typography>BuildX AI</Typography>
              <Switch checked={windows.ai.enabled} onChange={handleToggleWindow("ai")} color='primary' size='small' />
            </MenuItem>
            <MenuItem sx={{ display: "flex", justifyContent: "space-between", minHeight: 40 }}>
              <Typography>Outline</Typography>
              <Switch checked={windows.outline.enabled} onChange={handleToggleWindow("outline")} color='primary' size='small' />
            </MenuItem>
          </>
        )}
        <MenuItem sx={{ display: "flex", justifyContent: "space-between", minHeight: 40 }}>
          <Typography>Show View Links</Typography>
          <Switch checked={showViewLinks} onChange={handleShowViewLinks} color='primary' size='small' />
        </MenuItem>
        <MenuItem sx={{ display: "flex", justifyContent: "space-between", minHeight: 40 }}>
          <Typography>Show component Links</Typography>
          <Switch checked={showComponentLinks} onChange={handleShowComponentLinks} color='primary' size='small' />
        </MenuItem>
      </Menu>

      {Object.entries(windows).map(
        ([key, window]) =>
          window.enabled && (
            <BuildxWindow
              key={key}
              title={window.title}
              icon={WINDOW_DEFAULTS[key as keyof typeof WINDOW_DEFAULTS].icon}
              position={window.position}
              dimensions={window.dimensions}
              onClose={() => handleToggleWindow(key)({ target: { checked: false } } as any)}
              onPositionChange={newPosition => {
                setWindows(prev => ({
                  ...prev,
                  [key]: { ...prev[key], position: newPosition },
                }));
              }}
              onDimensionsChange={handleWindowDimensionsChange(key)}
              onResizeStart={handleClose} // Add this line
              onDragStart={handleClose}
            >
              {key === "ai" && <Chat />}
              {key === "outline" && <div>Outline Content</div>}
            </BuildxWindow>
          )
      )}
    </>
  );
};

export default BuildxGear;
