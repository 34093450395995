import { Box, Typography } from "@mui/material";
import React from "react";
import { BXEngine } from "src/BXEngine";
import BXPage from "src/components/BXUI/Page";
import { BXPageProvider } from "src/contexts/BXPageProvider";
import { BXApp, BXAppCollection } from "src/types/BXAppType";

interface GenericPageProps {
  code: string; //404, 500, etc..
  sharedAppCollection: BXAppCollection;
  currentApp: BXApp;
  currentTemplateConfig: any;
}

export const CustomGenericPage: React.FC<GenericPageProps> = ({ code, sharedAppCollection, currentApp, currentTemplateConfig }) => {
  const page = sharedAppCollection?.pages?.find(page => page?.genericType === code);
  //If a custom generic page is not created, render one by default.
  if (page?.views?.length === 0 && page?.layout !== "custom") {
    return (
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' minHeight={200} padding={2}>
        <Typography variant='h3' gutterBottom mb={2}>
          Oops, Page Not Found!
        </Typography>
      </Box>
    );
  }

  return (
    <BXPageProvider key={page?.id} pageId={page?.id as string} page={page} sharedCollection={sharedAppCollection}>
      <BXPage page={page} collectionId={sharedAppCollection?.id} layouts={currentTemplateConfig?.layouts}>
        {page?.layout === "custom" ? (
          <BXEngine
            page={page}
            auth={{}}
            key={page?.id}
            isVisible
            layout={[page as any]}
            path={[currentApp.name, page.name].join(".")}
            disablePageDirty
            isPageView
          />
        ) : (
          page?.views
            ?.filter(view => view.info?.visibility !== "Hidden")
            ?.map?.(view => (
              <BXEngine
                page={page}
                auth={{}}
                key={view?.id}
                layout={[view]}
                path={[currentApp.name, page.name, view.info?.name].join(".")}
              />
            ))
        )}
      </BXPage>
    </BXPageProvider>
  );
};
