import MonacoEditor, { Monaco } from "@monaco-editor/react";
import { useRef } from "react";

interface CustomMonacoEditorProps {
  value?: string;
  onChange?: (value: string | undefined) => void;
  language?: string;
  height?: string;
  width?: string;
  monacoEditorOptions?: any;
  monacoEditorTheme?: string;
  defaultValue?: string;
}

const CustomMonacoEditor: React.FC<CustomMonacoEditorProps> = ({
  value,
  onChange,
  language = "json",
  height = "400px",
  width = "100%",
  monacoEditorOptions,
  monacoEditorTheme,
  defaultValue,
}) => {
  const editorRef = useRef<any>();

  const handleEditorDidMount = (editor: any, monaco: Monaco) => {
    editorRef.current = editor;
    if (!monaco) return;

    monaco.languages.registerCompletionItemProvider(language, {
      triggerCharacters: [".", "{"],
      provideCompletionItems: (model, position) => {
        const lineContent = model.getLineContent(position.lineNumber);
        const textUntilPosition = lineContent.substring(0, position.column - 1);
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        };

        const lastOpenBrace = textUntilPosition.lastIndexOf("{");
        const lastDot = textUntilPosition.lastIndexOf(".");

        let relevantText = "";
        if (lastDot > lastOpenBrace) {
          relevantText = textUntilPosition.substring(lastOpenBrace + 1);
        } else {
          relevantText = textUntilPosition.substring(lastOpenBrace);
        }

        const strippedValue = relevantText.trim().replace(/[{}]/g, "");

        const createSuggestionWithRange = (label: string, index: number): any => ({
          label,
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: label,
          detail: "Buildx Syntax options",
          range,
          sortText: String(index).padStart(5, "0"),
          filterText: label,
          uniqueId: `${label}_${index}`,
        });

        const suggestionsSet = new Set<string>();
        const suggestions: any[] = [];

        const addSuggestion = (label: string, index = suggestions.length) => {
          if (!suggestionsSet.has(label)) {
            suggestionsSet.add(label);
            suggestions.push(createSuggestionWithRange(label, index));
          }
        };

        switch (true) {
          case relevantText === "{":
            addSuggestion("$global");
            addSuggestion("$app");
            addSuggestion("$buildx");
            addSuggestion("$buildxLogin");
            break;

          case /\$buildxLogin\.$/.test(relevantText):
            addSuggestion("email");
            addSuggestion("password");
            addSuggestion("recaptcha");
            break;

          case /\$global\.browser\.$/.test(strippedValue):
            addSuggestion("deviceType");
            addSuggestion("osVersion");
            addSuggestion("timeZone");
            break;

          case /\$global\.localStorage\.$/.test(strippedValue):
            addSuggestion("device_uuid");
            addSuggestion("<local_storage_key>");
            break;

          case /\$global\.$/.test(strippedValue):
            addSuggestion("location");
            addSuggestion("localStorage");
            addSuggestion("cookies");
            addSuggestion("browser");
            addSuggestion("breakpoint");
            break;

          case /\$buildx\.$/.test(strippedValue):
            addSuggestion("appVersion");
            break;

          default:
            return { suggestions: [] };
        }

        return { suggestions };
      },
    });
  };

  return (
    <MonacoEditor
      height={height}
      width={width}
      language={language}
      value={value}
      onChange={onChange}
      onMount={handleEditorDidMount}
      options={monacoEditorOptions}
      theme={monacoEditorTheme}
      defaultValue={defaultValue}
    />
  );
};

export default CustomMonacoEditor;
