import { BXApp } from "src/types/BXAppType";
import { compareVersions } from "src/utils/generalUtils";
import { decompressData } from "src/utils/services";
import { migrateTo1_0_0 } from "./1.0.0";
import { migrateTo1_0_1 } from "./1.0.1";
import { migrateTo1_0_2 } from "./1.0.2";
import { migrateTo1_0_3 } from "./1.0.3";
import { migrateTo1_0_4 } from "./1.0.4";
import { migrateTo1_0_5 } from "./1.0.5";
import { migrateTo1_0_6 } from "./1.0.6";
import { migrateTo1_0_7 } from "./1.0.7";
import { migrateTo1_0_8 } from "./1.0.8";

const migrations: any = {
  "1.0.0": migrateTo1_0_0,
  "1.0.1": migrateTo1_0_1,
  "1.0.2": migrateTo1_0_2,
  "1.0.3": migrateTo1_0_3,
  "1.0.4": migrateTo1_0_4,
  "1.0.5": migrateTo1_0_5,
  "1.0.6": migrateTo1_0_6,
  "1.0.7": migrateTo1_0_7,
  "1.0.8": migrateTo1_0_8,
};

export const handleMigrateApp = (app: BXApp, appVersionFromSync?: any) => {
  const decompressedData = decompressData(app?.templateConfig);
  const appVersion = app?.appVersion || decompressedData?.appVersion;

  const migrationsKeys = Object.keys(migrations);
  let versionKeys = !appVersion ? migrationsKeys : migrationsKeys.filter(version => compareVersions(version, appVersion) > 0);
  if (appVersionFromSync) {
    versionKeys = migrationsKeys.filter(
      version => compareVersions(version, appVersion) > 0 && compareVersions(appVersionFromSync, version) >= 0
    );
  }
  let appAfterMigration = app;
  for (let i = 0; i < versionKeys.length; i++) {
    const version = versionKeys[i];
    const migrationFunction = migrations[version];
    appAfterMigration = migrationFunction(appAfterMigration);
  }
  return appAfterMigration;
};
