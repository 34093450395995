import { CircularProgress } from "@mui/material";
import { FC } from "react";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";

export const LoadingApps: FC = () => {
  const loadingApps = useBuildxProviderValue("loadingApps");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  if (!loadingApps || fqdnApp) return null;

  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 85,
        zIndex: 999,
      }}
    >
      {loadingApps ? <CircularProgress /> : <></>}
    </div>
  );
};
