import _ from "lodash";
import { BXApp } from "src/types/BXAppType";
import { compressData, decompressData } from "src/utils/services";
import { actionDefaultKeys, components } from "src/views/pages/BuildX/FormBuilder/utils";

export function migrateTo1_0_8(_app: BXApp) {
  const app: any = {
    ..._app,
    appConfig: decompressData(_app?.appConfig),
    templateConfig: decompressData(_app?.templateConfig),
    upTemplateConfig: decompressData(_app?.upTemplateConfig),
  };

  processTemplateConfig(app?.templateConfig);
  traverseApp(app);

  _.set(app, "appVersion", "1.0.8");

  const compressedApp = {
    ...app,
    appConfig: compressData(app?.appConfig),
    templateConfig: compressData(app?.templateConfig),
    upTemplateConfig: compressData(app?.upTemplateConfig),
  };

  return compressedApp;
}
function removeMatchKeysWithDefaultsObj(item: any, defaults: any): any {
  function removeRecursively(obj: any, defaultsObj: any): any {
    if (!defaultsObj) return obj;
    const saved = { ...obj };
    Object.keys(defaultsObj).forEach(key => {
      if (defaultsObj && key in saved) {
        const savedValue = saved[key];
        const defaultValue = defaultsObj[key];
        if (savedValue && defaultValue && typeof savedValue === "object" && typeof defaultValue === "object") {
          if (JSON.stringify(savedValue) === JSON.stringify(defaultValue)) {
            delete saved[key];
          } else {
            saved[key] = removeRecursively(savedValue, defaultValue);
          }
        } else if (savedValue == defaultValue) {
          delete saved[key];
        }
      } else {
        saved[key] = "undefined";
      }
    });
    return saved;
  }
  const cleaned = removeRecursively(item, defaults);
  let cleanedAction = _.cloneDeep(cleaned);

  if (cleanedAction?.actionsMap) {
    Object.keys(cleanedAction.actionsMap).forEach(key => {
      if (Array.isArray(cleanedAction.actionsMap[key])) {
        cleanedAction.actionsMap[key] = cleanedAction.actionsMap[key].map((action: any) => {
          if (action.actionConfig) {
            action.actionConfig = removeRecursively(action.actionConfig, actionDefaultKeys);
          }
          return action;
        });
      }
    });
  }
  return { ...cleaned, type: item?.type, actionsMap: cleanedAction?.actionsMap || cleaned?.actionsMap };
}

function processFormBuilderComponentRecursively(component) {
  if (component && typeof component === "object") {
    const defaultsForItem = components.find((comp: any) => comp?.type === component?.type);
    const cleanedComponent = removeMatchKeysWithDefaultsObj(component, defaultsForItem);
    Object.assign(component, cleanedComponent);
    if (component?.optionMap && typeof component?.optionMap === "object") {
      Object.keys(component?.optionMap).forEach(key => {
        const defaultsForItem = components.find((comp: any) => comp?.type === component.optionMap[key].type);
        component.optionMap[key] = removeMatchKeysWithDefaultsObj(component?.optionMap[key], defaultsForItem);
        if (Array.isArray(component?.optionMap[key]?.children)) {
          component?.optionMap[key]?.children.forEach(child => processFormBuilderComponentRecursively(child));
        }
      });
    }

    if (Array.isArray(component.children)) {
      component.children.forEach(child => processFormBuilderComponentRecursively(child));
    }
  }
}

function processTemplateConfig(templateConfig) {
  if (templateConfig && Array.isArray(templateConfig.collections)) {
    templateConfig.collections?.forEach(collection => {
      if (Array.isArray(collection?.pages)) {
        collection?.pages?.forEach(page => {
          if (page?.dataSource && Array.isArray(page?.dataSource?.formBuilder)) {
            page?.dataSource?.formBuilder?.forEach(component => processFormBuilderComponentRecursively(component));
          }
          if (page?.views && Array.isArray(page?.views)) {
            page?.views?.forEach(view => {
              view?.dataSource?.formBuilder?.forEach(component => processFormBuilderComponentRecursively(component));
            });
          }
        });
      }
    });
    templateConfig?.layouts?.forEach(layout => {
      if (Array.isArray(layout?.dataSource?.formBuilder)) {
        layout?.dataSource?.formBuilder.forEach(view => processFormBuilderComponentRecursively(view));
      }
    });
  }
}

function traverseApp(obj, currentPath = "") {
  if (obj === null) {
    return;
  }

  if (Array.isArray(obj)) {
    obj.forEach((el, index) => {
      const path = `${currentPath}[${index}]`;
      if (typeof el === "object" || Array.isArray(el)) {
        traverseApp(el, path);
      }
    });
    return;
  }

  if (typeof obj === "object") {
    Object.keys(obj).forEach(key => {
      const path = currentPath ? `${currentPath}.${key}` : key;
      if (key == "isSideMenu" || key == "hasCustom") {
        delete obj[key];
      } else {
        traverseApp(obj[key], path);
      }
    });
    return;
  }
}
