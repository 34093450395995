import _ from "lodash";
import { BXApp } from "src/types/BXAppType";
import { compressData, decompressData } from "src/utils/services";

export function migrateTo1_0_7(_app: BXApp) {
  const app: any = {
    ..._app,
    appConfig: decompressData(_app?.appConfig),
    templateConfig: decompressData(_app?.templateConfig),
    upTemplateConfig: decompressData(_app?.upTemplateConfig),
  };

  traverseApp(app);
  _.set(app, "appVersion", "1.0.7");

  const compressedApp = {
    ...app,
    appConfig: compressData(app?.appConfig),
    templateConfig: compressData(app?.templateConfig),
    upTemplateConfig: compressData(app?.upTemplateConfig),
  };

  return compressedApp;
}

function convertToNewSyntax(data, path) {
  if (typeof data !== "string") {
    return data;
  }

  const regex = /\{([^{}]+)\}/g;
  const placeholdersList = data.match(regex);
  if (placeholdersList === null || placeholdersList.length === 0) {
    return data;
  }
  let modifiedData = data;

  // Be careful with the order of the syntax cases in the switch statement
  placeholdersList.forEach(placeholder => {
    const appPattern = "{$app.";
    const appPropsPattern = "{$app.props";
    const appLoginPattern = "{$app.login";
    if (placeholder.startsWith(appPattern) && !placeholder.startsWith(appPropsPattern) && !placeholder.startsWith(appLoginPattern)) {
      const remainingPath = placeholder.slice(appPattern.length, -1);
      const newPlaceholder = `{$app.layout.${remainingPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }
  });

  return modifiedData;
}

function traverseApp(obj, currentPath = "") {
  if (obj === null) {
    return;
  }

  if (Array.isArray(obj)) {
    obj.forEach((el, index) => {
      const path = `${currentPath}[${index}]`;
      if (typeof el === "object" || Array.isArray(el)) {
        traverseApp(el, path);
      } else {
        obj[index] = convertToNewSyntax(el, path);
      }
    });
    return;
  }

  if (typeof obj === "object") {
    Object.keys(obj).forEach(key => {
      const path = currentPath ? `${currentPath}.${key}` : key;
      if (typeof obj[key] === "object" || Array.isArray(obj[key])) {
        traverseApp(obj[key], path);
      } else {
        obj[key] = convertToNewSyntax(obj[key], path);
      }
    });
    return;
  }
}
