import { Box, SxProps, Tooltip } from "@mui/material";
import { joinObjects } from "hd-utils";
import _ from "lodash";
import { FC, Fragment, memo, useCallback, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { KeysToComponentMap } from "src/BXEngine";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useAppState } from "src/features/appState/hooks";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { selectComponentById } from "src/features/endUser/selectors";
import store from "src/store/store";
import { validationRules } from "src/utils/BXValidate/validationRules";
import { MUI_COMPONENTS } from "src/views/pages/BuildX/FormBuilder";
import { ComponentItemType, StepperComponentState } from "src/views/pages/BuildX/FormBuilder/types";
import { mapValuesRecursively, resolveResponsiveProperties, stepperGroupComponentState } from "src/views/pages/BuildX/FormBuilder/utils";
import { AppController } from "../AppController";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import OnTriggerHandler from "./OnTriggerHandler";
import { RenderActionable } from "./RenderActionable";
import { RenderComponent } from "./RenderComponent";
import RepeatedItemsComponent from "./RepeatedItemsComponent";
import { getControllerName, isParentFlexEndUser } from "./utils";

const RenderChildren: FC<any> = ({ elementId, index, dataEntry, viewName, ...props }) => {
  const {
    id,
    hiddenByStepper: repeatedHiddenByStepper,
    pageId,
    collectionId,
    sharedPageId,
    getValues,
    __data,
    parentIds,
    closeModal,
    info,
    item,
    queryKey,
    dataSource,
    path,
    handleSelectRow,
    pageOutlet,
    disabled,
    parentKey,
    stripe,
    currentRepeatedItem,
    referenceStepperGroups,
    repeatedParentKey,
    isRepeated,
    parentDataIndex,
    repeatedComponent,
    pageIdFromLayout,
  } = props;
  const leftDrawerOpened = useBuildxProviderValue("leftDrawerOpened");
  const currentBreakpoint = useBuildxProviderValue("currentBreakpoint");
  const viewsState = useBuildxProviderValue("viewsState");
  const currentApp = useBuildxProviderValue("currentApp");
  const fqdnApp = useBuildxProviderValue("fqdnApp");

  const multiLingual = useBuildxProviderValue("multiLingual");

  let views = [];

  if (currentApp) {
    views = currentApp?.templateConfig?.collections?.flatMap(col => col.pages).flatMap(page => page.views);
  } else {
    views = fqdnApp?.upTemplateConfig?.collections?.flatMap(col => col.pages).flatMap(page => page.views);
  }

  const metaData = {
    __data,
    item,
    queryKey,
    pageId,
    path,
    viewName: info?.viewName,
  };

  const viewNameOption =
    dataSource?.sourceType !== "NONE"
      ? dataSource?.sourceType === "FROM PARENT"
        ? { viewName: viewName }
        : { viewName: info?.viewName }
      : { viewName: info?.viewName };

  let element = useSelector(state => {
    return {
      ...selectComponentById(state, pageId, info?.viewName, elementId),
      dirty: undefined,
      errors: undefined,
    };
  }, shallowEqual);

  const {
    replaceDataPlaceholdersRecursively,
    replaceDataPlaceholders,
    useReplaceDataPlaceholdersUseSelector,
    useReplaceDataPlaceholdersRecursively,
  } = useReplaceDataPlaceholders(viewNameOption);
  const { handleSubmit, watch, produceTrigger } = useAppState();
  const dispatch = useDispatch();

  const queryKeys = [`${pageId}-${info?.viewName}-${element?.props?.key}`];
  const mapKey = useReplaceDataPlaceholdersUseSelector({
    queryString: element?.props?.isMapValues ? element?.config?.mapKey || "" : "", // {this.textf.state}
    item,
    viewsState,
    pageId,
    __data,
    env: currentApp?.env,
    pageIdFromLayout,
    currentBreakpoint,
    index,
    parentDataIndex,
    fallback: "",
  });
  const updateElementBasedOnMapKey = (element, item, viewsState, pageId, __data, currentAppEnv) => {
    if (element?.props?.isMapValues) {
      const condition = element?.config?.conditionMapKey || "equal";
      let selectedOption;
      if (condition === "equal") {
        selectedOption = element?.optionMap?.[mapKey];
      } else if (condition === "startWith") {
        const mapKeyStr = String(mapKey);
        const matchingKey = Object.keys(element?.optionMap || {}).find(key => mapKeyStr.startsWith(key));
        if (matchingKey) {
          selectedOption = element?.optionMap?.[matchingKey];
        }
      }

      return selectedOption
        ? {
            ...selectedOption,
            optionMap: element?.optionMap || {},
            selectedType: element?.selectedType,
          }
        : {
            ...(element?.optionMap?.default || {}),
            optionMap: element?.optionMap || {},
            selectedType: element?.selectedType,
          };
    } else {
      return _.cloneDeep(element);
    }
  };
  const dataSourceMapKey = useReplaceDataPlaceholdersUseSelector({
    queryString: element?.config?.isMapDataSource ? element?.config?.dataSourceMapKey : "",
    item,
    viewsState,
    pageId,
    __data,
    env: currentApp?.env,
    pageIdFromLayout,
    fallback: "",
  });

  const updateDataSourceBasedOnMapKey = (element, item, viewsState, pageId, __data, currentAppEnv) => {
    if (element?.config?.isMapDataSource) {
      const condition = element?.config?.conditionDataSourceMap || "equal";

      let selectedOption;
      if (condition === "equal") {
        selectedOption = element?.dataSourceMap?.[dataSourceMapKey];
      } else if (condition === "startWith") {
        const dataSourceMapKeyStr = String(dataSourceMapKey);
        const matchingKey = Object.keys(element?.dataSourceMap || {}).find(key => dataSourceMapKeyStr.startsWith(key));
        if (matchingKey) {
          selectedOption = element?.dataSourceMap?.[matchingKey];
        }
      }

      return selectedOption
        ? {
            ...element,
            configData: { ...selectedOption },
          }
        : {
            ...element,
            configData: { ...element?.dataSourceMap?.default },
          };
    } else {
      return element;
    }
  };

  element = updateElementBasedOnMapKey(element, item, viewsState, pageId, __data, currentApp?.env);
  element = updateDataSourceBasedOnMapKey(element, item, viewsState, pageId, __data, currentApp?.env);

  const resolvedElement = _.cloneDeep(element);
  resolveResponsiveProperties(resolvedElement, currentBreakpoint);

  element = resolvedElement;

  const ref = useRef(null);
  const handleLeftDrawerToggle = useCallback(() => {
    dispatch(setProviderState({ leftDrawerOpened: !leftDrawerOpened }));
  }, [leftDrawerOpened]);

  const elementDisabled = useReplaceDataPlaceholdersUseSelector({
    queryString: element?.config?.disable?.disableCondition,
    item,
    pageId,
    __data,
    env: currentApp?.env,
    index,
    fallback: undefined,
    fromParent: dataSource?.sourceType === "FROM PARENT",
    dataEntry,
    pageIdFromLayout,
    canWatch: true,
  });
  const isDisableConditionMet = (disableConfig, item, pageId, __data, currentAppEnv) => {
    try {
      if (!disableConfig?.disableCondition) return false;

      const isConditionMet = eval(elementDisabled);
      return !!isConditionMet;
    } catch (error) {
      return false;
    }
  };
  const elementVisibility = useReplaceDataPlaceholdersUseSelector({
    queryString: element?.config?.visibility?.visibilityCondition,
    item,
    pageId,
    __data,
    env: currentApp?.env,
    index,
    fallback: undefined,
    fromParent: dataSource?.sourceType === "FROM PARENT",
    dataEntry,
    pageIdFromLayout,
    canWatch: true,
  });

  const visibilityState = (visibilityConfig, item, pageId, __data, currentAppEnv) => {
    try {
      if (!visibilityConfig?.visibilityCondition) return "visible";

      const isConditionMet = eval(elementVisibility);
      if (isConditionMet) {
        const visibilityType = visibilityConfig?.selectedVisibilityType || "Hidden";

        switch (visibilityType) {
          case "Hidden":
            return "hidden";
          case "None":
            return "none";
          default:
            return "visible";
        }
      }
    } catch (error) {}
    return "visible";
  };
  const componentVisibility = visibilityState(element?.config?.visibility, item, pageId, __data, currentApp?.env);

  let isComponentDisabled =
    (isDisableConditionMet(element?.config?.disable, item, pageId, __data, currentApp?.env) && !element?.config?.disable?.withOverlay) ||
    element?.props?.disabled ||
    disabled;

  const overlay = element?.config?.disable?.withOverlay &&
    (isDisableConditionMet(element?.config?.disable, item, pageId, __data, currentApp?.env) || element?.props?.disabled) && (
      <Box
        sx={{
          backgroundColor: element?.config?.disable?.overlayColor || "#000",
          opacity: element?.config?.disable?.overlayOpacity || "0.5",
          position: "absolute",
          userSelect: "none",
          zIndex: 5,
          width: "100%",
          height: "100%",
        }}
      />
    );
  const viewRef = `${pageId}-${info?.viewName}`;
  const toggle = viewsState?.visibility?.[viewRef]?.[element?.props?.key]?.toggle;

  const displayRef = viewsState?.visibility?.[viewRef]?.[element?.props?.key]?.display ?? "none";
  let boxStyles: SxProps = {
    position: dataSource?.formBuilderConfig?.isDynamicHeight ? "unset" : "absolute",
    top: element?.top,
    left: element?.leftPercentage,
    width: element?.config?.isDynamicWidth
      ? "auto"
      : element?.config?.fixedWidth
      ? element?.config?.widthPx
      : element?.config?.widthPercentage,
    height: !element?.config?.isPercentageHeight ? element?.config?.heightPx : element?.config?.heightPercentage,
    overflow: element?.config?.config?.overflow || "visible",
    ...element?.config?.sx,
  };

  if (displayRef === "none") {
    (boxStyles as any).display = !toggle ? (element?.config?.hideElement || element?.isModalComponent ? "none" : "block") : "none";
  }

  if (displayRef === "hidden") {
    (boxStyles as any).visibility = !toggle ? (element?.config?.hideElement || element?.isModalComponent ? "hidden" : "visible") : "hidden";
  }

  if (
    [
      ComponentItemType.FlexContainer,
      ComponentItemType.GridContainer,
      ComponentItemType.StepperContainer,
      ComponentItemType.TableContainer,
    ].includes(selectComponentById(store.getState(), pageId, info?.viewName, element?.parentKey)?.type)
  ) {
    boxStyles = joinObjects<SxProps>(
      {
        position: "static",
        width: element?.config?.isDynamicWidth
          ? "auto"
          : element?.config?.fixedWidth
          ? element?.config?.widthPx
          : element?.config?.widthPercentage,
        ...(selectComponentById(store.getState(), pageId, info?.viewName, element?.parentKey)?.type === ComponentItemType.GridContainer && {
          width: "100%",
        }),
      },
      element?.config?.parentStyle
    )!;
  }

  if (isParentFlexEndUser(element, pageId, info?.viewName)) {
    boxStyles = joinObjects<SxProps>(
      {
        position: "static",
        width: element?.config?.isDynamicWidth
          ? "auto"
          : element?.config?.fixedWidth
          ? element?.config?.widthPx
          : element?.config?.widthPercentage,
        height: !element?.config?.isPercentageHeight ? element?.config?.heightPx : element?.config?.heightPercentage,
        ...element?.config?.sx,
        boxSizing: "border-box",
      },
      element?.config?.parentStyle
    )!;
  }

  const replacePropsPlaceholders = useReplaceDataPlaceholdersRecursively({
    obj: _.cloneDeep(element?.props),
    item,
    viewsState,
    pageId,
    pageIdFromLayout,
    formData: getValues,
    __data,
    viewName: viewNameOption,
    env: currentApp?.env,
    index,
    parentDataIndex,
    fallback: "",
    multiLingual: multiLingual,
    keyComp: element?.props?.key,
    dataEntry,
    fromParent: dataSource?.sourceType === "FROM PARENT",
  });

  let resolvedProps = mapValuesRecursively({ ...replacePropsPlaceholders });

  if (resolvedProps?.isChildContainerGroup) {
    resolvedProps = {
      ...resolvedProps,
      required: resolvedProps?.required ? (resolvedProps?.required == "false" ? false : resolvedProps?.required) : resolvedProps?.required,
    };
  }

  const CheckToolTipParent = element?.actionConfig?.label || resolvedProps.toolTip?.value ? Tooltip : Fragment;
  const toolTipParentProps: any =
    element?.actionConfig?.label || resolvedProps?.toolTip ? { title: element?.actionConfig?.label || resolvedProps?.toolTip?.value } : {};

  const isContainer = [
    ComponentItemType.FlexContainer,
    ComponentItemType.CustomContainer,
    ComponentItemType.GridContainer,
    ComponentItemType.StepperContainer,
    ComponentItemType.TableContainer,
  ].includes(element.type);

  const viewKey: string = getControllerName(element, repeatedParentKey, index, pageId, info?.viewName);

  const Component = MUI_COMPONENTS[element?.type];
  const CustomComponent = KeysToComponentMap[element?.type];

  if (!Component && !CustomComponent) return <></>;
  if (componentVisibility === "none") {
    return <></>;
  }

  let hiddenByStepper = _.isNil(repeatedHiddenByStepper) ? !!element?.config?.stepperParent : repeatedHiddenByStepper;
  if (element?.config?.stepperParent?.key && _.isNil(repeatedHiddenByStepper)) {
    const stepperParentKey =
      getControllerName({ props: { key: element?.config?.stepperParent?.key } }, repeatedParentKey, index, pageId, info?.viewName) +
      ".state";

    const parentStepperGroup = watch(stepperParentKey, { pageId, viewName: info?.viewName });

    if (parentStepperGroup) {
      const stepperGroup = replaceDataPlaceholdersRecursively({
        obj: _.cloneDeep(parentStepperGroup),
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        fallback: null,
      });

      const componentStepperState = stepperGroupComponentState(element?.props?.key, stepperGroup);
      hiddenByStepper = componentStepperState === StepperComponentState.FoundButNotSelected;
    }
  }

  if (element?.config?.repeated?.enabled && !repeatedComponent) {
    return (
      <RepeatedItemsComponent
        key={element?.id + "-" + index + "-"}
        elementId={element?.props?.key}
        element={element}
        item={item}
        viewsState={viewsState}
        pageId={pageId}
        __data={__data}
        props={props}
        currentApp={currentApp}
        multiLingual={multiLingual}
        viewName={info?.viewName}
        referenceStepperGroups={referenceStepperGroups}
        index={index}
        pageIdFromLayout={pageIdFromLayout}
      />
    );
  }

  const componentProps = {
    id,
    boxStyles,
    element,
    leftDrawerOpened,
    hiddenByStepper,
    componentVisibility,
    toolTipParentProps,
    overlay,
    resolvedProps,
    Component,
    ref,
    isComponentDisabled,
    metaData,
    pageId,
    collectionId,
    sharedPageId,
    info,
    views,
    pageOutlet,
    handleLeftDrawerToggle,
    produceTrigger,
    index,
    parentKey,
    stripe,
    dataEntry,
    viewName,
    dataSource,
    isRepeated,
    __data,
    parentIds,
    closeModal,
    handleSelectRow,
    path,
    parentDataIndex,
    replaceDataPlaceholders,
    currentApp,
    multiLingual,
    item,
    CheckToolTipParent,
    viewsState,
    getValues,
    viewNameOption,
    repeatedParentKey,
    isContainer,
    pageIdFromLayout,
  };

  const isControlled =
    element?.config?.controlledComponent ||
    element?.type === ComponentItemType.StepperContainer ||
    element?.type === ComponentItemType.PaginationBar;

  return (
    <PermissibleRender action={["VIEW"]} path={[path, element?.props?.key].join(".")}>
      {({ permitted }) =>
        permitted && (
          <RenderActionable
            permitted={permitted}
            element={element}
            collectionId={collectionId}
            sharedPageId={sharedPageId}
            path={path}
            queryKeys={queryKeys}
            pageId={pageId}
            info={info}
            item={item}
            handleSubmit={handleSubmit}
            id={id}
            __data={__data}
            closeModal={closeModal}
            parentIds={parentIds}
            handleSelectRow={handleSelectRow}
            index={index}
            stripe={stripe}
            currentRepeatedItem={currentRepeatedItem}
            dataSource={dataSource}
            dataEntry={dataEntry}
            viewNameOption={viewNameOption}
            views={views}
            getValues={getValues}
            pageIdFromLayout={pageIdFromLayout}
            isComponentDisabled={isComponentDisabled}
            componentVisibility={componentVisibility}
          >
            <>
              <OnTriggerHandler
                element={element}
                elementKey={element?.props?.key}
                pageId={pageId}
                viewName={props?.info?.viewName}
                viewsState={viewsState}
                viewKey={viewKey}
                watch={watch}
                produceTrigger={produceTrigger}
                __data={__data}
                id={id}
                info={info}
                path={path}
                dataSourceMapKey={dataSourceMapKey}
              />
              {!isControlled ? (
                <RenderComponent {...componentProps} isControlled={isControlled} />
              ) : (
                <AppController
                  viewNameOption={viewNameOption}
                  viewName={info?.viewName}
                  element={element}
                  isRepeated={isRepeated}
                  index={index}
                  defaultValue={element?.props?.defaultValue}
                  replaceDataPlaceholdersProps={{
                    item,
                    viewsState,
                    pageId,
                    index,
                    parentDataIndex,
                    __data,
                    env: currentApp?.env,
                    fallback: "",
                    multiLingual: multiLingual,
                    keyComp: element?.props?.key,
                    dataEntry,
                    fromParent: dataSource?.sourceType === "FROM PARENT",
                  }}
                  name={getControllerName({ ...element, props: resolvedProps }, repeatedParentKey, index, pageId, info?.viewName)}
                  enableDirtyCheck={props?.enableDirtyCheck || isContainer}
                  disabled={props?.disablePageDirty}
                  matchValidationField={replaceDataPlaceholders({
                    queryString: element?.props?.matchValue,
                    item,
                    viewsState,
                    pageId,
                    index,
                    __data,
                    env: currentApp?.env,
                    fallback: "",
                    multiLingual: multiLingual,
                    pageIdFromLayout,
                  })}
                  validate={validationRules(
                    {
                      ...element,
                      props: { ...resolvedProps, matchValue: element?.props?.matchValue, textFieldRegex: element?.props?.textFieldRegex },
                    },
                    value => {
                      return replaceDataPlaceholders({
                        queryString: value,
                        item,
                        viewsState,
                        pageId,
                        index,
                        __data,
                        env: currentApp?.env,
                        fallback: "",
                        multiLingual: multiLingual,
                        pageIdFromLayout,
                      });
                    }
                  )}
                  render={({ onChange, value, error }) => (
                    <RenderComponent {...componentProps} onChange={onChange} value={value} error={error} isControlled={isControlled} />
                  )}
                />
              )}
            </>
          </RenderActionable>
        )
      }
    </PermissibleRender>
  );
};

export default memo(RenderChildren, _.isEqual);
