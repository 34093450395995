import _ from "lodash";
import { BXApp } from "src/types/BXAppType";
import { compressData, decompressData } from "src/utils/services";

export function migrateTo1_0_4(_app: BXApp) {
  const app: any = {
    ..._app,
    appConfig: decompressData(_app?.appConfig),
    templateConfig: decompressData(_app?.templateConfig),
    upTemplateConfig: decompressData(_app?.upTemplateConfig),
  };

  migrateBuilderDimensionsV2(app);
  _.set(app, "appVersion", "1.0.4");

  const compressedApp = {
    ...app,
    appConfig: compressData(app?.appConfig),
    templateConfig: compressData(app?.templateConfig),
    upTemplateConfig: compressData(app?.upTemplateConfig),
  };

  return compressedApp;
}

function migrateBuilderDimensionsV2(app) {
  const changeSizeProperties = obj => {
    const populateProperties = element => {
      if (!element || !element.config) return;

      const ensureResponsiveValue = key => {
        // Remove `xs` if it equals `lg`
        if (element.config[key]?.xs && element.config[key]?.xs === element.config[key]?.lg) {
          delete element.config[key].xs;
        }

        // Remove `md` if it equals `lg`
        if (element.config[key]?.md && element.config[key]?.md === element.config[key]?.lg) {
          delete element.config[key].md;
        }
      };

      ensureResponsiveValue("fixedWidth");
      ensureResponsiveValue("isDynamicWidth");
      ensureResponsiveValue("isPercentageHeight");
      ensureResponsiveValue("isDynamicHeight");

      if (Array.isArray(element.children)) {
        element.children.forEach(child => populateProperties(child));
      }

      if (element?.props?.isMapValues && element?.optionMap) {
        Object.values(element.optionMap).forEach(child => populateProperties(child));
      }
    };

    if (Array.isArray(obj)) {
      obj.forEach(item => populateProperties(item));
    } else {
      populateProperties(obj);
    }
  };

  const traverseAndApply = obj => {
    if (!obj) return;

    if (Array.isArray(obj)) {
      obj.forEach(item => traverseAndApply(item));
    } else if (typeof obj === "object") {
      for (const key in obj) {
        if (key === "formBuilder" && (Array.isArray(obj[key]) || typeof obj[key] === "object")) {
          changeSizeProperties(obj[key]);
        } else {
          traverseAndApply(obj[key]);
        }
      }
    }
  };

  traverseAndApply(app);
}
