import { yupResolver } from "@hookform/resolvers/yup";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { IconActivity, IconPlaylistAdd, IconTrashX } from "@tabler/icons-react";
import axios from "axios";
import _, { isArray } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXIconPicker } from "src/components/BXUI/FormControls/IconPicker";
import { BXInputAutocomplete } from "src/components/BXUI/FormControls/InputAutocomplete";
import { RHFIconPicker } from "src/components/BXUI/FormControls/RHFIconPicker";
import BXModal from "src/components/BXUI/Modal";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import store from "src/store/store";
import { BXApp } from "src/types/BXAppType";
import { UIElement } from "src/types/UIElement";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axiosServices from "src/utils/axios";
import { decompressData } from "src/utils/services";
import { DataSource } from "src/views/pages/BuildX/AppBuilder/components/DataSource";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import { ViewProps } from "../../../../../types/BXAppBuilder";
import { MapChainActions } from "../../FormBuilder/MapChainActions";
import { getSharedViews } from "../../FormBuilder/utils";
import OASSelector from "../../OASSelector";
import SelectViewComponent from "../components/SelectViewComponent";
import UploadInfo from "../components/UploadInfo";
import ViewInfo from "../components/ViewInfo";
import DraggableRow from "./DraggableRow";

type CreateViewFormProps = {
  appId?: string;
  collectionId?: string;
  pageId?: string;
  viewId?: string;
  onSave: any;
  onCancel: Function;
  view?: UIElement;
  id?: string | number;
  tables: UIElement[] | null;
  views: UIElement[] | undefined;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  layout?: string;
  app?: BXApp;
  insideFormBuilderEditor?: boolean;
  closeModal?: any;
};

const CreateViewForm: FC<CreateViewFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  view,
  layout,
  id,
  tables,
  views,
  setIsDirty,
  appId,
  collectionId,
  pageId,
  viewId,
  app,
  insideFormBuilderEditor = false,
  closeModal,
}) => {
  const schema = yup
    .object({
      type: yup.string().required("Field is required"),
      info: yup.object().shape({
        name: yup
          .string()
          .test("name", "View should be unique", name => {
            const result = views?.find(_view => _view?.info?.name == name && _view?.id != view?.id);
            return !result;
          })
          .required("Field is required"),
        viewName: yup
          .string()
          .test("name", "View should be unique", name => {
            const result = views?.find(_view => _view?.info?.viewName == name && _view?.id != view?.id);
            return !result;
          })
          .required("Field is required"),
      }),
      uploadConfig: yup.object().shape({
        uploadAllowedTypes: yup
          .array()
          .test("uploadAllowedTypes", "Field is required", values => (view?.type == "upload" ? !!values?.length : true))
          .required("Field is required"),
        signedUrl: yup.string().when("uploadUrlType", {
          is: (uploadUrlType: any) => uploadUrlType == "aws" && view?.type == "upload",
          then: yup.string().required("Field is required"),
        }),
        customUrl: yup.string().when("uploadUrlType", {
          is: (uploadUrlType: any) => uploadUrlType == "custom" && view?.type == "upload",
          then: yup.string().required("Field is required"),
        }),
      }),
      dataSource: yup.object().shape({
        userInputs: yup.array().of(
          yup.object().shape({
            label: yup.string().required("Field is required"),
            inputDataSource: yup.string().required("Field is required"),
          })
        ),
        apiUrl: yup.string().when("sourceType", {
          is: (sourceType: any) => sourceType !== "API" || view?.type == "upload",
          then: yup.string(),
          otherwise: yup.string().required("Field is required"),
        }),
        // payload: yup.object().shape({
        //   uri: yup.string().when("sourceType", {
        //     is: (sourceType: any) => sourceType !== "API" || view?.type == "upload",
        //     then: yup.string(),
        //     otherwise: yup.string().required("Field is required"),
        //   }),
        // }),

        tableUrl: yup.string().when("sourceType", {
          is: (sourceType: any) => sourceType != "TABLE",
          then: yup.string(),
          otherwise: yup.string().required("Field is required"),
        }),
        columnCount: yup.string().when("sourceType", {
          is: (sourceType: any) => view?.type == "form",
          then: yup.string().required("Field is required"),
        }),
      }),
      config: yup.object().shape({
        ...(view?.type != "repeated-view" && {
          columns: yup.array().of(
            yup.object().shape({
              type: yup.string().required("Type is required"),
              name: yup.string().when("type", {
                is: (type: any) => type !== "Actions",
                then: yup.string().required("Field is required"),
              }),
              viewBuilderHeaderId: yup.string().when("headerCellType", {
                is: (headerCellType: string) => headerCellType === "viewBuilder",
                then: yup.string().required("Field is required"),
              }),
              source: yup.string().when("type", {
                is: (type: any) => type !== "View Builder" && type !== "Actions",
                then: yup.string().required("Source is required"),
              }),
              viewBuilderId: yup.string().when("type", {
                is: (type: any) => type == "View Builder",
                then: yup.string().required("Field is required"),
              }),
              dateFormat: yup.string().when("type", {
                is: (type: any) => type == "Date",
                then: yup.string().required("Field is required"),
              }),
            })
          ),
        }),
        ...(view?.type != "form-builder" &&
          {
            // actions: yup.array().of(
            //   yup.object().shape({
            //     label: yup.string().required("Field is required"),
            //     source: yup.string().when("actionSourceType.type", {
            //       is: "API",
            //       then: yup.string().required("Field is required"),
            //     }),
            //     actionViewType: yup.string().when("actionSourceType.type", {
            //       is: (data: any) => data != "API" && layout === "stack" && data != "Download",
            //       then: yup.string().required("Field is required"),
            //     }),
            //     method: yup.string().when("actionSourceType.type", {
            //       is: "API",
            //       then: yup.string().required("Field is required").default("GET"),
            //     }),
            //     isDismissibleView: yup.string().when("actionSourceType.type", {
            //       is: "API",
            //       then: yup.string().required("Field is required"),
            //     }),
            //     actionSourceViewType: yup.object().when("actionSourceType.type", {
            //       is: "Views",
            //       then: yup.object().shape({
            //         id: yup.string().required("Field is required"),
            //         type: yup.string().required("Field is required"),
            //       }),
            //       otherwise: yup.object().nullable(),
            //     }),
            //     BXLogin: yup.object().when("actionSourceType.type", {
            //       is: "BuildX Login",
            //       then: yup
            //         .object()
            //         .shape({
            //           emailField: yup.string().required("Field is required"),
            //           passwordField: yup.string().required("Field is required"),
            //         })
            //         .required("Field is required"),
            //       otherwise: yup.object().shape({
            //         emailField: yup.string(),
            //         passwordField: yup.string(),
            //       }),
            //     }),
            //   })
            // ),
          }),
        ...(view?.type == "repeated-view" && {
          repeatedViews: yup.array().of(
            yup.object().shape({
              viewBuilderId: yup.string().required("Field is required"),
              type: yup.string().required("Field is required"),
            })
          ),
        }),
        ...(view?.type == "repeated-view" && {
          repeatedViewLayout: yup.object().shape({
            xs: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
            sm: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
            md: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
            lg: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
            xl: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
          }),
        }),
      }),
    })
    .required();

  const [isTemplateError, setIsTemplateError] = useState();
  const { search } = useLocation();
  const [tabValue, setTabValue] = useState(1);
  const selectedAppId = useBuildxProviderValue("selectedAppId");
  const allowedApps = useBuildxProviderValue("allowedApps");

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm<FieldValues>({
    defaultValues: {
      ...view,
      dataSource: {
        ...view?.dataSource,
        apiUrl: view?.dataSource?.apiUrl || view?.dataSource?.url,
        tableUrl: view?.dataSource?.tableUrl || view?.dataSource?.url,
        userInputUrl: view?.dataSource?.userInputUrl || view?.dataSource?.url,
        sourceType: view?.dataSource?.sourceType || (view?.type === "form" || view?.type === "form-builder" ? "NONE" : "API"),
        simple: view?.dataSource?.simple || "{\n}",
        columnCount: view?.dataSource?.columnCount || 3,
      },
      config: {
        ...view?.config,
        actions: view?.config?.actions?.map((item: any, index: number) => ({
          ...item,
          showHeader: item?.showHeader ?? true,
          actionIndex: item?.actionIndex || index,
        })),
        repeatedViews: view?.config?.repeatedViews?.length ? view?.config?.repeatedViews : [{ id: "repeated-view", type: "repeated-view" }],
        repeatedViewLayout: {
          ...view?.config?.repeatedViewLayout,
          xs: view?.config?.repeatedViewLayout?.xs || 1,
          sm: view?.config?.repeatedViewLayout?.sm || 1,
          md: view?.config?.repeatedViewLayout?.md || 1,
          lg: view?.config?.repeatedViewLayout?.lg || 1,
          xl: view?.config?.repeatedViewLayout?.xl || 1,
        },
        columns: view?.config?.columns?.map(column => ({
          ...column,
          headerCellType: column?.headerCellType || "string",
          showEllipsisForActions: column?.showEllipsisForActions ?? true,
          numberOfActionsToDisplayEllipsis: column?.numberOfActionsToDisplayEllipsis,
          showIconList: column?.showIconList ?? true,
          showLabelList: column?.showLabelList ?? true,
          minWidth: column?.minWidth,
          maxWidth: column?.maxWidth,
          ellipsisActionIcon: column?.ellipsisActionIcon,
          fieldAlignment: column?.fieldAlignment || "left",
        })),
      },
      info: {
        ...view?.info,
        visibility: view?.info?.visibility || "Visible",
        showApiMode: view?.info?.showApiMode || "Visible",
        emptyStateView: view?.info?.emptyStateView || "None",
        paginationMode:
          view?.info?.paginationMode === "Pagination" || !view?.info?.paginationMode ? "Pagination Bar" : view?.info?.paginationMode,
      },
      uploadConfig: {
        ...view?.uploadConfig,
        uploadAllowedTypes: view?.uploadConfig?.uploadAllowedTypes || [],
        uploadUrlType: view?.uploadConfig?.uploadUrlType || "aws",
        isInput: view?.uploadConfig?.isInput || "No",
        fileNumber: view?.uploadConfig?.fileNumber || 1,
      },
    },
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onSaveData = async (values: any, openBuilder?: boolean) => {
    const data = _.cloneDeep(values);
    if (values.dataSource?.sourceType === "API" || values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.apiUrl;
    } else if (values.dataSource?.sourceType === "TABLE") {
      values.dataSource.url = values.dataSource.tableUrl;
    } else if (values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.userInputUrl;
    }

    if (data?.type === "form" && data?.dataSource?.template && !isTemplateError) {
      const componentNames: any = [];
      const generateForm = (template: any, path = "") => {
        if (_.isObject(template) as any) {
          _.forOwn(template, (value, key) => {
            if (_.isArray(value)) {
              value?.forEach((item, index) => {
                if (_.isObject(item) as any) {
                  generateForm(value, path);
                }
              });
            } else if (_.isObject(value)) {
              generateForm(value, path);
            } else {
              if (value?.[0] === "{" && value?.[value?.length - 1] === "}") {
                const newValue = value.substring(1, value.length - 1);
                const options = newValue.split(",");
                const viewName = options?.[4];
                if (viewName) {
                  componentNames.push(viewName);
                }
              }
            }
          });
        }
      };

      let code = {};
      try {
        code = JSON.parse(data?.dataSource?.template);
      } catch (e) {}

      generateForm(code);
      const templateComponentIds: any = [];
      const names = componentNames?.join(",");
      if (names) {
        const { data } = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/admin/component/list?names=${names}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        data?.items?.forEach((item: any) => {
          templateComponentIds.push(item?.id);
        });
      }
      values.dataSource.templateComponentIds = templateComponentIds;
      return onSave(values);
    }

    if (values.dataSource?.sourceType === "API" || values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.apiUrl;
    } else if (values.dataSource?.sourceType === "TABLE") {
      values.dataSource.url = values.dataSource.tableUrl;
    } else if (values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.userInputUrl;
    }
    onSave(values, openBuilder);
  };

  const handleBuilderClick = () => {
    handleSubmit((values: any) => onSaveData(values, true))();
  };

  const modalTabs = [
    {
      name: "info",
      label: "General",
      condition: view?.type != "upload",
      errorField: "info",
      value: 1,
    },
    {
      name: "repeatedViews",
      label: "View Layout",
      condition: view?.type == "repeated-view",
      errorField: "repeatedViewLayout",
      value: 7,
    },
    {
      name: "dataSource",
      label: "Data source",
      condition: view?.type != "upload",
      errorField: "dataSource",
      value: 2,
    },
    {
      name: "columns",
      label: "Columns",
      condition: view?.type == "data-table",
      errorField: "columns",
      value: 3,
    },
    {
      name: "actions",
      label: "Actions",
      condition: view?.type != "upload" && view?.type != "api" && view?.type !== "form-builder",
      errorField: "actions",
      value: 4,
    },
    {
      name: "reorder",
      label: "Reorder",
      condition: view?.type === "data-table" || view?.type === "repeated-view",
      errorField: "reorder",
      value: 5,
    },
    {
      name: "help",
      label: "Help",
      condition: view?.type != "api",
      errorField: "help",
      value: 6,
    },
  ];

  const currentSelectedApp = useMemo(() => allowedApps?.find(app => app.id === selectedAppId), [selectedAppId, allowedApps]);
  const sharedAppViews = currentSelectedApp?.templateConfig?.collections
    ?.flatMap(collection => collection.pages)
    ?.flatMap(page => page.views)
    .filter(v => v.info?.isShared);

  const _views = views?.concat(...(getSharedViews(currentSelectedApp) || []), ...(sharedAppViews || []));

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const collectionId = urlParams.get("collectionId");
    const pageId = urlParams.get("pageId");
    const viewId = urlParams.get("viewId");
    const tabName = urlParams.get("tab");

    if (collectionId && pageId && viewId && tabName && (views?.length ?? 0) > 0 && !closeModal) {
      const matchedView = views?.find(view => view?.id === viewId);
      if (matchedView) {
        const matchedTab = modalTabs.find(tab => tab.name === tabName && tab.condition);
        if (matchedTab) {
          setTabValue(matchedTab.value);
        }
      }
    }
  }, [views, search]);

  return (
    <Box component='form' noValidate autoComplete='off'>
      {view?.type != "upload" && (
        <Box sx={{ bgcolor: "background.paper" }}>
          <Tabs value={tabValue} onChange={handleChange} textColor='primary' indicatorColor='primary' style={{ textTransform: "none" }}>
            {view?.type != "upload" &&
              modalTabs.map(
                ({ value, label, condition, name, errorField }) =>
                  condition && (
                    <Tab
                      label={
                        <>
                          {(!!errors?.[errorField] ||
                            !!(errors as any)?.config?.[errorField] ||
                            !!(errors as any)?.config?.repeatedViews) && (
                            <Badge color='error' variant='dot' style={{ position: "absolute", top: 10, right: 10 }} />
                          )}
                          <Typography fontWeight={"bold"}>{label}</Typography>
                        </>
                      }
                      sx={{ textTransform: "none" }}
                      value={value}
                    />
                  )
              )}
          </Tabs>
        </Box>
      )}

      <Grid container spacing={3}>
        {tabValue == 1 && (
          <>
            <Grid item xs={12}>
              <ViewInfo
                view={view}
                appId={appId}
                collectionId={collectionId}
                pageId={pageId}
                control={control}
                errors={errors}
                viewType={view?.type}
                watch={watch}
                setValue={setValue}
                app={app}
                getValues={getValues}
                views={_views}
              />
            </Grid>
            {view?.type == "upload" && (
              <Grid item xs={12}>
                <UploadInfo
                  control={control}
                  errors={errors}
                  viewType={view?.type}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
                />
              </Grid>
            )}
          </>
        )}
        {view?.type !== "upload" && tabValue == 2 && (
          <Grid item xs={12}>
            <DataSource
              tables={tables?.filter(item => item?.id != view?.id) as UIElement[]}
              setValue={setValue}
              getValues={getValues}
              value={getValues("dataSource")}
              control={control}
              watch={watch}
              errors={errors}
              views={views}
              view={view}
              viewType={view?.type}
              setIsTemplateError={setIsTemplateError}
              onBuilderClick={handleBuilderClick}
              appId={appId}
              collectionId={collectionId}
              pageId={pageId}
              viewId={viewId}
              insideFormBuilderEditor={insideFormBuilderEditor}
              closeModal={closeModal}
            />
          </Grid>
        )}
        {view?.type != "api" && (
          <Grid item xs={12}>
            <ViewConfigEditor
              views={_views}
              layout={layout}
              watch={watch}
              view={view}
              setValue={setValue}
              getValues={getValues}
              control={control}
              errors={errors}
              appId={appId}
              collectionId={collectionId}
              pageId={pageId}
              setIsDirty={setIsDirty}
              tabValue={tabValue}
              reset={reset}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button
              onClick={
                !isTemplateError
                  ? handleSubmit((values: any) => onSaveData(values))
                  : () => {
                      enqueueSnackbarRef?.(isTemplateError, {
                        variant: "error",
                      });
                    }
              }
              variant={"contained"}
              aria-label={"save"}
            >
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};

export const getHeadersValue = (headers: any) => {
  if (!headers) return "";
};

const dataTypes = [
  "String",
  "Date Time",
  "Boolean",
  "Image",
  "Video",
  "Audio",
  "Link",
  "Number",
  "View Builder",
  // , "Component"
];

const dataTypesAppearance = new Map([
  ["Boolean", ["Switch", "Checkbox"]],
  //Has a details button
]);

const hasConfigure = ["Boolean", "Date Time", "String", "View Builder", "Link", "Video", "Actions", "Number"];
export const actionTypes = [
  { id: -1, type: "API" },
  { id: -2, type: "Download" },
  { id: -3, type: "Export CSV" },
  { id: -4, type: "Copy" },
  { id: -5, type: "Link" },
  { id: -6, type: "Views" },
  { id: -8, type: "Dismiss" },
  { id: -9, type: "BuildX Login" },
  { id: -10, type: "Add" },
  { id: -11, type: "Delete" },
  { id: -12, type: "Update" },
  { id: -13, type: "Refresh Views" },
  { id: -14, type: "Reset Form" },
  { id: -15, type: "Stepper Next" },
  { id: -16, type: "Stepper Previous" },
  { id: -17, type: "Select Stepper" },
  { id: -18, type: "Timer" },
  { id: -19, type: "Show Snackbar" },
  { id: -20, type: "Clean Dirty" },
  { id: -21, type: "Trigger" },
  { id: -22, type: "Visibility Toggle" },
  { id: -23, type: "Start Loading" },
  { id: -24, type: "End Loading" },
  { id: -25, type: "Trigger Data Source" },
  { id: -26, type: "Confirm" },
  { id: -27, type: "Cancel" },
  { id: -28, type: "Run Validation" },
  { id: -29, type: "Check Dirty" },
  { id: -30, type: "Next Page" },
  { id: -31, type: "Previous Page" },
  { id: -32, type: "Clear Validation" },
  { id: -33, type: "Stripe Payment" },
  { id: -34, type: "BuildX Logout" },
  { id: -35, type: "Stop Workflow" },
  { id: -36, type: "Fetch Next Page" },
  { id: -36, type: "Google Sign In" },
  { id: -37, type: "Open Modal Action" },
];

const ViewConfigEditor: FC<ViewProps> = ({
  appId,
  collectionId,
  pageId,
  view,
  control,
  errors,
  layout,
  setValue,
  getValues,
  watch,
  views,
  setIsDirty,
  tabValue,
  reset,
}) => {
  const [actionTabValue, setActionTabValue] = useState("api");
  const [selectedAction, setSelectedAction] = useState<any>(null); //Track the opened action config modal by ID
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const { search } = useLocation();
  const { fields, append, remove, update, move } = useFieldArray({
    control,
    name: "config.columns",
  });
  const dispatch = useDispatch();

  const {
    fields: actions,
    append: appendAction,
    remove: removeAction,
    move: moveAction,
  } = useFieldArray({
    control,
    name: view?.type == "form-builder" ? "dataSource.formBuilder" : "config.actions",
  });

  const {
    fields: repeatedViews,
    append: appendRepeatedView,
    remove: removeRepeatedView,
    move: moveRepeatedView,
  } = useFieldArray({
    control,
    name: "config.repeatedViews",
  });

  const data = views?.filter(item => item?.id != view?.id);
  const actionSourceTypes = [...actionTypes];
  data?.forEach((item: any) => actionSourceTypes.push({ ...item, _invisible: true }));
  const actionViews = [] as any;
  data?.forEach((item: any) => actionViews.push(item));

  const repeatedViewType = [
    {
      id: "repeated-view",
      type: "Custom Grid",
    },
    { id: "normal", type: "Normal" },
  ];

  const openNewWindow = () => {
    const urlToNavigate = `/buildx/app?appId=${appId}&collectionId=${collectionId}&pageId=${pageId}`;
    window.open(urlToNavigate, "_blank");
  };

  const handleAppSelect = (item: any) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(setProviderState({ selectedAppId: item }));
      if (item?.templateConfig == null) {
        axiosServices.get("/application/" + item).then(({ data }) => {
          if (data?.appConfig) {
            data.appConfig = decompressData(data?.appConfig);
          }
          if (data?.templateConfig) {
            data.templateConfig = decompressData(data?.templateConfig);
          }
          const currentApps = store.getState().buildxProvider.appDescriptor;
          const updatedApps = currentApps.map(app => (app.id == data?.id ? data : app));
          dispatch(setProviderState({ appDescriptor: updatedApps }));
          resolve();
        });
      }
    });
  };

  const formBuilderViews = views?.filter(item => item?.type === "form-builder");

  const moveTableColumnElement = (dragIndex: any, hoverIndex: number) => {
    move(dragIndex, hoverIndex);
  };

  const moveTableActionElement = (dragIndex: any, hoverIndex: number) => {
    moveAction(dragIndex, hoverIndex);
  };

  const moveTableViewElement = (dragIndex: any, hoverIndex: number) => {
    moveRepeatedView(dragIndex, hoverIndex);
  };

  let i = 0;
  const getActionRows = (actions: any, _path?: any, currentIndex?: any) => {
    return actions?.map((action: any, index: number) => {
      if (action?.children?.length && view?.type == "form-builder") {
        return getActionRows(action?.children, `${_path ? `${_path}.children[${index}]` : `[${index}]`}`, currentIndex || index);
      }
      if (!action?.config?.isAction && view?.type === "form-builder") return;
      i = i + 1;
      const path = `dataSource.formBuilder${_path ? `${_path}.children[${index}]` : `[${index}]`}`;

      const prefixActionPath = view?.type === "form-builder" ? `${path}.actionConfig` : `config.actions[${index}]`;
      const prefixActionErrorPath =
        view?.type === "form-builder" ? _.get(errors, `${path}.actionConfig`) : errors?.config?.actions?.[index];

      const actionSourceType = watch(`${prefixActionPath}.actionSourceType`)?.type;

      const isView =
        actionSourceType != "API" &&
        actionSourceType != "Download" &&
        actionSourceType != "Export CSV" &&
        actionSourceType != "Copy" &&
        actionSourceType != "Link" &&
        actionSourceType != "Dismiss" &&
        actionSourceType != "BuildX Login" &&
        actionSourceType != "Add" &&
        actionSourceType != "Update" &&
        actionSourceType != "Delete";

      const actionSourceTypeCurrentValue = isView ? -6 : watch(`${prefixActionPath}.actionSourceType`)?.id;

      const ParentComponent: any = view?.type != "form-builder" ? DraggableRow : TableRow;
      const parentComponentProps =
        view?.type != "form-builder"
          ? {
              component: TableRow,
              key: action?.id,
              id: action.id,
              index: index,
              name: "table-columns",
              moveElement: moveTableActionElement,
            }
          : { key: action?.id, id: action.id };

      const refreshViewsList = watch(`${prefixActionPath}.refreshActionView`);
      // * Check the views list type for the refresh to backward compatibility with prev. version
      const _type = typeof refreshViewsList?.[0];

      const autocompleteDefaultValue = (() => {
        if (_type === "string") {
          return refreshViewsList?.map(name => {
            const { id } = (views?.find(view => view?.info?.name === name) as UIElement) || {};
            return {
              id: id,
              name,
            };
          });
        }

        if (!refreshViewsList || refreshViewsList?.length === 0) {
          return [];
        }

        return (
          (isArray(refreshViewsList)
            ? refreshViewsList.map(view => {
                const _view = views?.find(v => v?.id === view.id);
                return {
                  ...view,
                  name: _view?.info?.name,
                };
              })
            : [
                {
                  ...refreshViewsList,
                  name: views?.find(view => view?.id === view.id)?.info?.name,
                },
              ]) || []
        );
      })();

      return (
        <ParentComponent {...parentComponentProps}>
          <TableCell>{i}.</TableCell>
          <TableCell style={{ minWidth: 150 }}>
            <BXInput
              name={
                view?.type === "form-builder"
                  ? action?.props?.children
                    ? `${path}.props.children`
                    : `${path}.props.key`
                  : `${prefixActionPath}.label`
              }
              disabled={view?.type === "form-builder"}
              control={control}
              error={prefixActionErrorPath?.label}
              label={"Label"}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </TableCell>
          <TableCell style={{ minWidth: 250 }}>
            <RHFIconPicker
              name={`${prefixActionPath}.iconConfig.icon`}
              objName={`${prefixActionPath}.iconConfig`}
              control={control}
              error={prefixActionErrorPath?.iconConfig?.icon}
              label={"Icon"}
              showCaption={false}
              setValue={setValue}
            />
          </TableCell>
          <TableCell>
            <Grid container justifyContent='center' alignItems='center' py={1}>
              <Grid item px={1} style={{ width: "100%" }}>
                <Button variant='outlined' onClick={() => setSelectedAction(watch(`${prefixActionPath}.id`))}>
                  Configure
                </Button>
              </Grid>
              <BXModal
                open={selectedAction === watch(`${prefixActionPath}.id`)}
                icon={<SettingsIcon />}
                maxWidth='lg'
                title='Actions Configuration'
                onClose={() => {
                  setSelectedAction(null);
                  setCloseModal(true);
                }}
              >
                <MapChainActions
                  view={view}
                  views={views}
                  setModalOpen={setSelectedAction}
                  onchangeLabel={null}
                  appId={appId}
                  collectionId={collectionId}
                  pageId={pageId}
                  handleConfigChange={(property, value) => {
                    setValue(`${prefixActionPath}.${property}`, value);
                  }}
                  onChangeProp={newMap => {
                    setValue(`${prefixActionPath}.actionsMap`, newMap);
                  }}
                  isTable
                  prefixTableActionPath={watch(prefixActionPath)}
                  actionColumn={actionColumn}
                />
              </BXModal>
            </Grid>
          </TableCell>
          <TableCell>
            <IconButton
              onClick={() => {
                const newData = getValues();
                newData.config.actions = newData.config.actions?.filter((a: any, _index: number) => _index != index);
                reset(newData);
                actionColumn();
              }}
            >
              <IconTrashX />
            </IconButton>
          </TableCell>
        </ParentComponent>
      );
    });
  };

  const actionColumn = () => {
    if (view?.type === "data-table") {
      const configActions = watch("config.actions");
      const configColumns = watch("config.columns");
      const actionInEachRow = watch("config.actions").filter(action => !action.isGlobal);

      if (configActions?.length && actionInEachRow?.length) {
        if (!configColumns.some(col => col.type === "Actions")) {
          append({ id: uuid(), name: "", type: "Actions" });
        }
      } else {
        const actionsColumnIndex = configColumns.findIndex(col => col.type === "Actions");
        if (actionsColumnIndex !== -1) {
          remove(actionsColumnIndex);
        }
      }
    }
  };

  const openView = viewBuilderId => {
    const url = `form-builder/${appId}/${collectionId}/${pageId}/${viewBuilderId}`;
    window.open(url, "_blank");
  };

  const openHeaderView = viewBuilderHeaderId => {
    const url = `form-builder/${appId}/${collectionId}/${pageId}/${viewBuilderHeaderId}`;
    window.open(url, "_blank");
  };

  const handleSetChangeIconProp = (path: string) => (newValue: any) => {
    if (newValue.icon) {
      setValue(`${path}.icon`, newValue.icon);
    }
    if (newValue.url) {
      setValue(`${path}.url`, newValue.url);
    }
    if (newValue.visibility) {
      setValue(`${path}.visibility`, newValue.visibility);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const collectionId = urlParams.get("collectionId");
    const pageId = urlParams.get("pageId");
    const viewId = urlParams.get("viewId");
    const tabName = urlParams.get("tab");
    const rowConfigId = urlParams.get("rowConfig");

    if (collectionId && pageId && viewId && tabName && rowConfigId && (views?.length ?? 0) > 0 && !closeModal) {
      const matchedView = views?.find(view => view?.id === viewId);
      if (matchedView) {
        setSelectedAction(watch(`config.actions[${rowConfigId}].id`));
      }
    }
  }, [views, search]);

  return (
    <Grid container spacing={3}>
      {view?.type === "data-table" && tabValue == 3 && (
        <Grid item style={{ width: "100%" }}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Column Label</TableCell>
                      <TableCell>Cell Type</TableCell>
                      {/* <TableCell>Components</TableCell> */}
                      <TableCell>Data Source</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(fields) && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography textAlign={"center"}>No columns</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {fields?.map((col: any, index: number) => (
                      <DraggableRow
                        component={TableRow}
                        key={col?.id}
                        id={col.id}
                        index={index}
                        name='table-columns'
                        moveElement={moveTableColumnElement}
                      >
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell width={250}>
                          <BXInput
                            label={"Label"}
                            name={`config.columns[${index}].name`}
                            control={control}
                            error={errors?.config?.columns?.[index]?.name}
                            draggable
                            onDragStart={e => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </TableCell>

                        <TableCell width={250}>
                          <FormControl fullWidth>
                            {col.type !== "Actions" && (
                              <BXInput
                                name={`config.columns[${index}].type`}
                                control={control}
                                error={errors?.config?.columns?.[index]?.type}
                                select
                                label={"Type"}
                                id='demo-simple-select'
                                onChange={() => {
                                  setValue(
                                    `config.columns[${index}].dataAppearance`,
                                    hasConfigure.includes(watch(`config.columns[${index}].type`))?.[0]
                                  );
                                }}
                              >
                                {dataTypes?.map(item => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </BXInput>
                            )}
                            {col.type === "Actions" && (
                              <BXInput
                                label={"Type"}
                                name={`config.columns[${index}].type`}
                                control={control}
                                error={errors?.config?.columns?.[index]?.type}
                                draggable
                                disabled
                                onDragStart={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              />
                            )}
                          </FormControl>
                        </TableCell>
                        {col.type !== "Actions" ? (
                          <TableCell width={300}>
                            {watch(`config.columns[${index}].type`) !== "View Builder" && (
                              <BXInputAutocomplete
                                label={"Source"}
                                view={view}
                                views={views}
                                name={`config.columns[${index}].source`}
                                control={control}
                                error={errors?.config?.columns?.[index]?.source}
                                draggable
                                disabled={watch(`config.columns[${index}].type`) === "View Builder"}
                                onDragStart={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              />
                            )}
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        <TableCell>
                          {hasConfigure.includes(watch(`config.columns[${index}].type`)) && (
                            <BXModal
                              label={"Configure"}
                              buttonProps={{
                                variant: "outlined",
                                style:
                                  errors?.config?.columns?.[index]?.dateFormat ||
                                  errors?.config?.columns?.[index]?.viewBuilderId ||
                                  errors?.config?.columns?.[index]?.viewBuilderHeaderId
                                    ? {
                                        borderColor: "red",
                                      }
                                    : {},
                              }}
                              onClose={() => {
                                setActionTabValue("api");
                              }}
                              title={"Configuration"}
                            >
                              {(handleClose: Function) => {
                                const selectedType = watch(`config.columns[${index}].type`);
                                return (
                                  <Grid container xs={12} padding={2} display={"flex"} flexDirection={"column"} gap={1}>
                                    <Grid container xs={12} gap={2}>
                                      {dataTypesAppearance.get(selectedType) && (
                                        <Grid xs={3} item>
                                          <BXInput
                                            name={`config.columns[${index}].dataAppearance`}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.dataAppearance}
                                            select
                                            label={"Data Appearance"}
                                            id='demo-simple-select'
                                            defaultValue={"Switch"}
                                          >
                                            {dataTypesAppearance.get(selectedType)?.map(item => (
                                              <MenuItem key={item} value={item}>
                                                {item}
                                              </MenuItem>
                                            ))}
                                          </BXInput>
                                        </Grid>
                                      )}
                                      <Grid item xs={12}>
                                        <Grid item xs={12} style={{ marginBottom: "15px" }}>
                                          <Typography fontWeight={"bold"}>Column Header</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                                          <BXInput
                                            label={"Header Cell"}
                                            name={`config.columns[${index}].headerCellType`}
                                            control={control}
                                            select
                                            error={errors?.config?.columns?.[index]?.headerCellType}
                                            defaultValue='string'
                                          >
                                            {[
                                              { value: "string", label: "String" },
                                              { value: "viewBuilder", label: "View Builder" },
                                            ].map(option => (
                                              <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </BXInput>
                                        </Grid>
                                        {watch(`config.columns[${index}].headerCellType`) === "string" && (
                                          <>
                                            <Grid item xs={12}>
                                              <BXInput
                                                label={"Label"}
                                                name={`config.columns[${index}].name`}
                                                fullWidth
                                                control={control}
                                                error={errors?.config?.columns?.[index]?.name}
                                                draggable
                                                onDragStart={e => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                }}
                                              />
                                            </Grid>
                                          </>
                                        )}
                                        {watch(`config.columns[${index}].headerCellType`) === "viewBuilder" && (
                                          <>
                                            <SelectViewComponent
                                              fieldName={`config.columns[${index}].viewBuilderHeaderId`}
                                              isRefreshing={isRefreshing}
                                              setIsRefreshing={setIsRefreshing}
                                              handleAppSelect={handleAppSelect}
                                              appId={appId}
                                              openView={openHeaderView}
                                              openNewWindow={openNewWindow}
                                              watch={watch}
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.viewBuilderHeaderId}
                                              formBuilderViews={formBuilderViews}
                                              setValue={setValue}
                                            />
                                            <Grid item xs={4} mt={2}>
                                              <BXInput
                                                name={`config.columns[${index}].customHeight`}
                                                type='number'
                                                control={control}
                                                error={errors?.config?.columns?.[index]?.customHeight}
                                                label={"Custom Height"}
                                              />
                                            </Grid>
                                          </>
                                        )}
                                      </Grid>
                                      {selectedType === "String" && (
                                        <BXSwitch
                                          name={`config.columns[${index}].allowWrap`}
                                          control={control}
                                          error={errors?.config?.columns?.[index]?.allowWrap}
                                          label={"Enable content wrapping"}
                                        />
                                      )}
                                      {selectedType === "Date Time" && (
                                        <>
                                          <Grid xs={3}>
                                            <BXInput
                                              name={`config.columns[${index}].dateFormat`}
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.dateFormat}
                                              placeholder='Ex. YYYY/MM/DD'
                                              label={"Date Format"}
                                            />
                                          </Grid>
                                          <Grid xs={12}>
                                            <BXSwitch
                                              name={`config.columns[${index}].isUserLocalTime`}
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.isUserLocalTime}
                                              label={"User local time"}
                                            />
                                          </Grid>
                                          {/* <Grid xs={12} container style={{ justifyContent: "center", alignContent: "center" }}>
                                            <Button
                                              variant='contained'
                                              onClick={() => {
                                                setValue(
                                                  `errors.config.columns[${index}].dataAppearance`,
                                                  dateValidator(watch(`config.columns[${index}].dataAppearance`))
                                                );
                                              }}
                                            >
                                              Done
                                            </Button>
                                          </Grid> */}
                                        </>
                                      )}

                                      {selectedType === "View Builder" && (
                                        <>
                                          <Grid item xs={12}>
                                            <Typography fontWeight={"bold"}>Column Data</Typography>
                                          </Grid>
                                          <SelectViewComponent
                                            fieldName={`config.columns[${index}].viewBuilderId`}
                                            isRefreshing={isRefreshing}
                                            setIsRefreshing={setIsRefreshing}
                                            handleAppSelect={handleAppSelect}
                                            appId={appId}
                                            openView={openView}
                                            openNewWindow={openNewWindow}
                                            watch={watch}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.viewBuilderId}
                                            formBuilderViews={formBuilderViews}
                                            setValue={setValue}
                                          />
                                        </>
                                      )}
                                      {selectedType === "Link" && (
                                        <>
                                          <Grid item xs={12}>
                                            <BXInput
                                              label={"Open Link As"}
                                              name={`config.columns[${index}].openLinkAs`}
                                              control={control}
                                              select
                                              error={errors?.config?.columns?.[index]?.openLinkAs}
                                              defaultValue='_self'
                                            >
                                              {[
                                                { value: "_blank", label: "New Tab" },
                                                { value: "_self", label: "Same Tab" },
                                              ].map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </BXInput>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <BXInput
                                              label={"URL Link"}
                                              fullWidth
                                              name={`config.columns[${index}].linkUrl`}
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.linkUrl}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                      {selectedType === "Video" && (
                                        <>
                                          <BXSwitch
                                            name={`config.columns[${index}].allowHyperlink`}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.allowHyperlink}
                                            label={"Enable Link"}
                                          />
                                          {watch(`config.columns[${index}].allowHyperlink`) === true && (
                                            <Grid xs={6}>
                                              <RHFIconPicker
                                                name={`config.columns[${index}].videoHyperlinkIcon`}
                                                objName={`config.columns[${index}].iconConfig`}
                                                control={control}
                                                error={errors?.config?.columns?.[index]?.videoHyperlinkIcon}
                                                label={"Link Icon"}
                                                setValue={setValue}
                                              />
                                            </Grid>
                                          )}
                                        </>
                                      )}
                                      {selectedType === "Number" && (
                                        <>
                                          <Grid item xs={12}>
                                            <Typography fontWeight={"bold"}>Number Configuration</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <BXInput
                                              name={`config.columns[${index}].minValue`}
                                              type='number'
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.minValue}
                                              label={"Minimum Value"}
                                              placeholder='Enter minimum value'
                                            />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <BXInput
                                              name={`config.columns[${index}].maxValue`}
                                              type='number'
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.maxValue}
                                              label={"Maximum Value"}
                                              placeholder='Enter maximum value'
                                            />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <BXInput
                                              name={`config.columns[${index}].round`}
                                              type='number'
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.round}
                                              label={"Round to Decimal Places"}
                                              placeholder='Enter decimal places to round'
                                              inputProps={{ min: 0 }}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>

                                    <Grid container gap={1} mt={2}>
                                      <Grid item xs={4}>
                                        <BXInput
                                          name={`config.columns[${index}].customWidth`}
                                          type='number'
                                          control={control}
                                          error={errors?.config?.columns?.[index]?.customWidth}
                                          label={"Custom Width"}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <BXInput
                                          name={`config.columns[${index}].alignColumn`}
                                          control={control}
                                          select
                                          error={errors?.config?.columns?.[index]?.alignColumn}
                                          label={"Align Column"}
                                        >
                                          {["Left", "Right", "Center"]?.map((item: any) => (
                                            <MenuItem key={item} value={item.toLowerCase()}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </BXInput>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <BXInput
                                          name={`config.columns[${index}].verticalAlign`}
                                          control={control}
                                          select
                                          error={errors?.config?.columns?.[index]?.verticalAlign}
                                          label={"Vertical Align"}
                                        >
                                          {["inherit", "top", "bottom", "middle"]?.map((item: any) => (
                                            <MenuItem key={item} value={item.toLowerCase()}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </BXInput>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BXSwitch
                                          name={`config.columns[${index}].allowCopy`}
                                          control={control}
                                          error={errors?.config?.columns?.[index]?.allowCopy}
                                          label={"Enable Copy Value"}
                                        />
                                      </Grid>
                                    </Grid>
                                    {watch(`config.columns[${index}].type`) === "Actions" && (
                                      <Grid item xs={4}>
                                        <BXSwitch
                                          name={`config.columns[${index}].showEllipsisForActions`}
                                          control={control}
                                          error={errors?.config?.columns?.[index]?.showEllipsisForActions}
                                          label={"Show ellipsis for actions"}
                                        />
                                      </Grid>
                                    )}
                                    {watch(`config.columns[${index}].showEllipsisForActions`) && (
                                      <Grid container xs={12} spacing={2}>
                                        <Grid item xs={12}>
                                          <BXIconPicker
                                            key={index}
                                            control={control}
                                            name={`config.columns[${index}].ellipsisActionIcon.icon`}
                                            label='Ellipsis Action Icon'
                                            defaultValue={watch(`config.columns[${index}].ellipsisActionIcon.icon`) || ""}
                                            previewIcon={true}
                                            onChange={value => setValue(`config.columns[${index}].ellipsisActionIcon.icon`, value)}
                                            objName={`config.columns[${index}].ellipsisActionIcon`}
                                            handleSetChangeIconProp={handleSetChangeIconProp}
                                            showCaption={false}
                                            inputLabelProps={{ style: { fontSize: "12px" } }}
                                            inputProps={{ style: { fontSize: "12px" } }}
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <BXInput
                                            name={`config.columns[${index}].numberOfActionsToDisplayEllipsis`}
                                            type='number'
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.numberOfActionsToDisplayEllipsis}
                                            label={"Number of actions to display ellipsis"}
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <BXInput
                                            name={`config.columns[${index}].fieldAlignment`}
                                            control={control}
                                            select
                                            error={errors?.config?.columns?.[index]?.fieldAlignment}
                                            label={"Field Alignment"}
                                          >
                                            {["left", "right", "center"]?.map((item: any) => (
                                              <MenuItem key={item} value={item.toLowerCase()}>
                                                {item}
                                              </MenuItem>
                                            ))}
                                          </BXInput>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <BXInput
                                            name={`config.columns[${index}].minWidth`}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.minWidth}
                                            label={"Min Width (px)"}
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <BXInput
                                            name={`config.columns[${index}].maxWidth`}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.maxWidth}
                                            label={"Max Width (px)"}
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <BXSwitch
                                            name={`config.columns[${index}].showIconList`}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.showIconList}
                                            label={"Show icon list"}
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <BXSwitch
                                            name={`config.columns[${index}].showLabelList`}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.showLabelList}
                                            label={"Show label list"}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                );
                              }}
                            </BXModal>
                          )}
                        </TableCell>
                        {col.type !== "Actions" ? (
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                const newData = getValues();
                                newData.config.columns = newData.config.columns?.filter((a: any, _index: number) => _index != index);
                                reset(newData);
                              }}
                            >
                              <IconTrashX />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </DraggableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <Grid container justifyContent={"center"} alignItems='center' mb={2}>
              <Button
                variant='outlined'
                startIcon={<IconPlaylistAdd />}
                onClick={() => {
                  append({
                    id: uuid(),
                    type: "String",
                    dateFormat: "YYYY/MM/DD",
                    alignColumn: "left",
                    videoHyperlinkIcon: "Video",
                    headerCellType: "string",
                    verticalAlign: "inherit",
                  });
                }}
              >
                Add Column
              </Button>
            </Grid>
          </Card>
        </Grid>
      )}
      {view?.type === "repeated-view" && tabValue == 7 && (
        <Grid item style={{ width: "100%" }}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={"bold"}>Number of Views in Display</Typography>
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.xs"}
                    error={errors?.config?.repeatedViewLayout?.xs}
                    control={control}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Extra Small (xs)"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.sm"}
                    error={errors?.config?.repeatedViewLayout?.sm}
                    control={control}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Small (sm)"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.md"}
                    control={control}
                    error={errors?.config?.repeatedViewLayout?.md}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Medium (md)"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.lg"}
                    error={errors?.config?.repeatedViewLayout?.lg}
                    control={control}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Large (lg)"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.xl"}
                    error={errors?.config?.repeatedViewLayout?.xl}
                    control={control}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Extra Large (xl)"}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Views in Layout</TableCell>
                      <TableCell>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(repeatedViews) && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography textAlign={"center"}>No views</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {repeatedViews?.map((col: any, index: number) => (
                      <DraggableRow
                        component={TableRow}
                        key={col?.id}
                        id={col.id}
                        index={index}
                        name='repeated-view'
                        moveElement={moveTableViewElement}
                      >
                        <TableCell>{index + 1}.</TableCell>

                        <TableCell width={700}>
                          <SelectViewComponent
                            fieldName={`config.repeatedViews[${index}].viewBuilderId`}
                            isRefreshing={isRefreshing}
                            setIsRefreshing={setIsRefreshing}
                            handleAppSelect={handleAppSelect}
                            appId={appId}
                            openView={openView}
                            openNewWindow={openNewWindow}
                            watch={watch}
                            control={control}
                            error={errors?.config?.repeatedViews?.[index]?.viewBuilderId}
                            formBuilderViews={formBuilderViews}
                            setValue={setValue}
                          />
                        </TableCell>

                        <TableCell width={400}>
                          <FormControl fullWidth>
                            <BXInput
                              name={`config.repeatedViews[${index}].type`}
                              control={control}
                              error={errors?.config?.repeatedViews?.[index]?.type}
                              select
                              label={"Type"}
                              disabled={watch(`config.repeatedViews[${index}].type`) == "repeated-view"}
                            >
                              {repeatedViewType?.map((item: any) => {
                                if (watch(`config.repeatedViews[${index}].type`) != "repeated-view" && item.id == "repeated-view") {
                                  return <></>;
                                }
                                return (
                                  <MenuItem key={item?.id} value={item?.id}>
                                    {item?.info?.name || item?.type}
                                  </MenuItem>
                                );
                              })}
                            </BXInput>
                          </FormControl>
                        </TableCell>

                        <TableCell align='right'>
                          {watch(`config.repeatedViews[${index}].type`) != "repeated-view" && (
                            <IconButton
                              onClick={() => {
                                const newData = getValues();
                                newData.config.repeatedViews = newData.config.repeatedViews?.filter(
                                  (a: any, _index: number) => _index != index
                                );
                                reset(newData);
                              }}
                            >
                              <IconTrashX />
                            </IconButton>
                          )}
                        </TableCell>
                      </DraggableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <Grid container justifyContent={"center"} alignItems='center' mb={2}>
              <Button variant='outlined' startIcon={<IconPlaylistAdd />} onClick={() => appendRepeatedView({ id: uuid(), type: "normal" })}>
                Add View
              </Button>
            </Grid>
          </Card>
        </Grid>
      )}

      {view?.type !== "upload" && view?.type !== "form-builder" && tabValue == 4 && (
        <Grid item overflow={"hidden"} width='100%'>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Action name</TableCell>
                      {/* {layout === "stack" && <TableCell>Navigation</TableCell>} */}

                      <TableCell>Icon</TableCell>
                      {/* <TableCell width={300}>URL</TableCell> */}
                      {/* <TableCell>Method</TableCell> */}

                      {/* {view?.type == "card-list" && <TableCell>Highlight value</TableCell>} */}

                      {/* <TableCell>Modal Size</TableCell> */}
                      {/* <TableCell>Dismissible View</TableCell> */}
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(actions) && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography textAlign={"center"}>No action</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {getActionRows(actions)}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            {view?.type != "form-builder" && (
              <Grid container justifyContent={"center"} alignItems='center' mb={2}>
                <Button
                  variant='outlined'
                  startIcon={<IconActivity />}
                  onClick={() => {
                    appendAction({
                      id: uuid(),
                      isGlobal: false,
                      isItem: false,
                      actionIndex: actions.length,
                    });
                    actionColumn();
                  }}
                >
                  Add Action
                </Button>
              </Grid>
            )}
          </Card>
        </Grid>
      )}
      {tabValue === 5 && (
        <Grid item container spacing={2} margin={2}>
          <Grid item xs={12}>
            <BXSwitch label={"Enable"} name={`config.order.enabled`} control={control} error={errors?.config?.order?.enabled} />
          </Grid>

          {watch("config.order.enabled") && (
            <Grid item xs={6}>
              <ApiInput
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                apiLabel='API URL'
                path={`config.order`}
                pathURL={`config.order.endpoint`}
                error={errors?.config?.order}
                errorURL={errors?.config?.order?.endpoint}
                control={control}
                OASElement={
                  <OASSelector
                    swaggerProps={{
                      onSuccess: (values: any, data: any) => {
                        setValue(`config.order.endpoint`, data?.path);
                        setValue(`config.order.uri`, data?.path);
                        setValue(`config.order.method`, (data?.method as string).toUpperCase());
                        setValue(`config.order.body`, formatJSON(JSON.stringify(data?.body || {})));
                        setValue(`config.order.headers`, values.headers);
                      },
                    }}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
      )}

      {tabValue === 6 && (
        <Grid item>
          <ReactMarkdown>
            {`
        Relative options:

          1. {this.data.id} to get the current id from active view
          2. {$.id} to get the id from form view
          3. {{envName}} you can get envName from env object
          5. {#.pagination.index} you can get index from pagination object
          6. {#.dnd.direction} you can get direction from dnd object
          7. {#.dnd.drag} you can get drag from dnd object
          8. {#.dnd.drop} you can get drop from dnd object
          9. {#.data.id} to get the data from previous view
          10. {#.userInputs.id} to get the user input value from previous view
          11. {#.data.items[0].id} to get the data value from previous view with specific value
          12. {#.#.#.data.id} to get the data value from previous views (three levels down )
        
        Absolute options:

          1. {viewX.data.items[0].id} to get the id from data object of specific view
          2. {viewX.data._selectedItem.id} to get the id from selected item in that view
          3. {viewX.userInputs.name} to get the name from user inputs object of that view
          4. {viewX.env.envName} you can get envName from env object`}
          </ReactMarkdown>
        </Grid>
      )}
    </Grid>
  );
};

export { CreateViewForm };
