import { ReactNode, createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { useAppState } from "src/features/appState/hooks";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { removeAppField } from "src/features/endUser/endUserSlice";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { isValidJS, processScriptContent } from "src/utils/generalUtils";

interface IBXPageContext {
  isDirtyPage: boolean;
}

const BXPageContext = createContext<IBXPageContext | null>(null);

export const BXPageProvider = ({
  children,
  pageId,
  page,
  sharedCollection,
}: {
  children: ReactNode;
  pageId: string;
  page: any;
  sharedCollection?: any;
}) => {
  const currentApp = useBuildxProviderValue("currentApp");
  const pageScriptArgs = page?.info?.pageScript?.args || [];
  const viewId = page?.info?.modalView;
  const preventdefaultModalBrowser = page?.info?.preventdefaultModalBrowser;
  const customModalType = page?.info?.customModalType;
  const customMessage = page?.info?.customMessage;
  const sharedViews = sharedCollection?.pages?.[0]?.views || [];
  const { getValue } = useAppState();
  const dispatch = useDispatch();
  const isDirtyPage = getValue(`${pageId}.isDirty`, { pageId }) || false;
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders();
  const [showPrompt, confirmNavigation, cancelNavigation, lastLocation] = useCallbackPrompt(
    isDirtyPage,
    preventdefaultModalBrowser,
    customModalType,
    customMessage
  );

  const mappedPageScriptArgs = pageScriptArgs.map(arg => ({
    scriptArgValue: replaceDataPlaceholders({
      queryString: arg.scriptArgValue,
      pageId,
      env: currentApp?.env,
    }),
  }));

  /**
   * ENG-1188 | Script handling effect
   */
  useEffect(() => {
    if (!page?.info?.pageScript) return;

    const headScripts = page.info.pageScript.head || "";
    const bodyScripts = page.info.pageScript.body || "";
    const headScriptsPlaceholder = processScriptContent(headScripts, "pageArgs", mappedPageScriptArgs, currentApp);
    const bodyScriptsPlaceholder = processScriptContent(bodyScripts, "pageArgs", mappedPageScriptArgs, currentApp);

    // Capture initial DOM state
    const initialHeadElements = [...document.head.children];
    const initialBodyElements = [...document.body.children];

    const addedElements: Element[] = [];
    const addedScripts: Element[] = [];

    // Process head scripts
    const parser = new DOMParser();
    const headDoc = parser.parseFromString(`<html>${headScriptsPlaceholder}</html>`, "text/html");
    headDoc.querySelectorAll("script").forEach(script => {
      const newScript = document.createElement("script");
      Array.from(script.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
      if (script.textContent && isValidJS(script.textContent)) {
        newScript.text = script.textContent;
        document.head.appendChild(newScript);
        addedElements.push(newScript);
      }
    });

    // Process body scripts
    const bodyDoc = parser.parseFromString(`<html>${bodyScriptsPlaceholder}</html>`, "text/html");
    const bodyScriptTags = bodyDoc.querySelectorAll("script");

    bodyScriptTags.forEach((scriptTag: any) => {
      const newScript = document.createElement("script");

      // Copy attributes and content from the original script
      for (const attr of scriptTag.attributes) {
        newScript.setAttribute(attr.name, attr.value);
      }
      if (scriptTag.text) {
        newScript.text = scriptTag.text;
      }

      // Append to body instead of head
      if (isValidJS(scriptTag.text)) {
        document.body.appendChild(newScript);
        addedScripts.push(newScript);
      }
    });

    // Identify dynamically created elements
    const newHeadElements = [...document.head.children].filter(el => !initialHeadElements.includes(el));
    const newBodyElements = [...document.body.children].filter(el => !initialBodyElements.includes(el));

    const dynamicElements = [...newHeadElements, ...newBodyElements].filter(
      el => !addedElements.includes(el) && !addedScripts.includes(el)
    );

    return () => {
      // Cleanup all added elements
      addedElements.forEach(el => el.remove());
      addedScripts.forEach(el => el.remove());
      dynamicElements.forEach(el => el.remove());
    };
  }, [page?.info?.pageScript]);

  useEffect(() => {
    return () => {
      dispatch(removeAppField({ name: pageId, pathVariables: { pageId } }));
    };
  }, [dispatch, pageId]);

  return (
    <>
      {showPrompt && preventdefaultModalBrowser && customModalType && (
        <BXConfirmationDialog
          open={showPrompt}
          title='WARNING'
          customDialogView={viewId}
          isCustom={true}
          pageId={pageId}
          views={sharedViews}
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
        />
      )}
      <BXPageContext.Provider value={{ isDirtyPage }}>{children}</BXPageContext.Provider>
    </>
  );
};
