import { id } from "date-fns/locale";
import _, { isArray } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DataProvider, LayoutProvider, RecyclerListView } from "recyclerlistview/web";
import { useAppState } from "src/features/appState/hooks";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { selectComponentById } from "src/features/endUser/selectors";
import { useFetchData } from "src/hooks/useFetchData";
import store from "src/store/store";
import { StepperComponentState } from "src/views/pages/BuildX/FormBuilder/types";
import { getSelectedPageInfo, stepperGroupComponentState } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import RenderChildren from "./RenderChildren";

function updatePagesForGroup(stepperObject: any, pageReference: string, replicaCount: number, basePath: string) {
  if (replicaCount < 0) {
    return stepperObject;
  }

  const newStepperObject = _.cloneDeep(stepperObject);

  if (!newStepperObject?.pages) return newStepperObject;

  const referencePages = _.cloneDeep(stepperObject.pages);
  newStepperObject.pages = referencePages;

  const mainPageIndex = _.findIndex(newStepperObject.pages, (page: any) => page.pageReference === pageReference);

  if (mainPageIndex !== -1) {
    if (replicaCount === 0) {
      newStepperObject.pages.splice(mainPageIndex, 1);
    } else {
      _.assign(newStepperObject.pages[mainPageIndex], {
        pageReference: `${basePath}[0]`,
        repeatReference: pageReference,
      });

      const replicas = _.times(replicaCount - 1, i =>
        _.assign(_.cloneDeep(newStepperObject.pages[mainPageIndex]), { pageReference: `${basePath}[${i + 1}]` })
      );

      newStepperObject.pages.splice(mainPageIndex + 1, 0, ...replicas);
    }
  }

  return newStepperObject;
}

const createLayoutProvider = getWindowWidth => {
  return new LayoutProvider(
    () => "default",
    (type, dim) => {
      dim.width = getWindowWidth || window.innerWidth || 0;
      dim.height = 100;
    }
  );
};

// const renderFooter = () => {
//   return <div style={{ height: "10px" }} />;
// };

const RepeatedItemsComponent = ({
  element: _element,
  elementId,
  item,
  viewsState,
  pageId,
  __data,
  currentApp,
  multiLingual,
  viewName,
  referenceStepperGroups,
  props,
  index,
  pageIdFromLayout,
}): any => {
  const { handleSelectRow: _handleSelectRow, ...restProps } = props;
  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively, useReplaceDataPlaceholdersUseSelector } = useReplaceDataPlaceholders(
    { viewName: props?.info?.viewName }
  );
  const { watch, setValue, getValue, produceTrigger } = useAppState();
  const [dataProvider, setDataProvider] = useState(
    () =>
      new DataProvider((r1, r2) => {
        return r1 !== r2;
      })
  );
  let element = useSelector(state => selectComponentById(state, pageId, props?.info?.viewName, elementId), shallowEqual);
  let parentComp = useSelector(state => selectComponentById(state, pageId, props?.info?.viewName, element?.parentKey), shallowEqual);
  const isCanvasComponentParent = element?.parentKey === "Canvas-Component-Parent" ? true : false;
  const dispatch = useDispatch();
  const getWindowWidth = Math.round((window.innerWidth * 1000) / 1000 - 25);
  const layoutProvider = useMemo(() => createLayoutProvider(getWindowWidth), [getWindowWidth]);
  const queryKeys = useMemo(() => [`${pageId}-${viewName}-${element?.props?.key}`], [pageId, viewName, element?.props?.key]);
  const minItem = useMemo(() => Number(element.config.repeated.minItems) || 0, [element.config.repeated.minItems]);
  const maxItem = useMemo(() => Number(element.config.repeated.maxItems) || Infinity, [element.config.repeated.maxItems]);
  const defaultNumItems = useMemo(() => Number(element.config.repeated.defaultItems), [element.config.repeated.defaultItems]);

  useFetchData({
    queryKeys,
    __data,
    viewId: id,
    limit: element?.configData?.paginationKey || 20,
    dataEntry: element?.configData?.dataEntry,
    pageId,
    options: {
      enabled: element?.configData?.sourceType == "API" || element?.configData?.sourceType == "LIST",
    },
    dataSource: element?.configData?.sourceType == "API" && element?.configData?.source,
    endpoint: element?.configData?.sourceType == "LIST" && element?.configData?.selectValues,
    viewName: viewName,
    componentKey: element?.props?.key,
    path: props?.path,
  });

  const key = useMemo(() => `${pageId}.${viewName}.${element?.props?.key}`, [pageId, viewName, element?.props?.key]);
  const keyParent = useMemo(() => `${pageId}.${viewName}.${element?.parentKey}`, [pageId, viewName, element?.parentKey]);

  const handleOnReach = async () => {
    await new Promise((resolve, reject) => {
      produceTrigger(
        keyParent,
        "onReach",
        {
          resolver: resolve,
        },
        { pageId, viewName }
      );
    });
  };

  const repeatedState = watch(`${key}.repeatedState`, { pageId, viewName });
  const repeatedStateDeleted = watch(`${key}.repeatedStateDeleted`, { pageId, viewName });
  const repeatedData = useReplaceDataPlaceholdersUseSelector({
    queryString: element?.config?.repeated?.data,
    item,
    viewsState,
    pageId,
    __data,
    viewName: props?.info?.viewName,
    env: currentApp?.env,
    fallback: "",
    multiLingual: multiLingual,
    index,
  });

  const stepperParentKey = `${pageId}.${viewName}.${element?.config?.stepperParent?.key}.state`;
  const stepperGroup = element?.config?.stepperParent?.key ? watch(stepperParentKey, { pageId, viewName }) : null;
  let data =
    Array.isArray(repeatedState) && repeatedState.length > 0 && repeatedState.length + repeatedStateDeleted >= repeatedData?.length
      ? repeatedState
      : Array.from({ length: repeatedData?.length || minItem }, () => ({}));
  let repeatCount = _.clamp(data.length, minItem, maxItem);
  let repeatData = Array.from(
    {
      length: defaultNumItems && !element?.config?.repeated?.data ? _.clamp(defaultNumItems, minItem, maxItem) : repeatCount,
    },
    (_, index) => data[index] || {}
  );

  function handleSelectRow(selectedRowData) {
    const key = `${pageId}-${viewName}`;

    const currentViewsState = store.getState().buildxProvider.viewsState;
    const updatedViewsState = {
      ...currentViewsState,
      [key]: {
        ...currentViewsState?.[key],
        _selectedItem: selectedRowData,
      },
    };

    dispatch(setProviderState({ viewsState: updatedViewsState }));
  }

  useEffect(() => {
    let newItemsRepeated: any = [];
    if (repeatData && isArray(repeatData)) {
      const existingRepeatedState = getValue(`${key}.repeatedState`, { pageId, viewName }) || [];
      const filteredRepeatedState = (existingRepeatedState || []).filter(state => state.isAdded === false).map(state => state.id);
      const lengthNeedToAdd = repeatData?.length - filteredRepeatedState.length;

      newItemsRepeated = Array.from({ length: lengthNeedToAdd }, () => ({
        id: uuid(),
        isAdded: false,
      }));
      const updatedRepeatedState = [...existingRepeatedState, ...newItemsRepeated];
      setValue(`${key}.repeatedState`, updatedRepeatedState, { pageId, viewName });
    }
  }, [repeatedData]);

  useEffect(() => {
    const data = repeatedState?.length ? repeatedState : repeatedData?.length > 0 ? repeatedData : [];
    if (data.length > 0) {
      setDataProvider(prev => prev.cloneWithRows(data));
    } else {
      setDataProvider(prev => prev.cloneWithRows([]));
    }
  }, [repeatedState, repeatedData, stepperGroup?.groupName]);

  useEffect(() => {
    if (stepperGroup) {
      let updatedStepperGroup = replaceDataPlaceholdersRecursively({
        obj: _.cloneDeep(updatePagesForGroup(stepperGroup, element?.props?.key, repeatData?.length, `${element?.props?.key}`)),
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        fallback: null,
      });
      if (updatedStepperGroup) {
        updatedStepperGroup.selected = getSelectedPageInfo(updatedStepperGroup.selectedPage, updatedStepperGroup.pages);
      }
      setValue(stepperParentKey, updatedStepperGroup, { pageId, viewName });
    }
  }, [stepperGroup?.groupName]);

  const rowRenderer = (type, data, itemIndex) => {
    let hiddenByStepper = !!element?.config?.stepperParent?.key;
    if (element?.config?.stepperParent?.key) {
      const componentStepperState = stepperGroupComponentState(`${element.props.key}[${itemIndex}]`, stepperGroup);
      hiddenByStepper = componentStepperState !== StepperComponentState.FoundAndSelected;
    }

    return (
      <RenderChildren
        key={data.id}
        handleSelectRow={handleSelectRow}
        {...restProps}
        elementId={elementId}
        element={element}
        currentRepeatedItem={repeatedState?.[itemIndex]}
        index={itemIndex}
        parentDataIndex={index}
        repeatedParentKey={element?.props?.key}
        hiddenByStepper={hiddenByStepper}
        isRepeated={true}
        repeatedComponent={true}
        pageIdFromLayout={pageIdFromLayout}
      />
    );
  };

  const renderContent = () => {
    if (dataProvider && dataProvider.getSize() > 0) {
      if (element?.config?.repeated?.enabledRecyclerList) {
        return (
          <RecyclerListView
            style={{ width: "100%", height: "100%" }}
            layoutProvider={layoutProvider}
            dataProvider={dataProvider}
            rowRenderer={rowRenderer}
            renderAheadOffset={1000}
            onEndReachedThreshold={300}
            useWindowScroll={isCanvasComponentParent || parentComp?.config?.isDynamicHeight ? true : false}
            onEndReached={handleOnReach}
            forceNonDeterministicRendering={true}
            renderItemContainer={(props: any, parentProps, children) => {
              return (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    width: "100%",
                  }}
                >
                  {children}
                </div>
              );
            }}
          />
        );
      }

      return (
        <>
          {repeatedState?.map?.((item, itemIndex) => {
            let hiddenByStepper = !!element?.config?.stepperParent?.key;
            if (element?.config?.stepperParent?.key) {
              const componentStepperState = stepperGroupComponentState(`${element.props.key}[${itemIndex}]`, stepperGroup);
              hiddenByStepper = componentStepperState !== StepperComponentState.FoundAndSelected;
            }

            return (
              <RenderChildren
                key={item.id}
                handleSelectRow={handleSelectRow}
                {...restProps}
                elementId={elementId}
                element={element}
                currentRepeatedItem={repeatedState?.[itemIndex]}
                index={itemIndex}
                parentDataIndex={index}
                repeatedParentKey={element?.props?.key}
                hiddenByStepper={hiddenByStepper}
                isRepeated={true}
                repeatedComponent={true}
                onEndReachedThreshold={100}
              />
            );
          })}
        </>
      );
    }
  };
  return renderContent();
};

export default memo(RepeatedItemsComponent);
