import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconRefresh } from "@tabler/icons-react";

import { gql } from "@apollo/client";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { useFetchData } from "src/hooks/useFetchData";
import { ElementBaseProps } from "src/types/UIElement";
import { handleCleanupQueries, handleRefetchQueries, returnArrayValue } from "src/utils/generalUtils";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";

export const BXAPi: FC<ElementBaseProps> = ({ id, dataSource, info, selectedViewId, views, pageId, __data = {} }) => {
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const queriesStateGraphQL = useBuildxProviderValue("queriesStateGraphQL");

  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const [currentActionEndpoint, setCurrentActionEndpoint] = useState("");
  const [dataEntry, setDataEntry] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { palette } = useTheme();

  const selectedDataEntry = dataSource?.sourceType == "USER INPUT" ? dataEntry : dataSource?.dataEntry;

  const withInfiniteScroll = !info?.paginationMode || info?.paginationMode == "Infinite Scroll";

  const queryKeys = [
    `${pageId}-${info?.viewName}`,
    // selectedViewId
  ];

  const { data, isFetching, refetchDataSource } = useFetchData({
    queryKeys,
    viewId: id,
    limit: dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    dataEntry: selectedDataEntry,
    withInfiniteQuery: withInfiniteScroll,
    __data,
    endpoint: currentActionEndpoint,
    options: {
      refetchInterval: info?.autoRefresh && (info?.timer || 60) * 1000,
      refetchIntervalInBackground: !!info?.autoRefresh,
    },
    dataSource: dataSource,
    viewName: info?.viewName,
  });
  let gqlQuery;
  const isGraphQL = dataSource?.payload?.isGraphQL;
  if (isGraphQL) {
    gqlQuery = gql`
      ${dataSource?.payload?.graphqlQuery}
    `;
  }
  useEffect(() => {
    return () => {
      if (dataSource?.sourceType == "USER INPUT") {
        handleCleanupQueries({
          isGraphQL,
          gqlQuery,
          queryKeys: [id],
        });
      }
    };
  }, []);

  let entries: any = [];

  // @ts-ignore
  if (!withInfiniteScroll) {
    entries = (
      _.isNil(selectedDataEntry) || !selectedDataEntry ? returnArrayValue(data) : returnArrayValue(_.get(data, selectedDataEntry as any))
    )?.filter?.(Boolean);
  } else {
    entries = (
      _.isNil(selectedDataEntry) || !selectedDataEntry
        ? _.flatten(returnArrayValue(data?.pages))
        : _.flatten(data?.pages?.map((p: any) => returnArrayValue(_.get(p, selectedDataEntry as any))))
    )?.filter?.(Boolean);
  }

  const userInputView = views?.find((view: any) => view?.id === dataSource?.userInputFormBuilderId);

  return (
    <Box paddingX={1}>
      <Card variant={"outlined"}>
        <Grid container paddingX={2} mt={2} mb={2}>
          <Grid container xs={12}>
            <Typography flex={1} fontSize={"16px"} lineHeight={2}>
              {replaceDataPlaceholders({
                queryString: info?.name,
                viewsState,
                pageId,
                __data,
                env: currentApp?.env,
              })}
            </Typography>
            <Tooltip title='Refresh'>
              <Box marginInlineEnd={1} alignSelf='center'>
                <IconButton
                  onClick={() => {
                    setIsRefreshing(true);
                    const key = `${pageId}-${info?.viewName}`;
                    handleRefetchQueries({
                      isGraphQL,
                      queriesStateGraphQL,
                      key,
                      queryKeys,
                      setIsRefreshing,
                    });
                  }}
                  disabled={isFetching}
                  style={{ backgroundColor: palette.primary.light }}
                >
                  {isRefreshing ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>

          <Grid container paddingY={2.5} spacing={"10px"}>
            <ReactJson src={entries as any} theme={"paraiso"} style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
