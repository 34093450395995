import { LoadingButton } from "@mui/lab";
import { BXIcon } from "src/components/BXUI/Icon";

export const CustomButton = props => {
  const {
    iconConfig,
    isLeft,
    iconColor,
    iconSize = "24px",
    componentIconSpacing,
    children,
    hover,
    info,
    pageId,
    appId,
    renderId,
    config,
    __config,
    metaData,
    "data-bx-key": dataBxKey,
    ...restProps
  } = props;

  const customIcon =
    iconConfig?.icon || iconConfig?.url ? (
      <BXIcon
        width={iconSize}
        height={iconSize}
        icon={props?.iconConfig?.icon}
        url={props?.iconConfig?.url}
        visibility={props?.iconConfig?.visibility}
        color={iconColor}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          marginLeft: isLeft ? "0px" : componentIconSpacing,
          marginRight: !isLeft ? "0px" : componentIconSpacing,
        }}
      />
    ) : null;

  const hoverStylesObj = {
    "&:hover": {
      ...hover?.styles,
    },
  };

  return (
    <LoadingButton
      {...restProps}
      sx={{
        ...(hover && hoverStylesObj),
      }}
    >
      {isLeft && customIcon}
      {children}
      {!isLeft && customIcon}
    </LoadingButton>
  );
};
