import _ from "lodash";
import { createContext, FC, MutableRefObject, ReactNode, useCallback, useContext, useRef, useState } from "react";
import { isHostAvailable } from "src/App";
import { useCheckPermissions } from "src/components/PermissionValidation";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { getAuth } from "src/utils/buildxProviderOperations";

type BuildxContextProps = {
  checkPermissions: (requiredPermissions: { path: string; action: string }[], checkAll?: boolean) => boolean;
  appRoutesMap: MutableRefObject<any>;
  unprotectedPages: MutableRefObject<Set<string>>;
  setValue?: Function;
  getValue?: Function;
  checkAppAuth: boolean;
};

export const BuildxContext = createContext<BuildxContextProps>({
  checkPermissions: () => false,
  appRoutesMap: { current: {} },
  unprotectedPages: { current: new Set() },
  setValue: _.noop,
  getValue: _.noop,
  checkAppAuth: false,
});

export const BuildxContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const currentProfilePermissions = useBuildxProviderValue("currentProfilePermissions");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const appTokens = useBuildxProviderValue("appTokens");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const currentApp = useBuildxProviderValue("currentApp");
  const isSwitchingMode = useBuildxProviderValue("isSwitchingMode");
  const appDescriptor = useBuildxProviderValue("appDescriptor");
  const defaultPermissions = useRef({ ALLOW: {}, DENY: {} });
  const unprotectedPages = useRef<Set<string>>(new Set());
  const appRoutesMap = useRef<any>({});
  const { checkPermissions } = useCheckPermissions(currentProfilePermissions || defaultPermissions.current);
  const [data, setData] = useState<any>({});

  const checkAppAuth = !!(
    currentApp?.appConfig?.auth?.authApi?.uri &&
    currentApp?.appConfig?.auth?.type !== "No Auth" &&
    (_.isNil(getAuth(currentApp?.id, currentProfileId, appProfiles, appTokens, currentApp)?.token) ||
      _.isEmpty(getAuth(currentApp?.id, currentProfileId, appProfiles, appTokens, currentApp)?.token) ||
      isSwitchingMode) &&
    (localStorage.getItem("admin-login") ||
      isHostAvailable ||
      (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn) ||
      !currentApp?.appConfig?.isSingleSignOn)
  );

  const setValue = (newData: any, id: string) => setData((oldData: any) => ({ ...oldData, [`${id}`]: newData }));
  const getValue = useCallback(
    (id: string) => {
      if (_.isNil(id)) return null;
      return data?.[id];
    },
    [data]
  );

  return (
    <>
      <BuildxContext.Provider
        value={{
          checkPermissions,
          unprotectedPages,
          appRoutesMap,
          setValue,
          getValue,
          checkAppAuth,
        }}
      >
        {children}
      </BuildxContext.Provider>
    </>
  );
};

export const useBuildxContext = () => useContext(BuildxContext);
