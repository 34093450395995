import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { UserPolicy, UserProfile, UserRole } from "src/types/user";
import * as yup from "yup";
import { CustomListItem } from "../FormBuilder/components/CustomListItem";
import { TablePicker } from "../FormBuilder/components/TablePicker";
import { PolicyStatementSummary } from "../ManagePolicies/PolicyStatementSummary";

type CreateUserFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  editing?: boolean;
  user?: UserProfile;
  height?: string | number;
  withoutPassword?: boolean;
  onlyPassword?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateUserForm: FC<CreateUserFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  user,
  editing = false,
  height,
  withoutPassword = false,
  onlyPassword = false,
  setIsDirty,
}) => {
  const withoutWhitespaceRegex: RegExp = /^\S*$/;
  const schema = yup
    .object({
      name: !onlyPassword ? yup.string().required() : yup.string(),
      handle: !onlyPassword
        ? yup.string().min(2).matches(withoutWhitespaceRegex, "The username should not contain whitespace").required()
        : yup.string(),
      roles: !onlyPassword ? yup.array().of(yup.object()).min(1, "User should have at least 1 Role").required() : yup.string(),
      email: !onlyPassword ? yup.string().email("Invalid email format").required("Mail is required") : yup.string(),
      password: onlyPassword ? yup.string().required() : yup.string(),
      confirmPassword: onlyPassword
        ? yup
            .string()
            .required()
            .oneOf([yup.ref("password"), null], "Passwords must match")
        : yup.string(),
    })
    .required();

  const [roles, setRoles] = useState<UserRole[]>([]);
  const [policies, setPolicies] = useState<UserPolicy[]>([]);

  const defaultValues = onlyPassword
    ? { id: user?.id, password: "" }
    : {
        id: user?.id,
        name: user?.name,
        handle: user?.handle,
        email: user?.email,
        roles: user?.roles,
        policies: user?.policies,
      };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useForm<FieldValues>({
    defaultValues: editing
      ? defaultValues
      : {
          roles: [],
        },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useCallbackPrompt(isDirty);
  useEffect(() => {
    Promise.all([
      axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/role", {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        params: {
          limit: 10000000000000,
        },
      }),
      axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/policy", {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        params: {
          limit: 100000,
        },
      }),
    ])
      .then(([rolesResponse, policiesResponse]) => {
        const rolesData = rolesResponse.data;
        const policiesData = policiesResponse.data.items;

        setRoles(rolesData);
        setPolicies(policiesData);

        if (!user?.roles || user.roles.length === 0) {
          const defaultRole = rolesData.find((role: UserRole) => role.name === "USER");
          if (defaultRole) {
            setValue("roles", [defaultRole]);
          }
        }
      })
      .catch(error => {
        console.error("Error fetching roles or policies:", error);
      });
  }, [isDirty, setIsDirty]);

  const policiesColumns = React.useMemo(() => {
    return [
      {
        name: "Policy",
        source: "name",
        sortable: true,
      },
      {
        name: "Description",
        source: "description",
      },
    ];
  }, []);
  const onPoliciesChange = useCallback(selection => setValue("policies", selection), [setValue]);

  return (
    <>
      <DialogContent>
        <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
          <Grid container spacing={3} height={height}>
            {!onlyPassword ? (
              <>
                <Grid item xs={12}>
                  <BXInput
                    name={"name"}
                    control={control}
                    label='Full name'
                    variant='outlined'
                    error={errors.name}
                    id={"bx-user-create-name-input"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BXInput
                    name={"handle"}
                    control={control}
                    label='Username'
                    variant='outlined'
                    error={errors.handle}
                    id={"bx-user-create-handle-input"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <BXInput
                    name={"email"}
                    control={control}
                    label='Email'
                    variant='outlined'
                    error={errors.email}
                    id={"bx-user-create-form-input"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"roles"}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <Autocomplete
                          componentsProps={{
                            clearIndicator: {
                              sx: {
                                marginRight: "7px",
                              },
                            },
                          }}
                          options={
                            user?.applicationId === "default" || !user ? roles : roles?.filter(r => r.id !== "ADMIN" && r.id !== "BX-ADMIN")
                          }
                          multiple
                          value={value}
                          getOptionLabel={(option: any) => option.name || roles.find(r => r.id === option.id)?.name}
                          filterSelectedOptions
                          renderOption={(props, option: any) => {
                            return (
                              <CustomListItem
                                collapsableContent={() => {
                                  if (!option.description) return null;
                                  return (
                                    <Box>
                                      <Typography color={"grey700"} variant='caption'>
                                        {option.description}
                                      </Typography>
                                      {option.policies?.map(pol => (
                                        <PolicyStatementSummary policy={pol} />
                                      ))}
                                    </Box>
                                  );
                                }}
                                {...props}
                                option={option}
                              />
                            );
                          }}
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id == value.id;
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(errors.roles)}
                              label={"Roles"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                              }}
                            />
                          )}
                        />
                        {errors.roles && <FormHelperText error>{errors.roles?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={12} gap={1} display={"flex"} flexDirection={"column"}>
                  <InputLabel>Policies</InputLabel>
                  <Controller
                    name='policies'
                    control={control}
                    render={({ field }) => {
                      return (
                        <TablePicker
                          renderSubRow={row => (
                            <CodeBlock
                              codeContainerStyle={{
                                fontSize: "12px",
                              }}
                              theme={dracula}
                              text={JSON.stringify(row.statements, null, "\t")}
                              language='javascript'
                              showLineNumbers
                            />
                          )}
                          columns={policiesColumns}
                          rows={policies}
                          defaultSelection={field.value}
                          onSelectionChange={onPoliciesChange}
                        />
                      );
                    }}
                  />
                </Grid>
                {!withoutPassword && (
                  <Grid item xs={12}>
                    <BXInput
                      name={"password"}
                      type={"password"}
                      control={control}
                      label='Password'
                      variant='outlined'
                      error={errors.password}
                      id={"bx-user-create-password-input"}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <BXInput
                    name={"password"}
                    type={"password"}
                    control={control}
                    label='New Password'
                    variant='outlined'
                    error={errors.password}
                    id={"bx-user-create-password-input"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BXInput
                    name={"confirmPassword"}
                    type={"password"}
                    control={control}
                    label='Confirm Password'
                    variant='outlined'
                    error={errors.confirmPassword}
                    id={"bx-user-create-password-input"}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ paddingInlineStart: 24, paddingInlineEnd: 24 }}>
        <Button onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
