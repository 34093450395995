
export interface ClipBoardResult {
  status: ClipboardOperationStatus;
  data?: string;
}

export enum ClipboardOperationStatus {
  COPIED_SUCCESSFULLY,
  READ_SUCCESSFULLY,
  ERROR
}


export const copyToClipboard = async (text: string): Promise<ClipBoardResult> => {
  try {
    await navigator.clipboard.writeText(text);
    return { status: ClipboardOperationStatus.COPIED_SUCCESSFULLY };
  } catch (error) {
    return { status: ClipboardOperationStatus.ERROR, data: JSON.stringify(error) };
  }
};

export const readFromClipboard = async (): Promise<ClipBoardResult> => {
  try {
    const text = await navigator.clipboard.readText();
    return { status: ClipboardOperationStatus.READ_SUCCESSFULLY, data: text };
  } catch (error) {
    return { status: ClipboardOperationStatus.ERROR, data: JSON.stringify(error) };
  }
};
