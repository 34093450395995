import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import { IconTrashX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { Controller, FieldValues, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import axiosServices from "src/utils/axios";
import * as yup from "yup";

type CreateAppRolesFormProps = {
  roleOptions?: any[];
  appId?: string;
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
};

export const CreateAppRolesForm: FC<CreateAppRolesFormProps> = ({ roleOptions, appId, onCancel = _.noop, onSave = _.noop }) => {
  const schema = yup
    .object({
      appRoles: yup
        .array()
        .of(
          yup.object().shape({
            roleName: yup.string().required("Role Name is required"),
            roleValues: yup.array().of(
              yup.object().shape({
                id: yup.string().required("Role ID is required"),
                name: yup.string().required("Role Name is required"),
              })
            ),
          })
        )
        .min(1, "At least one role mapping is required"),
    })
    .required();
  const [defaultRoleMapping, setDefaultRoleMapping] = useState<any>();
  const defaultRoles = defaultRoleMapping
    ? [
        {
          roleName: "defaults",
          roleValues:
            defaultRoleMapping.defaults?.map(role => ({
              id: role.id,
              name: role.name,
            })) || [],
        },
      ]
    : [];
  const roleMappings = defaultRoleMapping?.roleMappings || {};
  const roleArray = Object.entries(roleMappings).map(([roleName, roleValues]) => ({
    roleName,
    roleValues,
  }));
  const initialRoles = [...defaultRoles, ...roleArray];

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      appRoles: initialRoles,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "appRoles",
  });

  const fetchDefaultRoleMappings = async () => {
    if (appId) {
      const mappingResponse = await axiosServices.get(`admin/application/${appId}/default-role-mappings`);
      setDefaultRoleMapping(mappingResponse.data);
    }
  };

  useEffect(() => {
    if (fields?.length === 0 && initialRoles?.length > 0) {
      initialRoles.forEach(role => {
        append(role);
      });
    }
    if (fields?.length === 0 && initialRoles?.length === 0) {
      fetchDefaultRoleMappings();
    }
  }, [appId, fields, initialRoles]);

  if (!defaultRoleMapping || !roleOptions) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='100px'>
        <CircularProgress />
      </Box>
    );
  } else
    return (
      <Box component='form' noValidate autoComplete='off' mt={2}>
        {fields?.map((roleItem, roleIndex) => (
          <Grid container spacing={2} mb={2} key={roleItem.id}>
            <Grid item xs={3}>
              <BXInput
                fullWidth
                size='small'
                label='App Role Name'
                name={`appRoles.${roleIndex}.roleName`}
                control={control}
                error={errors?.appRoles?.[roleIndex]?.roleName}
                disabled={watch(`appRoles.${roleIndex}.roleName`) === "defaults"}
                value={watch(`appRoles.${roleIndex}.roleName`) === "defaults" ? "Default" : watch(`appRoles.${roleIndex}.roleName`)}
              />
            </Grid>
            <Grid item xs={7}>
              <Controller
                name={`appRoles.${roleIndex}.roleValues`}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={roleOptions}
                    size='small'
                    getOptionLabel={option => option.name}
                    onChange={(_, newValue) => {
                      field.onChange(newValue.map(role => ({ id: role.id, name: role.name })));
                    }}
                    value={field.value || []}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Buildx Roles'
                        error={!!errors?.appRoles?.[roleIndex]?.roleValues}
                        helperText={errors?.appRoles?.[roleIndex]?.roleValues?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item justifyContent='center' alignItems='center' xs={2}>
              <IconButton disabled={watch(`appRoles.${roleIndex}.roleName`) === "defaults"} onClick={() => remove(roleIndex)}>
                <IconTrashX color='red' size={18} />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent='center' alignItems='center' spacing={2} mt={2}>
          <Grid item>
            <Button variant='outlined' onClick={() => append({ roleName: "", roleValues: [] })}>
              Add row
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleSubmit(data => {
                onSave(data);
              })}
              variant='contained'
              aria-label={"save"}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
};

export default CreateAppRolesForm;
