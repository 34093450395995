import axios from "axios";

const extAxiosServices = axios.create({
  timeout: 100000,
});

extAxiosServices.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
extAxiosServices.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status == 401 && !window.location.host.endsWith(process.env.REACT_APP_ADMIN_HOST as string)) {
      if (!error.response.config?.params?.skipAuthErrorClear) {
        localStorage.clear();
        window.location.href = window.location.origin;
      }
    }
    return Promise.reject(
      (error?.response && error?.response?.data && { requestStatusCode: error?.request?.status, ...error.response.data }) ||
        "Wrong Services"
    );
  }
);
export default extAxiosServices;
