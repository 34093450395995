import { Grid, InputAdornment } from "@mui/material";
import { FC, useMemo } from "react";
import CreatePayload from "../BXUI/DataTable/CreatePayload";
import { BXInput } from "../BXUI/FormControls";
import { BXIcon } from "../BXUI/Icon";
import BXModal from "../BXUI/Modal";

interface ApiInputProps {
  apiLabel?: string;
  control: any;
  error?: any;
  errorURL?: any;
  methodLabel?: string;
  getValues?: any;
  setValue?: any;
  prefixPath?: any;
  jsonParse?: boolean;
  watch?: any;
  path?: string;
  pathURL?: string;
  viewType?: string;
  OASElement?: any;
  disabled?: boolean;
}

const ApiInput: FC<ApiInputProps> = props => {
  const {
    apiLabel = "Api",
    methodLabel = "Method",
    disabled,
    control,
    error,
    errorURL,
    getValues,
    setValue,
    jsonParse = false,
    watch,
    path,
    pathURL,
    viewType,
    OASElement,
  } = props;

  const payload = useMemo(() => {
    return {
      headers: watch(`${path}.headers`),
      body: watch(`${path}.body`),
      url: watch(`${path}.uri`),
    };
  }, [watch(`${path}.headers`), watch(`${path}.body`), watch(`${path}.uri`)]);

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <BXInput
          variant='outlined'
          required
          label={apiLabel}
          control={control}
          name={`${pathURL}`}
          error={errorURL}
          disabled={disabled}
          onChange={event => {
            setValue(`${path}.uri`, event.currentTarget.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <BXModal
                  disabled={disabled}
                  label={"Payload"}
                  icon={<BXIcon icon={"GitPullRequest"} width={14} height={14} color={"currentColor"} />}
                  buttonProps={{
                    variant: "contained",
                    startIcon: <BXIcon icon={"GitPullRequest"} width={14} height={14} color={"currentColor"} />,
                  }}
                  title={"Payload"}
                  withoutLabel
                >
                  {(handleClose: Function) => {
                    return (
                      <CreatePayload
                        OASElement={OASElement}
                        path={path}
                        pathURL={pathURL}
                        viewType={viewType}
                        setValue={setValue}
                        getValues={getValues}
                        control={control}
                        error={error}
                        watch={watch}
                        payload={payload}
                        onSave={(payload: any) => {
                          setValue(`${path}.headers`, payload.headers);
                          setValue(`${path}.body`, jsonParse ? JSON.parse(payload.body || null) : payload.body);
                          handleClose();
                        }}
                        defaultTab={"JSON"}
                        label={"Done"}
                        onClose={() => handleClose()}
                      />
                    );
                  }}
                </BXModal>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export { ApiInput };
