import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, FormControlLabel, Grid, IconButton, MenuItem, Switch, TextField } from "@mui/material";
import { IconEdit, IconTrashX } from "@tabler/icons-react";
import React, { useState } from "react";
import CustomMonacoEditor from "src/components/BXUI/CustomMonacoEditor/CustomMonacoEditor";

import BXModal from "src/components/BXUI/Modal";
import store from "src/store/store";

const AddVarModal = ({ onSave, onCancel, initialType = "string", initialValue = "" }) => {
  const [selectedType, setSelectedType] = useState<any>(initialType);
  const [updatedValue, setUpdatedValue] = useState<any>(() => {
    if (initialType === "object" && typeof initialValue === "object") {
      return JSON.stringify(initialValue, null, 2);
    }
    return initialValue;
  });
  const handleTypeChange = e => {
    const type = e.target.value;
    setSelectedType(type);

    if (type === "undefined") {
      setUpdatedValue(undefined);
    } else if (type === "null") {
      setUpdatedValue(null);
    } else if (type === "boolean") {
      if (updatedValue !== true && updatedValue !== false) {
        setUpdatedValue(false);
      }
    } else if (type === "string") {
      setUpdatedValue("");
    } else if (type === "object") {
      setUpdatedValue("{}");
    }
  };

  const handleValueChange = value => {
    setUpdatedValue(value);
  };

  const handleSubmit = () => {
    let finalValue;
    switch (selectedType) {
      case "undefined":
        finalValue = undefined;
        break;
      case "null":
        finalValue = null;
        break;
      case "boolean":
        finalValue = updatedValue;
        break;
      case "string":
        finalValue = updatedValue;
        break;
      case "object":
        try {
          finalValue = JSON.parse(updatedValue);
        } catch (error) {
          alert("Invalid JSON object.");
          return;
        }
        break;
      default:
        finalValue = updatedValue;
    }

    onSave({ type: selectedType, value: finalValue });
  };

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={6}>
        <TextField
          select
          label='Type'
          value={selectedType || ""}
          onChange={handleTypeChange}
          fullWidth
          variant='outlined'
          defaultValue={"string"}
        >
          {["string", "boolean", "undefined", "null", "object"].map(item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {selectedType === "string" && (
        <Grid item xs={12}>
          <TextField
            label='Default Value'
            value={updatedValue || ""}
            onChange={e => handleValueChange(e.target.value)}
            fullWidth
            variant='outlined'
          />
        </Grid>
      )}

      {selectedType === "boolean" && (
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={Boolean(updatedValue)} onChange={e => handleValueChange(e.target.checked)} color='primary' />}
            label='Default Value'
          />
        </Grid>
      )}

      {selectedType === "object" && (
        <Grid item xs={12}>
          <CustomMonacoEditor
            width='100%'
            height='200px'
            language='json'
            monacoEditorTheme='vs-dark'
            value={updatedValue}
            monacoEditorOptions={{ selectOnLineNumbers: true }}
            onChange={newValue => handleValueChange(newValue)}
          />
        </Grid>
      )}

      <Grid item xs={12} container justifyContent='center' spacing={2}>
        <Grid item>
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Vars: React.FC<any> = ({ initialFields = [{ id: Date.now(), varKey: "" }], handleVarsChange }) => {
  const [vars, setVars] = useState<any[]>(store.getState().builder.varsConfig?.vars || initialFields);
  const [isOpen, setIsOpen] = useState(false);
  const [editingVar, setEditingVar] = useState<any>(null);

  const addField = () => {
    const newField = { id: Date.now(), varKey: "", type: "string", value: "" };
    setVars(prevFields => {
      const updatedVars = [...prevFields, newField];
      handleVarsChange(updatedVars);
      return updatedVars;
    });
  };
  const removeField = (id: number) => {
    setVars(prevFields => {
      const updatedVars = prevFields.filter(field => field.id !== id);
      handleVarsChange(updatedVars);
      return updatedVars;
    });
  };

  const handleFieldChange = (id: number, newValue: string) => {
    setVars(prevFields => {
      const updatedVars = prevFields.map(field => (field.id === id ? { ...field, varKey: newValue } : field));
      handleVarsChange(updatedVars);
      return updatedVars;
    });
  };

  const openModalForVar = varItem => {
    setEditingVar(varItem);
    setIsOpen(true);
  };

  const handleSave = ({ type, value }) => {
    if (!editingVar) return;

    setVars(prevFields => {
      const updatedVars = prevFields.map(field => (field.id === editingVar.id ? { ...field, type, value } : field));
      handleVarsChange(updatedVars);
      return updatedVars;
    });

    setIsOpen(false);
  };

  return (
    <Box sx={{ p: 1 }}>
      {vars.map((field, index) => (
        <Box key={field.id} display='flex' alignItems='center' mb={2}>
          <TextField
            label={`Key ${index + 1}`}
            variant='outlined'
            size='small'
            fullWidth
            value={field.varKey}
            onChange={e => handleFieldChange(field.id, e.target.value)}
          />
          <IconButton aria-label='delete' color='default' onClick={() => openModalForVar(field)} sx={{ ml: 1 }}>
            <IconEdit color='white' />
          </IconButton>
          <IconButton aria-label='delete' color='error' onClick={() => removeField(field.id)} sx={{ ml: 1 }}>
            <IconTrashX color='red' />
          </IconButton>
        </Box>
      ))}
      <Button variant='contained' onClick={addField}>
        Add Var
      </Button>
      <BXModal open={isOpen} icon={<SettingsIcon />} maxWidth='md' title={`var : ${editingVar?.varKey}`} onClose={() => setIsOpen(false)}>
        {editingVar && (
          <AddVarModal
            onSave={handleSave}
            onCancel={() => setIsOpen(false)}
            initialType={editingVar?.type}
            initialValue={editingVar?.value}
          />
        )}
      </BXModal>
    </Box>
  );
};

export default Vars;
