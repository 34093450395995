import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import useAuth from "src/hooks/useAuth";
import { isValidJS, processScriptContent } from "./generalUtils";

const RenderScripts = () => {
  const currentApp = useBuildxProviderValue("currentApp");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders();
  const location = useLocation();

  const app = currentApp || fqdnApp;

  // Extract script configurations
  const scriptConfig = app?.appConfig?.script;
  const authorizedScriptConfig = app?.appConfig?.authorizedScript;
  const unauthorizedScriptConfig = app?.appConfig?.unauthorizedScript;

  // Stringify script configurations for stable dependency comparison
  const scriptConfigString = JSON.stringify(scriptConfig);
  const authorizedScriptConfigString = JSON.stringify(authorizedScriptConfig);
  const unauthorizedScriptConfigString = JSON.stringify(unauthorizedScriptConfig);

  const { isLoggedIn } = useAuth();

  const executeScripts = () => {
    if (!scriptConfig && !authorizedScriptConfig && !unauthorizedScriptConfig) return;
    if (isAdministrationMode) return;

    const globalScriptArgs = scriptConfig?.args || [];
    const authorizedScriptArgs = authorizedScriptConfig?.args || [];
    const unauthorizedScriptArgs = unauthorizedScriptConfig?.args || [];

    // Map script arguments with placeholders replaced
    const mappedGlobalScriptArgs = globalScriptArgs.map(arg => ({
      scriptArgValue: replaceDataPlaceholders({
        queryString: arg.scriptArgValue,
        env: currentApp?.env,
      }),
    }));

    const mappedAuthorizedScriptArgs = authorizedScriptArgs.map(arg => ({
      scriptArgValue: replaceDataPlaceholders({
        queryString: arg.scriptArgValue,
        env: currentApp?.env,
      }),
    }));

    const mappedUnauthorizedScriptArgs = unauthorizedScriptArgs.map(arg => ({
      scriptArgValue: replaceDataPlaceholders({
        queryString: arg.scriptArgValue,
        env: currentApp?.env,
      }),
    }));

    // Process script content with placeholders replaced
    const globalHead = processScriptContent(scriptConfig?.head || "", "globalArgs", mappedGlobalScriptArgs, currentApp);
    const globalBody = processScriptContent(scriptConfig?.body || "", "globalArgs", mappedGlobalScriptArgs, currentApp);

    // Authorized scripts
    const authorizedHead = isLoggedIn
      ? processScriptContent(authorizedScriptConfig?.head || "", "protectedArgs", mappedAuthorizedScriptArgs, currentApp)
      : "";
    const authorizedBody = isLoggedIn
      ? processScriptContent(authorizedScriptConfig?.body || "", "protectedArgs", mappedAuthorizedScriptArgs, currentApp)
      : "";

    // Unauthorized scripts
    const unauthorizedHead = !isLoggedIn
      ? processScriptContent(unauthorizedScriptConfig?.head || "", "unprotectedArgs", mappedUnauthorizedScriptArgs, currentApp)
      : "";
    const unauthorizedBody = !isLoggedIn
      ? processScriptContent(unauthorizedScriptConfig?.body || "", "unprotectedArgs", mappedUnauthorizedScriptArgs, currentApp)
      : "";

    // Combine all processed scripts
    const combinedHeadScripts = `${globalHead}${authorizedHead}${unauthorizedHead}`;
    const combinedBodyScripts = `${globalBody}${authorizedBody}${unauthorizedBody}`;

    // Capture initial state of the DOM
    const initialHeadElements = [...document.head.children];
    const initialBodyElements = [...document.body.children];

    const addedScripts: HTMLScriptElement[] = [];
    const addedNoscripts: HTMLElement[] = [];

    // Process head scripts
    const parser = new DOMParser();
    const headDoc = parser.parseFromString(combinedHeadScripts, "text/html");
    const scriptTags = headDoc.querySelectorAll("script");

    // Append <script> tags to head
    scriptTags.forEach((scriptTag: any) => {
      const newScript = document.createElement("script");

      // Copy attributes and content from the original script
      for (const attr of scriptTag.attributes) {
        newScript.setAttribute(attr.name, attr.value);
      }
      if (scriptTag.text) {
        newScript.text = scriptTag.text;
      }

      // Append to head
      if (isValidJS(scriptTag.text)) {
        document.head.appendChild(newScript);
        addedScripts.push(newScript);
      }
    });

    // Process body scripts
    const bodyDoc = parser.parseFromString(combinedBodyScripts, "text/html");
    const bodyScriptTags = bodyDoc.querySelectorAll("script");

    bodyScriptTags.forEach((scriptTag: any) => {
      const newScript = document.createElement("script");

      // Copy attributes and content from the original script
      for (const attr of scriptTag.attributes) {
        newScript.setAttribute(attr.name, attr.value);
      }
      if (scriptTag.text) {
        newScript.text = scriptTag.text;
      }

      // Append to body instead of head
      if (isValidJS(scriptTag.text)) {
        document.body.appendChild(newScript);
        addedScripts.push(newScript);
      }
    });

    // Use Regex to extract multiple <noscript> tags from the body string
    const noscriptRegex = /<noscript>([\s\S]*?)<\/noscript>/gi;
    let match;
    const noscriptContents: any = [];
    while ((match = noscriptRegex.exec(combinedBodyScripts)) !== null) {
      noscriptContents.push(match[1]);
    }

    // Append <noscript> tags to body
    noscriptContents.forEach(content => {
      const newNoscript = document.createElement("noscript");
      newNoscript.innerHTML = content;

      // Append to body
      document.body.appendChild(newNoscript);
      addedNoscripts.push(newNoscript);
    });

    // Return cleanup function
    return () => {
      addedScripts.forEach(script => script.remove());
      addedNoscripts.forEach(noscript => noscript.remove());
    };
  };

  useEffect(() => {
    const cleanup = executeScripts();
    return () => {
      cleanup?.();
    };
  }, [scriptConfigString, authorizedScriptConfigString, unauthorizedScriptConfigString, isLoggedIn, location.pathname]);

  return null;
};

export default RenderScripts;
