import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconCopy, IconEditCircle } from "@tabler/icons-react";
import _, { isNil } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { RHFIconPicker } from "src/components/BXUI/FormControls/RHFIconPicker";
import { BXIcon } from "src/components/BXUI/Icon";
import BXModal from "src/components/BXUI/Modal";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { BXApp } from "src/types/BXAppType";
import { BXPageType, PageLayout } from "src/types/BXPageType";
import { UIElement } from "src/types/UIElement";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { v4 } from "uuid";
import * as yup from "yup";
import ItemsPropertiesAccordion from "../../FormBuilder/components/ComponentEditor/EditorComponents/ItemsPropertiesAccordion";
import { handleSlugChange } from "../../FormBuilder/utils";
import { visibilityData } from "../components/ViewInfo";
import { ScriptArgsModal } from "./ScriptArgsModal";

type AccordionState = {
  pageScript: boolean;
  modalConfig: boolean;
};

type CreatePageFormProps = {
  onSave: (data: any, closeOnSuccess?: boolean) => Promise<any>;
  onCancel: Function;
  page?: BXPageType;
  app?: BXApp;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  layouts?: any[];
  params?: {
    appId?: string;
    collectionId?: string;
    isShared?: boolean;
  };
};

const schema = yup
  .object({
    title: yup.string().required(),
    slug: yup.string().required(),
    name: yup.string().required(),
    icon: yup.string(),
    inProfileMenu: yup.boolean(),
    layout: yup.string().required(),
    hidePageTitle: yup.boolean(),
    unprotectedPage: yup.boolean(),
    shouldRedirectAuthenticatedUser: yup.boolean(),
    info: yup.object({
      preventdefaultModalBrowser: yup.boolean(),
      customMessage: yup.string().when(["preventdefaultModalBrowser", "customModalType"], {
        is: (preventdefaultModalBrowser, customModalType) => preventdefaultModalBrowser === true && customModalType === false,
        then: yup.string().required("Custom Message is required"),
        otherwise: yup.string().notRequired(),
      }),
      modalView: yup.string().when(["preventdefaultModalBrowser", "customModalType"], {
        is: (preventdefaultModalBrowser, customModalType) => preventdefaultModalBrowser === true && customModalType === true,
        then: yup.string().required("Modal View is required"),
        otherwise: yup.string().notRequired(),
      }),
    }),
  })
  .required();

const layoutOptions: { value: PageLayout; label: string; icon: string }[] = [
  { value: "default", label: "Default", icon: "BrandPagekit" },
  { value: "stack", label: "Single", icon: "Stack2" },
  { value: "switch", label: "Switch", icon: "Stack" },
  { value: "custom", label: "Custom", icon: "Layout" },
];

const modalTabs = [
  {
    name: "general",
    label: "General",
    value: 1,
  },
  {
    name: "config",
    label: "Configuration",
    value: 2,
  },
];

export const CreatePageForm: FC<CreatePageFormProps> = ({ onCancel = _.noop, params, onSave = _.noop, page, app, layouts, setIsDirty }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    watch,
    getValues,
  } = useForm<FieldValues>({
    defaultValues: {
      ...page,
      layout: page?.layout || "default",
      hidePageTitle: page?.hidePageTitle !== undefined ? page.hidePageTitle : false,
      info: {
        ...page?.info,
        visibility: page?.info?.visibility || "Visible",
        preventdefaultModalBrowser: page?.info?.preventdefaultModalBrowser ?? false,
        customModalType: page?.info?.customModalType ?? false,
      },
      unprotectedPage: page?.unprotectedPage ?? false,
      shouldRedirectAuthenticatedUser: page?.shouldRedirectAuthenticatedUser ?? false,
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const [pageScriptArgsModalOpen, setPageScriptArgsModalOpen] = useState(false);
  const [accordionState, setAccordionState] = useState({
    pageScript: false,
    modalConfig: false,
  });
  const handleAccordionChange = (accordionKey: keyof AccordionState) => {
    setAccordionState(prevState => ({
      ...prevState,
      [accordionKey]: !prevState[accordionKey],
    }));
  };

  const sharedViews =
    app?.templateConfig?.collections
      ?.find(collection => collection.name === "Shared Collection")
      ?.pages?.find(page => page.title === "Shared Views")?.views || [];

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const [layoutValue, setLayoutValue] = useState<PageLayout | null>(getValues("layout") || null);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(1);
  const prevPageNameRef = useRef(watch("name") || "");
  const handleCopySlug = value => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        enqueueSnackbarRef("Copied!", { variant: "success" });
      })
      .catch(err => {
        enqueueSnackbarRef("Failed to copy!", { variant: "error" });
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        textColor='primary'
        indicatorColor='primary'
        style={{ textTransform: "none", marginBottom: "16px" }}
      >
        {modalTabs.map(({ value, label, name }) => (
          <Tab label={<Typography fontWeight={"bold"}>{label}</Typography>} sx={{ textTransform: "none" }} value={value} />
        ))}
      </Tabs>

      {tabValue == 1 && (
        <Container maxWidth={"md"}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BXInput
                name={"title"}
                control={control}
                label='Page Title'
                variant='outlined'
                id={"bx-page-create-title-input"}
                error={errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <BXInput
                name={"name"}
                control={control}
                label='Name'
                variant='outlined'
                id={"bx-page-create-name-input"}
                onChange={e => handleSlugChange(e.target.value, prevPageNameRef, getValues, setValue)}
                error={errors.name}
              />
            </Grid>
            {!params?.isShared && (
              <Grid item xs={12}>
                <BXInput
                  name={"slug"}
                  helperText={"/app/collection/page"}
                  control={control}
                  label='Slug'
                  variant='outlined'
                  id={"bx-view-create-slug-input"}
                  error={errors.slug}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Tooltip title='Copy to clipboard'>
                          <IconButton aria-label='copy slug' onClick={() => handleCopySlug(getValues("slug"))} edge='end' sx={{ mr: 0.3 }}>
                            <IconCopy color='white' size={18} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {!!layouts?.length && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"layoutId"}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disablePortal
                      id='app-layouts'
                      fullWidth
                      defaultValue={layouts?.find(layout => layout?.id == value)}
                      options={layouts}
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue?.id);
                      }}
                      getOptionLabel={item => item?.name}
                      renderInput={params => <TextField {...params} label='Layout' fullWidth variant='outlined' />}
                      componentsProps={{
                        clearIndicator: {
                          sx: {
                            marginRight: "7px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <BXInput control={control} name={"info.visibility"} select fullWidth label={"Visibility"}>
                {visibilityData?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </BXInput>
            </Grid>
            <Grid item xs={12}>
              <RHFIconPicker
                label={"Icon"}
                control={control}
                name={"iconConfig.icon"}
                objName={`iconConfig`}
                id={"bx-page-create-icon-input"}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={6}>
              <BXSwitch control={control} name={"hidePageTitle"} label={"Hide Page Title"} />
            </Grid>
            {!params?.isShared && (
              <Grid item xs={6}>
                <BXSwitch control={control} name={"unprotectedPage"} label={"Unprotected Page"} />
              </Grid>
            )}
            {!params?.isShared && watch(`unprotectedPage`) && (
              <Grid item xs={12}>
                <BXSwitch
                  control={control}
                  name={"shouldRedirectAuthenticatedUser"}
                  label={"Redirect authenticated user to logged in landing page"}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel style={{ marginBottom: 12, marginTop: 12 }}>Layout</FormLabel>
              <Box display={"flex"} justifyContent={"left"}>
                <ToggleButtonGroup
                  value={layoutValue || getValues("layout")}
                  exclusive
                  onChange={(e, value) => {
                    setLayoutValue(value);
                    setValue("layout", value);
                    if (value === "custom") {
                      setValue("pageLayoutContainer", {
                        id: v4(),
                        type: "FlexContainer",
                        children: page?.views.map(view => {
                          const id = v4();
                          // @ts-ignore
                          return { type: "viewRef", $refId: view.id, id, props: { id, key: id } } as UIElement;
                        }),
                        dataSource: {
                          sourceType: "NONE",
                          columnCount: "3",
                          userInputs: [],
                          simple: "{\n}",
                        },
                      } as UIElement);
                    }
                  }}
                  aria-label='text alignment'
                >
                  {layoutOptions.map(option => (
                    <ToggleButton
                      key={option.value}
                      value={option.value}
                      aria-label='left aligned'
                      style={{ display: "flex", flexDirection: "column", padding: 12 }}
                    >
                      <BXIcon icon={option.icon} />
                      {option.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>

                {layoutValue === "custom" && !isNil(page?.id) ? (
                  <>
                    <Button
                      onClick={() => {
                        handleSubmit(values => onSave(values, false))().then(() => {
                          const appId = params?.appId;
                          const collectionId = params?.collectionId;
                          const pageId = page?.id;
                          navigate(`/buildx/page-builder/${appId}/${collectionId}/${pageId}/`);
                        });
                      }}
                    >
                      Open Page Builder
                    </Button>
                  </>
                ) : layoutValue === "custom" ? (
                  <p>Save the page first to be able to edit the page custom layout</p>
                ) : null}
              </Box>
            </FormControl>
          </Grid>
        </Container>
      )}

      {tabValue == 2 && (
        <Container maxWidth={"md"}>
          <ItemsPropertiesAccordion
            expanded={accordionState.modalConfig}
            onChange={() => handleAccordionChange("modalConfig")}
            label='Dirty Modal'
            isAdmin={true}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} pt={2}>
                <BXSwitch control={control} name={"info.preventdefaultModalBrowser"} label={"Customize Default Dirty Modal"} />
                {watch(`info.preventdefaultModalBrowser`) && (
                  <Grid item xs={12} pt={2}>
                    <BXSwitch
                      control={control}
                      name={"info.customModalType"}
                      label={watch("info.customModalType") ? "Custom View" : "Custom Message"}
                    />

                    {watch("info.customModalType") === false && (
                      <Grid item xs={12}>
                        <BXInput
                          control={control}
                          name={"info.customMessage"}
                          error={!!(errors.info as any)?.customMessage}
                          helperText={(errors.info as any)?.customMessage?.message}
                          fullWidth
                          label={"Custom Message"}
                        />
                      </Grid>
                    )}

                    {watch("info.customModalType") === true && (
                      <Grid item xs={12}>
                        <BXInput
                          control={control}
                          name={"info.modalView"}
                          error={!!(errors.info as any)?.modalView}
                          helperText={(errors.info as any)?.modalView?.message}
                          select
                          fullWidth
                          label={"Modal View"}
                        >
                          {sharedViews?.map(view => (
                            <MenuItem key={view.id} value={view?.id}>
                              {view?.info?.name}
                            </MenuItem>
                          ))}
                        </BXInput>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>

          <ItemsPropertiesAccordion
            expanded={accordionState.pageScript}
            onChange={() => handleAccordionChange("pageScript")}
            label='Page Script'
            isAdmin={true}
          >
            <Grid container px={2} py={1} spacing={2}>
              <Grid item xs={12}>
                <BXInput
                  variant='outlined'
                  label={"Page Head Script"}
                  control={control}
                  multiline
                  minRows={3}
                  name={"info.pageScript.head"}
                  error={(errors as any)?.info?.pageScript?.head}
                />
              </Grid>
              <Grid item xs={12}>
                <BXInput
                  variant='outlined'
                  label={"Page Body Script"}
                  control={control}
                  multiline
                  minRows={3}
                  name={"info.pageScript.body"}
                  error={(errors as any)?.info?.pageScript?.body}
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => setPageScriptArgsModalOpen(true)} color='primary'>
                  Manage Page Script Args
                </Button>
                <BXModal
                  open={!!pageScriptArgsModalOpen}
                  label={"Page Script Args"}
                  icon={<IconEditCircle />}
                  buttonProps={{ variant: "text", color: "secondary", startIcon: <IconEditCircle /> }}
                  title={"Manage Page Script Args"}
                  onClose={() => setPageScriptArgsModalOpen(false)}
                >
                  {(handleClose: Function) => {
                    return (
                      <ScriptArgsModal
                        onCancel={() => setPageScriptArgsModalOpen(false)}
                        onSave={updatedValues => {
                          setValue("info.pageScript.args", updatedValues.args);
                          setPageScriptArgsModalOpen(false);
                        }}
                        scriptArgs={watch("info.pageScript.args") || []}
                      />
                    );
                  }}
                </BXModal>
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </Container>
      )}
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <Button onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
            Save
          </Button>
        </DialogActions>
      </Grid>
    </Box>
  );
};
