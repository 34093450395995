import { Box, Modal, Tooltip } from "@mui/material";
import { IconSettings } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BXBuilderContextProvider } from "src/BXEngine/BXBuilderContext";
import useNavigationHandlers from "src/hooks/useNavigationHandlers";
import store from "src/store/store";
import AuthGuard from "src/utils/route-guard/AuthGuard";
import { FormBuilderEditor } from "src/views/pages/BuildX/FormBuilder";
import { LayoutBuilder } from "src/views/pages/BuildX/FormBuilder/LayoutBuilder";
import { PageBuilder } from "src/views/pages/BuildX/FormBuilder/PageBuilder";

const ViewLink = ({ pageOutlet, isPageView, collectionId, pageId, id, componentId, isModal, sharedPageId }: any) => {
  const showViewLinks = useSelector((state: any) => state?.endUser?.administration?.showViewLinks);
  const showComponentLinks = useSelector((state: any) => state?.endUser?.administration?.showComponentLinks);
  const { openViewModal } = useNavigationHandlers();
  const [isOpen, setIsOpen] = useState(false);
  if ((!showViewLinks && !componentId) || (componentId && !showComponentLinks)) return null;

  return (
    <>
      {isOpen && (
        <Modal open={isOpen} style={{ paddingTop: 88, backgroundColor: "black" }}>
          <AuthGuard forbid={["USER"]}>
            <BXBuilderContextProvider>
              {pageOutlet ? (
                <LayoutBuilder
                  params={{ appId: store.getState().buildxProvider.currentApp?.id, layoutId: id, componentId }}
                  onBackClick={() => setIsOpen(false)}
                />
              ) : isPageView ? (
                <PageBuilder
                  params={{ appId: store.getState().buildxProvider.currentApp?.id, collectionId, pageId, componentId }}
                  onBackClick={() => setIsOpen(false)}
                />
              ) : (
                <FormBuilderEditor
                  params={{
                    appId: store.getState().buildxProvider.currentApp?.id,
                    collectionId,
                    pageId: sharedPageId || pageId,
                    viewId: id,
                    componentId,
                  }}
                  onBackClick={() => setIsOpen(false)}
                />
              )}
            </BXBuilderContextProvider>
          </AuthGuard>
        </Modal>
      )}

      <Box
        onClick={() => {
          if (isModal) {
            const currentApp = store.getState().buildxProvider.currentApp;
            openViewModal(currentApp?.id, collectionId, pageId, id);
          } else {
            setIsOpen(true);
          }
        }}
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          right: 0,
          cursor: "pointer",
          zIndex: 1300,
          opacity: 0.3,
          "&:hover": {
            opacity: 1,
          },
        }}
      >
        <Tooltip title={isPageView ? "Page" : pageOutlet ? "Layout" : "View"} placement='top'>
          <IconSettings width={15} height={15} />
        </Tooltip>
      </Box>
    </>
  );
};

export default ViewLink;
