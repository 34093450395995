import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { CustomCheckboxProps } from "./types";

export const CustomCheckbox: FC<CustomCheckboxProps> = props => {
  const { groupName, label, checkboxValue, onChange, value, singleValue } = props;

  let singleValueFlag = false;
  if (singleValue && singleValue !== "false") {
    singleValueFlag = true;
  }
  const handleMultipleCheckboxChange = event => {
    const newValue = event.target.value;
    let updatedValues;

    if (event.target.checked) {
      updatedValues = [...(value || []), newValue];
    } else {
      updatedValues = (value || []).filter(v => v !== newValue);
    }

    onChange?.(updatedValues);
  };

  const handleSingleCheckboxChange = event => {
    onChange?.(event.target.value);
  };
  const handleChange = singleValueFlag ? handleSingleCheckboxChange : handleMultipleCheckboxChange;
  const handleChecked = singleValueFlag
    ? value === (checkboxValue?.toLowerCase() || label?.toLowerCase())
    : (value || []).includes(checkboxValue?.toLowerCase() || label?.toLowerCase());
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            name={groupName}
            value={checkboxValue?.toLowerCase() || label?.toLowerCase()}
            checked={handleChecked}
            onChange={handleChange}
          />
        }
        label={label}
      />
    </FormControl>
  );
};
