import React, { FC, MutableRefObject, useMemo } from "react";
import { Navigate } from "react-router-dom";

interface PermissionRedirectGuardProps {
  children: React.ReactNode;
  permissionPath: string;
  checkPermissions: any;
  appRoutesMap: MutableRefObject<any>;
  isLoggedIn?: boolean;
  currentApp?: any;
  fqdnApp?: boolean;
}

interface AuthorizedRoute {
  permissionPath: string;
  permissionRoute: string;
  isUnprotected: boolean;
}

/**
 * Guard component that redirects to the first authorized route if the user
 * doesn't have permission to access the current route
 */
export const PermissionRedirectGuard: FC<PermissionRedirectGuardProps> = ({
  children,
  permissionPath,
  checkPermissions,
  appRoutesMap,
  isLoggedIn,
  currentApp,
  fqdnApp,
}) => {
  // Check if user has permission for current route
  const isPermitted = checkPermissions([{ path: permissionPath, action: "VIEW" }]);

  // Get all authorized routes
  const authorizedRoutes = useMemo<AuthorizedRoute[]>(() => {
    const routes = Object.values(appRoutesMap.current) as any[];

    return routes.filter((route): route is AuthorizedRoute => {
      if (!route) return false;

      const hasPermission = checkPermissions([
        {
          path: route.permissionPath || "",
          action: "VIEW",
        },
      ]);

      return !route.isUnprotected && hasPermission && !!route.permissionRoute && !route.permissionRoute.includes("/:");
    });
  }, [appRoutesMap.current, checkPermissions]);

  // If not permitted and user is logged in, redirect to first authorized route
  if (!isPermitted && isLoggedIn && authorizedRoutes.length > 0) {
    // Use explicit type assertion
    const firstRoute = authorizedRoutes[0] as AuthorizedRoute;
    let redirectTarget = firstRoute.permissionRoute;

    // Determine if we need to add app slug prefix (organization mode)
    const pathPrefix = fqdnApp ? "" : `/${currentApp?.slug}`;

    // Only add prefix if it's not already there
    if (pathPrefix && !redirectTarget.startsWith(pathPrefix)) {
      redirectTarget = `${pathPrefix}${redirectTarget}`;
    }

    // Normalize the path to prevent double slashes
    redirectTarget = redirectTarget.replace(/\/+/g, "/");

    return <Navigate to={redirectTarget} replace />;
  }

  // If permitted or not logged in, render children
  return <>{children}</>;
};
