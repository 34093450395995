import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { joinObjects } from "hd-utils";
import { FC, useEffect } from "react";
import { useDrop } from "react-dnd";
import { AcceptsTargetTypes } from "src/BXEngine";
import { selectComponentById } from "src/features/builder/selectors";
import store from "src/store/store";
import { moveBoxFunction } from "./utils";

const ParentDragElement: FC<any> = ({ boxItemRefs, item, parentFlex, isSideMenu, isOverCurrentComponents, canDropItem, children }) => {

  const [{ isOverCurrent }, drop] = useDrop({
    accept: AcceptsTargetTypes,
    drop: (draggedItem: any, monitor) => {
      if (draggedItem || !draggedItem?.parentId || !draggedItem?.id) {
        //Read actual item from redux store without resolving
        const builderItem = selectComponentById(store.getState(), item?.id);

        const movedItem =
          draggedItem?.length > 0
            ? draggedItem.map(el => {
                const itemObj = selectComponentById(store.getState(), el?.id);
                return { ...itemObj, boxPosition: el?.boxPosition, level: el?.level };
              })
            : draggedItem;
        moveBoxFunction(movedItem, boxItemRefs?.current[item?.id], builderItem, monitor);
        return { changeLevel: "Box" };
      }
    },

    // canDrop: (item, monitor) => true,

    collect: monitor => ({
      // canDrop: !monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    if (!isSideMenu) {
      isOverCurrentComponents.current[item?.id] = isOverCurrent;
      canDropItem.current = Object.values(isOverCurrentComponents.current).some(val => val == true);
    }
  }, [isOverCurrent, item]);

  return (
    <Box
      height={"100%"}
      width={"100%"}
      ref={el => {
        boxItemRefs.current[item.id] = el;
      }}
      sx={
        joinObjects(
          {
            position: item?.config?.isDynamicHeight || item?.config?.isDynamicWidth ? "relative" : "absolute",
            backgroundColor: item?.config?.flexCanvas && !isOverCurrent ? "unset" : isOverCurrent ? "rgba(255, 222, 0, 0.2)" : "unset",
          },
          parentFlex && {
            position: "static",
            backgroundColor: isOverCurrent ? "rgba(255, 222, 0, 0.2)" : "unset",
            visibility: "inherit",
          }
        ) as any
      }
      data-testid={`box-parent-drag-element-${item?.props?.key}`}
    >
      {boxItemRefs && (
        <Box
          sx={{
            position: "relative",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
          ref={drop}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default ParentDragElement;
