import { removeChildFromParent, upsertComponents } from "src/features/builder/builderSlice";
import { selectComponentById, selectComponentParent } from "src/features/builder/selectors";
import store from "src/store/store";

export type DIRECTION = "top" | "bottom" | "middle";

const findBlock = (id: string) => {
  const state = store.getState();
  return selectComponentById(state, id); // Fetch block by id from normalized entities
};

const removeBlocks = (idsToRemove: string[]) => {
  const state = store.getState();

  //Remove the dragged blocks from their old parents
  idsToRemove.forEach(id => {
    const parentId = selectComponentParent(state, id);
    const childId = id;

    store.dispatch(removeChildFromParent({ parentId: parentId, childId: childId }));
  });
};

const filterOutNestedBlocks = (blocks: any[]) => {
  // Extract all block IDs to determine parent-child relationships
  const blockIds = blocks.map(block => block.id);

  // Filter out blocks that are children of other blocks in blocksToMove
  return blocks.filter(block => {
    const state = store.getState();

    // Recursive function to check if the block is a child of any other block
    const isChildOfOtherBlock = (blockId: string): boolean => {
      const parent = selectComponentParent(state, blockId);
      if (!parent) return false; // No parent, not a child
      if (blockIds.includes(parent)) return true; // Parent is part of blocksToMove
      return isChildOfOtherBlock(parent); // Recursively check up the hierarchy
    };

    return !isChildOfOtherBlock(block.id); // Include only blocks that are not children of others
  });
};

const insertBlocksAtPosition = (targetId: string, blocksToMove: any[], position: DIRECTION) => {
  const state = store.getState();

  // Find the target block
  const targetBlock = findBlock(targetId);
  if (!targetBlock) return;

  const targetBlockParentId = selectComponentParent(state, targetBlock.id);
  const targetBlockParent = targetBlockParentId ? selectComponentById(state, targetBlockParentId) : null;

  // Extract new block IDs
  const blocksToMoveIds = blocksToMove.map(block => block.id);

  // Handle reordering within the same parent
  const sourceParentId = selectComponentParent(state, blocksToMoveIds[0]);
  const isSameParent = sourceParentId === targetBlockParentId;

  if (position === "top" || position === "bottom") {
    const parentToUpdate = targetBlockParent;
    if (!parentToUpdate) return;

    const existingChildren = [...(parentToUpdate.children || [])];
    const targetIndex = existingChildren.indexOf(targetId);

    if (targetIndex === -1) return;

    // Find current indices of blocks to move
    const currentIndices = blocksToMoveIds
      .map(id => existingChildren.indexOf(id))
      .filter(index => index !== -1)
      .sort((a, b) => a - b);

    // Calculate the actual insert index based on current positions
    let insertIndex = position === "top" ? targetIndex : targetIndex + 1;

    // Adjust insert index if moving items from above to below
    if (isSameParent && currentIndices.length > 0) {
      const firstMovedIndex = currentIndices[0];
      if (firstMovedIndex < targetIndex && position === "bottom") {
        // When moving from top to bottom, adjust the insert position
        insertIndex--;
      } else if (firstMovedIndex > targetIndex && position === "top") {
        // When moving from bottom to top, no adjustment needed
        insertIndex = targetIndex;
      }
    }

    // Remove the moved blocks from their current position
    const filteredChildren = existingChildren.filter(id => !blocksToMoveIds.includes(id));

    // Insert the blocks at the calculated position
    filteredChildren.splice(insertIndex, 0, ...blocksToMoveIds);

    // Update the parent with the new children order
    const updatedParent = {
      ...parentToUpdate,
      children: filteredChildren,
    };

    // Update the moved blocks with the correct parent ID
    const movedBlocks = blocksToMove.map(block => ({
      ...block,
      parentId: parentToUpdate.id,
    }));

    // Dispatch the updates
    store.dispatch(upsertComponents([updatedParent, ...movedBlocks]));
    return;
  }

  // Handle middle drops (into containers)
  if (position === "middle") {
    const sourceBlock = findBlock(sourceParentId);
    const updatedChildren = [...(targetBlock.children || [])];

    // If moving between containers, just add to the target
    if (sourceParentId !== targetBlock.id) {
      // Update source container (remove items)
      if (sourceBlock) {
        const updatedSourceChildren = sourceBlock.children?.filter(id => !blocksToMoveIds.includes(id)) || [];
        const updatedSourceBlock = {
          ...sourceBlock,
          children: updatedSourceChildren,
        };

        // Update target container (add items)
        const updatedTargetBlock = {
          ...targetBlock,
          children: [...updatedChildren, ...blocksToMoveIds],
        };

        // Update moved blocks with new parent
        const movedBlocks = blocksToMove.map(block => ({
          ...block,
          parentId: targetBlock.id,
        }));

        // Update both containers and the moved blocks
        store.dispatch(upsertComponents([updatedSourceBlock, updatedTargetBlock, ...movedBlocks]));
      }
    } else {
      // If reordering within the same container
      const filteredChildren = updatedChildren.filter(id => !blocksToMoveIds.includes(id));
      filteredChildren.push(...blocksToMoveIds);

      const updatedBlock = {
        ...targetBlock,
        children: filteredChildren,
      };

      const movedBlocks = blocksToMove.map(block => ({
        ...block,
        parentId: targetBlock.id,
      }));

      store.dispatch(upsertComponents([updatedBlock, ...movedBlocks]));
    }
  }
};

// Check if the target block is a child of the dragged block
const checkChildrenRecursively = (blockId: string, targetId: string): boolean => {
  const block = findBlock(blockId);
  if (!block || !block.children) return false;
  if (block.children.includes(targetId)) return true;
  return block.children.some(childId => checkChildrenRecursively(childId, targetId));
};

export const moveBlocks = (draggedIds: string[], targetId: string, direction: DIRECTION) => {
  // Find the blocks to move by their ids
  const blocksToMove = draggedIds.map(id => findBlock(id)).filter(Boolean);

  // Prevent moving blocks into themselves
  if (draggedIds.includes(targetId)) {
    return;
  }

  // Prevent moving blocks into their children
  const hasTargetInChildren = blocksToMove.some(block => {
    return checkChildrenRecursively(block.id, targetId);
  });

  if (hasTargetInChildren) {
    return;
  }

  if (blocksToMove.length === 0) return;

  // Remove the dragged blocks from their current parents
  removeBlocks(draggedIds);

  // For middle drops, handle container insertion
  if (direction === "middle") {
    const targetBlock = findBlock(targetId);
    if (!targetBlock) return;

    // Update the moved blocks with new parent
    const movedBlocks = blocksToMove.map(block => ({
      ...block,
      parentId: targetId,
    }));

    // Add the blocks to the target container's children
    const updatedTargetBlock = {
      ...targetBlock,
      children: [...(targetBlock.children || []), ...draggedIds],
    };

    // Update both the container and moved blocks
    store.dispatch(upsertComponents([updatedTargetBlock, ...movedBlocks]));
    return;
  }

  // For top/bottom drops, use existing logic
  return insertBlocksAtPosition(targetId, blocksToMove, direction);
};
