import { entries, isEqual } from "lodash";
import { BXPermissionList } from "src/BXEngine/types";

export function checkPermissions(
  currentPermissions: BXPermissionList,
  requiredPermissions?: { path: string; action: string }[],
  isAllowed = false
): boolean {
  if (!requiredPermissions) {
    return true;
  }
  // Helper function to compare permission strings
  const comparePermissionStrings = ([key, value], requiredPath, enablePartialCheck = false) => {
    // Tokenization: O(n)
    const pathTokens = requiredPath.path.toLowerCase().split(".");
    // Tokenization: O(m)
    const keyTokens = key.toLowerCase().split(".");
    // Value Comparison: O(k)
    const matchingPermissionValue = value.includes(requiredPath.action) || value.includes("*");
    // if pathTokens and keyTokens are equal then return true
    if (isEqual(pathTokens, keyTokens)) return true;

    if (keyTokens.length < pathTokens.length && enablePartialCheck) {
      return keyTokens.every((keyToken, index) => keyToken === pathTokens[index] || keyToken === "*") && matchingPermissionValue;
    }

    // Loop through tokens
    for (let pi = 0; pi < pathTokens.length; pi++) {
      const pathToken = pathTokens[pi]; // O(1)
      const keyToken = keyTokens[pi]; // O(1)
      if (!keyToken) return false; // O(1)
      if (keyToken === pathToken) continue; // O(1)
      if (keyToken === "*" && matchingPermissionValue) return true; // O(1)
    }

    return false;
  };

  // if the item is inherently allowed then just check the denied list and skip allowed list
  if (!isAllowed) {
    if (entries(currentPermissions.ALLOW).some(entry => entry[0] === "*" && entry[1].includes("*"))) {
      isAllowed = true;
    } else {
      isAllowed = requiredPermissions.every(p => entries(currentPermissions.ALLOW).some(v => comparePermissionStrings(v, p)));
    }
  }
  // Check for universal permission: "*" -> ["*"]

  // Check if any required permissions are denied
  const isDenied = requiredPermissions.every(p => entries(currentPermissions.DENY).some(v => comparePermissionStrings(v, p, true)));

  // Overall time complexity: O(max(n, m, x, y))
  // where:
  //   n is the length of p.path.
  //   m is the number of keys in current permissions allow and deny.
  //   x is the number of permissions in currentPermissions.ALLOW.
  //   y is the number of permissions in currentPermissions.DENY.

  // function returns if all required permissions has permissions, and return the actions
  // it has no partial check, so its not checking if one of the required permissions is permitted
  // return true if all required actions permitted, and false otherwise
  return isAllowed && !isDenied;
}
