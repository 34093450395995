import { FormControlLabel, styled, Switch } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { CustomSwitchProps } from "./types";

const CustomSwitchStyled = styled(Switch)<{
  thumbColor?: string;
  trackColor?: string;
  disableEffect?: boolean;
  customIconOn?: any;
  customIconOff?: any;
}>(({ thumbColor, trackColor, disableEffect, customIconOn, customIconOff }) => {
  return {
    margin: 8,
    "& .MuiSwitch-thumb": {
      backgroundSize: "contain",
      width: 22,
      height: 22,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: thumbColor || "#fff",
    },
    "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
      backgroundImage: `url(${customIconOn?.url || customIconOn?.icon || ""})`,
      backgroundColor: thumbColor || "#fff",
    },
    "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
      backgroundImage: `url(${customIconOff?.url || customIconOff?.icon || ""})`,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${trackColor} !important`,
      opacity: 1,
    },
    "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
      backgroundColor: `${trackColor} !important`,
      opacity: 0.3,
    },
    ...(!disableEffect && {
      "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
        backgroundColor: `${trackColor} !important`,
        opacity: `1 !important`,
      },
    }),
  };
});

const IOSSwitch = styled(Switch)<{
  thumbColor?: string;
  trackColor?: string;
  disableEffect?: boolean;
  trackHeight?: string;
  trackWidth?: string;
  thumbSize?: string;
}>(({ thumbColor, trackColor, disableEffect, thumbSize, trackHeight, trackWidth }) => {
  const effectiveThumbSize = thumbSize || "22px";
  const effectiveTrackWidth = trackWidth || "44px";
  const effectiveTrackHeight = trackHeight || "26px";

  const computedMargin = (parseInt(effectiveTrackHeight, 10) - parseInt(effectiveThumbSize, 10)) / 2;

  const checkedTransform = `translateX(calc(${effectiveTrackWidth} - ${effectiveThumbSize} - ${computedMargin * 2}px))`;
  const labelMarginLeft = parseInt(effectiveTrackWidth, 10) / 16 + 8 + "px";

  return {
    width: effectiveTrackWidth,
    height: effectiveTrackHeight,
    padding: 0,
    margin: labelMarginLeft,
    "& .MuiSwitch-track": {
      width: effectiveTrackWidth,
      height: effectiveTrackHeight,
      borderRadius: parseInt(effectiveTrackHeight, 10) / 2,
      backgroundColor: `${trackColor} !important` || "#E9E9EA",
      opacity: 1,
    },
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: computedMargin,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: checkedTransform,
        color: "#fff",
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: `${effectiveThumbSize}`,
      height: `${effectiveThumbSize}`,
      backgroundColor: `${thumbColor} !important` || "#fff",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${trackColor} !important`,
      opacity: 1,
    },
    "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
      backgroundColor: `${trackColor} !important`,
      opacity: 0.3,
    },
    ...(!disableEffect && {
      "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
        backgroundColor: `${trackColor} !important`,
        opacity: `1 !important`,
      },
    }),
  };
});

const Android12Switch = styled(Switch)<{
  thumbColor?: string;
  trackColor?: string;
  customAndroidOn?: any;
  customAndroidOff?: any;
  disableEffect?: boolean;
  trackHeight?: string;
  trackWidth?: string;
  thumbSize?: string;
}>(({ thumbColor, trackColor, customAndroidOn, customAndroidOff, disableEffect, trackHeight, trackWidth, thumbSize }) => {
  const effectiveThumbSize = thumbSize ? `${thumbSize}` : "16px";
  const effectiveTrackWidth = trackWidth ? `${trackWidth}` : "60px";
  const effectiveTrackHeight = trackHeight ? `${trackHeight}` : "32px";

  const computedPadding = (parseInt(effectiveTrackHeight, 10) - parseInt(effectiveThumbSize, 10)) / 2;
  const checkedTransform = `translateX(calc(${effectiveTrackWidth} - ${effectiveThumbSize} - ${computedPadding * 2}px))`;

  const validAndroidIconOn = customAndroidOn ? `url(${customAndroidOn?.url || customAndroidOn?.icon})` : "none";
  const validAndroidIconOff = customAndroidOff ? `url(${customAndroidOff?.url || customAndroidOff?.icon})` : "none";
  const labelMarginLeft = parseInt(effectiveTrackWidth, 10) / 16 + 8 + "px";

  return {
    width: effectiveTrackWidth,
    height: effectiveTrackHeight,
    padding: 0,
    margin: labelMarginLeft,
    "& .MuiSwitch-track": {
      backgroundColor: `${trackColor} !important`,
      borderRadius: parseInt(effectiveTrackHeight, 10) / 2,
      width: "100%",
      height: "100%",
      opacity: 1,
      position: "relative",
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
      "&::before": {
        backgroundImage: validAndroidIconOn,
        left: 12,
      },
      "&::after": {
        backgroundImage: validAndroidIconOff,
        right: 12,
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: computedPadding,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: checkedTransform,
        ...(disableEffect
          ? {
              "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
                backgroundColor: `${trackColor} !important`,
                opacity: 1,
              },
            }
          : {
              "& + .MuiSwitch-track": {
                backgroundColor: `${trackColor} !important`,
                opacity: 1,
                border: 0,
              },
            }),
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: thumbColor || "white",
      boxShadow: "none",
      width: effectiveThumbSize,
      height: effectiveThumbSize,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${trackColor} !important`,
      opacity: 1,
    },
    "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
      backgroundColor: `${trackColor} !important`,
      opacity: 0.3,
    },
    ...(!disableEffect && {
      "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
        backgroundColor: `${trackColor} !important`,
        opacity: `1 !important`,
      },
    }),
  };
});

export const CustomSwitch: FC<CustomSwitchProps> = ({
  switchAppearance,
  customIconOn,
  customIconOff,
  customAndroidIconOff,
  customAndroidIconOn,
  children,
  textTypo,
  format,
  formatString,
  config,
  __config,
  metaData,
  mapValuesObject,
  info,
  pageId,
  appId,
  renderId,
  wordwrap,
  defaultValue,
  onChange,
  label,
  "data-bx-key": dataBxKey,
  trackColor,
  labelPlacement,
  thumbColor,
  disableEffect,
  thumbSize,
  trackWidth,
  trackHeight,
  ...restProps
}) => {
  const isLoading = restProps.loading;
  const defaultChecked = defaultValue === true || (typeof defaultValue === "string" && defaultValue.toLowerCase() === "true");
  const [checked, setChecked] = useState(defaultChecked);
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultValue]);

  const e = (event: any) => {
    if (isLoading) return;
    if (onChange) {
      onChange(event.target.checked, event);
      setChecked(event.target.checked);
    }
  };

  const SwitchComponent = switchAppearance === "iOS" ? IOSSwitch : switchAppearance === "Android" ? Android12Switch : CustomSwitchStyled;

  return (
    <FormControlLabel
      control={
        <SwitchComponent
          trackColor={trackColor}
          thumbSize={thumbSize}
          trackWidth={trackWidth}
          trackHeight={trackHeight}
          customIconOn={customIconOn}
          customIconOff={customIconOff}
          customAndroidOff={customAndroidIconOff}
          customAndroidOn={customAndroidIconOn}
          thumbColor={thumbColor}
          disableEffect={disableEffect}
          {...restProps}
          disabled={restProps.disabled}
          checked={checked}
          edge='end'
          onChange={e}
          sx={{
            "& .MuiFormControlLabel-label": {
              marginLeft: "8px",
            },
          }}
        />
      }
      label={label}
      required={false}
      labelPlacement={labelPlacement}
    />
  );
};
