import _ from "lodash";
import { BXApp } from "src/types/BXAppType";
import { compressData, decompressData } from "src/utils/services";

export function migrateTo1_0_5(_app: BXApp) {
  const app: any = {
    ..._app,
    appConfig: decompressData(_app?.appConfig),
    templateConfig: decompressData(_app?.templateConfig),
    upTemplateConfig: decompressData(_app?.upTemplateConfig),
  };

  traverseApp(app);
  _.set(app, "appVersion", "1.0.5");

  const compressedApp = {
    ...app,
    appConfig: compressData(app?.appConfig),
    templateConfig: compressData(app?.templateConfig),
    upTemplateConfig: compressData(app?.upTemplateConfig),
  };

  return compressedApp;
}

function traverseApp(obj, currentPath = "") {
  if (obj === null) {
    return;
  }

  if (Array.isArray(obj)) {
    obj.forEach((el, index) => {
      const path = `${currentPath}[${index}]`;
      if (typeof el === "object" || Array.isArray(el)) {
        traverseApp(el, path);
      }
    });
    return;
  }
  if (typeof obj === "object") {
    Object.keys(obj).forEach(key => {
      const path = currentPath ? `${currentPath}.${key}` : key;
      if (key === "actionSourceType" || key === "actionSourceViewType") {
        const keysToDelete = ["children", "config", "dataSource", "fallBack", "height", "info", "uploadConfig", "width"];
        if (obj[key] && typeof obj[key] === "object") {
          keysToDelete.forEach(innerKey => {
            if (innerKey in obj[key]) {
              delete obj[key][innerKey];
            }
          });
        }
      } else if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key]) && obj[key].hasOwnProperty("lg")) {
        const breakpoints = ["xs", "md", "xl"];
        breakpoints.forEach(bp => {
          if (obj[key].hasOwnProperty(bp) && obj[key][bp] === obj[key].lg) {
            delete obj[key][bp];
          }
        });
        traverseApp(obj[key], path);
      } else {
        traverseApp(obj[key], path);
      }
    });
    return;
  }
}
