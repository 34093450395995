import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import BXModal from "src/components/BXUI/Modal";
import { convertToProperCase, dereferenceOAS } from "src/utils/generalUtils";
import { v4 as uuid } from "uuid";

import { IconEye, IconX } from "@tabler/icons-react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { ComponentItemType } from "../../FormBuilder/types";

const contentValues = ["application/json", "application/xml", "application/x-www-form-urlencoded", "text/plain"];

const addNumberPercent = (str: string, value: number) => {
  if (!str) return;
  // remove the percent sign from the input string and parse the number
  const num = parseFloat(str.replace("%", ""));
  // add 10 to the number
  const newNum = num + value;
  // append the percent sign to the new number and return it as a string
  return `${newNum}%`;
};

const extractValues = (props: any): any => {
  const { path, method, oas, rootPath, level, type, isForm, modalName, columns, template, formBuilder, fillFormBuilder, selectedContent } =
    props;
  const result: any = {
    url: path,
    method,
    dataEntry: "",
    columns: [],
    action: {},
    template: {},
  };

  const schema =
    oas?.paths?.[path]?.[method]?.responses?.["200"]?.schema ||
    oas?.paths?.[path]?.[method]?.responses?.["200"]?.content?.[selectedContent]?.schema ||
    oas?.definitions?.[modalName] ||
    oas?.components?.schemas?.[modalName];

  const searchQuery = oas?.paths?.[path]?.[method].parameters
    ?.map((param: any) => (param?.in == "query" ? `${param?.name}={${param?.name}}` : null))
    .filter(Boolean)
    .join("&");

  if (searchQuery) {
    result.url = `${path}?${searchQuery}`;
  }

  // if (!schema) return result;

  if (schema?.properties) {
    result.dataEntry = Object.keys(schema?.properties)?.filter(key => schema?.properties?.[key]?.type === "array")?.[0];
  }

  if (props?.dataEntry) {
    result.dataEntry = props?.dataEntry;
  }
  // create columns
  if (type === "columns" && (schema?.type == "array" || schema?.properties?.[result.dataEntry]?.type == "array")) {
    const root = schema.properties?.[result.dataEntry]?.items?.properties || schema?.items?.properties;

    const startPath = _.get(root, rootPath?.trim().split(".").join(".properties.") || "")?.properties;
    if (!startPath && rootPath?.trim()) return alert("Path not found");
    extractDataFromSchema({ schema: startPath || root, result: result.columns, level, type: "column" });
  }

  if (type === "action") {
    const actionData = {
      source: result.url,
      method: method.toUpperCase(),
      body: {},
      statusMessages: [],
    };

    const actionSchema =
      oas?.paths?.[path]?.[method].requestBody?.content?.[type == "action" ? "application/json" : selectedContent]?.schema;

    if (actionSchema) {
      actionData.body = {
        ...extractDataFromSchema({
          schema: { ...actionSchema },
          level,
          type: "action",
          isForm,
          columns,
          template,
        }),
      };
    } else {
      oas?.paths?.[path]?.[method].parameters.forEach((param: any) => {
        if (param.in === "body") {
          actionData.body = {
            [param.name]: extractDataFromSchema({
              schema: { ...param.schema },
              level,
              type: "action",
              isForm,
              columns,
              template,
            }),
          };
        }
      });
    }

    actionData.body = JSON.stringify(actionData.body);

    actionData.statusMessages = _.map(oas?.paths?.[path]?.[method].responses, (value, key) => ({ key, value: value?.description })) as any;

    result.action = actionData;
  }

  const extractDataFromObject = (props: any) => {
    const { data, logic, key, oldKey = "", path = "", isParentArray = false } = props;
    if (Array.isArray(data)) {
      return data.forEach(item => {
        extractDataFromObject({ ...props, data: item, isParentArray: true });
      });
    }
    if (typeof data === "object") {
      return _.forOwn(data, (value, key) => {
        extractDataFromObject({
          ...props,
          data: value,
          logic,
          oldKey: `${oldKey && oldKey + "."}${key}`,
          key,
          path: `${oldKey && oldKey + (isParentArray ? "[0]" : "") + "."}${key}`,
        });
      });
    }
    logic?.({ oldKey, data, key, path });
  };

  if (type === "form") {
    if (!template) {
      result.template = extractDataFromSchema({
        schema: schema,
        level,
        type: "form",
        columns,
        template,
      });
    } else {
      const mapObject: any = {};

      extractDataFromObject({
        data: extractDataFromSchema({
          schema: schema,
          level,
          type: "form",
          columns,
          template,
        }),
        logic: ({ oldKey }: any) => {
          const key = oldKey.replace(/{|}|#.|#|$/gi, "");
          const values = key.split(".");
          mapObject[values[values.length - 1]] = key;
        },
      });

      let _template: any = {};
      try {
        _template = JSON.parse(template);
      } catch (e) {}

      extractDataFromObject({
        data: _template,
        logic: ({ data, key, oldKey, path }: any) => {
          let options = data?.replace(/{|}/gi, "")?.split(",");
          if (options) {
            options[3] = `{${mapObject[key]}}`;
            options.join(",");
            options = `{${options}}`;
            _.set(_template, path, options);
          }
        },
      });
      result.template = _template;
    }
  }

  if (type === "form-builder") {
    if (fillFormBuilder) {
      let topOffset = 0;
      if (formBuilder?.length) {
        const highestElement = formBuilder.reduce((prev: any, current: any) => {
          return parseFloat(prev.top?.xl) > current.top?.xl ? prev : current;
        });
        topOffset = highestElement?.top?.xl + 55;
      }
      result.formBuilderReplaced = extractDataFromSchema({
        schema: schema,
        level,
        type: "form-builder",
        columns,
        template,
      });
      result.formBuilderMerged = [
        ...(formBuilder || []),
        ...(extractDataFromSchema({
          schema: schema,
          level,
          type: "form-builder",
          columns,
          template,
          topOffset,
        }) || []),
      ];
    } else {
      const mapObject: any = {};

      extractDataFromObject({
        data: extractDataFromSchema({
          schema: schema,
          level,
          type: "form",
          columns,
          template,
        }),
        logic: ({ oldKey }: any) => {
          const key = oldKey.replace(/{|}|#.|#|$/gi, "");
          const values = key.split(".");
          mapObject[values[values.length - 1]] = key;
        },
      });
      result.formBuilder = formBuilder?.map((item: any) => ({
        ...item,
        props: { ...item?.props, defaultValue: `{${mapObject[item?.props?.key]}}` },
      }));
    }
  }

  return result;
};

const mapTypes: any = {
  date: "Date",
  boolean: "Boolean",
};

type ExtractDataProps = {
  schema: any;
  result?: any;
  level?: number;
  oldKey?: string;
  currentLevel?: number;
  type: string;
  isForm?: boolean;
  columns?: any[];
  template?: any;
  topOffset?: number;
};

const extractDataFromSchema = (props: ExtractDataProps) => {
  const { schema, result, level, oldKey = "", currentLevel = 1, type, isForm, columns, template: _template, topOffset } = props;
  let data: any = {};

  let mapObject: any = {};

  if (columns?.length) {
    columns.forEach(column => {
      if (!column?.source) return;
      const key = column.source.replace(/{|}|#.|#|$/gi, "");
      const values = key.split(".");
      mapObject[values[values.length - 1]] = key;
    });
  }

  const extractDataFromObject = ({ data, oldKey = "" }: any) => {
    if (Array.isArray(data)) {
      return data.forEach(item => {
        extractDataFromObject({ data: item });
      });
    }
    if (typeof data === "object") {
      return _.forOwn(data, (value, key) => {
        extractDataFromObject({ data: value, oldKey: `${oldKey && oldKey + "."}${key}` });
      });
    }

    const key = oldKey.replace(/{|}|#.|#|$/gi, "");
    const values = key.split(".");
    mapObject[values[values.length - 1]] = key;
  };

  let template;
  try {
    template = JSON.parse(_template);
  } catch (e) {}

  if (template) {
    extractDataFromObject({ data: template });
  }

  _.forOwn(schema, (value, key) => {
    if (value.type === "array" && type === "columns") return;
    if (value.type === "object") {
      if (level && currentLevel >= level) return;
      return extractDataFromSchema({
        ...props,
        schema: value.properties,
        oldKey: `${oldKey && oldKey + "."}${key}`,
        currentLevel: currentLevel + 1,
      });
    }

    if (type == "column") {
      result.push({
        id: uuid(),
        name: convertToProperCase(key),
        source: `{this.data.${oldKey && oldKey + "."}${key}}`,
        type: mapTypes[value.type] || "String",
      });
    }

    if (type === "action" || type === "form" || type === "form-builder") {
      const getNestedObjAndArrayValues = (value: any, oldKey = "", parentType?: string, _data: any = {}, formBuilderData: any = []) => {
        _.forOwn(value, (_value, _key) => {
          const newKey = `${oldKey ? oldKey + (parentType === "array" ? "[0]" : "") + "." : ""}${_key}`;
          if (_value?.type === "object") {
            _data[_key] = { ...getNestedObjAndArrayValues(_value?.properties, newKey, data[_key], formBuilderData) };
          } else if (_value?.type === "array") {
            _data[_key] = [getNestedObjAndArrayValues(_value?.items?.properties, newKey, _value?.type, data[_key], formBuilderData)];
          } else {
            if (type == "form-builder") {
              const lastElement = formBuilderData[formBuilderData?.length - 1];

              formBuilderData.push({
                id: uuid(),
                type: ComponentItemType.TextField,
                props: {
                  id: uuid(),
                  key: newKey,
                  label: convertToProperCase(_key),
                  defaultValue: "",
                  autoComplete: "off",
                  placeholder: convertToProperCase(_key),
                  InputProps: {
                    sx: {
                      height: "100%",
                    },
                  },
                  sx: {
                    width: "100%",
                    height: "100%",
                  },
                } as TextFieldProps,
                config: {
                  defaultWidth: 150,
                  defaultHeight: 50,
                  fixedWidth: false,
                  isPercentageHeight: false,
                  controlledComponent: true,
                  widthPx: {
                    xs: 434,
                    xl: 618,
                  },
                  height: {
                    xs: 50,
                    xl: 50,
                  },
                  widthPercentage: {
                    xs: "48.77663772691397%",
                    xl: "48.77663772691397%",
                  },
                  heightPx: {
                    xs: 48,
                    xl: 48,
                  },
                },
                left: {
                  xs: !lastElement ? 100 : formBuilderData?.length % 2 == 0 ? 10 : lastElement?.left?.xs + 20,
                  xl: !lastElement ? 100 : formBuilderData?.length % 2 == 0 ? 10 : lastElement?.left?.xl + 20,
                },
                top: {
                  xs: !lastElement ? topOffset || 50 : formBuilderData?.length % 2 == 1 ? lastElement?.top?.xs : lastElement?.top?.xs + 55,
                  xl: !lastElement ? topOffset || 50 : formBuilderData?.length % 2 == 1 ? lastElement?.top?.xl : lastElement?.top?.xl + 55,
                },
                leftPercentage: {
                  xs: !lastElement || formBuilderData?.length % 2 == 0 ? "1%" : addNumberPercent(lastElement?.leftPercentage?.xs, 49),
                  xl: !lastElement || formBuilderData?.length % 2 == 0 ? "1%" : addNumberPercent(lastElement?.leftPercentage?.xl, 49),
                },
                topPercentage: {
                  xs: !lastElement
                    ? "10%"
                    : formBuilderData?.length % 2 == 1
                    ? lastElement?.topPercentage?.xs
                    : addNumberPercent(lastElement?.topPercentage?.xs, 12),
                  xl: !lastElement
                    ? "10%"
                    : formBuilderData?.length % 2 == 1
                    ? lastElement?.topPercentage?.xl
                    : addNumberPercent(lastElement?.topPercentage?.xl, 12),
                },
              });
            } else {
              _data[_key] =
                type === "form"
                  ? !!_template
                    ? newKey
                    : `{${convertToProperCase(_key)},string,,{this.data.${_key}}}`
                  : `{${isForm ? "$.this.data" : "this.data."}${mapObject[_key] || newKey}}`;
            }
          }
        });
        return type == "form-builder" ? formBuilderData : _data;
      };
      if (key === "properties") {
        data = getNestedObjAndArrayValues(value);
      }
    }
  });
  return data;
};

const CustomPlugin =
  ({ handleClose, handleApiClick, handleModelClick, onlyModels, isJustView = true }: any) =>
  () => {
    return {
      components: {
        InfoContainer: function () {
          return null;
        },
        ...(onlyModels && {
          operations: function () {
            return null;
          },
        }),
      },
      wrapComponents: {
        operation:
          (Original: any, { React }: any) =>
          (props: any) => {
            return (
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flex: 1 }}>{React.createElement(Original, props)}</Box>
                {!isJustView && (
                  <Button
                    variant={"outlined"}
                    onClick={() => {
                      handleApiClick({ path: props.operation.get("path"), method: props.operation.get("method") });
                    }}
                    sx={{ color: "black", marginTop: "10px !important", marginInlineStart: "5px !important", alignSelf: "flex-start" }}
                  >
                    Select
                  </Button>
                )}
              </Box>
            );
          },
        ModelWrapper:
          (Original: any, { React }: any) =>
          (props: any) => {
            return (
              <Box sx={{ position: "relative" }}>
                {React.createElement(Original, props)}
                {!!props?.name && !isJustView && (
                  <Button
                    variant={"outlined"}
                    onClick={() => {
                      const modelName = props?.name;
                      handleModelClick(modelName);
                      // handleClose?.();
                    }}
                    sx={{ position: "absolute", right: 0, top: 8, color: "black" }}
                  >
                    Select
                  </Button>
                )}
              </Box>
            );
          },
      },
      statePlugins: {
        spec: {
          wrapSelectors: {
            allowTryItOutFor: () => () => false,
            servers: () => () => false,
            schemes: () => () => false,
            securityDefinitions: () => () => false,
          },
        },
      },
    };
  };

const extractBodyFromSchema = (obj: any): any => {
  if (obj?.type === "array") {
    let arr: any[] = [];
    arr.push(extractBodyFromSchema(obj?.items));
    return arr;
  } else if (obj?.type === "object") {
    let body: any = {};
    for (var k in obj?.properties) {
      body[k] = extractBodyFromSchema(obj?.properties[k]);
    }
    return body;
  } else return null;
};

const SwaggerUIModal: FC<{
  type?: string;
  onSuccess?: any;
  isForm?: boolean;
  onlyModels?: boolean;
  columns?: any[];
  template?: any;
  formBuilder?: any;
  swaggerId?: string;
  isJustView?: boolean;
  isOpen?: boolean;
  fillFormBuilder?: any;
  handleSwaggerClose?: any;
}> = props => {
  const {
    type,
    onSuccess,
    isForm,
    onlyModels,
    columns,
    template,
    isJustView = false,
    swaggerId = "",
    isOpen,
    handleSwaggerClose = () => {},
    formBuilder,
    fillFormBuilder,
  } = props;
  const [oasData, setOasData] = useState<any>();
  const [isSwaggerOpen, setIsSwaggerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [result, setResult] = useState<any>();
  const [rootPath, setRootPath] = useState("");
  const [selectedContent, setSelectedContent] = useState<string | null>(null);
  const [dataEntry, setDataEntry] = useState("");
  const [level, setLevel] = useState();
  const [swaggerUrl, setSwaggerUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedOperation, setSelectedOperation] = useState<
    { path: string; method: string; swaggerModalClose: any; body: any; fillFormBuilder: any; type: any } | undefined
  >();
  const [selectedModel, setSelectedModel] = useState<{ swaggerModalClose: any } | undefined>();

  useEffect(() => {
    if (isSwaggerOpen || isOpen) {
      axios
        .get(process.env.REACT_APP_HOST_API_KEY + `/api/admin/oas-def/${swaggerId}/url`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        })
        .then(res => {
          setSwaggerUrl(res?.data?.url);
          axios.get(res?.data?.url).then(res => {
            setOasData(dereferenceOAS(res.data));
            setLoading(false);
          });
        });
    }
  }, [isSwaggerOpen, isOpen]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApiClick = ({ path, method, swaggerModalClose }: any) => {
    // const { schema } = (oasData as any)?.paths?.[path]?.[method]?.parameters?.find((param: any) => param.in === "body") || {};
    const { schema } = oasData?.paths?.[path]?.[method].requestBody?.content?.["application/json"] || {};

    const body = extractBodyFromSchema(schema);

    setSelectedOperation({ path, method, swaggerModalClose, fillFormBuilder, body, type });
    if (type !== "columns") return handleConfirm({ path, method, swaggerModalClose, fillFormBuilder, body, type });
    handleOpen();

    if (schema?.properties) {
      setDataEntry(Object.keys(schema?.properties)?.filter(key => schema?.properties?.[key]?.type === "array")?.[0]);
    } else {
      setDataEntry("");
    }
  };

  const handleModelClick = ({ modalName, handleClose }: any) => {
    const result = extractValues({
      modalName,
      oas: oasData,
      rootPath,
      level,
      type,
      isForm,
      columns,
      template,
      formBuilder,
      fillFormBuilder,
      selectedContent,
    });
    if (!formBuilder?.length) {
      onSuccess?.({ ...result, formBuilder: result?.formBuilderReplaced });
      handleClose();
    } else {
      setSelectedModel({ swaggerModalClose: handleClose });
      setResult(result);
      setMergeModalOpen(true);
    }
  };

  const handleConfirm = (data?: any) => {
    const { path, method, swaggerModalClose, body } = data || selectedOperation!;
    const result = extractValues({
      path,
      method,
      oas: oasData,
      dataEntry,
      rootPath,
      level,
      type,
      isForm,
      columns,
      template,
      formBuilder,
      selectedContent,
    });
    const bodyObj = selectedOperation?.body || body;

    onSuccess?.(result, { path, method, body: bodyObj });
    handleClose();
    swaggerModalClose?.();
  };

  const handleMerge = () => {
    const { swaggerModalClose } = selectedModel!;

    onSuccess?.({ ...result, formBuilder: result?.formBuilderMerged });
    swaggerModalClose?.();
  };
  const handleReplace = () => {
    const { swaggerModalClose } = selectedModel!;

    onSuccess?.({ ...result, formBuilder: result?.formBuilderReplaced });
    swaggerModalClose?.();
  };

  const getMethodColor = (method?: string) => {
    let color = "#61affe";
    switch (method) {
      case "get":
        color = "#61affe";
        break;
      case "post":
        color = "#49cc90";
        break;
      case "put":
        color = "#fca130";
        break;
      case "head":
        color = "#9012fe";
        break;
      case "delete":
        color = "#f93e3e";
        break;
      case "options":
        color = "#0d5aa7";
        break;
      case "patch":
        color = "#50e3c2";
        break;
      default:
        color = "#61affe";
    }
    return color;
  };

  const contentData = oasData?.paths?.[selectedOperation?.path!]?.[selectedOperation?.method!]?.responses?.["200"]?.content;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose as any}
        closeAfterTransition
        keepMounted={false}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        fullWidth
        maxWidth={"sm"}
        // classes={{ paper: classes.paper }}
      >
        <DialogTitle display={"flex"} alignItems='center'>
          <Box flex={1} display={"flex"} alignItems='center'>
            <Typography marginInlineStart={1} id='modal-modal-title' fontSize={16} fontWeight={800}>
              Response mapping
            </Typography>
          </Box>
          <Box sx={{ cursor: "pointer", display: "flex" }} onClick={() => handleClose()}>
            <IconX />
          </Box>
        </DialogTitle>
        <Divider />

        <DialogContent sx={{ padding: 2.5, marginX: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} display={"flex"} alignItems='center'>
              <Typography
                padding={0.5}
                bgcolor={getMethodColor(selectedOperation?.method)}
                minWidth={60}
                color='white'
                fontWeight='bold'
                borderRadius={1}
                marginInlineEnd={0.5}
                textAlign='center'
              >
                {selectedOperation?.method?.toUpperCase()}
              </Typography>
              <Typography>{selectedOperation?.path}</Typography>
            </Grid>
            {selectedOperation?.type === "columns" && (
              <>
                <Grid item xs={12}>
                  <TextField fullWidth label={"Data Entry"} value={dataEntry || ""} onChange={e => setDataEntry(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label={"Response root"} onChange={e => setRootPath(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"number"}
                    fullWidth
                    label={"Depth"}
                    value={level}
                    defaultValue={1}
                    onChange={e => setLevel(e.target.value == "0" ? "1" : (e.target.value as any))}
                  />
                </Grid>
              </>
            )}
            {!!contentData && (
              <Grid item xs={12}>
                <Autocomplete
                  options={Object.keys(contentData)}
                  renderInput={params => <TextField type='text' placeholder={"Content"} label={"Content"} {...params} />}
                  value={selectedContent || null}
                  onChange={(event, value) => {
                    setSelectedContent(value);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Button onClick={() => handleConfirm()} autoFocus variant='contained'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={mergeModalOpen}
        onClose={() => setMergeModalOpen(false)}
        closeAfterTransition
        keepMounted={false}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        fullWidth
        maxWidth={"sm"}
        // classes={{ paper: classes.paper }}
      >
        <DialogTitle display={"flex"} alignItems='center'>
          <Box flex={1} display={"flex"} alignItems='center'>
            <Typography marginInlineStart={1} id='modal-modal-title' fontSize={16} fontWeight={800}>
              Do you want to merge or replace?
            </Typography>
          </Box>
          <Box sx={{ cursor: "pointer", display: "flex" }} onClick={() => setMergeModalOpen(false)}>
            <IconX />
          </Box>
        </DialogTitle>
        <Divider />

        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Button
            onClick={() => {
              handleMerge();
              setMergeModalOpen(false);
            }}
            autoFocus
            variant='contained'
          >
            Merge
          </Button>
          <Button
            onClick={() => {
              handleReplace();
              setMergeModalOpen(false);
            }}
            autoFocus
            variant='outlined'
          >
            Replace
          </Button>
        </DialogActions>
      </Dialog>
      <BXModal
        label={"Swagger"}
        title={"Swagger"}
        paperProps={{
          style: { backgroundColor: "white" },
        }}
        withoutLabel
        buttonProps={{
          onClick: () => setIsSwaggerOpen(true),
        }}
        onClose={handleSwaggerClose}
        icon={<IconEye size={16} />}
        open={isOpen}
      >
        {(handleClose: Function) => {
          if (loading) return <CircularProgress />;
          return (
            <SwaggerUI
              url={swaggerUrl}
              plugins={[
                CustomPlugin({
                  isJustView,
                  onlyModels,
                  handleClose,
                  handleApiClick: (props: any) => handleApiClick({ ...props, swaggerModalClose: handleClose }),
                  handleModelClick: (modalName: string) => handleModelClick({ modalName, handleClose }),
                }),
              ]}
              defaultModelsExpandDepth={onlyModels || isJustView ? undefined : -1}
            />
          );
        }}
      </BXModal>
    </>
  );
};

export default SwaggerUIModal;
