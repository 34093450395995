import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPaths, formBuilderPath, pageBuilderPath } from "src/config";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";

interface AdministrationSwitcherProps {
  onChange?: (event: { target: { checked: boolean } }) => void;
}

const AdministrationSwitcher: FC<AdministrationSwitcherProps> = ({ onChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAdministration, setIsAdministration] = useState(false);
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const allowedApps = useBuildxProviderValue("allowedApps");
  const loadingApps = useBuildxProviderValue("loadingApps");
  const { palette } = useTheme();

  useEffect(() => {
    if (
      adminPaths.includes(location.pathname) ||
      location.pathname.startsWith(formBuilderPath) ||
      location.pathname.startsWith(pageBuilderPath)
    ) {
      setIsAdministration(true);
      dispatch(setProviderState({ isAdministrationMode: true }));
    }
  }, []);

  useEffect(() => {
    setIsAdministration(isAdministrationMode);
  }, [isAdministrationMode]);

  return (
    <Box marginX={1}>
      {!loadingApps && (
        <Grid container alignItems='center' id='Administration-box'>
          <Grid xs container item alignItems='center' bgcolor={palette.background.default} padding={1.5} borderRadius={2.5}>
            <Typography flex={1} marginInlineEnd={1}>
              Admin
            </Typography>
            <Grid item>
              <Switch
                size='small'
                checked={isAdministration}
                onChange={e => {
                  setIsAdministration(e.target.checked);
                  dispatch(setProviderState({ isAdministrationMode: e.target.checked, isSwitchingMode: false }));
                  if (allowedApps?.length === 0 && !e.target.checked) {
                    navigate({ pathname: "/" });
                  }
                  onChange?.(e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AdministrationSwitcher;
