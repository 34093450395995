import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Icon, IconX } from "@tabler/icons-react";
import { Resizable } from "re-resizable";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";

export const MIN_DIMENSIONS = { width: 300, height: 300 };
export const MAX_DIMENSIONS = { 
  width: 800, 
  height: () => window.innerHeight - (VIEWPORT_PADDING * 2) // Dynamic max height based on viewport
};
export const VIEWPORT_PADDING = 10;

interface BuildxWindowProps {
  title: string;
  position: { x: number; y: number };
  onClose: () => void;
  onPositionChange: (position: { x: number; y: number }) => void;
  onDimensionsChange: (dimensions: { width: number; height: number }) => void;
  onDragStart?: () => void;
  dimensions: { width: number; height: number };
  children?: React.ReactNode;
  icon?: Icon;  // Add this line
  onResizeStart?: () => void;  // Add this line
}

type StyleClasses = {
  dragHandle: string;
  container: string;
  header: string;
  content: string;
};

type StyleProps = {
  isDragging: boolean;
  isResizing: boolean;
};

const useStyles = makeStyles((theme: any) => ({
  dragHandle: {
    position: "fixed",
    zIndex: 1250,
    cursor: 'initial', // Reset to initial
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    width: '100%',
    height: '100%',
    position: 'relative',
    minWidth: MIN_DIMENSIONS.width,
    minHeight: MIN_DIMENSIONS.height,
    resize: 'both',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
    cursor: 'default',  // Add this line
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: ({ isDragging }: StyleProps) => isDragging ? 'grabbing !important' : 'grab !important',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    '& .MuiIconButton-root': {
      padding: 4,
      color: 'inherit',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      }
    }
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: 'inherit',
    color: 'inherit',
    cursor: 'default',  // Add this line
  }
}), { name: 'BuildxWindow' }) as (props: StyleProps) => StyleClasses;

const BuildxWindow: FC<BuildxWindowProps> = ({ 
  title,
  icon: IconComponent,  // Add this
  position, 
  onClose,
  onPositionChange,
  onDragStart,
  dimensions,
  onDimensionsChange,
  children,
  onResizeStart  // Add this line
}) => {
  const [isResizing, setIsResizing] = useState(false);
  const [isDragFromHeader, setIsDragFromHeader] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: "buildx-window",
    canDrag: !isResizing && isDragFromHeader, // Only allow drag when from header and not resizing
    item: () => {
      onDragStart?.();
      setIsDragFromHeader(false);
      const element = elementRef.current;
      const rect = element?.getBoundingClientRect() || { width: 0, height: 0 };
      return { 
        type: "buildx-window", 
        position, 
        dimensions: {
          width: Math.ceil(rect.width),
          height: Math.ceil(rect.height + 2)
        }
      };
    },
    end: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      if (delta) {
        const newPosition = adjustToViewport(
          {
            x: position.x + delta.x,
            y: position.y + delta.y
          },
          dimensions
        );
        onPositionChange(newPosition);
      }
      setIsDragFromHeader(false);  // Reset on drag end
    },
    collect: monitor => ({
      isDragging: monitor.isDragging() && isDragFromHeader // Add isDragFromHeader check here
    })
  }), [position, dimensions, isResizing, onDragStart, isDragFromHeader]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    const header = headerRef.current;
    if (header && header.contains(e.target as Node)) {
      setIsDragFromHeader(true);
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragFromHeader(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseUp]);

  const classes = useStyles({ isDragging, isResizing });  // Fixed: passing both props

  const elementRef = useRef<HTMLDivElement | null>(null);
  const setRefs = useCallback((element: HTMLDivElement | null) => {
    elementRef.current = element;
    // Only set drag ref on the header element
    if (element?.firstElementChild) {
      dragRef(element.firstElementChild as HTMLDivElement);
    }
  }, [dragRef]);

  const adjustToViewport = (
    pos: { x: number; y: number },
    dims: { width: number; height: number }
  ) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    return {
      x: Math.max(VIEWPORT_PADDING, Math.min(pos.x, viewportWidth - dims.width - VIEWPORT_PADDING)),
      y: Math.max(VIEWPORT_PADDING, Math.min(pos.y, viewportHeight - dims.height - VIEWPORT_PADDING))
    };
  };

  const calculateMaxDimensions = () => ({
    width: Math.min(MAX_DIMENSIONS.width, window.innerWidth - position.x - VIEWPORT_PADDING),
    height: Math.min(MAX_DIMENSIONS.height(), window.innerHeight - position.y - VIEWPORT_PADDING)
  });

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const maxWidth = viewportWidth - position.x - VIEWPORT_PADDING;
      const maxHeight = viewportHeight - position.y - VIEWPORT_PADDING;

      // Only shrink if window is larger than available space
      if (dimensions.width > maxWidth || dimensions.height > maxHeight) {
        onDimensionsChange({
          width: Math.min(dimensions.width, maxWidth),
          height: Math.min(dimensions.height, maxHeight)
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dimensions, position, onDimensionsChange]);

  return (
    <Box 
      ref={setRefs}
      className={classes.dragHandle}
      onMouseDown={handleMouseDown}
      onMouseLeave={() => setIsDragFromHeader(false)}  // Reset if mouse leaves the window
      style={{
        top: position.y,
        left: position.x,
        opacity: isDragging ? 0.5 : 1,
        pointerEvents: 'auto',
      }}
    >
      <Resizable
        size={dimensions}
        onResizeStart={(e, direction, ref) => {
          setIsResizing(true);
          onResizeStart?.();  // Add this line
        }}
        onResizeStop={(e, direction, ref, delta) => {
          setIsResizing(false);
          const newDimensions = {
            width: dimensions.width + delta.width,
            height: dimensions.height + delta.height
          };
          onDimensionsChange(newDimensions);
          // Adjust position after resize if needed
          const adjustedPosition = adjustToViewport(position, newDimensions);
          if (adjustedPosition.x !== position.x || adjustedPosition.y !== position.y) {
            onPositionChange(adjustedPosition);
          }
        }}
        minWidth={MIN_DIMENSIONS.width}
        minHeight={MIN_DIMENSIONS.height}
        maxWidth={calculateMaxDimensions().width}
        maxHeight={calculateMaxDimensions().height}
        enable={{
          bottomRight: true,
          bottom: false,
          bottomLeft: false,
          left: false,
          right: false,
          top: false,
          topLeft: false,
          topRight: false
        }}
        onResize={(e, direction, ref) => {
          if (isResizing) {
            ref.style.cursor = 'se-resize';
          }
        }}
      >
        <Box className={classes.container}>
          <div ref={headerRef} className={classes.header}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              {IconComponent && <IconComponent size={18} />}
              <Typography variant="subtitle1">{title}</Typography>
            </div>
            <IconX 
              size={18}
              style={{ cursor: 'pointer' }}
              onClick={onClose}
            />
          </div>
          <div className={classes.content}>
            {children}
          </div>
        </Box>
      </Resizable>
    </Box>
  );
};

export default BuildxWindow;