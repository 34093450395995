import useTheme from "@mui/material/styles/useTheme";
import { Circle, GoogleMap, Marker } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import "src/views/pages/BuildX/FormBuilder/components/GoogleMapAutocomplete/GoogleMapAutocompleteStyles.css";
import markerImage from "../../../../../../assets/images/google-maps-icon.png";

const DEFAULT_CENTER = { lat: 34.052235, lng: -118.243683 };

interface MapComponentOptions {
  enableMapTypeControl?: boolean;
  enableCircleRadius?: boolean;
  enableFullscreenControl?: boolean;
  enableZoomControl?: boolean;
  enableStreetViewControl?: boolean;
  keepPinCentered?: boolean;
  circleRadius?: number;
  borderRadius?: string;
}

interface MapComponentProps {
  selectedLocation: any;
  options: MapComponentOptions;
  onChange: (location: { lat: number; lng: number } | null) => void;
  zoomLevel: number;
  isApiLoaded: boolean;
  setSelectedLocation: (location: { lat: number; lng: number } | null) => void;
}

const areCoordinatesEqual = (a: { lat: number; lng: number } | null, b: { lat: number; lng: number } | null) => {
  if (!a || !b) return false;
  return a.lat === b.lat && a.lng === b.lng;
};

export const MapComponent = ({
  selectedLocation,
  options,
  onChange,
  zoomLevel,
  isApiLoaded = false,
  setSelectedLocation,
}: MapComponentProps) => {
  const {
    enableMapTypeControl,
    enableCircleRadius,
    enableFullscreenControl,
    enableZoomControl,
    enableStreetViewControl,
    keepPinCentered,
    circleRadius,
    borderRadius = "0",
  } = options;

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const currentLocation = selectedLocation || DEFAULT_CENTER;
  const theme = useTheme();
  const mapCenter =
    selectedLocation && typeof selectedLocation.lat === "number" && typeof selectedLocation.lng === "number"
      ? selectedLocation
      : DEFAULT_CENTER;
  const isProgrammaticUpdate = useRef(false);
  const updateTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const lastPosition = useRef(selectedLocation);

  const handleDebouncedUpdate = useCallback(
    (newPos: { lat: number; lng: number }) => {
      if (!areCoordinatesEqual(newPos, lastPosition.current)) {
        setSelectedLocation(newPos);
        onChange?.(newPos);
        lastPosition.current = newPos;
      }
    },
    [onChange, setSelectedLocation]
  );

  const handleMapClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (!keepPinCentered && e.latLng) {
        const newPos = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setSelectedLocation(newPos);
        onChange?.(newPos);
      }
    },
    [keepPinCentered, onChange]
  );

  const handleMarkerDragEnd = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (!keepPinCentered && e.latLng) {
        const newPos = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setSelectedLocation(newPos);
        onChange?.(newPos);
      }
    },
    [keepPinCentered, onChange]
  );

  const handleDragEnd = useCallback(() => {
    if (keepPinCentered && mapInstance) {
      const newCenter = mapInstance.getCenter();
      if (newCenter) {
        const newPos = { lat: newCenter.lat(), lng: newCenter.lng() };
        setSelectedLocation(newPos);
        onChange?.(newPos);
      }
    }
  }, [keepPinCentered, onChange, mapInstance]);

  const handleLoad = (map: google.maps.Map) => {
    setMapInstance(map);
  };

  const handleUnmount = (_: google.maps.Map) => {
    setMapInstance(null);
  };

  useEffect(() => {
    if (!mapInstance || !keepPinCentered) return;

    const updateLocationFromCenter = () => {
      if (isProgrammaticUpdate.current) {
        isProgrammaticUpdate.current = false;
        return;
      }

      const newCenter = mapInstance.getCenter();
      if (newCenter) {
        const newPos = {
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        };

        if (updateTimeout.current) clearTimeout(updateTimeout.current);
        updateTimeout.current = setTimeout(() => {
          handleDebouncedUpdate(newPos);
        }, 150);
      }
    };

    const zoomListener = google.maps.event.addListener(mapInstance, "zoom_changed", updateLocationFromCenter);

    return () => {
      if (updateTimeout.current) clearTimeout(updateTimeout.current);
      google.maps.event.removeListener(zoomListener);
    };
  }, [mapInstance, handleDebouncedUpdate, keepPinCentered]);

  if (!isApiLoaded) {
    return <></>;
  }

  return (
    <div style={{ flex: 1, width: "100%", height: "100%" }}>
      <GoogleMap
        onLoad={handleLoad}
        onUnmount={handleUnmount}
        center={currentLocation}
        zoom={zoomLevel}
        onClick={!keepPinCentered ? handleMapClick : undefined}
        onDragEnd={keepPinCentered ? handleDragEnd : undefined}
        options={{
          mapTypeControl: enableMapTypeControl,
          fullscreenControl: enableFullscreenControl,
          zoomControl: enableZoomControl,
          streetViewControl: enableStreetViewControl,
          gestureHandling: keepPinCentered ? "greedy" : "auto",
          center: selectedLocation?.lat !== undefined && selectedLocation?.lng !== undefined ? selectedLocation : mapCenter,
        }}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          borderRadius: `${borderRadius}px`,
        }}
      >
        {/* Add Marker  */}
        {keepPinCentered ? (
          <div style={{ left: "50%", marginLeft: -24, marginTop: -48, position: "absolute", top: "50%" }}>
            <img style={{ height: 64, width: 64 }} src={markerImage} alt='' />
          </div>
        ) : (
          selectedLocation && (
            <Marker
              position={selectedLocation}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
              icon={{
                url: markerImage,
                scaledSize: new window.google.maps.Size(64, 64),
                anchor: new window.google.maps.Point(32, 32),
              }}
            />
          )
        )}

        {/* Add Circle if radius is provided */}
        {enableCircleRadius && circleRadius && (
          <Circle
            center={selectedLocation || DEFAULT_CENTER}
            radius={Number(circleRadius)}
            options={{
              fillColor: theme.palette.primary.main,
              strokeColor: theme.palette.primary.main,
              fillOpacity: 0.2,
              strokeOpacity: 0.5,
              strokeWeight: 2,
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};
